import React, { Component } from 'react'
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import axios from 'axios';
import {OverlayTrigger,Popover} from 'react-bootstrap';
import queryString from 'query-string';
import Logistics from './Logistics';
import Staff from './Staff';
import {Modal,Button} from 'react-bootstrap';
import MarketingsForm from './MarketingsForm';
import ReturnoninvestmentsForm from './ReturnoninvestmentsForm';
import Exhibitorlogo from '../assets/exhibitor_logo.png';
import PosteventForm from './PosteventForm';
import { NavLink } from 'react-router-dom';
import EventsForm from './EventsForm';
import VendorsForm from './VendorsForm';
import BudgetsForm from './BudgetsForm';
import { Events } from '../models/Events';
import { CompleteEvent } from './CompleteEvent';

export class EditEvent extends Component {
    constructor(props){
        super(props);
          this.state = {
            step: 0,
            current_step: 2,
            eventname:'Event Info',
            eventid : '',
            eventdate:'',
            enddate:'',
            welcome :false,
            body:true,
            popup:false,
            sowpop:false,
            popstep:0,
            formObj : Events,
            EditformObj:[],
            complete:false,
            notcomplete:true,
            
          };  
          this.eventStep=this.eventStep.bind(this);
          this.eventStepBack=this.eventStepBack.bind(this);  
    }
    componentDidMount(){
        console.log(this.state.step);
        console.log(this.state.step,this.state.current_step);
            let url = this.props.location.search;
            let params = queryString.parse(url); 
            this.setState({eventid:params.id});
            console.log(params.cstep);
            let st=parseInt(params.cstep);
            this.setState({step:st});
            console.log(st);
            axios.post(base_url+'events/eventid/',{eventid:params.id, accountid:this.props.user.accountid},  this.props.user.header )
            .then(res =>{
                console.log(res);
                if(res.data.length > 0){
                // console.log(res.data);
                 var temp=res.data[0];
                 var temp1=this.formatDate(temp.start_date);
                 var stp= parseInt(temp.step);
                this.setState({popstep:stp});
                 this.setState({eventname:temp.eventname, eventdate:temp1 ,current_step:stp,enddate:temp.end_date});
                }else{
                    this.props.history.push('/app/dashboard');
                }
            }).catch(function (error) { console.log(error); });      

    }
    formatDate = (dt) =>{
        const date = new Date(dt);
        const month = date.toLocaleString('default', { month: 'long' });
        return month+' '+ date.getDate()+', '+date.getFullYear();
        

    }  
    backToLogin = () => {
        this.props.history.push('/login');
    }
    updateStep =(id,cstep)=>{
        this.props.history.push('/app/editevent/?id='+id+'&cstep='+cstep); 
	}
    
    showPopup = () =>{
        if(this.state.welcome === true){
            this.setState({Popover:true});
        }
    }
   
    eventStep=(evname,evdate,endate,evid)=>{
       let step = this.state.step + 1;
       if(step > 6){
       let d1= new Date();
       let d2= new Date(this.state.enddate);
       if(d1 <= d2){
          // console.log(d1);
          // console.log(d2);
           this.setState({welcome: true,body:false});
           return;
       }
       }
       this.setState({ step:step,enddate:endate,current_step:step });
       

       if(step <= 2){
           var temp=this.formatDate(evdate);
        this.setState({eventname:evname, eventdate:temp, enddate:endate,eventid:evid});
       }

       if(step >= this.state.current_step){
        this.setState({current_step:step});
        axios.post(base_url+'events/updatestep', {step:step, eventid:evid }, this.props.user.header )
        .then(res =>{
       // console.log(res);   
        }).catch(function (error) { console.log(error); });  
       // console.log(this.state.step);

       }
       this.updateStep(evid,step);
      // this.props.history.push('/app/editevent/?id='+evid+'&cstep='+step); 
      
      }
    eventStepBack=()=>{
        let step = this.state.step - 1;
        this.setState({ step:step});
       // console.log(this.state.step);
       this.updateStep(this.state.eventid,step);
       //this.props.history.push('/app/editevent/?id='+this.state.eventid+'&cstep='+step); 
    }
    set_Step=(stp)=>{
        this.setState({step:stp});
        if( this.state.step-1 < 6){
        this.setState({body:true,welcome: false});
        }
        this.updateStep(this.state.eventid,stp);
       // this.props.history.push('/app/editevent/?id='+this.state.eventid+'&cstep='+stp); 
        //console.log(this.state.step);
    }
    
    openPopoverModal = () =>{
        let d1= new Date();
        let d2= new Date(this.state.enddate);
         if(this.state.welcome === true || (this.state.popstep == 6 && d1<=d2)){
        this.setState({sowpop:!this.state.sowpop});
         }
    }
  

      render_menu(stp,label){
        const popover = (
			<Popover id="popover-basic">
			  <Popover.Content>
              <strong>Complete After The Event Date Ends.</strong>
			  </Popover.Content>
			</Popover>
		  );
    
       
             if(stp == this.state.step){
                return(
                   <div className="btnstyleActive" onClick={()=>{this.set_Step(stp)}}>
                    <span className="circleBtnActive">{stp}</span><span className="textActive">{label}</span>
                   </div> 
                );
             }else if(stp <= this.state.current_step){
                return(
                   <div className="btnstyle" onClick={()=>{this.set_Step(stp)}}>
                       <span className="circleBtnActive">{stp}</span><span className="textActive">{label}</span>
                   </div>  
                );
             }else{
            
                 return(
                    <div className="btnstyle" onClick={this.openPopoverModal}>
                        <span className="circleBtn">{stp}</span><span >{label}</span>
                    </div>
                   );
            }
            
      }
    render_menus(){
        return(
            
            <div className="nav flex-column"  aria-orientation="vertical">
             {this.render_menu(1,'Event Info')}
             {this.render_menu(2,'Logistics & Documents')}
             {this.render_menu(3,'Staff')}
             {this.render_menu(4,'Marketing')}
             {this.render_menu(5,'Vendors')}
             {this.render_menu(6,'Budget')}
             {this.render_menu(7,'ROI Report')}
             {this.render_menu(8,'Post Event')}
             
             </div>
        )
    }
    render_body(){
        switch(this.state.step) {
             case 1: return (<EventsForm parentObject={this}  header={this.props.user.header}  eventid={this.state.eventid} eventname={this.state.eventname} enddate={this.state.enddate} eventdate={this.state.eventdate} eventAction='edit' />);
             case 2: return (<Logistics parentObject={this}  header={this.props.user.header}  eventid={this.state.eventid} enddate={this.state.enddate} eventAction='edit' />);
             case 3: return (<Staff parentObject={this}  header={this.props.user.header}  eventid={this.state.eventid} enddate={this.state.enddate} eventAction='edit'/>);
             case 4: return (<MarketingsForm parentObject={this}  header={this.props.user.header}  eventid={this.state.eventid} enddate={this.state.enddate} eventAction='edit'/>);
             case 5: return (<VendorsForm parentObject={this}  header={this.props.user.header}  eventid={this.state.eventid} enddate={this.state.enddate} eventAction='edit' />);
             case 6: return (<BudgetsForm parentObject={this}  header={this.props.user.header}  eventid={this.state.eventid}enddate={this.state.enddate}  eventAction='edit'/>);
             case 7: return (<ReturnoninvestmentsForm parentObject={this}  header={this.props.user.header}  eventid={this.state.eventid} enddate={this.state.enddate} eventAction='edit' />);
             case 8: return (<PosteventForm parentObject={this}  header={this.props.user.header}  eventid={this.state.eventid} enddate={this.state.enddate} eventAction='edit' />);
            }
    }


    render() {
        return (
            <div className="container-fluid">
                <br/>
                <div className="d-flex row  flex-shrink">
                    <div className="p-2 col-md-3"><NavLink to="/app/dashboard"><img  src={Exhibitorlogo} alt="Logo" /></NavLink></div>
                    <div className="p-2 eventalign col-sm-6"><div className="pluscolor"><b>{this.state.eventname}</b></div></div>
                    <div className="p-2 eventalign col-sm-3"><div className="pluscolor"><b>{this.state.eventdate}</b></div></div>
               </div>
                <hr/> 
                <div className="d-flex row">
                     <div className="p-2 col-md-3">{this.render_menus()}</div>
                     {this.state.welcome?<div className="p-2 col-md-9"><CompleteEvent/></div>:null}
                     {this.state.body?<div className="p-2 col-md-9">{this.render_body()}</div>:null}
                </div>
                <Modal show={this.state.sowpop} onHide={this.openPopoverModal}  size="sm"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered>
                <Modal.Body>
                <br/><br/>
                <div className="d-flex justify-content-center row">
              <h6>  <strong>Complete After The Event Date Ends.</strong> </h6> 
              <br/><br/>
              <br/>
                </div>
                <div className="d-flex justify-content-center row">
               <Button variant="primary" onClick={this.openPopoverModal}>OK</Button>
               </div>
                </Modal.Body>
              </Modal>
             </div>
        )
    }
    
}


const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(EditEvent);