///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Dashboard from './Dashboard';
import Test from './Test';
/*
include the component forms you have created these are sample created for other projects
*/
import EventsForm from '../components/EventsForm';
import HotelsForm from '../components/HotelsForm';
import TasksForm from '../components/TasksForm';
import Addevent from '../components/Addevent';
import EditEvent from '../components/EditEvent';
import EditEventDashboard from '../components/EditEventDashboard';
import AdminForm from '../components/AdminForm';
import  Accounts  from '../components/Accounts';
import UsersForm from '../components/UsersForm';


const AppLayout = ({ match }) =>(
  <div>
    <Header />
    <main>
      <Switch>

        <Route path={`${match.path}`} exact component={Dashboard} />
        <Route path={`${match.path}/addevent`} component={Addevent} /> 
        <Route path={`${match.path}/editevent`} component={EditEvent} /> 
        <Route path={`${match.path}/account`} component={Accounts} /> 
        <Route path={`${match.path}/profile`} component={UsersForm} />   
        <Route path={`${match.path}/admin`} component={AdminForm} /> 
        <Route path={`${match.path}/editeventdashboard`} component={EditEventDashboard} /> 
        <Route path={`${match.path}/events`} component={EventsForm} />
        <Route path={`${match.path}/hotels`} component={HotelsForm} />
        <Route path={`${match.path}/tasks`} component={TasksForm} />
        <Route path={`${match.path}/test`} component={Test} />
        <Redirect to={`${match.url}`} />
      </Switch>
    </main>
	  <Footer />
  </div>
)

export default AppLayout;
