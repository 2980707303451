///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Returnoninvestments = {
	exhibiting_cost : '', 
	total_sales : '', 
	total_leads : '', 
	cost_per_leads : '', 
	total_number_meetings : '', 
	total_number_attendees : '', 
	total_media_impressions : '', 
	social_media_mentions : '', 
	media_contacts_link : '', 
	certifications : '', 
	slide_deck : '', 
	certificationsfile : '',
	slidedeckfile: '',
	totalexhibitorfile:'',
	totalleadfile:'',
	mediafile:'',
	filename2 :'',
	visiter_booth:'',
	other_exhibitors_list : '', 
	business_objective : '', 
	business_obj_achieved : '', 
	status : '', 
	eventsId : '', 
	notes : '',
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}