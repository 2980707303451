import React, { Component } from 'react'
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import axios from 'axios';
import { FaUserCircle,FaPlus,FaFilePdf,FaTrash,FaPen } from 'react-icons/fa';
import { Validators } from '../utils/Validators';
import queryString from 'query-string';
import { NavLink } from 'react-router-dom';
import Exhibitorlogo from '../assets/exhibitor_logo.png';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import '../styles/Styles.css';
import { Transactions } from '../models/transactions';
import { PdfRec } from '../utils/generateReceipt';

export class TransactionsForm extends Component {

    constructor(props){
        super(props);
          this.state = {
              formObj:Transactions,
              transactionList:[] 
          };     
    }
    componentDidMount(){
    //  console.log(this.props.accountid);
      axios.post(base_url+'transactions/listby/',{accountid:this.props.accountid},this.props.header)
      .then(res =>{
      console.log(res.data);
      this.setState({transactionList:res.data});
      }).catch(function (error) { 
        console.log(error);        
      }); 
      
    }
    
    render_transactionList(){
		if( this.state.transactionList != undefined && this.state.transactionList.length > 0){
		return this.state.transactionList.map((item, index) =>{
			let dt=new Date(item.date_created);
			var day = ("0" + dt.getDate()).slice(-2);
			var month = ("0" + (dt.getMonth() + 1)).slice(-2);
			var year = dt.getFullYear();
      let date= month+'-'+day+'-'+year;
		  return (
				<tr key={index} id={index}>
				<td>{item.id}</td>
        <td>{item.description}</td>
				<td>{date}</td>
				<td>${item.amount}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
        <FaFilePdf size="25px" color="blue" onClick={() =>this.createPdf(item.accountid)}/>
        </td>
				</tr>
		  ) 
		  }, this);
	  }
	}
  createPdf = (accountid) => {
    console.log(this.props.header)
		PdfRec.openPdf(accountid, this.props.header);
	}
    render() {
        return (
            <div className="container-fluid">
             <div className="d-flex row">
              <div className="p-2 col-md-8">
                <label className="label-custom pluscolor">Transaction Details</label>
                  <table className="table table-sm table-responsive-stack">
                      <thead className="thead">
                          <tr>
                              <td>OrderId#</td>
                              <td>Description</td>
                              <td>Billing Date</td>
                              <td>Amount</td> 
                          </tr>
                      </thead>
                      <tbody>
                      {this.render_transactionList()}
                      </tbody>
                  </table>
              </div> 
     </div>    
      </div>      
    
        )
    }
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(TransactionsForm);
