////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';
import { base_url } from '../constants/Constants';
import {Postevent } from '../models/Postevent';
import '../styles/Styles.css';
import StarRatings from "react-star-ratings";


class PosteventForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
			edit : true,
			showEdit : false,
			formObj : Postevent,
			permissions : Permissions,
			eventid : '',
			eventname :'',
			eventdate :'',
			starvalue :0,
			eventrepeate_yes:true,
			eventrepeate_no:false,
		}
	   
		this.onSubmit = this.onSubmit.bind(this);
		this.changeFollowups = this.changeFollowups.bind(this);
		this.changeActionitem = this.changeActionitem.bind(this);
		this.changeSucessReason = this.changeSucessReason.bind(this);
		this.changeRepetReason = this.changeRepetReason.bind(this);
		this.changeNotes = this.changeNotes.bind(this);
		this.changeRating = this.changeRating.bind(this);
		this.handleObjectChange=this.handleObjectChange.bind(this);
		this.deletefollowsup=this.deletefollowsup.bind(this);

	}

	componentDidMount(){
		axios.get(base_url+'postevent/listbyeventid/'+this.props.eventid,  this.props.user.header )
                .then(res =>{
				if(res.data.length > 0 ){
				  console.log(res.data);  
				var temp=res.data[0];
				var star=0;
				if(temp.ratings !== ''){
				 star=parseInt(res.data[0].ratings);
				}
				if(temp.repeat_event == "YES"){
					console.log(temp.repeat_event);
					this.setState({eventrepeate_yes:true,eventrepeate_no:false});
				   }else if(temp.repeat_event == "NO"){
					this.setState({eventrepeate_yes:false,eventrepeate_no:true});
				   }else{
					this.setState({eventrepeate_yes:false,eventrepeate_no:false});
					}
				 //console.log(star);
				  this.setState({ starvalue:star});
				  this.setState({ formObj:temp});
				}else{
					var temp = [];
					temp.push({id: 1,actions_items:"Follow Ups"});
					var tempForm =this.state.formObj;
					tempForm.followups=temp;
					this.setState({ formObj:tempForm});
				}
              }).catch(function (error) { console.log(error); });   
	}
	continue = (e) =>{
        this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.enddate,this.props.eventid); 	
    }
     back = (e) =>{
        this.props.parentObject.eventStepBack();
	}
	backToLogin = () => {
        this.props.history.push('/app');
    }

	changeFollowups = (e) => {
		let newformObj=this.state.formObj;
		var string = e.target.value;
		newformObj.followups= string.charAt(0).toUpperCase() + string.slice(1);
		this.setState({ formObj:newformObj });
	}
	changeActionitem = (e) => {
		let newformObj=this.state.formObj;
		newformObj.actions_items=e.target.value
		this.setState({ formObj:newformObj });
	}
	changeSucessReason = (e) => {
		let newformObj=this.state.formObj;
		var string = e.target.value;
		newformObj.event_success_reasons=string.charAt(0).toUpperCase() + string.slice(1);
		this.setState({ formObj:newformObj });
	}
	changeRepetReason = (e) => {
		let newformObj=this.state.formObj;
		newformObj.repeat_event=e.target.value
		this.setState({ formObj:newformObj });
	}
	changeNotes = (e) => {
		let newformObj=this.state.formObj;
		newformObj.notes=e.target.value
		this.setState({ formObj:newformObj }); 
	}
	addFollowsup = () =>{
		var follow=this.state.formObj.followups;
		var  max = Math.max.apply(null,  follow.map(item => item.id));
		var count=0;
        if(follow.length >0){
			var  max = Math.max.apply(null,  follow.map(item => item.id));
			count=max+1;
		}else{
			count=1;
		}
		var bList = this.state.formObj;
		bList.followups.push({id: count,actions_items:""});
		this.setState({formObj:bList});
	}
	handleObjectChange = (id, event) => {
		event.preventDefault();
		var bList = this.state.formObj;
		var string = event.target.value;
		const myRowIndex = bList.followups.findIndex((row) => row.id === id);
		bList.followups[myRowIndex].actions_items = string.charAt(0).toUpperCase() + string.slice(1);
		this.setState({ formObj:bList }); 
}
	changeRating = (newRating) => {
		var temp=this.state.formObj;
		temp.ratings=newRating;
		this.setState({starvalue:newRating});
		//this.setState({formObj:temp});
		console.log(temp.ratings);
	 };
	 repeateEventYes = () =>{
         this.setState({eventrepeate_yes: true,eventrepeate_no: false,});
	 }
	 repeateEventNo = () =>{
		this.setState({eventrepeate_yes: false,eventrepeate_no: true,});
	}
	repeatevent(){
		if(this.state.eventrepeate_yes == true){
			var temp=this.state.formObj;
			temp.repeat_event='YES';
			this.setState({formObj:temp});
		}else{
		 var temp=this.state.formObj;
		 temp.repeat_event='NO';
		 this.setState({formObj:temp});
		}
	 }

	
	render_formButton(){
        if(this.props.eventAction === 'edit'){
            return(
                <div className="d-flex justify-content-end  row">
				 <div className="p-2">
				  <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()} >Back</button>
				 </div>
				<div className="p-2">
				 <button type="button" className="btn btn-primary btn-block" onClick={() =>this.onSubmit()}>Complete</button>
			   </div>
               </div>
   
            )
        }else{
            return(
               <div className="d-flex justify-content-end  row">
				 <div className="p-2">
				  <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()} >Back</button>
				 </div>
				<div className="p-2">
				 <button type="button" className="btn btn-primary btn-block" onClick={() =>this.onSubmit()}>Complete</button>
			   </div>
               </div>
   
            )
        }
	}
	render_followsup(){
		if(this.state.formObj.followups !== undefined && this.state.formObj.followups !== ''){
		 return this.state.formObj.followups.map((item, index) =>{
			var display="invisiblefollow label-custom";
			if(item.actions_items === ""){
				display="bobjective label-custom";
			} 
			 return (
				   <tr key={item.id} id={index}>
			     	<td className={display}><input type="text" id="action"  name="actions_items" value={item.actions_items} onChange={(e)=>{this.handleObjectChange(item.id, e)}}></input>
					 <span><FaTrash size="15px" color="red" onClick={() => {this.deletefollowsup(index)}} /></span></td>
				   </tr>
			 ) 
			 }, this);
		 
	   }
	 }
	 deletefollowsup = (i) =>{
		let temp=this.state.formObj;
		temp.followups.splice(i,1);
		this.setState({formObj:temp});
		let newform=this.state.formObj;
        newform.events_id=this.props.eventid;
			axios.post(base_url+'postevent/save', newform, this.props.user.header )
			.then(res =>{
				console.log(res.data.data);
				//this.setState({ formObj:res.data});
				axios.get(base_url+'postevent/listbyeventid/'+this.props.eventid,  this.props.user.header )
                .then(res =>{
				  console.log(res.data);
				 //this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.eventid); 
              }).catch(function (error) { console.log(error); });   
			}).catch(function (error) { console.log(error); });
	 }
	
	onSubmit(){
		this.repeatevent();
		let newform=this.state.formObj;
        newform.status='Active';
        newform.events_id=this.props.eventid;
			axios.post(base_url+'postevent/save', newform, this.props.user.header )
			.then(res =>{
				console.log(res.data.data);
				//this.setState({ formObj:res.data});
				axios.get(base_url+'postevent/listbyeventid/'+this.props.eventid,  this.props.user.header )
                .then(res =>{
				  console.log(res.data);
				  this.setState({formObj:res.data});
				   //this.history.push('/app');
				   //this.props.router.push('/app');
				  //return <Redirect to="/app" />;
				  if(this.props.history !== undefined) {
					  console.log(this.props.history);
				  this.props.history.push('/app'); 
				  }
				 //this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.eventid); 
              }).catch(function (error) { console.log(error); });   
			}).catch(function (error) { console.log(error); }); 
	}


	render() {
		const ratings=this.state.starvalue;
		//console.log(ratings);
		
         return (
			<div id="idpostevent" className="shadow-sm p-3 mb-5 bg-white rounded">
				 <div className="brdcolor" >
				 <div className="d-flex row">
				 <div className="p-2 col-md-12">	 
				 <table className="table table-bordered table-sm" >
						<thead className="thead">
							  <tr>
								  <td>Add Your Events Follow Ups </td>
								</tr>
							</thead>
							<tbody>
								{this.render_followsup()}
							</tbody>
						</table>
						<div className="p-2 col-md-12">
						<div className="d-flex justify-content-end row">
                        <div className="p-2">
                        <button type="button" className="btn btn-primary btn-block btn-sm" onClick={this.addFollowsup}>+Add</button>
                        </div>
                        </div>
						</div>
					</div>
					</div>
					  <div className="d-flex row">
                         <div className="p-2 col-md-12">
                             <label htmlFor="idevent_success_reasons" className="label-custom">What Would Have Made the Event More Successful?</label>
                             <textarea className="form-control form-control-sm" rows="1" cols="20" id="idevent_success_reasons" name="event_success_reasons" value={this.state.formObj.event_success_reasons} onChange={(e)=>{this.changeSucessReason(e)}} />
                         </div> 
                      </div> 
					  <div className="d-flex row">
                          <div className="p-2 col-md-12">
                            <label htmlFor="idrepeat_event" className="label-custom">Would You Repeat This Event?</label>
                           </div> 
                      </div> 
					<div className="d-flex">
					<div className="form-group row col-md-4">
					<div className="form-check">
					<input className="form-check-input" type="radio" name="optradio" id="eventyes" value="eventyes" checked={this.state.eventrepeate_yes} onChange={(e)=>{this.repeateEventYes(e)}}  />
					<label className="label-custom" htmlFor="eventyes">
					Yes
					</label>
					</div>&nbsp;&nbsp;&nbsp;
					<div className="form-check">
					<input className="form-check-input" type="radio" name="optradio" id="eventno" value="eventno" checked={this.state.eventrepeate_no} onChange={(e)=>{this.repeateEventNo(e)}} />
					<label className="label-custom" htmlFor="eventno">
					No
					</label>
					</div>
					</div>
					</div><br/>
				    <div className="d-flex row">
					      <div className="p-2 col-md-12">
						    <label htmlFor="reptevent" className="label-custom">Rate The Event  </label>	  
							</div>
						  <StarRatings
						   rating={this.state.starvalue}
						   starRatedColor="gold"
						   changeRating={this.changeRating}
						   numberOfStars={5}
						   name='rating'
						   size ={30}
						  />
					 </div>
					 
					 <div className="d-flex row">
                        <div className="col-md-12">  
                        {this.render_formButton()}
                        </div>
                    </div>
				</div>	
			</div>
		)}
		
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default withRouter(connect(mapToProps)(PosteventForm));
