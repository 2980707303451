////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
/*import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';*/
import { base_url } from '../constants/Constants';
import '../styles/Styles.css';
import queryString from 'query-string';

class UserActivate extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			
		}
	}

	componentDidMount(){				
		let url = this.props.location.search;
		let params = queryString.parse(url);	
		var paramid = {token:params.token}		
		 axios.post(base_url+'user/activate', paramid)
				.then(res => { alert("User Activated Successfully");
				this.props.history.push('/login');
			}).catch(function (error) { console.log(error); }); 
	}
	
	render() {
		
	 return (
		<div></div>
		)
	}	
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(UserActivate);
