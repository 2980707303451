///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Meetings = {
	eventsId : '', 
	firstname : '',
	staffname : '', 
	lastname : '',
	meeting_person :'', 
	location : '',
	meeting_person_firstname : '', 
	meeting_person_lastname : '', 
	start_datetime : '', 
	end_datetime : '', 
	calendarlink : '', 
	notes : '', 
	status : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}