///////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of Deemsoft, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////
import { base_url } from '../constants/Constants';
import axios from 'axios'

export const Validators = {

    name: function(val){
       // console.log(val);
        let ret='';
       if(val !== undefined){
            var pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); 
            if (pattern.test(val)) {
                ret = "Please only use standard alphanumerics";
            }else{
                ret = "";
            } 
            if(val.length >= 3) {
                ret = "";
            } else {
                ret = "Need to enter minimum of 3 Alphnumerics";
            }

       }else{
           ret = "It must have at least one Alphanumeric";
       }
      // console.log(ret);
       return ret;
    },


    email: function(val){
        if(val !== undefined){
           // var pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/); 
           var pattern =/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

          // var pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
          // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          // var pattern =new RegExp(/^[_a-z0-9-+]+(\.[_a-z0-9-+]+)*(\+[a-z0-9-]+)?@[a-z0-9-.]+(\.[a-z0-9]+)$/);
            if (pattern.test(val)) {
                return "";
            }else{
                return "Please enter correct email";
            }        
       }else{
           return "Please enter correct email address";
       }
    },

    number: function(val){
        let ret='';
        if(val !== undefined && val !== '' && val !== '0' && val !== '0.00'){       
         ret="";
        }else{
            ret ="Please Enter Number";
        }
       return ret;      
    },
    numbers: function(val){
        let ret='';
        if(val !== undefined && val !== ''){       
         ret="";
        }else{
            ret ="Please Total Sales.";
        }
       return ret;      
    },
    password: function(val){
        //var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        var ret="";
        if(val !== undefined && val !== ''){
             ret="";
        }else{
            ret="Please Enter password";  
        }
    return ret;
    },
cardnumber: function(val){
        //var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        var ret="";
        if(val !== undefined && val !== ''){
            var ccNum = val;
          //var cardnum = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
          var cardnum = /^[0-9]{14,16}$/;
          if (cardnum.test(ccNum)) {
         ret="";
         }else{
          ret='Please Provide Valid Card No.';
         }
        }else{
            ret="Please Enter Card Number";  
        }
    return ret;
    },

    date: function(val){
       // console.log(val);
        let ret='';
        let current=new Date("01/01/2020");
        let date=new Date(val);
        if(val !== undefined && val !== ''){
            let currdate=current.getTime();
            let resdate=date.getTime();
            if(currdate > resdate){
                ret="Date cannot be less than 01/01/2020!!";
            }  
         
        }else{
            ret ="Please enter date."
        }
       return ret;
    },
    
    month : function(val){
        // console.log(val);
         let ret='';
         if(val !== undefined && val !== ''){
            if(val.length !== 2){
            ret ="Please enter month  example:'02' ";
            }else{
             ret ="";
            }
         }else{
             ret ="Please enter Month."
         }
        return ret;
     },
    year : function(val){
        // console.log(val);
         let ret='';
         if(val !== undefined && val !== ''){
            if(val.length !== 4){
            ret ="Please enter year  example:'2021' ";
            }else{
             ret ="";
            }
         }else{
             ret ="Please enter Expiratons Year."
         }
        return ret;
     },
     cvc : function(val){
        // console.log(val);
         let ret='';
         if(val !== undefined && val !== ''){
            if(val.length >= 3){
                ret ="";
            }else{
                ret ="Please enter minimum 3 digit cvc ";             
            }
         }else{
             ret ="Please enter cvc.";
         }
        return ret;
     },
 

    phone: function(val){
        var pattern = new RegExp(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
        let ret='';
        if(val !== undefined && val !== ''){
            if (!pattern.test(val)) {
                ret= "Please enter valid phone number.";
            }else if(val.length != 14){
                ret="Please Enter Valid 10 Digits Phone Number.";
            }else{
             ret='';       

             }
        }else{
            ret="Please Enter Phone Number";    

        }
        return ret;      
       
    },
    select: function(val){
       // console.log(val);
        let ret='';
        if(val !== undefined && val !== ''){
          ret= "";
        }else{
            ret ="Please Select this feild."
        }
       return ret;
    },
    radio: function(val){
        // console.log(val);
         let ret='';
         if(val !== undefined && val !== ''){
           ret= "";
         }else{
             ret ="Please Select Events type ."
         }
        return ret;
     },
    zipcode: function(val){
       let ret='';
       if(val !== undefined){
            var pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); 
            if (pattern.test(val)) {
                ret = "Please only use standard alphanumerics";
            }else{
                ret = "";
            } 
            if(val.length >= 3) {
                ret = "";
            } else {
                ret = "Need to enter minimum of 3 Alphnumerics";
            }

       }else{
           ret = "It must have at least one Alphanumeric";
       }
      // console.log(ret);
       return ret;
    },
    time: function(val){
        let ret="";
        if(val !== undefined && val !== '' && val !== null){
                ret= ""; 
        }else{
            ret= "Please enter Time";
        }
    return ret;
       
    },
    bobjective:function(val){
        let ret="";
        console.log(val);
      if(val === true){
       ret="";
      }else{
        ret="please select any one of the business objective.";  
      }

        return ret;
    },
  state : function(val){
        // console.log(val);
         let ret='';
        if(val !== undefined){
             var pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); 
             if (pattern.test(val)) {
                 ret = "Please only use standard alphanumerics";
             }else{
                 ret = "";
             } 
             if(val.length >= 2) {
                 ret = "";
             } else {
                 ret = "Need to enter minimum of 2 Alphnumerics";
             }
 
        }else{
            ret = "It must have at least two Alphanumeric";
        }
       // console.log(ret);
        return ret;
     },
 
    website: function(val){
        const pattern = /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g;
        let ret='';
        if(val !== undefined && val !== ''){
            if (!pattern.test(val)) {
                ret= "Please enter Valid URL.";
            }else{
                ret= ""; 
            }
        }else{
            ret= "Please enter Valid URL.";
        }
    return ret;
       
    }
}
