import React, { Component } from 'react'
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import axios from 'axios';
import { FaUserTimes,FaUserCheck} from 'react-icons/fa';
import queryString from 'query-string';
import { NavLink } from 'react-router-dom';
import { setPage } from '../actions/PageAction';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { FaUserCircle,FaPlus,FaFilePdf } from 'react-icons/fa';
import Exhibitorlogo from '../assets/exhibitor_logo.png';
import { Users } from '../models/Users';
import '../styles/Styles.css';
import { PdfRec } from '../utils/generateReceipt';

export class AdminForm extends Component {

    constructor(props){
        super(props);
          this.state = {
              formObj:Users,
            eventid:'',
            accountid:'',
            usersList:[],
            transactionList:[],
            checkadmin:false,
            showmodal:false,
            showSuccessMsg:false,
          };   
    }
    componentDidMount(){
       //console.log(this.props.user.header);
       let url = this.props.location.search;
       let params = queryString.parse(url); 
       this.setState({accountid:params.id});
      axios.get(base_url+'users/list/', this.props.user.header )
      .then(res =>{
        //console.log(res.data);
      this.setState({usersList:res.data});
      }).catch(function (error) { 
        console.log(error);        
      }); 
        
      axios.post(base_url+'users/userid/',{accountid: this.props.user.accountid}, this.props.user.header )
      .then(res =>{
          //console.log(res.data[0].type);
          if(res.data[0].type === 'admin'){
              this.setState({checkadmin:true});
          }
          this.setState({userObj:res.data[0]});
   }).catch(function (error) { console.log(error); });   

    }
    gotoAccounts = () => {
      this.props.history.push('/app/account'); 
    }
    gotoAdmin = () => {
      this.props.history.push('/app/admin'); 
    }
    backToLogout = () => {
      this.props.history.push('/logout');
    }
      gotoProfile = () => {
      this.props.history.push('/app/profile/?id='+this.props.user.accountid); 
    }
    changeStatus = (id,status) =>{
      let temp=this.state.usersList;
      let newform=temp[id];
      //newform.accountid=this.props.accountid;
      newform.status=status;
      axios.post(base_url+'users/save', newform, this.props.user.header )
			.then(res =>{
           axios.get(base_url+'users/list/', this.props.user.header )
            .then(res =>{
                this.setState({usersList:res.data});
            }).catch(function (error) { console.log(error);});    
            }).catch(function (error) { console.log(error); });

    }

    chageCard=(i)=>{
      var date=new Date();
      date.setMonth(date.getMonth()+1);
      var y = date.getFullYear();
      var d=date.getDate();
      var m=date.getMonth()+1;
      var dt= (m <= 9 ? '0' + m : m) + '-' +(d <= 9 ? '0' + d : d) + '-' + y;
    
     axios.get(base_url+'transactions/id/'+i, this.props.header)
     .then(res =>{
       var trdata=res.data[0];
       axios.post(base_url+'creditcards/cardsstatus',{accountid:trdata.accountid}, this.props.header)
       .then(res =>{
         var cardobj=res.data.data[0];
        var encoded=res.data.data[0].card;
         axios.post(base_url+'creditcards/chargecreditcard', {card:encoded},this.props.header) 
         .then(res => {
           console.log(res);
           if(res.data.status === 'success'){ 
                trdata.status="success";
                axios.post(base_url+'transactions/save',trdata,this.props.header ).then(res =>{
                  axios.post(base_url+'transactions/listby/',{accountid:trdata.accountid},this.props.header)
                  .then(res =>{
                    this.setState({transactionList:res.data});
                  }).catch(function (error) { console.log(error);});
                }).catch(function (error) { console.log(error);});

                cardobj.charging_date = dt;
                cardobj.date_updated = new Date().toISOString();
                axios.post(base_url+'creditcards/save',cardobj,this.props.header ).then(res =>{}).catch(function (error) { console.log(error);});
                axios.post(base_url+'users/userid', {accountid:trdata.accountid}, this.props.user.header )
                .then(res =>{
                var newform=res.data[0];
                newform.status="Enabled";
                newform.charging_date = dt;
                newform.date_updated = new Date().toISOString();
                var emailobj={email: newform.email,firstname: newform.firstname};
                axios.post(base_url+'users/save', newform, this.props.user.header )
                .then(res =>{
                    console.log(emailobj);
                    axios.post(base_url+'creditcards/sendemail',emailobj, this.props.user.header )
                    .then(res =>{
                    }).catch(function (error) { console.log(error);});   
                    axios.get(base_url+'users/list/', this.props.user.header )
                      .then(res =>{
                          this.setState({usersList:res.data,showSuccessMsg:true});
                      }).catch(function (error) { console.log(error);});    
                      }).catch(function (error) { console.log(error); });
              }).catch(function (error) { console.log(error);}); 

           }else{
            console.log("failure");
           }
        }).catch(function (error) { console.log(error);}); 
      }).catch(function (error) { console.log(error);}); 
    }).catch(function (error) { console.log(error);}); 
    }
    showTransaction = (i) =>{
      axios.post(base_url+'transactions/listby/',{accountid:this.state.usersList[i].accountid},this.props.header)
      .then(res =>{
      this.setState({transactionList:res.data});
      this.setState({showmodal:true});
      }).catch(function (error) { 
        console.log(error);        
      }); 
    }

   render_Usertable(){
    if( this.state.usersList != undefined && this.state.usersList.length > 0){
      return this.state.usersList.map((item, index) =>{
        let dt1=new Date(item.date_created);
        var day = ("0" + dt1.getDate()).slice(-2);
        var month = ("0" + (dt1.getMonth() + 1)).slice(-2);
        var year = dt1.getFullYear();
        var h = dt1.getHours();
        var m =("0" + dt1.getMinutes()).slice(-2);
        var _time2 = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
        let time= month+'-'+day+'-'+year;
        return (
      		<tr key={index} id={index} onClick={()=>{this.showTransaction(index)}}>
         <td>{item.firstname}</td>
         <td>{item.lastname}</td>
         <td>{item.company}</td>
         <td>{item.email}</td>
         <td>{item.phone}</td>
         <td>{item.type}</td>
         <td>{time}</td>
         <td>{item.status}<span><FaUserTimes size="30px" color="red" onClick={()=>{this.changeStatus(index,"Disabled")}}/>&nbsp;&nbsp;
         <FaUserCheck size="30px" color="blue" onClick={()=>{this.changeStatus(index,"Enabled")}}/>&nbsp;&nbsp;</span>
         </td>
         </tr>
        ) 
         }, this);
    }

   }
   render_transactionList(){
		if( this.state.transactionList != undefined && this.state.transactionList.length > 0){
		return this.state.transactionList.map((item, index) =>{
      //console.log(item.accountid);
			let dt=new Date(item.date_created);
			var day = ("0" + dt.getDate()).slice(-2);
			var month = ("0" + (dt.getMonth() + 1)).slice(-2);
			var year = dt.getFullYear();
      let date= month+'-'+day+'-'+year;
      var show=false;
      if(item.status == "Pending" || item.status == "failed"){
        show=true;
      }
		  return (
				<tr key={index} id={index}>
				<td>{item.id}</td>
        <td>{item.description}</td>
				<td>{date}</td>
        <td>{item.status}</td>
				<td>${item.amount}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
        </td>
        <td> <FaFilePdf size="25px" color="blue" onClick={() =>this.createPdf(item.accountid)}/>
        {show?<button className="btn btn-primary btn-sm" onClick={()=>{this.chageCard(item._id)}}>Pay</button>:null}
        </td>
				</tr>
		  ) 
		  }, this);
	  }
	}
  createPdf = (accountid) => {
		PdfRec.openPdf(accountid, this.props.user.header);
	}

    render() {
        return (
            <div className="container-fluid">
            <br/>
            <div className="d-flex row  flex-shrink">
                <div className="p-2 col-md-3"><NavLink to="/app/dashboard"><img  src={Exhibitorlogo} alt="Logo" /></NavLink></div>
                <div className="p-2 eventalign col-sm-6"><div className="pluscolor"><b>Users Details</b></div></div>
                <div className="p-2 flex-grow-1"></div>
                <div className="p-2 dropdown"><span id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">< FaUserCircle size="35px"/></span>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  {this.state.checkadmin?<span  className="dropdown-item" onClick={() =>this.gotoAdmin()}><span>Admin</span></span>:null}
                  <span  className="dropdown-item" onClick={() =>this.gotoAccounts()}><span>Account</span></span>
                <span  className="dropdown-item" onClick={() =>this.gotoProfile()}><span >Profile</span></span>
                <div className="dropdown-divider"></div>
                <span className="dropdown-item" onClick={() =>this.backToLogout()} >Logout</span>
              </div>
            </div>
           </div>
            <hr/> 
            <div className="brdcolor" >
            <div className="d-flex row">
                <div className="p-2 col-md-12">
                  <table className="table table-bordered table-sm table-responsive-stack">
                      <thead className="thead">
                          <tr>
                              <td>First Name</td>
                              <td>Last Name</td>
                              <td>Company</td>
                              <td>Email</td>
                              <td>Phone</td>
                              <td>User Type</td>
                              <td>Created Date</td>
                              <td>Status</td>
                          </tr>
                      </thead>
	                	<tbody>
                      {this.render_Usertable()}
                        </tbody>
                  </table>
                </div>
                </div>
                <Modal size="lg" show={this.state.showmodal} onHide={()=>{this.setState({showmodal:false})}} >
				<Modal.Header closeButton>
				<Modal.Title>Transaction Details</Modal.Title>
				</Modal.Header>
					<Modal.Body>
          <div className="d-flex row">
          {this.state.showSuccessMsg ?
           <div className="p-2 col-md-12">
          <FlashMessage duration={5000} persistOnHover={true}>
                      {flashMessage("Payment Successful", 'Success')}
                      </FlashMessage>
                      </div> :null
                  }	
              <div className="p-2 col-md-12">

                  <table className="table table-sm table-responsive-stack">
                      <thead className="thead">
                          <tr>
                              <td>OrderId#</td>
                              <td>Description</td>
                              <td>Billing Date</td>
                              <td>Status</td>
                              <td>Amount</td> 
                              <td></td>
                          </tr>
                      </thead>
                      <tbody>
                      {this.render_transactionList()}
                      </tbody>
                  </table>
              </div> 
     </div>    
          </Modal.Body>
				<Modal.Footer>
					<Button variant="warning" onClick={()=>{this.setState({showmodal:false})}}> Close </Button>
				</Modal.Footer>
            </Modal>
           </div>
         </div>
        )
    }
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(AdminForm);
