///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Documents = {
	document : '', 
	filename : '',
	filetype : '',
	url : '', 
	userid : '', 
	password : '', 
	notes : '', 
	status : '', 
	events_Id : '',
	uploadType : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}