/////////////////////////////////////////////////
// Warning : Propeerty of Deemsoft, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
//////////////////////////////////////////////////////
import React, {Component} from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { base_url } from '../constants/Constants';
import {Events } from '../models/Events';
import { setPage } from '../actions/PageAction';
import Exhibitorlogo from '../assets/exhibitor_logo.png';
import '../styles/Styles.css';
import { FaUserCircle,FaPlus,FaFilePdf } from 'react-icons/fa';
import { PdfDoc } from '../utils/GeneratePdf';
import { Users } from '../models/Users';

class Dashboard extends Component {
	constructor(props){
		super(props);
		this.state = {
			tableText:'',
			message:'',
			msgType:'',
			formObj : Events,
			userObj : Users,
			checkadmin: false,
			eventsList : [],
		}
	}  
	componentDidMount(){
	//	console.log(this.props.user);
		axios.get(base_url+'events/list/'+this.props.user.accountid, this.props.user.header )
				.then(res =>{
					console.log(res);
					if(res.data !== undefined && res.data.length > 0){
					this.setState({ eventsList:res.data });
					}
			}).catch(function (error) { console.log(error); });  

			axios.post(base_url+'users/userid/',{accountid: this.props.user.accountid}, this.props.user.header )
            .then(res =>{
				console.log(res.data[0].type);
				if(res.data[0].type === 'admin'){
					this.setState({checkadmin:true});
				}
				this.setState({userObj:res.data[0]});
         }).catch(function (error) { console.log(error); });  
		
	} 
	formatDate = (dt) =>{
        const date = new Date(dt);
        const month = date.toLocaleString('default', { month: 'long' });
        return month+' '+ date.getDate()+', '+date.getFullYear();   
    }
	dashboard = () => {
		this.props.history.push('/app');      
	}
	addEvent = () => {
		this.props.history.push('/app/addevent');      
	}
	editEvent = (id,cstep) => {
		this.props.history.push('/app/editevent/?id='+id+'&cstep='+cstep); 
	}
	gotoProfile = () => {
		this.props.history.push('/app/profile/?id='+this.props.user.accountid); 
	}
	gotoAccounts = () => {
		this.props.history.push('/app/account'); 
	}
	gotoAdmin = () => {
		this.props.history.push('/app/admin'); 
	}
	backToLogout = () => {
		this.props.history.push('/logout');
	}
	createPdf = ( id ) => {
    	PdfDoc.openPdf(id, this.props.user.header);
	}
	render_eventsList(){
		if( this.state.eventsList !== undefined && this.state.eventsList.length > 0){
		for(var i=0;i<this.state.eventsList.length;i++){
		return this.state.eventsList.map((item, index) =>{
			var prp = parseInt((item.step/8)*100);
			var temp =this.formatDate(item.start_date);
		  return (
			<div key={item.id} id={index}>			
				  <div className="p-2">
				     <div className="addevent" >
							<div className="form-row">
								<div className="p-2 flex-grow-1" onClick={() =>this.editEvent(item.id,1)} ></div>
								<div className="p-2"><FaFilePdf onClick={() =>this.createPdf(item.id)} size="25px" color="red" /></div>
							</div >
							<div  onClick={() =>this.editEvent(item.id,1)}>
							<br/>
							<div className="form-row">
								<div className="col-md-1" ></div>
								<div className="col-md-7"><span className="wlcmcolor">{temp}</span></div>
								<div className="col-md-3" ></div>
							</div>
							<div className="form-row">
								<div className="col-md-1"></div>
								<div className="col-md-10"><span className="wlcmcolor" onClick={() =>this.editEvent(item.id,1)}><b>{item.eventname}</b></span></div>
							</div><br/>
							<div className="form-row">
								<div className="col-md-2"></div>
								<div className="col-md-6">
									<div className="progress">
										<div className="progress-bar" role="progressbar" style={{width: prp+'%' }} aria-valuenow={prp} aria-valuemin="0" aria-valuemax="100">{item.step}/8</div>
									</div>
								</div>
							</div>
								<div  className="p-2 flex-fill"></div>
								<div  className="p-2 flex-fill"></div>
								<div  className="p-2 flex-fill"></div>
							</div>	
						</div>	
					</div>
			  </div>

		  ) 
		  }, this); 
		}   
	  }
	}
 	render() {
	 setPage('/app');
    	return (
      <div className="container-fluid">
		  <br/>
		<div className="d-flex row">
			<div className="p-2 flex-grow-1"><span className="smalllogo"><img  src={Exhibitorlogo} alt="Logo" /></span></div>
			<div className="p-2 dropdown"><span id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">< FaUserCircle size="35px"/></span>
			<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
		    	{this.state.checkadmin?<span  className="dropdown-item" onClick={() =>this.gotoAdmin()}><span>Admin</span></span>:null}
			    <span  className="dropdown-item" onClick={() =>this.gotoAccounts()}><span>Account</span></span>
				<span  className="dropdown-item" onClick={() =>this.gotoProfile()}><span >Profile</span></span>
				<div className="dropdown-divider"></div>
				<span className="dropdown-item" onClick={() =>this.backToLogout()} >Logout</span>
			</div>
			</div>
		</div>
		<hr/>
		<div className="boxstyle">
		<div className="d-flex flex-wrap">
			<div className="p-2 " onClick={() =>this.addEvent()}>
			    <div className="addevent">
				    <br/><br/><br/>
			        <div className="d-flex row">
						<div className="d-flex col-sm-4"></div>
						<div className="d-flex justify-content-center col-md-4"  onClick={() =>this.addEvent()}><FaPlus className="pluscolor" size="35px" /></div>
						<div className="d-flex  justify-content col-md-4"></div>
			        </div> <br/>
		         	<div className="d-flex row">
						<div className="d-flex col-md-1"></div>
						<div className="d-flex justify-content-center col-md-10" onClick={() =>this.addEvent()} ><span className="wlcmcolor"><b>ADD NEW EVENT</b></span></div>
						<div className="d-flex col-md-1"></div>
			        </div>	    
		 	   </div>	
			</div>
			{this.render_eventsList()}
	    </div>	
		</div>
      </div>
    );
  }
}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Dashboard);