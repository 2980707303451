///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Events = {
	eventname : '', 
	accountid:'',
	start_date :'',
	end_date:'',
	organizer:'',
	eventsId : '', 
	phone : '', 
	email : '', 
	website : '',
	upload : '',
	filename:'',
	portal_url:'',
	userhost_id:'',
	userhost_password:'',
	event_type : '', 
	event_url : '', 
	event_login : '', 
	event_password : '', 
	virtual_url : '', 
	virtual_hostid : '', 
	virtual_host_pass : '', 
	virtual_user_pass : '', 
	meeting_url:'',
	virtual_passwd:'',
	virtual_starttime:'',
	virtual_endtime:'',
	address1 : '', 
	address2 : '', 
	city : '', 
	state : '', 
	country : '', 
	zipcode : '', 
	firstname : '', 
	lastname : '', 
	start_datetime : '', 
	end_datetime : '', 
	exhibitor_kit : '', 
	notes : '',
	bussinesobjective:[],
	status : '', 
	shared : '', 
	bobjective:'',
	step : '',
	id : '', 
	_id: '',
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
	
}