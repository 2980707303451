////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import {Modal,Button} from 'react-bootstrap';
import queryString from 'query-string';
import { Permissions } from '../models/Permissions';
import {Users } from '../models/Users';
import { FaUserCircle,FaPlus,FaFilePdf } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Exhibitorlogo from '../assets/exhibitor_logo.png';
import '../styles/Styles.css';


class UsersForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		
            formObj : Users,
            validations:{},
            dltconfirm : false,
            saveuser : false,
            checkadmin: false,
		
		}
	
        this.onSubmit = this.onSubmit.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.getFormattedPhoneNum = this.getFormattedPhoneNum.bind(this);
        this.validate = this.validate.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
	
    }


	componentDidMount(){
        let validtemp={};
		for (let key in Users) { validtemp[key] = ""; }
		this.setState({validations:validtemp});

		console.log(this.props.user.header);
        let url = this.props.location.search;
        let params = queryString.parse(url); 
        this.setState({accountid:params.id});
        
        axios.post(base_url+'users/userid/',{accountid: params.id}, this.props.user.header )
            .then(res =>{
				console.log(res.data[0]);
           this.setState({formObj:res.data[0]});
         }).catch(function (error) { console.log(error); });  

         axios.post(base_url+'users/userid/',{accountid: this.props.user.accountid}, this.props.user.header )
         .then(res =>{
             console.log(res.data[0].type);
             if(res.data[0].type === 'admin'){
                 this.setState({checkadmin:true});
             }
             this.setState({userObj:res.data[0]});
      }).catch(function (error) { console.log(error); });   

    }
    changeUser = (val,e) =>{
		let tempform = this.state.formObj;
		switch(val){
			case "firstname": { tempform.firstname = e.target.value; } break;
			case "lastname": { tempform.lastname = e.target.value; } break;
			case "company": { tempform.company = e.target.value; } break;
			case "phone": { tempform.phone = e.target.value; } break; 
            case "email": { tempform.email = e.target.value; } break; 
			case "address1": { tempform.address1 = e.target.value; } break; 
			case "address2": { tempform.address2 = e.target.value; } break; 
			case "city": { tempform.city = e.target.value; } break; 
			case "state": { tempform.state = e.target.value; } break; 
			case "zip": { tempform.zip = e.target.value; } break; 
		}

		this.setState({formObj:tempform});
  }
  validate = (val) =>{
    let tempval = this.state.validations;
    switch(val){
        case "firstname": { tempval.firstname =Validators.name(this.state.formObj.firstname); } break;
        case "lastname": { tempval.lastname = Validators.name(this.state.formObj.lastname); } break;
        case "company": { tempval.company = Validators.name(this.state.formObj.company); } break;
        case "phone": { tempval.phone = Validators.phone(this.state.formObj.phone); } break; 
        case "email": {tempval.email = Validators.email(this.state.formObj.email); } break; 
        case "address1": { tempval.address1 = Validators.name(this.state.formObj.address1); } break; 
    	case "address2": { tempval.address2 = Validators.name(this.state.formObj.address2); } break; 
        case "city": { tempval.city = Validators.name(this.state.formObj.city); } break; 
        case "state": { tempval.state = Validators.state(this.state.formObj.state); } break; 
        case "country": { tempval.country = Validators.name(this.state.formObj.country); } break; 
        case "zip": { tempval.zip = Validators.zipcode(this.state.formObj.zip); } break; 
    }
    this.setState({validations:tempval});
}
backToLogin = () => {
    this.props.history.push('/login');
}
  getFormattedPhoneNum = () =>{
    let phnnum=this.state.formObj.phone;
    var pattern = new RegExp(/^[0-9\b]+$/);
    if(phnnum !== null && phnnum !== ''){ 
       let output = "(";
       phnnum.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
       {
         if ( g1.length ) {
           output += g1;
           if ( g1.length == 3 ) {
               output += ")";
               if ( g2.length ) {
                   output += " " + g2; 
                   if ( g2.length == 3 ) {
                       output += "-";
                       if ( g3.length ) {
                           output += g3;
                       }
                   }
               }
            }
         }
       }   
     );        
   //return output;
   let newform=this.state.formObj;
   newform.phone=output;
   this.setState({formObj:newform})
   console.log(output);		 
  } 
}   
 
deleteAccount(){
    var newform=this.state.formObj;
    newform.status="Disabled";
   axios.post(base_url+'users/save', newform, this.props.user.header )
   .then(res =>{
       console.log(res.data);
       this.setState({dltconfirm:!this.state.dltconfirm});
   }).catch(function (error) { console.log(error); });

}

confirmdeleteModal = () =>{
    this.setState({dltconfirm:!this.state.dltconfirm});

}
updateSuccess = () =>{
    this.setState({saveuser:!this.state.saveuser}); 
}
	


	onSubmit(){
        var temp="";
        for (let key in Users) { this.validate(key);} 
        for (let key in this.state.validations) { temp += this.state.validations[key];}
        if(temp == ""){
             var newform=this.state.formObj;
            newform.status="Enabled";
			axios.post(base_url+'users/save', newform, this.props.user.header )
			.then(res =>{
                console.log(res.data);
                this.updateSuccess();
				//this.setState({ formObj:res.data});
            }).catch(function (error) { console.log(error); });
        }
	}
    gotoAccounts = () => {
		this.props.history.push('/app/account'); 
	}
	gotoAdmin = () => {
		this.props.history.push('/app/admin'); 
	}
	backToLogout = () => {
		this.props.history.push('/logout');
	}
    gotoProfile = () => {
		this.props.history.push('/app/profile/?id='+this.props.user.accountid); 
	}

	render() {

      return (
		<div className="container-fluid">
		<br/>
		<div className="d-flex row  flex-shrink">
			<div className="p-2 col-md-3"><NavLink to="/app/dashboard"><img  src={Exhibitorlogo} alt="Logo" /></NavLink></div>
			<div className="p-2 eventalign col-sm-6"><div className="pluscolor"><b>Profile Details</b></div></div>
            <div className="p-2 flex-grow-1"></div>
                <div className="p-2 dropdown"><span id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">< FaUserCircle size="35px"/></span>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
		    	{this.state.checkadmin?<span  className="dropdown-item" onClick={() =>this.gotoAdmin()}><span>Admin</span></span>:null}
			    <span  className="dropdown-item" onClick={() =>this.gotoAccounts()}><span>Account</span></span>
				<span  className="dropdown-item" onClick={() =>this.gotoProfile()}><span >Profile</span></span>
				<div className="dropdown-divider"></div>
				<span className="dropdown-item" onClick={() =>this.backToLogout()} >Logout</span>
			</div>
            </div>
	   </div>
		<hr/> 
			<div id="idusers" className="shadow-sm p-3 mb-5 bg-white rounded">
			<div className="brdcolor" >
              
            <div className="d-flex row">
         
                    <div className="p-2 ">
                        <label htmlFor="firstname" className="label-custom" >First Name</label>
                        <input type="text"  id="firstnamer" name="firstname" value={this.state.formObj.firstname} onChange={(e)=>{this.changeUser("firstname",e)}} onBlur={()=>{ this.validate("firstname")}} className="form-control form-control-sm " style={this.state.validations.firstname==""?{}:{"borderColor":"red"}} required   />
					          	<span className="errormsg">{this.state.validations.firstname}</span>
                    </div>
                    <div className="p-2">
                    <label htmlFor="lastname" className="label-custom" >Last Name</label>
                        <input type="text"  id="lastnamer" name="lastname" value={this.state.formObj.lastname} onChange={(e)=>{this.changeUser("lastname",e)}}  onBlur={()=>{ this.validate("lastname")}} className="form-control form-control-sm " style={this.state.validations.lastname==""?{}:{"borderColor":"red"}} required   />
					          	<span className="errormsg">{this.state.validations.lastname}</span>
                    </div>
                    <div className="p-2">
                    <label htmlFor="company" className="label-custom" >Company</label>
                        <input type="text"  id="company" name="company" value={this.state.formObj.company} onChange={(e)=>{this.changeUser("company",e)}}  onBlur={()=>{ this.validate("company")}} className="form-control form-control-sm " style={this.state.validations.company==""?{}:{"borderColor":"red"}} required   />
					          	<span className="errormsg">{this.state.validations.company}</span>
                    </div>
                 </div>
                 <div className="d-flex row">
                    <div className="p-2">
                        <label htmlFor="email" className="label-custom" >Email</label>
                        <input type="text"  id="email" name="email" value={this.state.formObj.email} onChange={(e)=>{this.changeUser("email",e)}}  onBlur={()=>{ this.validate("email")}} className="form-control form-control-sm " style={this.state.validations.email==""?{}:{"borderColor":"red"}} required   />
					          	<span className="errormsg">{this.state.validations.firstname}</span>
                    </div>
                    <div className="p-2 ">
                    <label htmlFor="phone" className="label-custom" >Phone</label>
                        <input type="text"  id="phone" name="phone" value={this.state.formObj.phone} onChange={(e)=>{this.changeUser("phone",e)}} onKeyUp={()=>{this.getFormattedPhoneNum()}}  onBlur={()=>{ this.validate("phone")}} className="form-control form-control-sm " style={this.state.validations.phone==""?{}:{"borderColor":"red"}} required   />
					          	<span className="errormsg">{this.state.validations.phone}</span>  
                    </div>
                </div>
                <div className="d-flex row">
                    <div className="p-2">
                        <label htmlFor="address1" className="label-custom" >Address1</label>
                        <input type="text"  id="address1" name="address1" value={this.state.formObj.address1} onChange={(e)=>{this.changeUser("address1",e)}}  onBlur={()=>{ this.validate("address1")}} className="form-control form-control-sm " style={this.state.validations.firstname==""?{}:{"borderColor":"red"}} required   />
					          	<span className="errormsg">{this.state.validations.firstname}</span>
                     </div> 
                     <div className="p-2">           
                        <label htmlFor="address2" className="label-custom" >Address2</label>
                        <input type="text"  id="address2" name="address2" value={this.state.formObj.address2} onChange={(e)=>{this.changeUser("address2",e)}}  onBlur={()=>{ this.validate("address2")}} className="form-control form-control-sm " style={this.state.validations.address2==""?{}:{"borderColor":"red"}} required   />
					          	<span className="errormsg">{this.state.validations.address2}</span>
                    </div>
                    <div className="p-2">
                    <label htmlFor="city" className="label-custom" >City</label>
                        <input type="text"  id="city" name="city" value={this.state.formObj.city} onChange={(e)=>{this.changeUser("city",e)}}  onBlur={()=>{ this.validate("city")}} className="form-control form-control-sm " style={this.state.validations.city==""?{}:{"borderColor":"red"}} required   />
					          	<span className="errormsg">{this.state.validations.city}</span>
                    
                    </div>
                    <div className="p-2">
                    <label htmlFor="state" className="label-custom" >State</label>
                        <input type="text"  id="state" name="state" value={this.state.formObj.state} onChange={(e)=>{this.changeUser("state",e)}}  onBlur={()=>{ this.validate("state")}} className="form-control form-control-sm " style={this.state.validations.state==""?{}:{"borderColor":"red"}} required   />
					          	<span className="errormsg">{this.state.validations.state}</span>
                    
                    </div>
                    <div className="p-2 ">
                    <label htmlFor="zipcode" className="label-custom" >Zipcode</label>
                        <input type="text"  id="zipcode" name="zipcode" value={this.state.formObj.zip} onChange={(e)=>{this.changeUser("zip",e)}}  onBlur={()=>{ this.validate("zip")}} className="form-control form-control-sm " style={this.state.validations.zip==""?{}:{"borderColor":"red"}} required   />
					    <span className="errormsg">{this.state.validations.zip}</span>
                    </div>
                  
              </div>
    
              <div className="d-flex row">
              <div className="buttonadjust">
              <div className="d-flex">
              <div className="p-2">
                 <button type="button" className="btn btn-primary btn-block btn-sm" onClick={this.onSubmit} >Save Details</button>
              </div>
              </div>
              </div>
              <div>
              <div className="p-2">
                     <button type="button" className="btn btn-primary btn-block btn-sm" onClick={this.confirmdeleteModal} >Cancel Account</button>
              </div>
              </div>
			  </div>
              </div>
                <Modal show={this.state.dltconfirm} onHide={this.confirmdeleteModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                <br/><br/>
                <div className="d-flex justify-content-center row">
                     <h6> Are You Sure??Want to Delete Your Account!!" </h6> 
                         <br/><br/><br/>
                </div>
                <div className="d-flex justify-content-center row">
                   <Button variant="danger" onClick={this.deleteAccount}> Yes</Button>
                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                   <Button variant="success" onClick={this.confirmdeleteModal}>No</Button>
                  
                </div>
                </Modal.Body>
              </Modal>

              <Modal show={this.state.saveuser} onHide={this.updateSuccess}  size="sm"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered>
                <Modal.Body>
                <br/><br/>
                <div className="d-flex justify-content-center row">
                 <h5>  <strong className="textblue">Thank You !!! {this.state.formObj.firstname}</strong> </h5> 
                 <br/><br/> 
               </div>
               <div className="d-flex justify-content-center row">
                 <h6>Your Profile Details Updated successfully.</h6>
               </div> <br/> <br/>
                <div className="d-flex justify-content-center row">
               <Button variant="primary" onClick={this.updateSuccess}>OK</Button>
               </div>
                </Modal.Body>
              </Modal>
                </div>
			  </div>  	
		)}
		
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(UsersForm);
