///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Vendors = {
	title : '', 
	vendorname : '',
	contactname: '',
	vendorLink:'',
	service : '', 
	firstname : '', 
	lastname : '', 
	phone : '', 
	email : '', 
	company : '', 
	designation : '', 
	address1 : '', 
	address2 : '', 
	city : '', 
	state : '', 
	country : '', 
	zipcode : '', 
	url : '', 
	notes : '', 
	status : '', 
	events_id : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}