///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Creditcards = {
	card: '',
	cardname: '',
	cardnumber: '',
	expmonth: '',
	expyear: '', 
	cvc: '', 
    status : '', 
    amount:'', 
    subscription_type:'', 
	events_Id : '',
	id : '', 
	cardstatus:'',
	charging_date :'',
	accountid:'',
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}