import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import { base_url } from '../constants/Constants';
import axios from 'axios';

async function getDocumentDefnition(eventid, header, options){
    
    var event={};
    await axios.get(base_url+'events/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ event = res.data[0]; }
    }).catch((error) => { console.log(error); }); 

         //console.log(event.step);
         var steps=event.step;
         //console.log(steps);


    var documents=[];
    await axios.get(base_url+'documents/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ documents = res.data; }
    }).catch((error) => { console.log(error); }); 

    var orders=[];
    await axios.get(base_url+'orders/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ orders = res.data; }
    }).catch((error) => { console.log(error); }); 

    var shippings=[];
    await axios.get(base_url+'shippings/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ shippings = res.data; }
    }).catch((error) => { console.log(error); }); 


    var staff=[];
    await axios.get(base_url+'staff/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ staff = res.data; }else{
            staff=[];
        }
    }).catch((error) => { console.log(error); }); 

    var hotel=[];
    await axios.get(base_url+'hotels/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ hotel = res.data; }else{
            hotel=[];
        }
    }).catch((error) => { console.log(error); }); 

    var meeting=[];
    await axios.get(base_url+'meetings/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ meeting = res.data; }else{
            meeting=[];
        }
    }).catch((error) => { console.log(error); }); 

    var marketing=[];
    await axios.get(base_url+'marketings/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ marketing = res.data; }
    }).catch((error) => { console.log(error); }); 

    var vendor=[];
    await axios.get(base_url+'vendors/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ vendor = res.data; }else{
            vendor=[];
        }
    }).catch((error) => { console.log(error); }); 
    
    var budget=[];
    await axios.get(base_url+'budgets/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ budget = res.data; }
        else{
            budget=[{exhibitor_costList:[] ,marketing_costList:[],totalcost: "0.00",total_exhibitorcost: "0.00",total_cost:"0.00",total_marketingcost: "0.00",total_travelcost: "0.00",travel_costList:[]} ];
        }
    }).catch((error) => { console.log(error); }); 

    var roireports={};
    await axios.get(base_url+'returnoninvestments/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ roireports = res.data[0]; 
        }else{
            roireports ={business_obj_achieved: "",
            business_objective: "",
            certifications: "",
            certificationsfile: "",
            cost_per_leads: "",
            exhibiting_cost: "",
            filename2: "",
            media_contacts_link: "",
            mediafile: "",
            notes: "",
            other_exhibitors_list: "",
            slide_deck: "",
            slidedeckfile: "",
            social_media_mentions: "",
            status: "",
            total_leads: "",
            total_media_impressions: "",
            total_number_attendees: "",
            total_number_meetings: "",
            total_sales: "",
            totalexhibitorfile: "",
            totalleadfile: "",
            visiter_booth: "",
            notes:"",
        };
        }

    }).catch((error) => { console.log(error); }); 

    var postevent=[];
    var follow=[];
    if(steps<=8){
    await axios.get(base_url+'postevent/listbyeventid/'+eventid, header )
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ 
            postevent = res.data;
            follow=postevent[0].followups;
         }else{
            postevent = [{event_success_reasons: ""}];
         }
    }).catch((error) => { console.log(error); }); 
    
    }

    var staffnote={};
    await axios.post(base_url+'notes/listnotes',{eventid:eventid, stepname:"staff"},header )
    .then(res =>{
        console.log(res.data[0]);
        if(res.data.length > 0 ){ 
            staffnote = res.data[0];
         }
    }).catch((error) => { console.log(error); }); 

    var marketingnote={};
    await axios.post(base_url+'notes/listnotes',{eventid:eventid, stepname:"marketings"},header )
    .then(res =>{
        console.log(res.data[0]);
        if(res.data.length > 0 ){ 
            marketingnote = res.data[0];
         }
    }).catch((error) => { console.log(error); }); 

    var vendornote={};
    await axios.post(base_url+'notes/listnotes',{eventid:eventid, stepname:"vendors"},header )
    .then(res =>{
        console.log(res.data[0]);
        if(res.data.length > 0 ){ 
            vendornote = res.data[0];
         }
    }).catch((error) => { console.log(error); }); 
    
    
    //console.log(documents); 
    
        var data = {
        id:"2342",
        company:"Deemsoft",
        contact:"Manager",
        email:"test@deemsoft.com",
        phone:"4534534534",
        address:"1234 Main Rd",
        total : "2000.00",
        tax:"0.00",
        shipping:"0.00",
        grandtotal:"2000.00",
        paid:"0.00",
        balance:"2000.00",
        notes:"",
        terms:"",
        }
    var title="Invoice";
    var currency="$";
    var exhcost=budget[0].exhibitor_costList;
    var markcost=budget[0].marketing_costList;
    var travcost=budget[0].travel_costList;
    
    var newdt= "";
    var newdt1= "";
    var newdt2= "";
    let dt=new Date(event.start_date);
    var day = dt.getDate();
    var month =  dt.getMonth()+1; //  getMonth() returns month from 0-11 not 1-12
    var year = dt.getFullYear();
    var dateStr= (month <= 9 ? '0' + month : month) + '-' +(day <= 9 ? '0' + day : day) + '-' + year;
    
    // var dateStr = date + "-" + month + "-" + year;
        var h = dt.getHours();
        var m =("0" + dt.getMinutes()).slice(-2);
        //var _time = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM'); 
        if( dateStr !== "" && dateStr !== undefined && dateStr !== null){
            newdt= dateStr;
        }
    
    if(event.event_type === "virtual"){
        let dt=new Date(event.virtual_startime);
        var h = dt.getHours();
        var m =("0" + dt.getMinutes()).slice(-2);
        var _time = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
        if( _time !== "" && _time !== undefined && _time !== null){
            newdt2= _time;
        }
        

        let dt1=new Date(event.virtual_endtime);
        var h1 = dt1.getHours();
        var m1 =("0" + dt1.getMinutes()).slice(-2);
        var _time1 = (h1 > 12) ? (h1-12 + ':' + m1 +' PM') : (h1 + ':' + m1 +' AM');
        
        if( _time1 !== "" && _time1 !== undefined && _time1 !== null){
            newdt1= _time1;
        }

       
    }

        var documentlist=[
                    [	
                        { text: 'Document', style: 'tableHeader' }, 				
                        { text: 'File Name', style: 'tableHeader' },       					
                    ]
                ];

        var orderList=[
                    [	
                        { text: 'Product', style: 'tableHeader' }, 				
                        { text: 'Ordering Deadline', style: 'tableHeader' }, 
                        { text: 'URL', style: 'tableHeader' }, 
                        { text: 'User Id', style: 'tableHeader' }, 
                        { text: 'Password', style: 'tableHeader' },       					
                    ]
                ];

        var shippingList=[
                    [	
                        { text: 'Shipping Items', style: 'tableHeader' }, 				
                        { text: 'Address/Link', style: 'tableHeader' }, 
                        { text: 'Time', style: 'tableHeader' }, 
                        { text: 'Shipping Back', style: 'tableHeader' }, 
                        { text: 'Donating', style: 'tableHeader' },    
                        { text: 'Calender Link', style: 'tableHeader' },       					
                    ]
                ];             
        var marketinglist=[
                    [
                        { text: 'Marketing Activations', style: 'tableHeader' }, 
                        { text: 'Notes', style: 'tableHeader' },      				
                        { text: 'File Name', style: 'tableHeader' },       					
                    ]
                ];
        var stafflist=[
                    [
                        { text: 'Staff Name', style: 'tableHeader' }, 				
                        { text: 'Email', style: 'tableHeader' },   
                        { text: 'Phone', style: 'tableHeader' }, 
                        { text: 'Date and Time Attanding', style: 'tableHeader' },       	
                        { text: 'Task', style: 'tableHeader' },
                    ]
                ];

        var hotellist=[
                    [
                        { text: 'Staff Name', style: 'tableHeader' }, 				
                        { text: 'Hotel Address', style: 'tableHeader' },   
                        { text: 'Checked In', style: 'tableHeader' }, 
                        { text: 'Checked Out', style: 'tableHeader' },       	
                        { text: 'Reservation Link', style: 'tableHeader' },
                    ]
                ];
        var meetinglist=[
                    [
                        { text: 'Staff Name', style: 'tableHeader' }, 				
                        { text: 'Whom', style: 'tableHeader' },   
                        { text: 'Location', style: 'tableHeader' }, 
                        { text: 'Start Time', style: 'tableHeader' },       	
                        { text: 'End Time', style: 'tableHeader' },
                        { text: 'Calender Link', style: 'tableHeader' },
                    ]
                ];
     var exhibitorcostList=[
                    [
                        { text: 'Name', style: 'tableHeader' }, 				
                        { text: 'Price', style: 'tableHeader' },   
                    
                    ]
                ]; 
    var marketingcostList=[
                    [
                        { text: 'Name', style: 'tableHeader' }, 				
                        { text: 'Price', style: 'tableHeader' },   
                    
                    ]
                ]; 
    var travelcostList=[
                    [
                        { text: 'Name', style: 'tableHeader' }, 				
                        { text: 'Price', style: 'tableHeader' },   
                    
                    ]
                ]; 
    var vendorlist=[
                    [
                        { text: 'Vendor Name', style: 'tableHeader' }, 				
                        { text: 'Contact Name', style: 'tableHeader' },   
                        { text: 'Phone', style: 'tableHeader' }, 
                        { text: 'Vendors Link', style: 'tableHeader' },       	
                    ]
                ];			
        var followupstList = [
                    [
                        { text: 'Follow Ups', style: 'tableHeader' }, 				
                    
                    ]];		
    
                for(var i in staff) {                    
                    let dt=new Date(staff[i].dates_attending);
                    var day = ("0" + dt.getDate()).slice(-2);
                    var month = ("0" + (dt.getMonth() + 1)).slice(-2);
                    var year = dt.getFullYear();
                    var h = dt.getHours();
                    var m =("0" + dt.getMinutes()).slice(-2);
                    var _time = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
                    let date_time= month+'-'+day+'-'+year+'('+ _time+')';           
                    stafflist.push(
                    [
                        { text: ''+staff[i].staffname+'' , style:'header2'},						
                        { text: ''+staff[i].email+'' , style:'header2'},
                        { text: ''+staff[i].phone+'' , style:'header2'},
                        { text: ''+date_time+'' , style:'header2'},
                        { text: ''+staff[i].notes+'' , style:'header2'},
                    ]);
                }
                 for(var i in hotel) { 
                    let dt=new Date(hotel[i].checkin);
                    var day = ("0" + dt.getDate()).slice(-2);
                    var month = ("0" + (dt.getMonth() + 1)).slice(-2);
                    var year = dt.getFullYear();
                    var t = dt.getHours();
                    var min=("0" + dt.getMinutes()).slice(-2);
                    var _time1 = (t > 12) ? (t-12 + ':' + min +' PM') : (t + ':' + min +' AM');
                    let chkin= month+'-'+day+'-'+year+'('+_time1+')';
        
                    let dt1=new Date(hotel[i].checkout);
                    var day = ("0" + dt1.getDate()).slice(-2);
                    var month = ("0" + (dt1.getMonth() + 1)).slice(-2);
                    var year = dt1.getFullYear();
                    var h = dt1.getHours();
                    var m =("0" + dt1.getMinutes()).slice(-2);
                    var _time2 = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
                    let chkout= month+'-'+day+'-'+year+'('+_time2+')';                   
                              
                    hotellist.push(
                    [
                        { text: ''+hotel[i].staffname+'' , style:'header2'},						
                        { text: ''+hotel[i].hoteladdress+'' , style:'header2'},
                        { text: ''+chkin+'' , style:'header2'},
                        { text: ''+chkout+'' , style:'header2'},
                        { text: ''+hotel[i].reservationlink+'' , style:'header2'},
                    ]);
                }

                for(var i in meeting) { 

                    let dt=new Date(meeting[i].start_datetime);
                    var day = ("0" + dt.getDate()).slice(-2);
                    var month = ("0" + (dt.getMonth() + 1)).slice(-2);
                    var year = dt.getFullYear();
                    var h = dt.getHours();
                    var m =("0" + dt.getMinutes()).slice(-2);
                    var _time1 = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
                    let stime= month+'-'+day+'-'+year+'('+_time1+')';

                    let dt1=new Date(meeting[i].end_datetime);
                    var day = ("0" + dt1.getDate()).slice(-2);
                    var month = ("0" + (dt1.getMonth() + 1)).slice(-2);
                    var year = dt1.getFullYear();
                    var time = dt1.getHours();
                    var min =("0" + dt1.getMinutes()).slice(-2);
                    var _time2 = (time > 12) ? (time-12 + ':' + min +' PM') : (time + ':' + min +' AM');
                    let etime= month+'-'+day+'-'+year+'('+_time2+')';  

                    meetinglist.push(
                    [
                        { text: ''+meeting[i].staffname+'' , style:'header2'},						
                        { text: ''+meeting[i].meeting_person+'' , style:'header2'},
                        { text: ''+meeting[i].location+'' , style:'header2'},
                        { text: ''+stime+'' , style:'header2'},
                        { text: ''+etime+'' , style:'header2'},
                        { text: ''+meeting[i].calendarlink+'' , style:'header2'},
                    ]);
                }
                
                for(var i in marketing) {                    
                    var slno = Number(i) + 1;               
                    marketinglist.push(
                    [
                        { text: ''+marketing[i].activity+'' , style:'header2'},	
                        { text: ''+marketing[i].notes+'' , style:'header2'},					
                        { text: ''+marketing[i].filename+'' , style:'header2'},
                    ]);
                }
                for(var i in vendor) {                    
                    var slno = Number(i) + 1;               
                    vendorlist.push(
                    [
                        { text: ''+vendor[i].vendorname+'' , style:'header2'},						
                        { text: ''+vendor[i].contactname+'' , style:'header2'},
                        { text: ''+vendor[i].phone+'' , style:'header2'},
                        { text: ''+vendor[i].vendorLink+'' , style:'header2'},
                    ]);
                }
    
                for(var i in documents) { 
                    
                    documentlist.push(
                    [
                        { text: ''+documents[i].document+'' , style:'header2'},						
                        { text: ''+documents[i].filename+'' , style:'header2'},
                    ]);
                }

                for(var i in orders) {  
                    orderList.push(
                    [
                        { text: ''+orders[i].product+'' , style:'header2'},						
                        { text: ''+orders[i].last_date+'' , style:'header2'},
                        { text: ''+orders[i].url+'' , style:'header2'},						
                        { text: ''+orders[i].userid+'' , style:'header2'},
                        { text: ''+orders[i].password+'' , style:'header2'},						
                        
                    ]);
                }

                for(var i in shippings) { 
                    let dt=new Date(shippings[i].shipp_time);
		        	var h = dt.getHours();
		         	var m =("0" + dt.getMinutes()).slice(-2);
		         	var _time = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM'); 
                    shippingList.push(
                    [
                        { text: ''+shippings[i].product+'' , style:'header2'},						
                        { text: ''+shippings[i].from_address1+'' , style:'header2'},
                        { text: ''+_time+'' , style:'header2'},						
                        { text: ''+shippings[i].shippingback+'' , style:'header2'},
                        { text: ''+shippings[i].donatable_item+'' , style:'header2'},	
                        { text: ''+shippings[i].calanderlink+'' , style:'header2'},						
                        
                    ]);
                }
            
                 for(var i in exhcost) {                              
                    exhibitorcostList.push(
                    [
                        { text: ''+exhcost[i].name+'' , style:'header2'},						
                        { text: ''+currency+' '+exhcost[i].price+'' , alignment:'right',style:'header2'},
                        
                    ]);
                } 
                for(var i in markcost) {                              
                    marketingcostList.push(
                    [
                        { text: ''+markcost[i].name+'' , style:'header2'},						
                        { text: ''+currency+' '+markcost[i].price+'' ,alignment:'right', style:'header2'},
                        
                    ]);
                }
                for(var i in travcost) {                              
                    travelcostList.push(
                    [
                        { text: ''+travcost[i].name+'' , style:'header2'},						
                        { text: ''+currency+' '+travcost[i].price+'' ,alignment:'right', style:'header2'},
                        
                    ]);
                } 
                exhibitorcostList.push(
                  [
                    { text: 'Total',alignment:'right', style:'header2' }, 
                    { text:  ''+currency+' '+budget[0].total_exhibitorcost+'', alignment:'right', style:'header2' }
                  ]);
                  marketingcostList.push(
                  [
                    { text: 'Total',alignment:'right', style:'header2' }, 
                    { text:  ''+currency+' '+budget[0].total_marketingcost+'', alignment:'right', style:'header2' }
                  ]);
                  travelcostList.push(
                  [
                    { text: 'Total',alignment:'right', style:'header2' }, 
                    { text:  ''+currency+' '+budget[0].total_travelcost+'', alignment:'right', style:'header2' }
                  ]);
    
                if(steps<=8){
                  for(var i in follow) {                              
                    followupstList.push(
                    [
                        { text: ''+follow[i].actions_items+'' , style:'header2'},						
                        
                    ]);
                }
            } 
       /*--------------Notes section----------- */
       var eventnotes=[[{text:'',alignment:'left', fontSize:11, border:[false, false, false, false]}]];
         if(event.notes !== ""){
             eventnotes= [	
                [ { text:'Notes : '+event.notes+'',alignment:'left',fontSize:11, border:[false, false, false, false]}
                ]
                ]  ;
         }
         console.log(staffnote.note);
         var staffnotes=[[{text:'',alignment:'left', fontSize:11, border:[false, false, false, false]}]];
         if(staffnote.note !== "" && staffnote.note !== undefined ){
            staffnotes= [	
                [  
                     { text:'Notes : '+ staffnote.note+'',alignment:'left',fontSize:11, border:[false, false, false, false]}
                ]
                ]  ;
         }
         console.log(marketingnote.note);
         var marketingnotes=[[{text:'',alignment:'left', fontSize:11, border:[false, false, false, false]}]];
         if(marketingnote.note !== "" && marketingnote.note !== undefined){
            marketingnotes= [
                [ { text:'Notes : '+ marketingnote.note+'',alignment:'left', fontSize:11, border:[false, false, false, false]}
                ]
                ]  ;
         }
         console.log(vendornote.note);
         var vendornotes=[[{text:'',alignment:'left', fontSize:11, border:[false, false, false, false]}]];
         if(vendornote.note !== "" && vendornote.note !== undefined){
            vendornotes= [	
                [ { text:'Notes : '+ vendornote.note+'',alignment:'left', fontSize:11, border:[false, false, false, false]}
                ]
                ]  ;
         }

         var budgetnotes=[[{text:'',alignment:'left', fontSize:11, border:[false, false, false, false]}]];
         if(budget[0].notes!== ""){
            budgetnotes= [	
                [ { text:'Notes : '+budget[0].notes+'',alignment:'left', fontSize:11, border:[false, false, false, false]}
                ]
                ]  ;
         }

         var roinotes=[[{text:'',alignment:'left', fontSize:11, border:[false, false, false, false]}]];
         if(roireports.notes !== ""){
            roinotes= [	
                [ { text:'Notes : '+roireports.notes+'',alignment:'left',fontSize:11, border:[false, false, false, false]}
                ]
                ]  ;
         }
         var posteventnotes=[[{text:'',alignment:'left', fontSize:11, border:[false, false, false, false]}]];
         if(postevent.notes !== ""){
            posteventnotes= [	
                [ { text:'Notes : '+postevent.notes+'',alignment:'left', fontSize:11, border:[false, false, false, false]}
                ]
                ]  ;
         }

 
      if(steps == 2){
          if(event.event_type === 'In Person'){
        var documentDefinition = {
            pageOrientation: 'page',
           // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
            content: 
             [
                {
                    text:''+event.eventname+'\n\n',
                    style:'header'
                },
                {  table:{
                   headerRows: 1,
                   widths: [ '*', 100 ],
                   body:[[{	
                           table:{ 
                           widths: [ '*' ],
                           body: [	
                               
                               [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                               [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                               [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                               [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                               [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                           ]},
                           border:[false, false, false, false]
                           },						
                           {	
                           table: { 
                           headerRows: 1,
                           widths: [ 40, '*' ],
                           body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                    { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                   ],
                                   [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                    { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                   ]
                                 ]
                           },
                           border:[false, false, false, false]
                           }	
                      ]]
                   }
               },

               
               { text:'\n'},
               
               { table:{
                   headerRows: 1,
                   widths: [ '65%', '*' ],		
                     body: [
                           [{ text:'Address : \n'+event.address1+' '+event.address2+' \n '+event.city+' '+event.state+' '+event.zipcode+'\n', alignment:'left', fontSize:11, border:[false, false, false, false]}, 
                        ]
                           ]
                   }
               },	
              { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: 'Logistics and Documents', style:'header1' },
               { text: 'Exhibitor Documents', style:'label' },
               { table:{
                   headerRows: 1,
                   widths: [ 160, '*'],
                     body: documentlist
                   },
                   layout: {
                   hLineWidth: function (i, node) {
                       return  1;
                   },
                   vLineWidth: function (i, node) {
                       return  1;
                   },
                   hLineColor: function (i, node) {
                       return  '#1160a6';
                   },
                   vLineColor: function (i, node) {
                       return  '#1160a6';
                   },
               },
               },	
               { text: '\n' },
               { text: 'Product Ordering Options', style:'label' },
               { table:{
                headerRows: 1,
                widths: [  70, 95,'*', 150, 80],
                  body: orderList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },	

            { text: '\n' },
               { text: ' Shipping Products', style:'label' },
               { table:{
                headerRows: 1,
                widths: [  70, 95,'*',80, 80,'*'],
                  body: shippingList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },	
             ],
             styles:{
                header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
                header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
                header2:{fontSize:10},
                header4:{fontSize:10},
                header3:{fontSize:10, alignment:'right'},
                tableHeader:{fillColor:'#1160a6', color:'white'},
                label:{fontSize:11},
             }
        };
    }else{

        var documentDefinition = {
            pageOrientation: 'page',
           // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
            content: 
             [
                {
                    text:''+event.eventname+'\n\n',
                    style:'header'
                },
                {  table:{
                   headerRows: 1,
                   widths: [ '*', 100 ],
                   body:[[{	
                           table:{ 
                           widths: [ '*' ],
                           body: [	
                               
                               [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                               [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                               [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                               [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                               [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                           ]},
                           border:[false, false, false, false]
                           },						
                           {	
                           table: { 
                           headerRows: 1,
                           widths: [ 40, '*' ],
                           body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                    { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                   ],
                                   [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                    { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                   ]
                                 ]
                           },
                           border:[false, false, false, false]
                           }	
                      ]]
                   }
               },
               
               { text:'\n'},
               
               { table:{
                   headerRows: 1,
                   widths: [ '65%', '*' ],		
                     body: [
                           [{ text:'URL : '+event.virtual_url+'\n User Name : '+event.virtual_hostid+'\n Password : '+event.virtual_user_pass+'\n', alignment:'left', fontSize:10, border:[false, false, false, false]}
                           ]
                           ]
                   }
               },	
              { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: 'Logistics and Documents', style:'header1' },
               { text: 'Exhibitor Documents', style:'label' },
               { table:{
                   headerRows: 1,
                   widths: [ 160, '*'],
                     body: documentlist
                   },
                   layout: {
                   hLineWidth: function (i, node) {
                       return  1;
                   },
                   vLineWidth: function (i, node) {
                       return  1;
                   },
                   hLineColor: function (i, node) {
                       return  '#1160a6';
                   },
                   vLineColor: function (i, node) {
                       return  '#1160a6';
                   },
               },
               },
            ],
            styles:{
                header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
                header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
                header2:{fontSize:10},
                header4:{fontSize:10},
                header3:{fontSize:10, alignment:'right'},
                tableHeader:{fillColor:'#1160a6', color:'white'},
                label:{fontSize:11},
             }
        };

    }

     }          
    
     if(steps == 3){
        if(event.event_type === 'In Person'){   
            var documentDefinition = {
                pageOrientation: 'page',
               // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
                content: 
                 [
                     {
                         text:''+event.eventname+'\n\n',
                         style:'header'
                     },
                     {  table:{
                        headerRows: 1,
                        widths: [ '*', 100 ],
                        body:[[{	
                                table:{ 
                                widths: [ '*' ],
                                body: [	
                                    
                                    [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                                    [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                                    [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                                    [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                                    [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                                ]},
                                border:[false, false, false, false]
                                },						
                                {	
                                table: { 
                                headerRows: 1,
                                widths: [ 40, '*' ],
                                body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                         { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                        ],
                                        [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                         { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                        ]
                                      ]
                                },
                                border:[false, false, false, false]
                                }	
                           ]]
                        }
                    },
                    
                    { text:'\n'},
                    
                    { table:{
                        headerRows: 1,
                        widths: [ '65%', '*' ],		
                          body: [
                                [
                                    { text:'Address : \n'+event.address1+' '+event.address2+'\n '+event.city+' '+event.state+' '+event.zipcode+'\n', alignment:'left', fontSize:11, border:[false, false, false, false]}, 
                             ]
                                ]
                        }
                    },
                  { text: '\n' },	
                   { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: '\n' },
            { text: 'Logistics and Documents', style:'header1' },
                    { text: 'Exhibitor Documents', style:'label' },
                    { table:{
                        headerRows: 1,
                        widths: [ 160, '*'],
                          body: documentlist
                        },
                        layout: {
                        hLineWidth: function (i, node) {
                            return  1;
                        },
                        vLineWidth: function (i, node) {
                            return  1;
                        },
                        hLineColor: function (i, node) {
                            return  '#1160a6';
                        },
                        vLineColor: function (i, node) {
                            return  '#1160a6';
                        },
                    },
                    },	
                    { text: '\n' },
                    { text: 'Product Ordering Options', style:'label' },
                    { table:{
                     headerRows: 1,
                     widths: [  70, 95,'*', 150, 80],
                       body: orderList
                     },
                     layout: {
                     hLineWidth: function (i, node) {
                         return  1;
                     },
                     vLineWidth: function (i, node) {
                         return  1;
                     },
                     hLineColor: function (i, node) {
                         return  '#1160a6';
                     },
                     vLineColor: function (i, node) {
                         return  '#1160a6';
                     },
                 },
                 },	
     
                 { text: '\n' },
                    { text: ' Shipping Products', style:'label' },
                    { table:{
                     headerRows: 1,
                     widths: [  70, 95,'*',80, 80,'*'],
                       body: shippingList
                     },
                     layout: {
                     hLineWidth: function (i, node) {
                         return  1;
                     },
                     vLineWidth: function (i, node) {
                         return  1;
                     },
                     hLineColor: function (i, node) {
                         return  '#1160a6';
                     },
                     vLineColor: function (i, node) {
                         return  '#1160a6';
                     },
                    },
                   },	
                    { text:'\n'},
                    { text: 'Staff Information', style:'header1' },
                    { text: 'Staff Details', style:'label' },
                    { table:{
                        headerRows: 1,
                        widths: [ 70, 95,'*', 150, 80],
                          body: stafflist
                        },
                        layout: {
                        hLineWidth: function (i, node) {
                            return  1;
                        },
                        vLineWidth: function (i, node) {
                            return  1;
                        },
                        hLineColor: function (i, node) {
                            return  '#1160a6';
                        },
                        vLineColor: function (i, node) {
                            return  '#1160a6';
                        },
                    }
                    },
                    { text:'\n'},
                    { text: 'Hotel Details', style:'label' },
                    { table:{
                        headerRows: 1,
                        widths: [ 70,'*','*', '*', '*'],
                          body: hotellist
                        },
                        layout: {
                        hLineWidth: function (i, node) {
                            return  1;
                        },
                        vLineWidth: function (i, node) {
                            return  1;
                        },
                        hLineColor: function (i, node) {
                            return  '#1160a6';
                        },
                        vLineColor: function (i, node) {
                            return  '#1160a6';
                        },
                    }
                    },
                    { text: '\n' },
                    { text: 'Meetings Details', style:'label' },
                    { table:{
                     headerRows: 1,
                     widths: [  70, 95,'*',80, 80,'*'],
                       body: meetinglist
                     },
                     layout: {
                     hLineWidth: function (i, node) {
                         return  1;
                     },
                     vLineWidth: function (i, node) {
                         return  1;
                     },
                     hLineColor: function (i, node) {
                         return  '#1160a6';
                     },
                     vLineColor: function (i, node) {
                         return  '#1160a6';
                     },
                 },
                 },	
                 { text: '\n' },
                 { table:{
                    headerRows: 1,
                    widths: [ '100%', '*' ],		
                      body: staffnotes
                    }
                },
                { text: '\n' },
             ],
             styles:{
                header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
                header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
                header2:{fontSize:10},
                header4:{fontSize:10},
                header3:{fontSize:10, alignment:'right'},
                tableHeader:{fillColor:'#1160a6', color:'white'},
                label:{fontSize:11},
             }
        };
    }else{
        var documentDefinition = {
            pageOrientation: 'page',
           // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
            content: 
             [
                 {
                     text:''+event.eventname+'\n\n',
                     style:'header'
                 },
                 {  table:{
                    headerRows: 1,
                    widths: [ '*', 100 ],
                    body:[[{	
                            table:{ 
                            widths: [ '*' ],
                            body: [	
                                
                                [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                            ]},
                            border:[false, false, false, false]
                            },						
                            {	
                            table: { 
                            headerRows: 1,
                            widths: [ 40, '*' ],
                            body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                     { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                    ],
                                    [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                     { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                    ]
                                  ]
                            },
                            border:[false, false, false, false]
                            }	
                       ]]
                    }
                },
                
                { text:'\n'},
                
                { table:{
                    headerRows: 1,
                    widths: [ '65%', '*' ],		
                      body: [
                            [  { text:'URL : '+event.virtual_url+'\n User Name : '+event.virtual_hostid+'\n Password : '+event.virtual_user_pass+'\n', alignment:'left', fontSize:10, border:[false, false, false, false]}
                            ]
                            ]
                    }
                },
             { text: '\n' },	
               { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: '\n' },
            { text: 'Logistics and Documents', style:'header1' },
                { text: 'Exhibitor Documents', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 160, '*'],
                      body: documentlist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                },
                },	
                { text:'\n'},
                { text: 'Staff Information', style:'header1' },
                { text: 'Staff Details', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 70, 95,'*', 150, 80],
                      body: stafflist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                }
                },
                { text: '\n' },
                { table:{
                    headerRows: 1,
                    widths: [ '65%', '*' ],		
                      body: staffnotes
                    }
                },
                { text: '\n' },
            ],
            styles:{
                header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
                header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
                header2:{fontSize:10},
                header4:{fontSize:10},
                header3:{fontSize:10, alignment:'right'},
                tableHeader:{fillColor:'#1160a6', color:'white'},
                label:{fontSize:11},
             }
        };

    }

 }    


 if(steps == 4){
    if(event.event_type === 'In Person'){   
        var documentDefinition = {
            pageOrientation: 'page',
           // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
            content: 
             [
                 {
                     text:''+event.eventname+'\n\n',
                     style:'header'
                 },
                 {  table:{
                    headerRows: 1,
                    widths: [ '*', 100 ],
                    body:[[{	
                            table:{ 
                            widths: [ '*' ],
                            body: [	
                                
                                [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                            ]},
                            border:[false, false, false, false]
                            },						
                            {	
                            table: { 
                            headerRows: 1,
                            widths: [ 40, '*' ],
                            body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                     { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                    ],
                                    [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                     { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                    ]
                                  ]
                            },
                            border:[false, false, false, false]
                            }	
                       ]]
                    }
                },
                
                { text:'\n'},
                
                { table:{
                    headerRows: 1,
                    widths: [ '65%', '*' ],		
                      body: [
                             [{ text:'Address : \n'+event.address1+' '+event.address2+' \n '+event.city+' '+event.state+' '+event.zipcode+'\n', alignment:'left', fontSize:11, border:[false, false, false, false]}, 
                            ]
                            ]
                    }
                },
                { text: '\n' },	
               { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: '\n' },
            { text: 'Logistics and Documents', style:'header1' },
                { text: 'Exhibitor Documents', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 160, '*'],
                      body: documentlist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                },
                },	
                { text: '\n' },
                { text: 'Product Ordering Options', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*', 150, 80],
                   body: orderList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
 
             { text: '\n' },
                { text: ' Shipping Products', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*',80, 80,'*'],
                   body: shippingList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                },
               },	
                { text:'\n'},
                { text: 'Staff Information', style:'header1' },
                { text: 'Staff Details', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 70, 95,'*', 150, 80],
                      body: stafflist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                }
                },
                { text:'\n'},
                { text: 'Hotel Details', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 70,'*','*', '*', '*'],
                      body: hotellist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                }
                },
                { text: '\n' },
                { text: 'Meetings Details', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*',80, 80,'*'],
                   body: meetinglist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: staffnotes
                }
            },
             { text:'\n\n'},
             { text: 'Marketing Information', style:'header1' },
             { text: 'Marketing Details', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 160,100, '*'],
                   body: marketinglist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: marketingnotes
                }
            },
            { text: '\n' },
         ],
         styles:{
            header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
            header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
            header2:{fontSize:10},
            header4:{fontSize:10},
            header3:{fontSize:10, alignment:'right'},
            tableHeader:{fillColor:'#1160a6', color:'white'},
            label:{fontSize:11},
         }
    };
}else{
    var documentDefinition = {
        pageOrientation: 'page',
       // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
        content: 
         [
             {
                 text:''+event.eventname+'\n\n',
                 style:'header'
             },
             {  table:{
                headerRows: 1,
                widths: [ '*', 100 ],
                body:[[{	
                        table:{ 
                        widths: [ '*' ],
                        body: [	
                            
                            [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                        ]},
                        border:[false, false, false, false]
                        },						
                        {	
                        table: { 
                        headerRows: 1,
                        widths: [ 40, '*' ],
                        body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                 { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                ],
                                [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                 { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                ]
                              ]
                        },
                        border:[false, false, false, false]
                        }	
                   ]]
                }
            },
            
            { text:'\n'},
            
            { table:{
                headerRows: 1,
                widths: [ '65%', '*' ],		
                  body: [
                        [ { text:'URL : '+event.virtual_url+'\n User Name : '+event.virtual_hostid+'\n Password : '+event.virtual_user_pass+'\n', alignment:'left', fontSize:10, border:[false, false, false, false]}
                        ]
                        ]
                }
            },	
            { text: '\n' },
           { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: '\n' },
            { text: 'Logistics and Documents', style:'header1' },
            { text: 'Exhibitor Documents', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 160, '*'],
                  body: documentlist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },	
            { text:'\n'},
            { text: 'Staff Information', style:'header1' },
            { text: 'Staff Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 70, 95,'*', 150, 80],
                  body: stafflist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: staffnotes
                }
            },
            { text:'\n\n'},
            { text: 'Marketing Information', style:'header1' },
            { text: 'Marketing Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 160,100, '*'],
                  body: marketinglist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            { text:'\n\n'},
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: marketingnotes
                }
            },
            
        ],
        styles:{
            header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
            header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
            header2:{fontSize:10},
            header4:{fontSize:10},
            header3:{fontSize:10, alignment:'right'},
            tableHeader:{fillColor:'#1160a6', color:'white'},
            label:{fontSize:11},
         }
    };

}

}


if(steps == 5){
    if(event.event_type === 'In Person'){   
        var documentDefinition = {
            pageOrientation: 'page',
           // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
            content: 
             [
                 {
                     text:''+event.eventname+'\n\n',
                     style:'header'
                 },
                 {  table:{
                    headerRows: 1,
                    widths: [ '*', 100 ],
                    body:[[{	
                            table:{ 
                            widths: [ '*' ],
                            body: [	
                                
                                [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                            ]},
                            border:[false, false, false, false]
                            },						
                            {	
                            table: { 
                            headerRows: 1,
                            widths: [ 40, '*' ],
                            body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                     { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                    ],
                                    [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                     { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                    ]
                                  ]
                            },
                            border:[false, false, false, false]
                            }	
                       ]]
                    }
                },
                
                { text:'\n'},
                
                { table:{
                    headerRows: 1,
                    widths: [ '65%', '*' ],		
                      body: [
                            [ { text:'Address : \n'+event.address1+' '+event.address2+' \n '+event.city+' '+event.state+' '+event.zipcode+'\n', alignment:'left', fontSize:11, border:[false, false, false, false]}, 
                        ]
                            ]
                    }
                },	
                { text: '\n' },
               { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: '\n' },
            { text: 'Logistics and Documents', style:'header1' },
                { text: 'Exhibitor Documents', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 160, '*'],
                      body: documentlist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                },
                },	
                { text: '\n' },
                { text: 'Product Ordering Options', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*', 150, 80],
                   body: orderList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
 
             { text: '\n' },
                { text: ' Shipping Products', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*',80, 80,'*'],
                   body: shippingList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                },
               },	
                { text:'\n'},
                { text: 'Staff Information', style:'header1' },
                { text: 'Staff Details', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 70, 95,'*', 150, 80],
                      body: stafflist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                }
                },
                { text:'\n'},
                { text: 'Hotel Details', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 70,'*','*', '*', '*'],
                      body: hotellist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                }
                },
                { text: '\n' },
                { text: 'Meetings Details', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*',80, 80,'*'],
                   body: meetinglist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: staffnotes
                }
            },
             { text:'\n\n'},
             { text: 'Marketing Information', style:'header1' },
             { text: 'Marketing Details', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 160,100, '*'],
                   body: marketinglist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: marketingnotes
                }
            },
             { text:'\n\n'},
             { text: 'Vendor Information', style:'header1' },
             { text: 'Vendor Details', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 80, 80,'*', 160],
                   body: vendorlist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: vendornotes
                }
            },
            { text: '\n' },
         ],
         styles:{
            header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
            header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
            header2:{fontSize:10},
            header4:{fontSize:10},
            header3:{fontSize:10, alignment:'right'},
            tableHeader:{fillColor:'#1160a6', color:'white'},
            label:{fontSize:11},
         }
    };
}else{
    var documentDefinition = {
        pageOrientation: 'page',
       // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
        content: 
         [
             {
                 text:''+event.eventname+'\n\n',
                 style:'header'
             },
             {  table:{
                headerRows: 1,
                widths: [ '*', 100 ],
                body:[[{	
                        table:{ 
                        widths: [ '*' ],
                        body: [	
                            
                            [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                        ]},
                        border:[false, false, false, false]
                        },						
                        {	
                        table: { 
                        headerRows: 1,
                        widths: [ 40, '*' ],
                        body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                 { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                ],
                                [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                 { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                ]
                              ]
                        },
                        border:[false, false, false, false]
                        }	
                   ]]
                }
            },
            
            { text:'\n'},
            
            { table:{
                headerRows: 1,
                widths: [ '65%', '*' ],		
                  body: [	[  { text: 'Virtual\n', alignment:'left', bold:true, border:[false, false, false, false]}
                        ],
                        [   { text:'URL : '+event.virtual_url+'\n User Name : '+event.virtual_hostid+'\n Password : '+event.virtual_user_pass+'\n', alignment:'left', fontSize:10, border:[false, false, false, false]}
                        ]
                        ]
                }
            },	
            { text: '\n' },
           { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: '\n' },
            { text: 'Logistics and Documents', style:'header1' },
            { text: 'Exhibitor Documents', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 160, '*'],
                  body: documentlist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },	
            { text:'\n'},
            { text: 'Staff Information', style:'header1' },
            { text: 'Staff Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 70, 95,'*', 150, 80],
                  body: stafflist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: staffnotes
                }
            },
            { text:'\n'},
            { text: 'Marketing Information', style:'header1' },
            { text: 'Marketing Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 160,100, '*'],
                  body: marketinglist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: marketingnotes
                }
            },
            { text:'\n'},
            { text: 'Vendor Information', style:'header1' },
            { text: 'Vendor Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 80, 80,'*', 160],
                  body: vendorlist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: vendornotes
                }
            },
            { text: '\n' },
        ],
        styles:{
            header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
            header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
            header2:{fontSize:10},
            header4:{fontSize:10},
            header3:{fontSize:10, alignment:'right'},
            tableHeader:{fillColor:'#1160a6', color:'white'},
            label:{fontSize:11},
         }
    };

}
}




if(steps == 6){
    if(event.event_type === 'In Person'){   
        var documentDefinition = {
            pageOrientation: 'page',
           // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
            content: 
             [
                 {
                     text:''+event.eventname+'\n\n',
                     style:'header'
                 },
                 {  table:{
                    headerRows: 1,
                    widths: [ '*', 100 ],
                    body:[[{	
                            table:{ 
                            widths: [ '*' ],
                            body: [	
                                
                                [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                            ]},
                            border:[false, false, false, false]
                            },						
                            {	
                            table: { 
                            headerRows: 1,
                            widths: [ 40, '*' ],
                            body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                     { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                    ],
                                    [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                     { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                    ]
                                  ]
                            },
                            border:[false, false, false, false]
                            }	
                       ]]
                    }
                },
                
                { text:'\n'},
                
                { table:{
                    headerRows: 1,
                    widths: [ '65%', '*' ],		
                      body: [	
                            [{ text:'Address : \n'+event.address1+' '+event.address2+'\n '+event.city+' '+event.state+' '+event.zipcode+'\n', alignment:'left', fontSize:11, border:[false, false, false, false]}, 
                            ]
                            ]
                    }
                },
                { text: '\n' },
           { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: '\n' },
            { text: 'Logistics and Documents', style:'header1' },
                { text: 'Exhibitor Documents', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 160, '*'],
                      body: documentlist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                },
                },	
                { text: '\n' },
                { text: 'Product Ordering Options', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*', 150, 80],
                   body: orderList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
 
             { text: '\n' },
                { text: ' Shipping Products', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*',80, 80,'*'],
                   body: shippingList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                },
               },	
                { text:'\n'},
                { text: 'Staff Information', style:'header1' },
                { text: 'Staff Details', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 70, 95,'*', 150, 80],
                      body: stafflist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                }
                },
                { text:'\n'},
                { text: 'Hotel Details', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 70,'*','*', '*', '*'],
                      body: hotellist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                }
                },
                { text: '\n' },
                { text: 'Meetings Details', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*',80, 80,'*'],
                   body: meetinglist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: staffnotes
                }
            },
             { text:'\n'},
             { text: 'Marketing Information', style:'header1' },
             { text: 'Marketing Details', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 160,100, '*'],
                   body: marketinglist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: marketingnotes
                }
            },
             { text:'\n'},
             { text: 'Vendor Information', style:'header1' },
             { text: 'Vendor Details', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 80, 80,'*', 160],
                   body: vendorlist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: vendornotes
                }
            },
             {text:'\n'},
             { text: 'Budget Information', style:'header1' },
             { text: 'Exhibition Cost', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 100,100],
                   body: exhibitorcostList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             {text:'\n\n'},
             { text: 'Marketing Cost', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 100,100],
                   body: marketingcostList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             {text:'\n\n'},
             { text: 'Traveling Cost', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 100,100],
                   body: travelcostList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             {text:'\n\n'},
             { text: 'Net Total Cost', style:'label' },
             { 	table: {
                 widths: [ 100,100],
                     body: [
                         [{text:'Total Exhibition Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_exhibitorcost+'',alignment:'right', style:'header3'}],
                         [{text:'Total Marketing Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_marketingcost+'',alignment:'right', style:'header3'}],
                         [{text:'Total Travel Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_travelcost+'',alignment:'right', style:'header3'}],
                         [{text:'Net Total' ,alignment:'right', style:'header2'}, {text:''+currency+''+budget[0].total_cost+'',alignment:'right',style:'header3'}],
                     ]
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: budgetnotes
                }
            },
         ],
         styles:{
            header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
            header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
            header2:{fontSize:10},
            header4:{fontSize:10},
            header3:{fontSize:10, alignment:'right'},
            tableHeader:{fillColor:'#1160a6', color:'white'},
            label:{fontSize:11},
         }
    };
}else{
    var documentDefinition = {
        pageOrientation: 'page',
       // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
        content: 
         [
             {
                 text:''+event.eventname+'\n\n',
                 style:'header'
             },
             {  table:{
                headerRows: 1,
                widths: [ '*', 100 ],
                body:[[{	
                        table:{ 
                        widths: [ '*' ],
                        body: [	
                            
                            [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                        ]},
                        border:[false, false, false, false]
                        },						
                        {	
                        table: { 
                        headerRows: 1,
                        widths: [ 40, '*' ],
                        body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                 { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                ],
                                [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                 { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                ]
                              ]
                        },
                        border:[false, false, false, false]
                        }	
                   ]]
                }
            },
            
            { text:'\n'},
            
            { table:{
                headerRows: 1,
                widths: [ '65%', '*' ],		
                  body: [
                        [ { text:'URL : '+event.virtual_url+'\n User Name : '+event.virtual_hostid+'\n Password : '+event.virtual_user_pass+'\n', alignment:'left', fontSize:10, border:[false, false, false, false]}
                        ]
                        ]
                }
            },	
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: '\n' },
            { text: 'Logistics and Documents', style:'header1' },
            { text: 'Exhibitor Documents', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 160, '*'],
                  body: documentlist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },	
            { text:'\n'},
            { text: 'Staff Information', style:'header1' },
            { text: 'Staff Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 70, 95,'*', 150, 80],
                  body: stafflist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: staffnotes
                }
            },
            { text:'\n'},
            { text: 'Marketing Information', style:'header1' },
            { text: 'Marketing Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 160,100, '*'],
                  body: marketinglist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: marketingnotes
                }
            },
            { text:'\n'},
            { text: 'Vendor Information', style:'header1' },
            { text: 'Vendor Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 80, 80,'*', 160],
                  body: vendorlist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: vendornotes
                }
            },
            {text:'\n'},
            { text: 'Budget Information', style:'header1' },
            { text: 'Exhibition Cost', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 100,100],
                  body: exhibitorcostList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n\n'},
            { text: 'Marketing Cost', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 100,100],
                  body: marketingcostList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n\n'},
            { text: 'Traveling Cost', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 100,100],
                  body: travelcostList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n\n'},
            { text: 'Net Total Cost', style:'label' },
            { 	table: {
                widths: [ 100,100],
                    body: [
                        [{text:'Total Exhibition Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_exhibitorcost+'',alignment:'right', style:'header3'}],
                        [{text:'Total Marketing Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_marketingcost+'',alignment:'right', style:'header3'}],
                        [{text:'Total Travel Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_travelcost+'',alignment:'right', style:'header3'}],
                        [{text:'Net Total' ,alignment:'right', style:'header2'}, {text:''+currency+''+budget[0].total_cost+'',alignment:'right',style:'header3'}],
                    ]
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },	
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: budgetnotes
                }
            },
        ],
        styles:{
            header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
            header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
            header2:{fontSize:10},
            header4:{fontSize:10},
            header3:{fontSize:10, alignment:'right'},
            tableHeader:{fillColor:'#1160a6', color:'white'},
            label:{fontSize:11},
         }
    };

}

}
 



if(steps == 7){
    if(event.event_type === 'In Person'){   
        var documentDefinition = {
            pageOrientation: 'page',
           // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
            content: 
             [
                 {
                     text:''+event.eventname+'\n\n',
                     style:'header'
                 },
                 {  table:{
                    headerRows: 1,
                    widths: [ '*', 100 ],
                    body:[[{	
                            table:{ 
                            widths: [ '*' ],
                            body: [	
                                
                                [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                            ]},
                            border:[false, false, false, false]
                            },						
                            {	
                            table: { 
                            headerRows: 1,
                            widths: [ 40, '*' ],
                            body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                     { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                    ],
                                    [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                     { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                    ]
                                  ]
                            },
                            border:[false, false, false, false]
                            }	
                       ]]
                    }
                },
                
                { text:'\n'},
                
                { table:{
                    headerRows: 1,
                    widths: [ '65%', '*' ],		
                      body: [
                            [ { text:'Address : \n'+event.address1+' '+event.address2+' \n '+event.city+' '+event.state+' '+event.zipcode+'\n', alignment:'left', fontSize:11, border:[false, false, false, false]}, 
                            ]
                            ]
                    }
                },	
                { text: '\n' },
                { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: '\n' },
            { text: 'Logistics and Documents', style:'header1' },
                { text: 'Exhibitor Documents', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 160, '*'],
                      body: documentlist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                },
                },	
                { text: '\n' },
                { text: 'Product Ordering Options', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*', 150, 80],
                   body: orderList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
 
             { text: '\n' },
                { text: ' Shipping Products', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*',80, 80,'*'],
                   body: shippingList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                },
               },	
                { text:'\n'},
                { text: 'Staff Information', style:'header1' },
                { text: 'Staff Details', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 70, 95,'*', 150, 80],
                      body: stafflist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                }
                },
                { text:'\n'},
                { text: 'Hotel Details', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 70,'*','*', '*', '*'],
                      body: hotellist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                }
                },
                { text: '\n' },
                { text: 'Meetings Details', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*',80, 80,'*'],
                   body: meetinglist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: staffnotes
                }
            },	
             { text:'\n'},
             { text: 'Marketing Information', style:'header1' },
             { text: 'Marketing Details', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 160,100, '*'],
                   body: marketinglist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: marketingnotes
                }
            },
             { text:'\n'},
             { text: 'Vendor Information', style:'header1' },
             { text: 'Vendor Details', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 80, 80,'*', 160],
                   body: vendorlist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: vendornotes
                }
            },
             {text:'\n'},
             { text: 'Budget Information', style:'header1' },
             { text: 'Exhibition Cost', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 100,100],
                   body: exhibitorcostList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             {text:'\n\n'},
             { text: 'Marketing Cost', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 100,100],
                   body: marketingcostList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             {text:'\n\n'},
             { text: 'Traveling Cost', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 100,100],
                   body: travelcostList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             {text:'\n\n'},
             { text: 'Net Total Cost', style:'label' },
             { 	table: {
                 widths: [ 100,100],
                     body: [
                         [{text:'Total Exhibition Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_exhibitorcost+'',alignment:'right', style:'header3'}],
                         [{text:'Total Marketing Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_marketingcost+'',alignment:'right', style:'header3'}],
                         [{text:'Total Travel Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_travelcost+'',alignment:'right', style:'header3'}],
                         [{text:'Net Total' ,alignment:'right', style:'header2'}, {text:''+currency+''+budget[0].total_cost+'',alignment:'right',style:'header3'}],
                     ]
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: budgetnotes
                }
            },
             {text:'\n'},
            { text: 'ROI Report', style:'header1' },
            
            { 	table: {
                widths: [ 100,100],
                    body: [
                        [{text:'Exhibition Total Cost' , style:'header4'}, {text:''+currency+''+budget[0].total_cost+'', style:'header3'}],
                        [{text:'Total Event Sales' , style:'header4'}, {text:''+currency+''+roireports.total_sales+'', style:'header3'}],
                        [{text:'Total Leads' , style:'header4'}, {text:''+roireports.total_leads+'', style:'header3'}],
                        [{text:'Cost/Leads' , style:'header4'}, {text:''+currency+''+roireports.cost_per_leads+'', style:'header3'}],
                    ]
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },
            {text:'\n'},
            { text: 'Total Number of Brand Impression', style:'label' },	
            { 	table: {
                widths: [ 150,100],
                    body: [
                        [{text:'How Many Attendees Total' , style:'header4'}, {text:''+roireports.total_number_attendees+'', style:'header3'}],
                        [{text:'How Many Attendees Visited Our Booth' , style:'header4'}, {text:''+roireports.visiter_booth+'', style:'header3'}],
                        [{text:'Number of Media Impressions' , style:'header4'}, {text:''+roireports.total_media_impressions+'', style:'header3'}],
                        [{text:'Social Media Mentions' , style:'header4'}, {text:''+roireports.social_media_mentions+'', style:'header3'}],
                        [{text:'Media Contacts' , style:'header4'}, {text:''+roireports.media_contacts_link+'', style:'header3'}],
                    ]
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },
            {text:'\n'},
            { text: 'Knowledge Transfer and Industry Trends',bold:true, style:'label' },
            {text:'\n'},
            {
            text:[
                { text: 'Certification Name : ',bold:true, style:'header2' },
                { text: ''+roireports.certifications+'', style:'header2' },
                ]
            },
            {
            text:[
                { text: 'Slide Decks : ',bold:true, style:'header2' },
                { text: ''+roireports.slide_deck+'', style:'header2' },
                ]
            },
            {
            text:[
                { text: 'Total Number Exhibitors : ',bold:true, style:'header2' },
                { text: ''+roireports.other_exhibitors_list+'', style:'header2' },
                {text:'   '},
                { text: ''+roireports.totalexhibitorfile+'', style:'header2' },
                ]
            },
            { text: '\n\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: roinotes
                }
            },
         ],
         styles:{
            header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
            header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
            header2:{fontSize:10},
            header4:{fontSize:10},
            header3:{fontSize:10, alignment:'right'},
            tableHeader:{fillColor:'#1160a6', color:'white'},
            label:{fontSize:11},
         }
    };
}else{
    var documentDefinition = {
        pageOrientation: 'page',
       // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
        content: 
         [
             {
                 text:''+event.eventname+'\n\n',
                 style:'header'
             },
             {  table:{
                headerRows: 1,
                widths: [ '*', 100 ],
                body:[[{	
                        table:{ 
                        widths: [ '*' ],
                        body: [	
                            
                            [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                        ]},
                        border:[false, false, false, false]
                        },						
                        {	
                        table: { 
                        headerRows: 1,
                        widths: [ 40, '*' ],
                        body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                 { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                ],
                                [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                 { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                ]
                              ]
                        },
                        border:[false, false, false, false]
                        }	
                   ]]
                }
            },
            
            { text:'\n'},
            
            { table:{
                headerRows: 1,
                widths: [ '65%', '*' ],		
                  body: [
                        [  { text:'URL : '+event.virtual_url+'\n User Name : '+event.virtual_hostid+'\n Password : '+event.virtual_user_pass+'\n', alignment:'left', fontSize:10, border:[false, false, false, false]}
                        ]
                        ]
                }
            },	
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            { text: '\n' },
            { text: 'Logistics and Documents', style:'header1' },
            { text: 'Exhibitor Documents', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 160, '*'],
                  body: documentlist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },	
            { text:'\n'},
            { text: 'Staff Information', style:'header1' },
            { text: 'Staff Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 70, 95,'*', 150, 80],
                  body: stafflist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: staffnotes
                }
            },
            { text:'\n'},
            { text: 'Marketing Information', style:'header1' },
            { text: 'Marketing Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 160,100, '*'],
                  body: marketinglist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: marketingnotes
                }
            },
            { text:'\n'},
            { text: 'Vendor Information', style:'header1' },
            { text: 'Vendor Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 80, 80,'*', 160],
                  body: vendorlist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: vendornotes
                }
            },
            {text:'\n'},
            { text: 'Budget Information', style:'header1' },
            { text: 'Exhibition Cost', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 100,100],
                  body: exhibitorcostList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n\n'},
            { text: 'Marketing Cost', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 100,100],
                  body: marketingcostList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n\n'},
            { text: 'Traveling Cost', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 100,100],
                  body: travelcostList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n\n'},
            { text: 'Net Total Cost', style:'label' },
            { 	table: {
                widths: [ 100,100],
                    body: [
                        [{text:'Total Exhibition Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_exhibitorcost+'',alignment:'right', style:'header3'}],
                        [{text:'Total Marketing Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_marketingcost+'',alignment:'right', style:'header3'}],
                        [{text:'Total Travel Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_travelcost+'',alignment:'right', style:'header3'}],
                        [{text:'Net Total' ,alignment:'right', style:'header2'}, {text:''+currency+''+budget[0].total_cost+'',alignment:'right',style:'header3'}],
                    ]
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },	
            { text: '\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: budgetnotes
                }
            },
            {text:'\n'},
            { text: 'ROI Report', style:'header1' },
            
            { 	table: {
                widths: [ 100,100],
                    body: [
                        [{text:'Exhibition Total Cost' , style:'header4'}, {text:''+currency+''+budget[0].total_cost+'', style:'header3'}],
                        [{text:'Total Event Sales' , style:'header4'}, {text:''+currency+''+roireports.total_sales+'', style:'header3'}],
                        [{text:'Total Leads' , style:'header4'}, {text:''+roireports.total_leads+'', style:'header3'}],
                        [{text:'Cost/Leads' , style:'header4'}, {text:''+currency+''+roireports.cost_per_leads+'', style:'header3'}],
                    ]
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },
            {text:'\n'},
            { text: 'Total Number of Brand Impression', style:'label' },	
            { 	table: {
                widths: [ 150,100],
                    body: [
                        [{text:'How Many Attendees Total' , style:'header4'}, {text:''+roireports.total_number_attendees+'', style:'header3'}],
                        [{text:'How Many Attendees Visited Our Booth' , style:'header4'}, {text:''+roireports.visiter_booth+'', style:'header3'}],
                        [{text:'Number of Media Impressions' , style:'header4'}, {text:''+roireports.total_media_impressions+'', style:'header3'}],
                        [{text:'Social Media Mentions' , style:'header4'}, {text:''+roireports.social_media_mentions+'', style:'header3'}],
                        [{text:'Media Contacts' , style:'header4'}, {text:''+roireports.media_contacts_link+'', style:'header3'}],
                    ]
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },
            {text:'\n'},
            { text: 'Knowledge Transfer and Industry Trends',bold:true, style:'label' },
            {text:'\n'},
            {
            text:[
                { text: 'Certification Name : ',bold:true, style:'header2' },
                { text: ''+roireports.certifications+'', style:'header2' },
                ]
            },
            {
            text:[
                { text: 'Slide Decks : ',bold:true, style:'header2' },
                { text: ''+roireports.slide_deck+'', style:'header2' },
                ]
            },
            {
            text:[
                { text: 'Total Number Exhibitors : ',bold:true, style:'header2' },
                { text: ''+roireports.other_exhibitors_list+'', style:'header2' },
                {text:'   '},
                { text: ''+roireports.totalexhibitorfile+'', style:'header2' },
                ]
            },
            { text: '\n\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: roinotes
                }
            },
            
        ],
        styles:{
            header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
            header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
            header2:{fontSize:10},
            header4:{fontSize:10},
            header3:{fontSize:10, alignment:'right'},
            tableHeader:{fillColor:'#1160a6', color:'white'},
            label:{fontSize:11},
         }
    };

}

}
if(steps == 8){
    if(event.event_type === 'In Person'){   
        var documentDefinition = {
            pageOrientation: 'page',
           // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
            content: 
             [
                 {
                     text:''+event.eventname+'\n\n',
                     style:'header'
                 },
                 {  table:{
                    headerRows: 1,
                    widths: [ '*', 100 ],
                    body:[[{	
                            table:{ 
                            widths: [ '*' ],
                            body: [	
                                
                                [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                                [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                            ]},
                            border:[false, false, false, false]
                            },						
                            {	
                            table: { 
                            headerRows: 1,
                            widths: [ 40, '*' ],
                            body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                     { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                    ],
                                    [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                     { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                    ]
                                  ]
                            },
                            border:[false, false, false, false]
                            }	
                       ]]
                    }
                },
                
                { text:'\n'},
                
                { table:{
                    headerRows: 1,
                    widths: [ '65%', '*' ],		
                      body: [
                            [ { text:'Address :\n '+event.address1+' '+event.address2+' \n  '+event.city+' '+event.state+' '+event.zipcode+'\n', alignment:'left', fontSize:11, border:[false, false, false, false]}, 
                            ]
                            ]
                    }
                },	
                { text: '\n' },
                { table:{
                    headerRows: 1,
                    widths: [ '100%', '*' ],		
                      body: eventnotes
                    }
                },
                { text: '\n' },
                { text: 'Logistics and Documents', style:'header1' },
                { text: 'Exhibitor Documents', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 160, '*'],
                      body: documentlist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                },
                },	
                { text: '\n' },
                { text: 'Product Ordering Options', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*', 150, 80],
                   body: orderList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
 
             { text: '\n' },
                { text: ' Shipping Products', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*',80, 80,'*'],
                   body: shippingList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                },
               },	
                { text:'\n'},
                { text: 'Staff Information', style:'header1' },
                { text: 'Staff Details', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 70, 95,'*', 150, 80],
                      body: stafflist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                }
                },
                { text:'\n'},
                { text: 'Hotel Details', style:'label' },
                { table:{
                    headerRows: 1,
                    widths: [ 70,'*','*', '*', '*'],
                      body: hotellist
                    },
                    layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                    vLineColor: function (i, node) {
                        return  '#1160a6';
                    },
                }
                },
                { text: '\n' },
                { text: 'Meetings Details', style:'label' },
                { table:{
                 headerRows: 1,
                 widths: [  70, 95,'*',80, 80,'*'],
                   body: meetinglist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: staffnotes
                }
            },
             { text:'\n'},
             { text: 'Marketing Information', style:'header1' },
             { text: 'Marketing Details', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 160,100, '*'],
                   body: marketinglist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: marketingnotes
                }
            },
             { text:'\n'},
             { text: 'Vendor Information', style:'header1' },
             { text: 'Vendor Details', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 80, 80,'*', 160],
                   body: vendorlist
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: vendornotes
                }
            },
             {text:'\n'},
             { text: 'Budget Information', style:'header1' },
             { text: 'Exhibition Cost', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 100,100],
                   body: exhibitorcostList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             {text:'\n\n'},
             { text: 'Marketing Cost', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 100,100],
                   body: marketingcostList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             {text:'\n\n'},
             { text: 'Traveling Cost', style:'label' },
             { table:{
                 headerRows: 1,
                 widths: [ 100,100],
                   body: travelcostList
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             }
             },
             {text:'\n\n'},
             { text: 'Net Total Cost', style:'label' },
             { 	table: {
                 widths: [ 100,100],
                     body: [
                         [{text:'Total Exhibition Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_exhibitorcost+'',alignment:'right', style:'header3'}],
                         [{text:'Total Marketing Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_marketingcost+'',alignment:'right', style:'header3'}],
                         [{text:'Total Travel Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_travelcost+'',alignment:'right', style:'header3'}],
                         [{text:'Net Total' ,alignment:'right', style:'header2'}, {text:''+currency+''+budget[0].total_cost+'',alignment:'right',style:'header3'}],
                     ]
                 },
                 layout: {
                 hLineWidth: function (i, node) {
                     return  1;
                 },
                 vLineWidth: function (i, node) {
                     return  1;
                 },
                 hLineColor: function (i, node) {
                     return  '#1160a6';
                 },
                 vLineColor: function (i, node) {
                     return  '#1160a6';
                 },
             },
             },	
             { text: '\n' },
             { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: budgetnotes
                }
            },
             {text:'\n'},
            { text: 'ROI Report', style:'header1' },
            
            { 	table: {
                widths: [ 100,100],
                    body: [
                        [{text:'Exhibition Total Cost' , style:'header4'}, {text:''+currency+''+budget[0].total_cost+'', style:'header3'}],
                        [{text:'Total Event Sales' , style:'header4'}, {text:''+currency+''+roireports.total_sales+'', style:'header3'}],
                        [{text:'Total Leads' , style:'header4'}, {text:''+roireports.total_leads+'', style:'header3'}],
                        [{text:'Cost/Leads' , style:'header4'}, {text:''+currency+''+roireports.cost_per_leads+'', style:'header3'}],
                    ]
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },
            {text:'\n'},
            { text: 'Total Number of Brand Impression', style:'label' },	
            { 	table: {
                widths: [ 150,100],
                    body: [
                        [{text:'How Many Attendees Total' , style:'header4'}, {text:''+roireports.total_number_attendees+'', style:'header3'}],
                        [{text:'How Many Attendees Visited Our Booth' , style:'header4'}, {text:''+roireports.visiter_booth+'', style:'header3'}],
                        [{text:'Number of Media Impressions' , style:'header4'}, {text:''+roireports.total_media_impressions+'', style:'header3'}],
                        [{text:'Social Media Mentions' , style:'header4'}, {text:''+roireports.social_media_mentions+'', style:'header3'}],
                        [{text:'Media Contacts' , style:'header4'}, {text:''+roireports.media_contacts_link+'', style:'header3'}],
                    ]
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },
            {text:'\n'},
            { text: 'Knowledge Transfer and Industry Trends',bold:true, style:'label' },
            {text:'\n'},
            {
            text:[
                { text: 'Certification Name : ',bold:true, style:'header2' },
                { text: ''+roireports.certifications+'', style:'header2' },
                ]
            },
            {
            text:[
                { text: 'Slide Decks : ',bold:true, style:'header2' },
                { text: ''+roireports.slide_deck+'', style:'header2' },
                ]
            },
            {
            text:[
                { text: 'Total Number Exhibitors : ',bold:true, style:'header2' },
                { text: ''+roireports.other_exhibitors_list+'', style:'header2' },
                {text:'   '},
                { text: ''+roireports.totalexhibitorfile+'', style:'header2' },
                ]
            },
            { text: '\n\n' },
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: roinotes
                }
            },
            {text:'\n'},
            { text: 'Post Event', style:'header1' },
            { text: 'Events Follow Ups', style:'label' },
            { table:{
                headerRows: 1,
                widths: ['*'],
                  body: followupstList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n\n'},
            {text:[{ text: 'What Would Have Made the Event More Successful?\n', style:'label' },
           
            {text:''+postevent[0].event_success_reasons+'',style:'header2'}
            
            ]},
            {text:'\n'},
            {text:[{ text: 'Would You Repeat This Event?\n', style:'label' },
           
            {text:''+postevent[0].repeat_event +'',style:'header2'}
            
            ]},
            
         ],
         styles:{
            header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
            header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
            header2:{fontSize:10},
            header4:{fontSize:10},
            header3:{fontSize:10, alignment:'right'},
            tableHeader:{fillColor:'#1160a6', color:'white'},
            label:{fontSize:11},
         }
    };
}else{
    var documentDefinition = {
        pageOrientation: 'page',
       // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
        content: 
         [
             {
                 text:''+event.eventname+'\n\n',
                 style:'header'
             },
             {  table:{
                headerRows: 1,
                widths: [ '*', 100 ],
                body:[[{	
                        table:{ 
                        widths: [ '*' ],
                        body: [	
                            
                            [{ text: 'Conference/Tradeshow Name : '+event.eventname+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Contact Name : '+event.organizer+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Phone : '+event.phone+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Email : '+event.email+'', alignment:'left', border:[false, false, false, false] },],
                            [{ text: 'Event Type : '+event.event_type+'', alignment:'left', border:[false, false, false, false] },]
                        ]},
                        border:[false, false, false, false]
                        },						
                        {	
                        table: { 
                        headerRows: 1,
                        widths: [ 40, '*' ],
                        body: [ [{ text: 'ID :', alignment:'right', border:[false, false, false, false]}, 
                                 { text: event.id, alignment:'right', bold:true, border:[false, false, false, false] } 
                                ],
                                [{ text: 'Date :', alignment:'right', border:[false, false, false, false] }, 
                                 { text: ''+newdt+'', alignment:'right', border:[false, false, false, false]} 
                                ]
                              ]
                        },
                        border:[false, false, false, false]
                        }	
                   ]]
                }
            },
           
            { text:'\n'},
            
            { table:{
                headerRows: 1,
                widths: [ '65%', '*' ],		
                  body: [	
                        [ { text:'URL : '+event.virtual_url+'\n User Name : '+event.virtual_hostid+'\n Password : '+event.virtual_user_pass+'\n', alignment:'left', fontSize:10, border:[false, false, false, false]}
                        ]
                        ]
                }
            },
            {text:'\n'},
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: eventnotes
                }
            },
            {text:'\n'},
            { text: 'Logistics and Documents', style:'header1' },
            { text: 'Exhibitor Documents', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 160, '*'],
                  body: documentlist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },	
            { text:'\n'},
            { text: 'Staff Information', style:'header1' },
            { text: 'Staff Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 70, 95,'*', 150, 80],
                  body: stafflist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n'},
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: staffnotes
                }
            },
            { text:'\n'},
            { text: 'Marketing Information', style:'header1' },
            { text: 'Marketing Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 160,100, '*'],
                  body: marketinglist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n'},
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: marketingnotes
                }
            },
            { text:'\n'},
            { text: 'Vendor Information', style:'header1' },
            { text: 'Vendor Details', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 80, 80,'*', 160],
                  body: vendorlist
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n'},
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: vendornotes
                }
            },
            {text:'\n'},
            { text: 'Budget Information', style:'header1' },
            { text: 'Exhibition Cost', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 100,100],
                  body: exhibitorcostList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n\n'},
            { text: 'Marketing Cost', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 100,100],
                  body: marketingcostList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n\n'},
            { text: 'Traveling Cost', style:'label' },
            { table:{
                headerRows: 1,
                widths: [ 100,100],
                  body: travelcostList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n\n'},
            { text: 'Net Total Cost', style:'label' },
            { 	table: {
                widths: [ 100,100],
                    body: [
                        [{text:'Total Exhibition Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_exhibitorcost+'',alignment:'right', style:'header3'}],
                        [{text:'Total Marketing Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_marketingcost+'',alignment:'right', style:'header3'}],
                        [{text:'Total Travel Cost' , style:'header2'}, {text:''+currency+''+budget[0].total_travelcost+'',alignment:'right', style:'header3'}],
                        [{text:'Net Total' ,alignment:'right', style:'header2'}, {text:''+currency+''+budget[0].total_cost+'',alignment:'right',style:'header3'}],
                    ]
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },
            {text:'\n'},	
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: budgetnotes
                }
            },
            {text:'\n'},
            { text: 'ROI Report', style:'header1' },
            
            { 	table: {
                widths: [ 100,100],
                    body: [
                        [{text:'Exhibition Total Cost' , style:'header4'}, {text:''+currency+''+budget[0].total_cost+'', style:'header3'}],
                        [{text:'Total Event Sales' , style:'header4'}, {text:''+currency+''+roireports.total_sales+'', style:'header3'}],
                        [{text:'Total Leads' , style:'header4'}, {text:''+roireports.total_leads+'', style:'header3'}],
                        [{text:'Cost/Leads' , style:'header4'}, {text:''+currency+''+roireports.cost_per_leads+'', style:'header3'}],
                    ]
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },
            {text:'\n'},
            { text: 'Total Number of Brand Impression', style:'label' },	
            { 	table: {
                widths: [ 150,100],
                    body: [
                        [{text:'How Many Attendees Total' , style:'header4'}, {text:''+roireports.total_number_attendees+'', style:'header3'}],
                        [{text:'How Many Attendees Visited Our Booth' , style:'header4'}, {text:''+roireports.visiter_booth+'', style:'header3'}],
                        [{text:'Number of Media Impressions' , style:'header4'}, {text:''+roireports.total_media_impressions+'', style:'header3'}],
                        [{text:'Social Media Mentions' , style:'header4'}, {text:''+roireports.social_media_mentions+'', style:'header3'}],
                        [{text:'Media Contacts' , style:'header4'}, {text:''+roireports.media_contacts_link+'', style:'header3'}],
                    ]
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            },
            },
            {text:'\n'},
            { text: 'Knowledge Transfer and Industry Trends',bold:true, style:'label' },
            {text:'\n'},
            {
            text:[
                { text: 'Certification Name : ',bold:true, style:'header2' },
                { text: ''+roireports.certifications+'', style:'header2' },
                ]
            },
            {
            text:[
                { text: 'Slide Decks : ',bold:true, style:'header2' },
                { text: ''+roireports.slide_deck+'', style:'header2' },
                ]
            },
            {
            text:[
                { text: 'Total Number Exhibitors : ',bold:true, style:'header2' },
                { text: ''+roireports.other_exhibitors_list+'', style:'header2' },
                {text:'   '},
                { text: ''+roireports.totalexhibitorfile+'', style:'header2' },
                ]
            },
            {text:'\n\n'},
            { table:{
                headerRows: 1,
                widths: [ '100%', '*' ],		
                  body: roinotes
                }
            },
            {text:'\n'},
            { text: 'Post Event', style:'header1' },
            { text: 'Events Follow Ups', style:'label' },
            { table:{
                headerRows: 1,
                widths: ['*'],
                  body: followupstList
                },
                layout: {
                hLineWidth: function (i, node) {
                    return  1;
                },
                vLineWidth: function (i, node) {
                    return  1;
                },
                hLineColor: function (i, node) {
                    return  '#1160a6';
                },
                vLineColor: function (i, node) {
                    return  '#1160a6';
                },
            }
            },
            {text:'\n\n'},
            {text:[{ text: 'What Would Have Made the Event More Successful?\n', style:'label' },
           
            {text:''+postevent[0].event_success_reasons+'',style:'header2'}
            
            ]},
            {text:'\n'},
            {text:[{ text: 'Would You Repeat This Event?\n', style:'label' },
           
            {text:''+postevent[0].repeat_event +'',style:'header2'}
            
            ]},
    
            
        ],
        styles:{
            header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
            header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
            header2:{fontSize:10},
            header4:{fontSize:10},
            header3:{fontSize:10, alignment:'right'},
            tableHeader:{fillColor:'#1160a6', color:'white'},
            label:{fontSize:11},
         }
    };

}

}
 
    //console.log(documentDefinition);  
    const {vfs} = vfsFonts.pdfMake;
    pdfMake.vfs = vfs; 
    if(options == 'open'){  
    pdfMake.createPdf(documentDefinition).open(); 
    }else if(options == 'download'){
     pdfMake.createPdf(documentDefinition).download(); 
    }else{
     pdfMake.createPdf(documentDefinition).print(); 
    }
    return ;
}

export const PdfDoc = {    
    openPdf: function(id, header){ 
        console.log(header); 
       
        var dd=getDocumentDefnition(id, header, 'open');
      
    },

    DownloadPdf: function(id, header){  
        getDocumentDefnition(id, header, 'download');
    },

    PrintPdf: function(id, header){  
        getDocumentDefnition(id, header, 'print');
    }
}