///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Orders = {
	product : '', 
	eventsId : '', 
	step : '',
	service_name:'',
	service_phone:'',
	service_email:'',
	last_date : '', 
	provider : '', 
	url : '', 
	userid : '', 
	password : '', 
	notes : '', 
	status : '', 
	resources : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}