///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Tasks = {
	task : '', 
	staffname : '',
	eventsId : '', 
	firstname : '', 
	lastname : '', 
	details : '',
	due_date : '',
	description : '', 
	start_datetime : '', 
	start_datetime : '', 
	calendarlink : '', 
	notes : '', 
	status : '', 
	eventsId : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}