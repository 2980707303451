/////////////////////////////////////////////////
// Warning : Propeerty of Deemsoft, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
//////////////////////////////////////////////////////

import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { Store, Persistor } from './services/Store';
import AuthorizedRoute from './commons/AuthorizedRoute';
import AppLayout from './commons/AppLayout';
import Login from './commons/Login';
import Logout from './commons/Logout';
import Signup from './commons/Signup';
import Password from './commons/Password';
import UserActivate from './commons/UserActivate';
import ResetPassword from './commons/ResetPassword';
import Test from './commons/Test';
export default class App extends React.Component {
	render() {  
		return (
			<Provider store={Store}>
			<PersistGate loading={null} persistor={Persistor}>			
			<Router basename="/" >
				 <Switch>
				  <Route path="/" exact component={Login} />
				  <Route path="/test" component={Test} />
				  <Route path="/logout" component={Logout} />
				  <Route path="/login" component={Login} />
				  <Route path="/signup" component={Signup} />
				  <Route path="/password" component={Password} />
				  <Route path="/resetpassword" component={ResetPassword} />
				  <Route path="/useractivate" component={UserActivate} />
				  <AuthorizedRoute path="/app" component={AppLayout} />				 
				  <Redirect to="/" />
				</Switch>
			</Router>
			</PersistGate>			
			</Provider>
		); 
	}
}
