///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Transactions = {
	orderid : '',
    cardnumber : '',
	description:'',
	transaction_type : '', 
	status:'',
	amount:'',
	events_Id : '',
	id : '', 
	accountid : '',
	billing_date:'',
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}