///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';


export default class Footer extends React.Component {
  render() {
    return (
	 <div >
		
	  </div>
	);
	}
}