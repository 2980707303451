const initialState = {
  pending: true,
  logged: false,
  header:{},
  userid:'',
  email:'',
  name:'',
  phone:'',
  groupid:''
}

const LoggedInReducer = (state = initialState, action) => {
  
  if (action.type === 'GET_LOGGED_USER') {
    return Object.assign({}, state, {
      pending: false
    })
  }
  
  if (action.type === 'SET_LOGGED_USER') {
    return Object.assign({}, state, {
      pending: false,
      logged: action.logged,
      header: action.header,
      email: action.email,
      name: action.name,
      phone: action.phone,
      userid: action.userid,
      groupid: action.groupid,
      accountid:action.accountid,
    })
  }
  
  return state;
}

export default LoggedInReducer;