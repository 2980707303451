////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {Button,Modal} from 'react-bootstrap';
import { FaPen,FaTrash} from 'react-icons/fa';
import { Validators } from '../utils/Validators';
import { DateTime } from '../utils/DateTime';
import { base_url } from '../constants/Constants';
import {Meetings } from '../models/Meetings';
import '../styles/Styles.css';


class MeetingsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
			edit : true,
			showmeet:false,
			validations:{},
			showEdit : false,
			formObj : Meetings,
			meetingList : [],
		}

		this.onSubmit = this.onSubmit.bind(this);
		this.editMeetingInfo = this.editMeetingInfo.bind(this);
		this.changeMeeting = this.changeMeeting.bind(this);
		this.validate = this.validate.bind(this);
	}

	componentDidMount(){
		let validtemp={};
		for (let key in Meetings) { validtemp[key] = ""; }
		this.setState({validations:validtemp});

		axios.get(base_url+'meetings/listbyeventid/'+this.props.eventid,  this.props.user.header )
			.then(res =>{
                if(res.data.length > 0){
				 this.setState({ meetingList:res.data });
				}else{
					var temp={};
					temp={id:0,events_id:this.props.eventid,status:'Active',staffname:"<Enter Staff Name>",meeting_person:"<Enter Person Name>",location:"<Enter Location>",start_datetime:"12-12-2020",end_datetime:"12-14-2020", calendarlink:"<Enter Calendar Link>"};
					axios.post(base_url+'meetings/save', temp, this.props.user.header ).then(res =>{
						axios.get(base_url+'meetings/listbyeventid/'+this.props.eventid,  this.props.user.header )
						.then(res =>{
							 this.setState({ meetingList:res.data });
							}).catch(function (error) { console.log(error); });
					}).catch(function (error) { console.log(error); }); 
				}
		}).catch(function (error) { console.log(error); }); 	
	}
	editMeetingInfo = (row) => {
		if(row != undefined && row >=0 && this.state.meetingList.length >0){
			let temp={id:"",_id:"",staffname:"",meeting_person:"",location:"",start_datetime:"",end_datetime:"",calendarlink:""};
		
			temp.id=this.state.meetingList[row].id;
			temp._id=this.state.meetingList[row]._id;

			if(this.state.meetingList[row].staffname === '<Enter Staff Name>'){
				temp.staffname="";
			}else{
				temp.staffname=this.state.meetingList[row].staffname;
			}
			if(this.state.meetingList[row].meeting_person === '<Enter Person Name>'){
				temp.meeting_person="";
			}else{
				temp.meeting_person=this.state.meetingList[row].meeting_person;
			}
			if(this.state.meetingList[row].location === '<Enter Location>'){
				temp.location="";
			}else{
				temp.location=this.state.meetingList[row].location;
			}
			if(this.state.meetingList[row].calendarlink === '<Enter Calendar Link>'){
				temp.calendarlink="";
			}else{
				temp.calendarlink=this.state.meetingList[row].calendarlink;
			}
			if(this.state.meetingList[row].start_datetime === "12-12-2020"){
				temp.start_datetime="";
			}else{
				temp.start_datetime=this.state.meetingList[row].start_datetime;
			}
			if(this.state.meetingList[row].end_datetime === "12-14-2020"){
				temp.end_datetime="";
			}else{
				temp.end_datetime=this.state.meetingList[row].end_datetime;
			}

			this.setState({formObj:temp});
			}
        this.setState({showmeet:!this.state.showmeet});
	}

	meetingModal = () => {
		let temp={staffname:"",meeting_person:"",location:"",start_datetime:"",end_datetime:"",calendarlink:""};
		this.setState({ formObj:temp });let temps='';
		for (let key in this.state.validations) { temps += this.state.validations[key];}
	    if(temps !== "" ){
			this.setState({ validations:temp });
		}
        this.setState({showmeet:!this.state.showmeet});
	}
	validate = (val) =>{
		let tempvalid = this.state.validations;
		switch(val){
			case "staffname": { tempvalid.staffname = Validators.name(this.state.formObj.staffname); } break;
			case "meeting_person": { tempvalid.meeting_person = Validators.name(this.state.formObj.meeting_person); } break;
			case "location": { tempvalid.location = Validators.name(this.state.formObj.location); } break;
			case "start_datetime": { tempvalid.start_datetime = Validators.date(this.state.formObj.start_datetime); } break;
			case "end_datetime": { tempvalid.end_datetime = Validators.date(this.state.formObj.end_datetime); } break;
			case "calendarlink": { tempvalid.calendarlink = Validators.website(this.state.formObj.calendarlink); } break;
		
		}
		this.setState({validations:tempvalid});
	}
	changeMeeting = (val,e) =>{
		let tempform = this.state.formObj;
		switch(val){
			case "staffname": {var string = e.target.value; tempform.staffname =string.charAt(0).toUpperCase() + string.slice(1); } break;
			case "meeting_person": {var string = e.target.value; tempform.meeting_person = string.charAt(0).toUpperCase() + string.slice(1); } break;
			case "location": { var string = e.target.value; tempform.location = string.charAt(0).toUpperCase() + string.slice(1);} break;
			case "start_datetime": { tempform.start_datetime = e;  } break;
			case "end_datetime": { tempform.end_datetime = e;  } break;
			case "calendarlink": { tempform.calendarlink = e.target.value;  } break;
		}
		this.setState({formObj:tempform});
	}

	handleDeleteRow = (i) => {
		let meetingnewform=this.state.meetingList[i];
		meetingnewform.status='Inctive';
		meetingnewform.events_id=this.props.eventid;
			axios.post(base_url+'meetings/save', meetingnewform, this.props.user.header )
			.then(res =>{
			axios.get(base_url+'meetings/listbyeventid/'+this.props.eventid,  this.props.user.header )
			.then(res =>{
				 this.setState({ meetingList:res.data });
				}).catch(function (error) { console.log(error); }); 	
			}).catch(function (error) { console.log(error); });
	}

	render_Meetingslist(){
		if( this.state.meetingList != undefined && this.state.meetingList.length > 0){
		return this.state.meetingList.map((item, index) =>{
			let dt=new Date(item.start_datetime);
			var day = ("0" + dt.getDate()).slice(-2);
			var month = ("0" + (dt.getMonth() + 1)).slice(-2);
			var year = dt.getFullYear();
			var h = dt.getHours();
			var m =("0" + dt.getMinutes()).slice(-2);
			var _time1 = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
			let stime= month+'-'+day+'-'+year+'('+_time1+')';

			let dt1=new Date(item.end_datetime);
			var day = ("0" + dt1.getDate()).slice(-2);
			var month = ("0" + (dt1.getMonth() + 1)).slice(-2);
			var year = dt1.getFullYear();
			var time = dt1.getHours();
			var min =("0" + dt1.getMinutes()).slice(-2);
			var _time2 = (time > 12) ? (time-12 + ':' + min +' PM') : (time + ':' + min +' AM');
			let etime= month+'-'+day+'-'+year+'('+_time2+')';
		  return (
				<tr key={index} id={index}>
			    <td>{item.staffname}</td>
			     <td>{item.meeting_person}</td>
				  <td>{item.location}</td>
				  <td>{stime}</td>
				   <td>{etime}</td> 
				   <td>{item.calendarlink}
				   <span><FaPen size="15px" color="blue" onClick={()=>{this.editMeetingInfo(index)}}/>&nbsp;&nbsp;
				   <FaTrash size="15px" color="red" onClick={() => {this.handleDeleteRow(index)}} />&nbsp;&nbsp;</span></td>           
		   </tr>
		  ) 
		}, this);
	  }
	
	}

	onSubmit(){
		let temp='';
        for (let key in Meetings) { this.validate(key);}
        for (let key in this.state.validations) { temp += this.state.validations[key];}
	    if(temp == "" ){
		let meetingnewform=this.state.formObj;
		meetingnewform.status='Active';
		meetingnewform.events_id=this.props.eventid;
			axios.post(base_url+'meetings/save', meetingnewform, this.props.user.header )
			.then(res =>{
			axios.get(base_url+'meetings/listbyeventid/'+this.props.eventid,  this.props.user.header )
			.then(res =>{
				 this.setState({ meetingList:res.data });
				 {this.meetingModal()}
				}).catch(function (error) { console.log(error); }); 	
			}).catch(function (error) { console.log(error); });
		}
	}


	render() {
		return (
			<div id="idmeetings">
				<div className="d-flex row">
                 <div className="form-group col-md-12">
                    <label htmlFor="meetinfo" className="label-custom"> Add Your Meeting Info</label>
                     <table className="table table-bordered table-sm table-responsive-stack ">
                      <thead className="thead">
                          <tr>
                              <td>Staff Name</td>
                              <td>Whom</td>
                              <td>Location</td>
                              <td>Start Time</td>
                              <td>End Time</td>
                              <td>Calender Link</td>
                          </tr>
                      </thead>
                      <tbody>{this.render_Meetingslist()}</tbody>
                  </table>
			  <div className="p-2 col-md-12">
			  <div className="d-flex justify-content-end row">
                 <div className="p-2">
                     <button type="button" className="btn btn-primary btn-block btn-sm" onClick={()=>{this.meetingModal()}}>+Add</button>
                 </div>
				 </div>
               </div>
			   </div>
              </div>
                <Modal size="lg" show={this.state.showmeet} onHide={()=>{this.meetingModal()}} >
                   <Modal.Header closeButton>
                      <Modal.Title>Meeting Info</Modal.Title>
                   </Modal.Header>
                      <Modal.Body>
					  <div className="brdcolor">
                     <div className="form-row">
                         <div className="form-group col-md-4">
                         <label htmlFor="idstaffname" className="label-custom">Staff Name</label>
                     <input name="staffname" id="idstaffname" type="text" placeholder="Enter Staff Name" className="form-control form-control-sm" value={this.state.formObj.staffname} onChange={(e)=>{this.changeMeeting("staffname",e)}} onBlur={()=>{this.validate("staffname")}} style={this.state.validations.staffname==""?{}:{"borderColor":"red"}} />
								 <span className="errormsg">{this.state.validations.staffname}</span>
                    </div>
                    <div className="form-group col-md-4">
                         <label htmlFor="meeting_person" className="label-custom">Whom</label>
                         <input name="meeting_person" id="meeting_person" type="text" placeholder="" className="form-control form-control-sm" value={this.state.formObj.meeting_person} onChange={(e)=>{this.changeMeeting("meeting_person",e)}} onBlur={()=>{this.validate("meeting_person")}} style={this.state.validations.meeting_person==""?{}:{"borderColor":"red"}} />
								 <span className="errormsg">{this.state.validations.meeting_person}</span>
                    </div>
                    <div className="form-group col-md-4">
                         <label htmlFor="idlocation" className="label-custom">Location</label>
                         <input name="location" id="idlocation" type="text" placeholder="Enter Location" className="form-control form-control-sm" value={this.state.formObj.location} onChange={(e)=>{this.changeMeeting("location",e)}} onBlur={()=>{this.validate("location")}} style={this.state.validations.location==""?{}:{"borderColor":"red"}} />
								 <span className="errormsg">{this.state.validations.location}</span>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                     <label htmlFor="idstart_datetime" className="label-custom">Start Date</label><br/>
                     <DateTime name="start_datetime" 
					 	value={this.state.formObj.start_datetime}  
					    onChange={this.changeMeeting}
					    class_name={ this.state.validations.start_datetime==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
					    onBlur={this.validate}
				        /><br/> <span className="errormsg">{this.state.validations.start_datetime}</span>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="idend_datetime" className="label-custom">End Date</label><br/>
                      <DateTime name="end_datetime" 
					 	value={this.state.formObj.end_datetime}  
					    onChange={this.changeMeeting}
					    class_name={ this.state.validations.end_datetime==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
					    onBlur={this.validate}
				        /><br/> <span className="errormsg">{this.state.validations.end_datetime}</span>
                    </div>

                </div>
				<div className="form-row">
				<div className="form-group col-md-6">
                         <label htmlFor="idcalendarlink" className="label-custom">Calender Link</label>
                         <input name="calendarlink" id="idcalendarlink" type="text" placeholder="Enter Calender Link" className="form-control form-control-sm" value={this.state.formObj.calendarlink} onChange={(e)=>{this.changeMeeting("calendarlink",e)}} onBlur={()=>{this.validate("calendarlink")}} style={this.state.validations.calendarlink==""?{}:{"borderColor":"red"}} />
								 <span className="errormsg">{this.state.validations.calendarlink}</span>
                    </div>
				</div>
				</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="warning" onClick={()=>{this.meetingModal()}}>Close</Button>
					<Button variant="primary" onClick={()=>{this.onSubmit()}}>Save</Button>
				</Modal.Footer>
			</Modal>
      </div>
	)}
		
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(MeetingsForm);
