////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { FaPen,FaTrash } from 'react-icons/fa';
import { Validators } from '../utils/Validators';
import { DateTime } from '../utils/DateTime';
import { base_url } from '../constants/Constants';
import { Staff } from '../models/Staff';
import '../styles/Styles.css';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';


class StaffForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
      edit : true,
      showStaff:false,
			hide:false,
			showEdit : false,
      formObj : Staff,
      validations:{},
      staffList : [],
		}
		this.onSubmit = this.onSubmit.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.saveStaff = this.saveStaff.bind(this);
    this.validate = this.validate.bind(this);
    this.changeNewStaff =this.changeNewStaff.bind(this);
    this.getFormattedPhoneNum = this.getFormattedPhoneNum.bind(this);
    this.handleDeleteRow = this.handleDeleteRow.bind(this);
        
	}

	componentDidMount(){
    let validtemp={};
		for (let key in Staff) { validtemp[key] = ""; }
        this.setState({validations:validtemp});

    axios.get(base_url+'staff/listbyeventid/'+this.props.eventid,  this.props.user.header )
    .then(res =>{
    if(res.data.length>0){
     this.setState({ staffList:res.data });
    }else{
      var temp={};
      temp={id:0,events_id:this.props.eventid,status:'Active',staffname:"<Enter Staff Name>",email:"<Enter email>",phone:"(xxx) xxx-xxxx",dates_attending:"12-12-2020",notes:"<Enter Task>"};
      axios.post(base_url+'staff/save',temp, this.props.user.header ).then(res =>{
        console.log(res.data);
        axios.get(base_url+'staff/listbyeventid/'+this.props.eventid,  this.props.user.header )
        .then(res =>{
         this.setState({ staffList:res.data });
         console.log(res.data);
        }).catch(function (error) { console.log(error); });
      }).catch(function (error) { console.log(error); });
    }
    }).catch(function (error) { console.log(error); });  
    axios.get(base_url+'events/listbyeventid/'+this.props.eventid, this.props.header )
   .then(res =>{
      var temp1=res.data[0].start_date;
      var temp2=res.data[0].end_date;
      this.setState({start_date:temp1});
      this.setState({end_date:temp2});
    }).catch(function (error) { console.log(error); });    
 
  }

  handleModal = () =>{
   let temp={staffname:"",email:"",phone:"",dates_attending:"",role:"",notes:""};
   this.setState({ formObj:temp });
    let temps='';

		 for (let key in this.state.validations) { temps += this.state.validations[key];}
	    if(temps !== "" ){
			this.setState({ validations:temp });
		}	
    this.setState({showStaff:!this.state.showStaff});
  }
  
	changeNewStaff = (row) => {
    if(row != undefined && row >= 0 && this.state.staffList.length >0){
      let temp={_id : "",id : "",staffname:"",email:"",phone:"",dates_attending:"",role:"",notes:""};
      temp.id=this.state.staffList[row].id;
      temp._id=this.state.staffList[row]._id;
      if(this.state.staffList[row].staffname === '<Enter Staff Name>'){
        temp.staffname="";
      }else{
        temp.staffname=this.state.staffList[row].staffname;
      }
      if(this.state.staffList[row].email === '<Enter email>'){
        temp.email="";
      }else{
        temp.email=this.state.staffList[row].email;
      }
      if(this.state.staffList[row].phone === '(xxx) xxx-xxxx'){
        temp.phone="";
      }else{
        temp.phone=this.state.staffList[row].phone;
      }
      if(this.state.staffList[row].dates_attending === "12-12-2020"){
        temp.dates_attending="";
      }else{
        temp.dates_attending=this.state.staffList[row].dates_attending;
      }
      if(this.state.staffList[row].notes === "<Enter Task>" ){
        temp.notes="";
      }else{
        temp.notes=this.state.staffList[row].notes;
      }
      this.setState({formObj:temp});
    }
     this.setState({showStaff:!this.state.showStaff});
  }


  validate = (val) =>{
      let tempvalid = this.state.validations;
      switch(val){
              case "staffname": { tempvalid.staffname = Validators.name(this.state.formObj.staffname); } break;
              case "email": { tempvalid.email = Validators.email(this.state.formObj.email); } break;
              case "dates_attending": { tempvalid.dates_attending = Validators.date(this.state.formObj.dates_attending); } break;
              case "phone": { tempvalid.phone = Validators.phone(this.state.formObj.phone); } break;
              case "notes": { tempvalid.notes = Validators.name(this.state.formObj.notes); } break;
          }
          this.setState({validations:tempvalid});
    } 

   saveStaff = (val,e) =>{
        let tempstaff = this.state.formObj;
        switch(val){
                case "staffname": {var string = e.target.value; tempstaff.staffname = string.charAt(0).toUpperCase() + string.slice(1); } break;
                case "email": { tempstaff.email = e.target.value; } break;
                case "dates_attending": { tempstaff.dates_attending = e; this.validate("dates_attending"); } break;
                case "phone": { tempstaff.phone = e.target.value; } break;
                case "notes": {var string = e.target.value; tempstaff.notes =string.charAt(0).toUpperCase() + string.slice(1); } break;
            }
       this.setState({formObj:tempstaff});
    } 
  
    handleDeleteRow = (i) => {
      let newform=this.state.staffList[i];
      newform.status='Inactive';
      newform.events_id=this.props.eventid;
        axios.post(base_url+'staff/save', newform , this.props.user.header )
        .then(res =>{
        axios.get(base_url+'staff/listbyeventid/'+this.props.eventid,  this.props.user.header )
          .then(res =>{
           this.setState({ staffList:res.data });
          }).catch(function (error) { console.log(error); });    
        }).catch(function (error) { console.log(error); });
    }
    getFormattedPhoneNum = () =>{
      let phnnum=this.state.formObj.phone;
      var pattern = new RegExp(/^[0-9\b]+$/);
      if(phnnum !== null && phnnum !== ''){ 
         let output = "(";
         phnnum.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
       {
         if ( g1.length ) {
         output += g1;
         if ( g1.length == 3 ) {
           output += ")";
           if ( g2.length ) {
             output += " " + g2; 
             if ( g2.length == 3 ) {
               output += "-";
               if ( g3.length ) {
                 output += g3;
               }
             }
           }
          }
         }
       }   
       );        
     let newform=this.state.formObj;
     newform.phone=output;
      } 
   }   
    
    render_stafflist(){
      if( this.state.staffList != undefined && this.state.staffList.length > 0){
      return this.state.staffList.map((item, index) =>{
        let dt=new Date(item.dates_attending);
        var day = ("0" + dt.getDate()).slice(-2);
        var month = ("0" + (dt.getMonth() + 1)).slice(-2);
        var year = dt.getFullYear();
        var h = dt.getHours();
        var m =("0" + dt.getMinutes()).slice(-2);
        var _time = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
        let newdt= month+'-'+day+'-'+year+'('+ _time+')';
        
        return (
      		<tr key={index} id={index}>
        	<td>{item.staffname}</td>
          <td>{item.email}</td>
    	   	<td>{item.phone}</td>
    			<td>{newdt}</td>
     			<td>{item.notes}
           <span><FaPen size="15px" color="blue" onClick={()=>{this.changeNewStaff(index)}}/>&nbsp;&nbsp;
				   <FaTrash size="15px" color="red" onClick={() => {this.handleDeleteRow(index)}} />&nbsp;&nbsp;</span></td>             
         </tr>
        ) 
         }, this);
    }
  }
	onSubmit(){
    let temp='';
    for (let key in Staff) { this.validate(key);}
    for (let key in this.state.validations) { temp += this.state.validations[key];}
     if(temp == "" ){
    let newform=this.state.formObj;
    newform.status='Active';
    newform.events_id=this.props.eventid;
			axios.post(base_url+'staff/save', newform , this.props.user.header )
			.then(res =>{
      axios.get(base_url+'staff/listbyeventid/'+this.props.eventid,  this.props.user.header )
				.then(res =>{
         this.setState({ staffList:res.data });
         {this.handleModal()}
				}).catch(function (error) { console.log(error); });    
      }).catch(function (error) { console.log(error); });
    }
	}

	render() {
   return (
			<div id="idstaff">
                <div className="d-flex row">
                    <div className="form-group col-md-12">
                    <label htmlFor="stafftable" className="label-custom">Add Your Participating Staff</label>
                    <table className="table table-bordered table-sm table-responsive-stack">
                    <thead className="thead">
                        <tr>
                        <td>Staff Name</td>
                        <td>Email</td>
                        <td>Phone</td>
                        <td>Date and Time Attending</td>
                        <td>Tasks</td>
                        </tr> 
                    </thead>
                      <tbody>{this.render_stafflist()}</tbody>  
                    </table>
                    <div className="p-2 col-md-12">
                   <div className="d-flex justify-content-end row">
                  <div className="p-2">
                  <button type="button" className="btn btn-primary btn-block btn-sm" onClick={()=>{this.handleModal()}}>+Add</button> 
                  </div>
                </div>
                </div>
                    </div>
                </div>
                 <Modal size="lg"  show={this.state.showStaff} onHide={()=>{this.handleModal()}} >
                    <Modal.Header closeButton>
                      <Modal.Title>STAFF</Modal.Title>
                    </Modal.Header>
                        <ModalBody>
                        <div className="brdcolor">
                            <div className="form-row">
                                <div className="form-group col-md-7">
                                  <label htmlFor="idstaffname" className="label-custom">Staff Name</label> 
                                  <input name="staffname" id="idstaffname" type="text" value={this.state.formObj.staffname} onChange={(e)=>{this.saveStaff("staffname",e)}} placeholder="Enter Staff Name"  onBlur={()=>{ this.validate("staffname")}} className="form-control form-control-sm" style={this.state.validations.staffname==""?{}:{"borderColor":"red"}} required /> 	
                                 <span className="errormsg">{this.state.validations.staffname}</span>
                                </div>
                                <div className="form-group col-md-5">
                                  <label htmlFor="idemail" className="label-custom">Email</label> 
                                  <input name="email" id="idemail" type="text" value={this.state.formObj.email} onChange={(e)=>{this.saveStaff("email",e)}} placeholder="Enter Email" onBlur={()=>{ this.validate("email")}} className="form-control form-control-sm" style={this.state.validations.email==""?{}:{"borderColor":"red"}} required /> 	
                                 <span className="errormsg">{this.state.validations.email}</span>
                                </div>
                             </div>
                             <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="idphone" className="label-custom">Phone</label> 
                                  <input name="phone" id="idphone" type="text" value={this.state.formObj.phone}  onChange={(e)=>{this.saveStaff("phone",e)}} onKeyUp={()=>{this.getFormattedPhoneNum()}} placeholder="Enter Phone No." onBlur={()=>{ this.validate("phone")}} className="form-control form-control-sm" style={this.state.validations.phone==""?{}:{"borderColor":"red"}} required /> 	
                                 <span className="errormsg">{this.state.validations.phone}</span>
                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="iddates_attending" className="label-custom"> Date and Time Attending</label> <br/>
                                  <DateTime name="dates_attending" 
					                       	value={this.state.formObj.dates_attending}  
					                      	onChange={this.saveStaff}
					                      	class_name={ this.state.validations.dates_attending==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
					                        onBlur={this.validate}
				                        	/><br/>
                                <span className="errormsg">{this.state.validations.dates_attending}</span>
                                </div>
                             </div>
                             <div className="form-row">
                                <div className="form-group col-md-12">
                                 <label htmlFor="idnotes" className="label-custom">Task</label>
                                 <textarea name="notes" rows="1" cols="10" id="idnotes" type="text" placeholder="Enter Task"  value={this.state.formObj.notes} onChange={(e)=>{this.saveStaff("notes",e)}} onBlur={()=>{ this.validate("notes")}} className="form-control form-control-sm" style={this.state.validations.notes==""?{}:{"borderColor":"red"}} required /> 	
                                 <span className="errormsg">{this.state.validations.notes}</span>
                                </div>
                              </div>
                              </div>  
                        </ModalBody>
                        <ModalFooter>
                          <Button variant="warning" onClick={()=>{this.handleModal()}}>Close</Button>
                          <Button variant="primary" onClick={()=>{this.onSubmit()}}>Save</Button>
                        </ModalFooter>
                </Modal>
		 </div>
		)}
		
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(StaffForm);
