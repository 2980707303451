import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import LoggedInReducer from '../reducers/LoggedInReducer';
import PageStateReducer from '../reducers/PageStateReducer';
import  CartStateReducer from '../reducers/CartStateReducer';

const rootReducer = combineReducers({
  loggedUserState : LoggedInReducer,
  currentPageState : PageStateReducer,
  cartState : CartStateReducer
})

//const Store = createStore(reducers)

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const Store = createStore(persistedReducer);
export const Persistor = persistStore(Store);

