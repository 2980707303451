////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {Button,Modal} from 'react-bootstrap';
import { Validators } from '../utils/Validators';
import { FaPen,FaTrash} from 'react-icons/fa';
import { base_url } from '../constants/Constants';
import {Orders } from '../models/Orders';
import '../styles/Styles.css';


class OrdersForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
			edit : true,
			showprdct:false,
			showEdit : false,
			formObj : Orders,
			validations : {},
			orderList : [],
		}
		this.onSubmit = this.onSubmit.bind(this);
		this.validate = this.validate.bind(this);
		this.orderEvent = this.orderEvent.bind(this);
		this.handleProductModal = this.handleProductModal.bind(this);
		this.handleDeleteRow = this.handleDeleteRow.bind(this);
		this.changeNewProduct = this.changeNewProduct.bind(this);

	}

	componentDidMount(){
		let validtemp={};
		for (let key in Orders) { validtemp[key] = ""; }
		this.setState({validations:validtemp});

		axios.get(base_url+'permissions/getbypermissionsgroup/'+this.props.user.groupid+'/Orders', this.props.user.header )
		.then(res =>{ this.setState({ permissions:res.data[0]}); }).catch(function (error) { console.log(error); });
		axios.get(base_url+'orders/listbyeventid/'+this.props.eventid, this.props.user.header )
		.then(res =>{
			if(res.data.length > 0){
			 this.setState({ orderList:res.data});
			}else{
				var temp={};
				temp={id : 0,events_id:this.props.eventid,status:'Active',service_name:"<Enter Name>",service_phone:"(xxx) xxx-xxxx",service_email:"<Enter Email>",product:"<Enter Product Name>",last_date:"12-12-2020", url:"<Enter Product url>",userid: "<Enter User Id>",password:"<Enter Password>"};
				axios.post(base_url+'orders/save', temp, this.props.user.header )
				.then(res =>{
					axios.get(base_url+'orders/listbyeventid/'+this.props.eventid,  this.props.user.header )
			       .then(res =>{
				 this.setState({ orderList:res.data });
				}).catch(function (error) { console.log(error); }); 
				}).catch(function (error) { console.log(error); });
			}
			}).catch(function (error) { console.log(error); });
	
	}
	validate = (val) =>{
		let tempvalid = this.state.validations;
		switch(val){
			//case "service_name": { tempvalid.service_name = Validators.name(this.state.formObj.service_name); } break;
			//case "service_phone": { tempvalid.service_phone = Validators.phone(this.state.formObj.service_phone); } break;
			//case "service_email": { tempvalid.service_email = Validators.email(this.state.formObj.service_email); } break;
			case "product": { tempvalid.product = Validators.name(this.state.formObj.product); } break;
			case "url": { tempvalid.url = Validators.website(this.state.formObj.url); } break;
			case "last_date": { tempvalid.last_date = Validators.date(this.state.formObj.last_date); } break;
			case "userid": { tempvalid.userid = Validators.name(this.state.formObj.userid); } break;
			case "password": { tempvalid.password = Validators.password(this.state.formObj.password); } break;
		}
		this.setState({validations:tempvalid});
	}
	orderEvent = (val,e) =>{
		let tempoder = this.state.formObj;
		switch(val){
			//case "service_name": { tempoder.service_name = e.target.value; } break;
			//case "service_phone": { tempoder.service_phone = e.target.value; } break;
			//case "service_email": { tempoder.service_email = e.target.value; } break;
			case "product": { var string = e.target.value; tempoder.product = string.charAt(0).toUpperCase() + string.slice(1); } break;
			case "url": { tempoder.url = e.target.value; } break;
			case "last_date": { tempoder.last_date = e.target.value; } break;
			case "userid": {tempoder.userid = e.target.value; } break;
			case "password": { tempoder.password = e.target.value; } break;
		}
		this.setState({formObj:tempoder});
	}



	changeNewProduct = (row) => {
		if(row != undefined && row >= 0 && this.state.orderList.length >0){
			let temp={id:"",_id:"",product:"",last_date:"",url:"",userid:"",password:""};
			temp.id=this.state.orderList[row].id;
			temp._id=this.state.orderList[row]._id;
			if(this.state.orderList[row].product === '<Enter Product Name>'){
				temp.product="";
			}else{
				temp.product=this.state.orderList[row].product;
			}
			if(this.state.orderList[row].url === '<Enter Product url>'){
				temp.url="";
			}else{
				temp.url=this.state.orderList[row].url;
			}
			if(this.state.orderList[row].userid === '<Enter User Id>'){
				temp.userid="";
			}else{
				temp.userid=this.state.orderList[row].userid;	
			}
			if(this.state.orderList[row].password === '<Enter Password>'){
				temp.password="";
			}else{
				temp.password=this.state.orderList[row].password;
			}
			if(this.state.orderList[row].last_date === '12-12-2020'){
				temp.last_date="";
			}else{
				temp.last_date=this.state.orderList[row].last_date;
			}

			this.setState({formObj:temp});
			}
        this.setState({showprdct:!this.state.showprdct});
	}
	handleProductModal = () => {
		let temp={product:"",last_date:"",url:"",userid:"",password:""};
		this.setState({ formObj:temp });
		let temps='';
		for (let key in this.state.validations) { temps += this.state.validations[key];}
	    if(temps !== "" ){
			this.setState({ validations:temp });
		}	
        this.setState({showprdct:!this.state.showprdct});
	}
	handleDeleteRow = (i) => {
		let ordernewform=this.state.orderList[i];
		ordernewform.status='Inactive';
		ordernewform.step=2;
		ordernewform.events_id=this.props.eventid;
			axios.post(base_url+'orders/save', ordernewform, this.props.user.header )
			.then(res =>{
			axios.get(base_url+'orders/listbyeventid/'+this.props.eventid,  this.props.user.header )
			.then(res =>{
				 this.setState({ orderList:res.data });
				}).catch(function (error) { console.log(error); }); 
			}).catch(function (error) { console.log(error); });

	}
	getFormattedPhoneNum = () =>{
		let phnnum=this.state.formObj.service_phone;
		var pattern = new RegExp(/^[0-9\b]+$/);
		if(phnnum !== null && phnnum !== ''){ 
		   let output = "(";
		   phnnum.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
		   {
			 if ( g1.length ) {
			   output += g1;
			   if ( g1.length == 3 ) {
				   output += ")";
				   if ( g2.length ) {
					   output += " " + g2; 
					   if ( g2.length == 3 ) {
						   output += "-";
						   if ( g3.length ) {
							   output += g3;
						   }
					   }
				   }
				}
			 }
		   }   
		 );        
	   let newform=this.state.formObj;
	   newform.service_phone=output;		
	  } 
   }   
	render_OrderList(){
		if( this.state.orderList != undefined && this.state.orderList.length > 0){
		return this.state.orderList.map((item, index) =>{
			let dt=new Date(item.last_date);
			var day = ("0" + dt.getDate()).slice(-2);
			var month = ("0" + (dt.getMonth() + 1)).slice(-2);
			var year = dt.getFullYear();
			let date= month+'-'+day+'-'+year;

		  return (
				<tr key={index} id={index}>
				{/* <td>{item.service_name}</td>
				<td>{item.service_phone}</td>
				<td>{item.service_email}</td> */}
				<td>{item.product}</td>
				<td>{date}</td>
				<td>{item.url}</td>
				<td>{item.userid}</td>
				<td>{item.password}
				<span><FaPen size="15px" color="blue" onClick={()=>{this.changeNewProduct(index)}}/>&nbsp;&nbsp;
				      <FaTrash size="15px" color="red" onClick={() => {this.handleDeleteRow(index)}} />&nbsp;&nbsp;</span></td>             
		   </tr>
		  ) 
		   }, this);
	  }
	}
  

	onSubmit(){
		let temp='';
        for (let key in Orders) { this.validate(key);}
        for (let key in this.state.validations) { temp += this.state.validations[key];}
	    if(temp == "" ){
		let ordernewform=this.state.formObj;
		ordernewform.status='Active';
		ordernewform.step=2;
		ordernewform.events_id=this.props.eventid;
			axios.post(base_url+'orders/save', ordernewform, this.props.user.header )
			.then(res =>{
			axios.get(base_url+'orders/listbyeventid/'+this.props.eventid,  this.props.user.header )
			.then(res =>{
				 this.setState({ orderList:res.data });
				{this. handleProductModal()}
				}).catch(function (error) { console.log(error); }); 
			}).catch(function (error) { console.log(error); });
		}
	}

	render() {

	 return (
			<div id="idorders">
			     	<div className="d-flex row">
                      <div className="form-group col-sm-12">
                         <label className="label-custom">Add Your Product Ordering Options</label>
                            <table className="table table-bordered table-sm table-responsive-stack">
                                <thead className="thead">
                                    <tr>
									   {/*  <td>Service Provider Name</td>
										<td>Service Provider Phone</td>
										<td>Service Provider Email</td> */}
                                        <td>Product</td>
                                        <td>Ordering Deadline</td>
                                        <td>URL</td>
                                        <td>User Id</td>
                                        <td>Password</td>
                                    </tr>
                                </thead>
		                        <tbody>{this.render_OrderList()}</tbody>
                            </table>
						<div className="p-2 col-md-12">	
						 <div className="d-flex justify-content-end row">
                        <div className="p-2">
                            <button type="button" className="btn btn-primary btn-block btn-sm"  onClick={()=>{this.handleProductModal()}} >+Add</button>
                        </div>
						</div>
						</div>
                    </div>
                    </div>
					<Modal size="lg" show={this.state.showprdct} onHide={()=>{this.handleProductModal()}} animation={false} >
					<Modal.Header closeButton>
					<Modal.Title>Product Info</Modal.Title>
				    </Modal.Header>
					<Modal.Body>
					<div className="brdcolor">
					<div className="form-row">
						<div className="form-group col-md-8">
						     <label htmlFor="idproduct1" className="label-custom" >Product</label> 
						     <input value={this.state.formObj.product} name="product" id="idproduct1" type="text" placeholder="Enter Product Name" onChange={(e)=>{this.orderEvent("product",e)}} onBlur={()=>{ this.validate("product")}} className="form-control form-control-sm" style={this.state.validations.product==""?{}:{"borderColor":"red"}} required /> 	
                             <span className="errormsg">{this.state.validations.product}</span>

					     </div>
						<div className="form-group col-md-4">
						     <label htmlFor="idlast_date" className="label-custom" >Ordering Last Date</label> 
						     <input value={this.state.formObj.last_date} name="last_date" id="idlast_date" type="date" onChange={(e)=>{this.orderEvent("last_date",e)}} onBlur={()=>{ this.validate("last_date")}} className="form-control form-control-sm" style={this.state.validations.last_date==""?{}:{"borderColor":"red"}} required /> 	
                             <span className="errormsg">{this.state.validations.last_date}</span>
					    </div>
					</div>
					<div className="form-row"> 
						<div className="form-group col-md-6">
						     <label htmlFor="idurl" className="label-custom" >URL</label> 
						    <input value={this.state.formObj.url} name="url" id="idurl"  type="text" placeholder="Enter URL" onChange={(e)=>{this.orderEvent("url",e)}} onBlur={()=>{ this.validate("url")}} className="form-control form-control-sm" style={this.state.validations.url==""?{}:{"borderColor":"red"}} required /> 	
                             <span className="errormsg">{this.state.validations.url}</span>
					    </div>
						<div className="form-group col-md-3">
							<label htmlFor="iduserid" className="label-custom" >User Name</label> 
						    <input value={this.state.formObj.userid} name="userid" id="iduserid" placeholder="Enter User Name" onChange={(e)=>{this.orderEvent("userid",e)}} onBlur={()=>{ this.validate("userid")}} className="form-control form-control-sm" style={this.state.validations.userid==""?{}:{"borderColor":"red"}} required /> 	
                             <span className="errormsg">{this.state.validations.userid}</span>
					   </div>
						<div className="form-group col-md-3">
						     <label htmlFor="idpassword" className="label-custom" >Password</label> 
						     <input value={this.state.formObj.password} name="password" id="idpassword" placeholder="Enter Password" onChange={(e)=>{this.orderEvent("password",e)}} onBlur={()=>{ this.validate("password")}} className="form-control form-control-sm" style={this.state.validations.password==""?{}:{"borderColor":"red"}} required /> 	
                             <span className="errormsg">{this.state.validations.password}</span>	
					   </div>
					</div>
					</div>
					</Modal.Body>
					<Modal.Footer>
					<Button variant="warning" onClick={()=>{this.handleProductModal()}}>Close</Button>
					<Button variant="primary"  onClick={()=>this.onSubmit()}>Save</Button>
					</Modal.Footer>
					</Modal>

        </div>
		)}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(OrdersForm);
