////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { base_url } from '../constants/Constants';
import { Events } from '../models/Events';
import {Modal,Button} from 'react-bootstrap';
import { Validators } from '../utils/Validators';
import {DateTime, TimeOnly } from '../utils/DateTime';
import { FaTrash } from 'react-icons/fa';
import '../styles/Styles.css';

class EventsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			step:1,
			showEdit : false,
			formObj : Events,
			validations : {},
			person:false,
			virtual:true,
			status:false,
			errorobjective:'',
			errorshow :true,
			timerval:null,
			indexno:null,
			dltconfirm : false,
		}
		
		this.onSubmit = this.onSubmit.bind(this);
		this.getFormattedPhoneNum = this.getFormattedPhoneNum.bind(this);
		this.validate = this.validate.bind(this);
		this.changeEvent = this.changeEvent.bind(this);
		this.addBusinessObj = this.addBusinessObj.bind(this);
		this.handleObjectChange = this.handleObjectChange.bind(this);
		this.deleterecord = this.deleterecord.bind(this);
		this.selectBobjective = this.selectBobjective.bind(this);
		this.confirmdelete=this.confirmdelete.bind(this);
		
	}

	componentDidMount(){
		//let tempvalid = Object.create(Events);
		let validtemp={};
		for (let key in Events) { validtemp[key] = ""; }
		var tdate = new Date();
		this.setState({validations:validtemp, timerval:tdate});

		  if(this.props.eventid !== undefined && this.props.eventid !== ''){
			 // console.log(this.props.eventid);
		 	axios.get(base_url+'events/listbyeventid/'+this.props.eventid, this.props.header )
            .then(res =>{
				//console.log(res.data);
			if(res.data.length > 0 ){
			   var temp=res.data[0];
			  // console.log(temp);
			   if(temp.event_type == "In Person"){
				console.log(temp.event_type);
			    this.setState({person:true,virtual:false});
			   }else if(temp.event_type == "Virtual"){
			     this.setState({virtual:true,person:false}); 
			   }else{
			    this.setState({person:false,virtual:false});
			   }
			   this.setState({formObj:temp});
			}
             }).catch(function (error) { console.log(error); });  
            
		 }else{
			let temps =[];
			temps.push({id: 1,name:"Leads",status:true});
			temps.push({id: 2,name:"Industry Contacts",status:true});
			temps.push({id: 3,name:"Sales",status:false});
			temps.push({id: 4,name:"Brand Awareness",status:false});
			temps.push({id: 5,name:"Education",status:false});
		   let tempForm={};
		   for (let key in Events) { tempForm[key] = ""; }
		   tempForm.bussinesobjective=temps;
		   tempForm.country="United States";
		   this.setState({ formObj:tempForm});
		   //this.setState({ person:true}); 
		}
	}
	
	validate = (val) =>{
		let tempvalid = this.state.validations;
		switch(val){
			case "eventname": { tempvalid.eventname = Validators.name(this.state.formObj.eventname); } break;
			case "start_date": { tempvalid.start_date = Validators.date(this.state.formObj.start_date); } break;
			case "end_date": { tempvalid.end_date = Validators.date(this.state.formObj.end_date); } break;
			case "organizer": { tempvalid.organizer = Validators.name(this.state.formObj.organizer); } break;
			case "phone": { tempvalid.phone = Validators.phone(this.state.formObj.phone); } break; 
			case "email": { tempvalid.email = Validators.email(this.state.formObj.email); } break; 
			case "address1":{ tempvalid.address1 = Validators.name(this.state.formObj.address1); } break;
			//case "address2":{ tempvalid.address2 = Validators.name(this.state.formObj.address2); } break;
			case "city":{ tempvalid.city = Validators.name(this.state.formObj.city); } break;
			case "state": { tempvalid.state = Validators.select(this.state.formObj.state); } break;
			case "country": { tempvalid.country = Validators.select(this.state.formObj.country); } break;
			case "zipcode":{ tempvalid.zipcode = Validators.zipcode(this.state.formObj.zipcode); } break;
			case "virtual_hostid": { tempvalid.virtual_hostid = Validators.name(this.state.formObj.virtual_hostid); } break;
			case "virtual_url": { tempvalid.virtual_url = Validators.name(this.state.formObj.virtual_url); } break;
			}
		this.setState({validations:tempvalid});
	}

	changeEvent = (val,e) =>{
	//	console.log(val, e);
		let tempform = this.state.formObj;
		switch(val){
			case "eventname": {  var string = e.target.value; tempform.eventname = string.charAt(0).toUpperCase() + string.slice(1);  } break;
			case "start_date": { tempform.start_date = e; this.validate("start_date"); } break;
			case "end_date": { tempform.end_date =  e; this.validate("end_date"); } break;
			case "organizer": {var string = e.target.value; tempform.organizer = string.charAt(0).toUpperCase() + string.slice(1); } break;
			case "phone": { tempform.phone = e.target.value; } break; 
			case "email": { tempform.email = e.target.value; } break; 
			case "address1": {  var string = e.target.value; tempform.address1 = string.charAt(0).toUpperCase() + string.slice(1); } break; 
			case "address2": { var string = e.target.value; tempform.address2 = string.charAt(0).toUpperCase() + string.slice(1); } break; 
			case "city": {  var string = e.target.value; tempform.city = string.charAt(0).toUpperCase() + string.slice(1);; } break; 
			case "state": { tempform.state = e.target.value; } break; 
			case "country": { tempform.country = e.target.value; } break; 
			case "zipcode": { tempform.zipcode = e.target.value; } break; 
			case "event_type": { tempform.event_type = e.target.value; } break; 
			case "virtual_hostid": {  tempform.virtual_hostid = e.target.value; } break; 
			case "virtual_user_pass": { tempform.virtual_user_pass = e.target.value; } break; 
			case "virtual_url": { tempform.virtual_url = e.target.value; } break; 
			case "notes": { tempform.notes = e.target.value; } break; 
		}
		this.setState({formObj:tempform});
	}
	
	onClickInPerson = (e) =>{
		this.setState({person: true,virtual : false});	 
	}
	onClickVirtual = (e) =>{
		this.setState({virtual: true,person : false});
	}
	 
    addBusinessObj = () =>{
	    var bobj=this.state.formObj.bussinesobjective;
		var count=0;
			if(bobj.length >0){
				var  max = Math.max.apply(null,  bobj.map(item => item.id));
				count=max+1;
			}else{
				count=1;
			}
		var bList = this.state.formObj;
		bList.bussinesobjective.push({id: count,name : "",status:true});
		this.setState({formObj:bList});
	}
	  
 	handleObjectChange = (id, e) => {
		e.preventDefault();
		var bList = this.state.formObj;
		const myRowIndex = bList.bussinesobjective.findIndex((row) => row.id === id);
		bList.bussinesobjective[myRowIndex].name = e.target.value;
		this.setState({ formObj:bList }); 
	}
	deleterecord = () =>{
		if(this.state.indexno !== null ){
			let tempdelete=this.state.formObj;
			tempdelete.bussinesobjective.splice(this.state.indexno,1);
			this.setState({formObj:tempdelete});
			let  newformObj=this.state.formObj;
			newformObj.events_id=this.props.eventid;
			this.setState({ formObj:newformObj })
				axios.post(base_url+'events/save', newformObj, this.props.user.header )
				.then(res =>{
					this.setState({dltconfirm:!this.state.dltconfirm});
				}).catch(function (error) { console.log(error); });
	    } 
    }
	selectBobjective = (id, st) =>{
		var bList = this.state.formObj;
		const myRowIndex = bList.bussinesobjective.findIndex((row) => row.id === id);
		if(st !== true){
			bList.bussinesobjective[myRowIndex].status = true;
			this.setState({ errorshow:true}); 
		}else{
			bList.bussinesobjective[myRowIndex].status = false;
		}
		this.setState({ formObj:bList }); 
	}
	
	 getFormattedPhoneNum = () =>{
		 let phnnum=this.state.formObj.phone;
		 var pattern = new RegExp(/^[0-9\b]+$/);
		 if(phnnum !== null && phnnum !== ''){ 
	    	let output = "(";
		    phnnum.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
			{
			  if ( g1.length ) {
				output += g1;
				if ( g1.length == 3 ) {
					output += ")";
					if ( g2.length ) {
						output += " " + g2; 
						if ( g2.length == 3 ) {
							output += "-";
							if ( g3.length ) {
								output += g3;
							}
						}
					}
				 }
			  }
			}   
		  );        
		//return output;
		let newform=this.state.formObj;
		newform.phone=output;	 
	   } 
	}   
	  
 eventType(){
	if(this.state.person == true){
		var eventtemp=this.state.formObj;
		eventtemp.event_type='In Person';
		this.setState({formObj:eventtemp});
	}else if(this.state.virtual == true){
	    var eventtemp=this.state.formObj;
	    eventtemp.event_type='Virtual';
	    this.setState({formObj:eventtemp});
	}else{
		var eventtemp=this.state.formObj;
		eventtemp.event_type='';
        this.setState({formObj:eventtemp});
	}
 }
 checkObjectivestatus(){
	let objtemp=this.state.formObj.bussinesobjective;
	let count=0;
	for (let i = 0; i < objtemp.length; i++) {
	  if (objtemp[i].status === true) count++;
	}
	let temp=this.state.validations;
	console.log(count);		  
	if(count == 0){
		temp.bussinesobjective="Atleast one business objective must be selected.";

	}else{
		temp.bussinesobjective="";		
	}	  
	this.setState({validations:temp});
 }
 checkEventDate=()=>{
	 let startdate = new Date(this.state.formObj.start_date);
	 let enddate = new Date(this.state.formObj.end_date);
	 let currdate = startdate.getTime();
	 let resdate = enddate.getTime();
	 let temp = this.state.validations;
	 if(currdate > resdate){
		 temp.end_date = "Date cannot be less than Start Date!!";
	 }
 }
confirmdelete = (i) =>{
	var r =window.confirm("Are You Sure??Want to Delete this Record!!");
  if (r == true) {
   this.deleterecord(i);
  } 
}
confirmdeleteModal = (i) =>{
	console.log(i);
    this.setState({dltconfirm:!this.state.dltconfirm,indexno:i});
}


 renderBussinessObj(){
	//console.log(this.state.formObj.bussinesobjective);
	if( this.state.formObj.bussinesobjective != undefined && this.state.formObj.bussinesobjective.length > 0){
		return this.state.formObj.bussinesobjective.map((item, index) =>{
		 var display="invisibleborder";
		   if(item.name === ""){
			display="colorborder";
		   }
		  return (
			<div key={index} id={index}>
				<div className='p-2'>
				<div className="deletebox form-check">
				<input className="form-check-input" type="checkbox" checked={item.status} onChange={(e)=>{this.selectBobjective(item.id,item.status)}} />
				<input className={display} type="text" value={item.name} onChange={(e)=>{this.handleObjectChange(item.id,e)}} />
				<span><FaTrash size="15px" color="red" onClick={() => {this.confirmdeleteModal(index)}} /></span>
				</div>
				</div>
			</div>
		  ) 
		  }, this);  
	  }
  }
 render_formButton(){
	 if(this.props.eventAction === 'edit'){
		 return(
			<div className="d-flex justify-content-end row">
			<div className="p-2">
			<button type="button" className="btn btn-primary btn-block" onClick={(e) =>this.onSubmit(e)}>Next</button>
			</div>
			</div>
		 )
	 }else{
		 return(
			<div className="d-flex justify-content-end row">
			 <div className="p-2">
			<button type="button" className="btn btn-primary btn-block" onClick={(e) =>this.onSubmit(e)}>Next</button>
			</div>
			</div>
		)
	 }
 }

onSubmit(){
	{this.eventType()}
	let temp='';
	for (let key in Events) { this.validate(key);}
	let tempvalid=this.state.validations;
	tempvalid.virtual_user_pass= "";
	this.setState({validations:tempvalid});
	
	if(this.state.formObj.event_type === 'In Person'){
	 let tempvalid=this.state.validations;
	   tempvalid.virtual_hostid = "";
	   tempvalid.virtual_user_pass= "";
	   tempvalid.virtual_url = "";
	   this.setState({validations:tempvalid});
	}else {
		let tempvalid=this.state.validations;
		tempvalid.address1 = "";
		tempvalid.address2= "";
		tempvalid.city = "";
		tempvalid.country ="";
		tempvalid.state = "";
		tempvalid.zipcode = "";
		this.setState({validations:tempvalid});
	}
	this.checkObjectivestatus();
	for (let key in this.state.validations) {this.checkEventDate(); temp += this.state.validations[key];}
	if(temp == ""){
	    let newformObj=this.state.formObj;
		newformObj.status='Active';
		newformObj.accountid=this.props.user.accountid;
		newformObj.events_id=this.props.eventid;
		axios.post(base_url+'events/save', newformObj, this.props.user.header )
			.then(res =>{
				console.log(res);
				this.setState({ formObjdata:res.data.data});
				this.props.parentObject.eventStep(this.state.formObj.eventname,this.state.formObj.start_date,this.state.formObj.end_date,this.state.formObjdata.id); 
				
	}).catch(function (error) { console.log(error); });  
}	
}

	

render() {
		return (
			<div id="idevents" className="shadow-sm p-3 mb-5 bg-white rounded">
				 <div className="brdcolor" >
				    <div className="d-flex row">
					<div className="p-2 col-md-12 ">
						<label htmlFor="ideventname" className="label-custom">Conference/Tradeshow Name</label> 
						<input value={this.state.formObj.eventname} name="eventname" id="ideventname"  type="text" onChange={(e)=>{this.changeEvent("eventname",e)}} onBlur={()=>{ this.validate("eventname")}} className="form-control form-control-sm" style={this.state.validations.eventname==""?{}:{"borderColor":"red"}} required /> 	
                          <span className="errormsg">{this.state.validations.eventname}</span>
					</div>
					</div>
					<div className="d-flex row">
						<div className="p-2">
						<label htmlFor="start_date" className="label-custom" >Start Date</label><br/>
		
						<DateTime name="start_date" 
						value={this.state.formObj.start_date}  
						onChange={this.changeEvent}
						class_name={ this.state.validations.start_date==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
					    onBlur={this.validate}
					     /><br/>
						<span className="errormsg">{this.state.validations.start_date}</span>
						</div>
						<div className="p-2 col-md-1"></div>
						<div className="p-2">
						<label htmlFor="end_date" className="label-custom" >End Date</label><br/>
                        <DateTime name="end_date" 
						value={this.state.formObj.end_date}  
						onChange={this.changeEvent}
						class_name={ this.state.validations.end_date==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
					    onBlur={this.validate}
					     /><br/>
						<span className="errormsg">{this.state.validations.end_date}</span>
						</div>
					</div>
                    <div className="d-flex row">
							<div className="p-2 flex-fill">
								<label htmlFor="organizer" className="label-custom" >Conference Organizer Contact Name</label>
								<input type="text"  id="organizer" name="organizer" value={this.state.formObj.organizer} onChange={(e)=>{this.changeEvent("organizer",e)}} onBlur={()=>{ this.validate("organizer")}} className="form-control form-control-sm" style={this.state.validations.organizer==""?{}:{"borderColor":"red"}} required />
						        <span className="errormsg">{this.state.validations.organizer}</span>
							</div>
							<div className="p-2 col-md-3">
								<label htmlFor="phone" className="label-custom" >Phone</label>
								<input type="text"  id="phone" name="phone" value={this.state.formObj.phone} onChange={(e)=>{this.changeEvent("phone",e)}} onBlur={()=>{ this.validate("phone")}} onKeyUp={()=>{this. getFormattedPhoneNum()}} className="form-control form-control-sm" style={this.state.validations.phone==""?{}:{"borderColor":"red"}} required />
						        <span className="errormsg">{this.state.validations.phone}</span>
							</div>
							<div className="p-2 col-md-4">
								<label htmlFor="email" className="label-custom" >Email</label>
								<input type="email"  id="email" name="email" value={this.state.formObj.email} onChange={(e)=>{this.changeEvent("email",e)}} onBlur={()=>{ this.validate("email")}} className="form-control form-control-sm" style={this.state.validations.email==""?{}:{"borderColor":"red"}} required />
						        <span className="errormsg">{this.state.validations.email}</span>
							</div>
                    </div>
				    <div className="d-flex row">
					<div className="d-flex  col-md-5">
					<div className="form-check">
					<input className="form-check-input" type="radio" name="optradio" id="virtual" value="virtual" checked={this.state.virtual} onChange={(e)=>{this.onClickVirtual(e)}} />
					<label className="label-custom" htmlFor="virtual">
					Virtual
					</label>
					</div>
					&nbsp;&nbsp;&nbsp;
					<div className="form-check">
					<input className="form-check-input" type="radio" name="optradio" id="person" value="person" checked={this.state.person} onChange={(e)=>{this.onClickInPerson(e)}} />
					<label className="label-custom" htmlFor="person">
					In Person
					</label>
					</div>
					</div>
					</div>
					{this.state.person?
					<div>
						<div className="d-flex row">
						<div className="p-2 col-md-6">
						<label htmlFor="idaddress1" className="label-custom" >Address</label> 
						<input value={this.state.formObj.address1} name="address1" id="idaddress1"  type="text" onChange={(e)=>{this.changeEvent("address1",e)}} onBlur={()=>{ this.validate("address1")}} className="form-control form-control-sm" style={this.state.validations.address1==""?{}:{"borderColor":"red"}} required /> 	
                          <span className="errormsg">{this.state.validations.address1}</span> 
					   	</div>
						<div className="p-2 col-md-6">
						<label htmlFor="idaddress2" className="label-custom" >Suite or Hall</label> 
						<input value={this.state.formObj.address2} name="address2" id="idaddress2"  type="text" onChange={(e)=>{this.changeEvent("address2",e)}}  className="form-control form-control-sm" required /> 	
					    </div>
						</div>
                        <br/>
						<div className="d-flex row">
						<div className="p-2 col-md-3">
						<label htmlFor="idcity" className="label-custom">City</label>
						<input type="text"  className="form-control form-control-sm" id="idcity" name="city"   value={this.state.formObj.city} onChange={(e)=>{this.changeEvent("city",e)}} onBlur={()=>{ this.validate("city")}} className="form-control form-control-sm" style={this.state.validations.city==""?{}:{"borderColor":"red"}} required /> 	
                          <span className="errormsg">{this.state.validations.city}</span>
                       
						</div>
						
						<div className="p-2 col-md-3">
						<label htmlFor="idstate" className="label-custom">State</label>
						<select className="form-control form-control-sm" id="idstate" name="state" value={this.state.formObj.state} onChange={(e)=>{this.changeEvent("state",e)}} onBlur={()=>{ this.validate("state")}} className="form-control form-control-sm" style={this.state.validations.state==""?{}:{"borderColor":"red"}} > 	
						<option value="">Select a State</option>
                        <option>Alabama</option>
                        <option>Alaska</option>
						<option>Arizona</option>
						<option>Arkansas</option>
						<option>California</option>
						<option>Colorado</option>
						<option>Connecticut</option>
						<option>Delaware</option>
						<option>District Of Columbia</option>
						<option>Florida</option>
						<option>Georgia</option>
						<option>Hawaii</option>
						<option>Idaho</option>
						<option>Illinois</option>
						<option>Indiana</option>
						<option>Iowa</option>
						<option>Kansas</option>
						<option>Kentucky</option>
						<option >Louisiana</option>
						<option >Maine</option>
						<option >Maryland</option>
						<option >Massachusetts</option>
						<option >Michigan</option>
						<option >Minnesota</option>
						<option >Mississippi</option>
						<option >Missouri</option>
						<option >Montana</option>
						<option >Nebraska</option>
						<option>Nevada</option>
						<option>New Hampshire</option>
						<option>New Jersey</option>
						<option>New Mexico</option>
						<option>New York</option>
						<option>North Carolina</option>
						<option>North Dakota</option>
						<option>Ohio</option>
						<option>Oklahoma</option>
						<option>Oregon</option>
						<option>Pennsylvania</option>
						<option>Rhode Island</option>
						<option>South Carolina</option>
						<option>South Dakota</option>
						<option>Tennessee</option>
						<option>Texas</option>
						<option>Utah</option>
						<option >Vermont</option>
						<option>Virginia</option>
						<option>Washington</option>
						<option>West Virginia</option>
						<option>Wisconsin</option>
						<option >Wyoming</option>
						</select>
						<span className="errormsg">{this.state.validations.state}</span>
						</div>
						<div className="p-2 col-md-3">
						<label htmlFor="idzipcode" className="label-custom" >Zipcode</label> 
						<input value={this.state.formObj.zipcode} name="zipcode" id="idzipcode"  type="text" onChange={(e)=>{this.changeEvent("zipcode",e)}} onBlur={()=>{ this.validate("zipcode")}} className="form-control form-control-sm" style={this.state.validations.zipcode==""?{}:{"borderColor":"red"}} required /> 	
                          <span className="errormsg">{this.state.validations.zipcode}</span>
                       
						</div>
						<div className="p-2 col-md-3">
						<label htmlFor="idcountry" className="label-custom">Country</label>
						<select id="idcountry" name="country"  value={this.state.formObj.country} onChange={(e)=>{this.changeEvent("country",e)}} onBlur={()=>{ this.validate("country")}} className="form-control form-control-sm" style={this.state.validations.country==""?{}:{"borderColor":"red"}} > 	
							<option >United States</option>
							<option>Belgium</option>
							<option>Cambodia</option>
							<option>Colombia</option>
							<option>France</option>
							<option>Germany</option>
							<option>Switzerland</option>
							</select>
							<span className="errormsg">{this.state.validations.country}</span>
						</div>
					</div><br/>
                  </div>:null}
                     
				  {this.state.virtual?
                  <div>
                    <div className="d-flex row">
                      <div className="p-2 col-md-6">
                      <label htmlFor="idvirtual_url" className="label-custom">Virtual Platform URL</label>
                      <input type="text" id="idvirtual_url" name="virtual_url" value={this.state.formObj.virtual_url} onChange={(e)=>{this.changeEvent("virtual_url",e)}} onBlur={()=>{ this.validate("virtual_url")}} className="form-control form-control-sm" style={this.state.validations.virtual_url==""?{}:{"borderColor":"red"}} required /> 	
                          <span className="errormsg">{this.state.validations.virtual_url}</span>
                       
					  </div>
					  <div className="p-2 col-md-3">
                      <label htmlFor="idvirtual_hostid" className="label-custom">User Name</label>
                      <input type="text"  name="virtual_hostid" id="idvirtual_hostid" value={this.state.formObj.virtual_hostid} onChange={(e)=>{this.changeEvent("virtual_hostid",e)}} onBlur={()=>{ this.validate("virtual_hostid")}} className="form-control form-control-sm" style={this.state.validations.virtual_hostid==""?{}:{"borderColor":"red"}} required /> 	
                          <span className="errormsg">{this.state.validations.virtual_hostid}</span>
                       
                    </div>
                    
                    <div className="p-2 col-md-3">
                    <label htmlFor="idvirtual_user_pass" className="label-custom">Password</label>
                      <input type="text"  name="virtual_user_pass" id="idvirtual_user_pass"  value={this.state.formObj.virtual_user_pass} onChange={(e)=>{this.changeEvent("virtual_user_pass",e)}} onBlur={()=>{ this.validate("virtual_user_pass")}} className="form-control form-control-sm" style={this.state.validations.virtual_user_pass==""?{}:{"borderColor":"red"}} required /> 	
                          <span className="errormsg">{this.state.validations.virtual_user_pass}</span>
                      </div>

                    </div>
					</div>
					:null}

			  <div className="d-flex row">   
				    <div className="p-2 flex-fill col-md-5">
					 <label className="label-custom">Select your Business Objectives</label>
					 <div><span className="errormsg">{this.state.validations.bussinesobjective}</span> </div>	
						<div className="d-flex flex-wrap">
							{this.renderBussinessObj()}
						</div>
						<div className="d-flex row">
						<div className="col-md-6"><button type="btn" className="btn btn-primary btn-sm" onClick={this.addBusinessObj}>+Add</button>
						</div>
						<div className="col-md-4"></div>
						</div>
				     </div> 	
			    <div className="p-2 col-md-7">
			       <label htmlFor="idnotes" className="label-custom">Add Notes</label>
                   <textarea type="text" rows="2" cols="20" name="notes" id="idnotes" value={this.state.formObj.notes} onChange={(e)=>{this.changeEvent("notes",e)}}  onBlur={()=>{ this.validate("notes")}} className="form-control" style={this.state.validations.notes==""?{}:{"borderColor":"red"}} required /> 	
                    <span className="errormsg">{this.state.validations.notes}</span>    
                </div> 
			   </div>
               <div className="d-flex row ">
			   <div className="col-md-12">
				   {this.render_formButton()}
			   </div>
			   </div>

			   </div>

			   <Modal show={this.state.dltconfirm} onHide={this.confirmdeleteModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                <br/><br/>
				
                <div className="d-flex justify-content-center row">
                     <h6> Are You Sure?? Want to Delete this Record!! </h6> 
                         <br/><br/><br/>
                </div>
                <div className="d-flex justify-content-center row">
                   <Button variant="danger"  onClick={this.deleterecord} >Yes</Button>
                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                   <Button variant="warning" onClick={this.confirmdeleteModal}>No</Button>
                  
                </div>
                </Modal.Body>
              </Modal>

				</div>
		)}
		
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(EventsForm);
