import React, { Component } from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import {Events } from '../models/Events';
import { Validators } from '../utils/Validators';
import { base_url } from '../constants/Constants';
import ShippingsForm from './ShippingsForm';
import OrdersForm from './OrdersForm';
import DocumentsForm from './DocumentsForm';



export class Logistics extends Component {
    constructor(props){
        super(props);
        this.state={
            notes : '',
            showUploadsdoc : false,
            showExternalweb : true,
            formObj : Events,
            validations : {},
            uploadDocs : [],
            eventid: '',
            filename:'',
            docfilename:'',
            uploadfilename:'',
            showvirtual: false,
           
        }

        this.changeEvent = this.changeEvent.bind(this);
        this.validate =this.validate.bind(this);
        this.updateFile = this.updateFile.bind(this);
        this.changeuploadType = this.changeuploadType.bind(this);
        this.handleFileName = this.handleFileName.bind(this);
    }
    componentDidMount(){
       // console.log(this.props.header);
        let validtemp={};
		for (let key in Events) { validtemp[key] = ""; }
        this.setState({validations:validtemp});
        
        axios.get(base_url+'events/listbyeventid/'+this.props.eventid, this.props.header )
            .then(res =>{
               var temp=res.data[0];
                if(temp.event_type == 'In Person'){
                this.setState({showvirtual:true});
                 } 
                 this.setState({formObj:temp});
                // console.log(this.state.formObj);
             }).catch(function (error) { console.log(error); }); 
            
    
    }
    validate = (val) =>{
		let tempvalid = this.state.validations;
		switch(val){
            case "portal_url": { tempvalid.portal_url = Validators.name(this.state.formObj.portal_url); } break;
            case "event_login": { tempvalid.event_login = Validators.name(this.state.formObj.event_login); } break;
            case "event_password": { tempvalid.event_password = Validators.password(this.state.formObj.event_password); } break;
        }
        this.setState({validations:tempvalid});
    }  
    changeEvent = (val,e) =>{
		let templog = this.state.formObj;
		switch(val){
            case "portal_url": { templog.portal_url = e.target.value; } break;
            case "event_login": {  templog.event_login =  e.target.value;} break;
            case "event_password": { templog.event_password = e.target.value; } break;
        }
        this.setState({formObj:templog});
    }   
 

    continue = (e) => {
        this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.enddate,this.props.eventid); 		
      }
    back = (e) => {
        this.props.parentObject.eventStepBack();
    }
    resetAll(){
     this.setState({showUploadsdoc:false, showExternalweb:false})
    }
    changeuploadType=(e)=>{
        let newformObj=this.state.formObj;
		newformObj.uploadType=e.target.value
		this.setState({ formObj:newformObj })
		//console.log(this.state.formObj);
    }
    updateFile = (filename) =>{
         this.setState({filename:filename});
    }
    handleFileName=()=>{

    }
    
    onSubmittEvent(){
        let temp='';
        for (let key in Events) { this.validate(key);}
        for (let key in this.state.validations) { temp += this.state.validations[key];}
	    if(temp == "" ){
        let newformObj=this.state.formObj;
        newformObj.events_id=this.props.eventid;
        newformObj.filename=this.state.filename;
        this.setState({ formObj:newformObj });
       // console.log(newformObj);
			axios.post(base_url+'events/save', newformObj, this.props.user.header )
			.then(res =>{
                axios.get(base_url+'events/listbyeventid/'+this.props.eventid, this.props.header )
                .then(res =>{
                    this.setState({ formObj:res.data[0] });
                    console.log(this.props.enddate);
                this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.enddate,this.props.eventid); 
			}).catch(function (error) { console.log(error); });
        }).catch(function (error) { console.log(error); });
    } 
    }
    render_formButton(){
        if(this.state.formObj.event_type === 'In Person'){
            return(
               <div className="d-flex justify-content-end row">
                    <div className="p-2">
                        <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()}>Back</button>
                    </div>
                        <div className="p-2">
                        <button type="button" className="btn btn-primary btn-block" onClick={() =>this.onSubmittEvent()}>Next</button>
                    </div>
               </div>
   
            )
        }else{
            return(
               <div className="d-flex  justify-content-end row">
                    <div className="p-2">
                        <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()}>Back</button>
                    </div>
                        <div className="p-2">
                        <button type="button" className="btn btn-primary btn-block" onClick={() =>this.continue()}>Next</button>
                    </div>
               </div>
   
            )
        }
    }

    render() {

        return (
            
                <div className="shadow-sm p-3 mb-5 bg-white rounded">
                     <div className="brdcolor" >   
                     {this.state.showvirtual ?<div>
                        <div className="d-flex row">
                        <div className="p-2 flex-fill">
                        <label htmlFor="idportal_url" className="label-custom" >Exhibitor Portal Login Website URL</label>
                        <input type="text" id="idportal_url" name="portal_url" value={this.state.formObj.portal_url} onChange={(e)=>{this.changeEvent("portal_url",e)}} onBlur={()=>{ this.validate("portal_url")}} className="form-control form-control-sm" style={this.state.validations.portal_url==""?{}:{"borderColor":"red"}} required /> 
                        <span className="errormsg">{this.state.validations.portal_url}</span>
                        </div>
                        <div className="p-2 col-md-3">
                        <label htmlFor="iduserhost_id" className="label-custom" >User Name</label>
                        <input type="text" id="iduserhost_id" name="userhost_id" value={this.state.formObj.event_login} onChange={(e)=>{this.changeEvent("event_login",e)}} onBlur={()=>{ this.validate("event_login")}} className="form-control form-control-sm" style={this.state.validations.event_login==""?{}:{"borderColor":"red"}} required /> 
                        <span className="errormsg">{this.state.validations.event_login}</span>
                        </div>
                        <div className="p-2 col-md-3">
                        <label htmlFor="iduserhost_password" className="label-custom" >Password</label>
                        <input type="text" id="iduserhost_password" name="event_password" value={this.state.formObj.event_password} onChange={(e)=>{this.changeEvent("event_password",e)}} onBlur={()=>{ this.validate("event_password")}} className="form-control form-control-sm" style={this.state.validations.event_password==""?{}:{"borderColor":"red"}} required /> 
                        <span className="errormsg">{this.state.validations.event_password}</span>
                        </div>
                        </div>
                        </div> :null}
                           
                        <div className="d-flex row">
                        <div className="p-2 col-md-12">
                        <DocumentsForm  parentObject={this}  header={this.props.user.header} eventid={this.props.eventid}/>
                        </div>
                        </div>
                         {this.state.showvirtual ?<div>
                        <div className="d-flex row">
                        <div className="p-2 col-md-12"><OrdersForm parentObject={this}  header={this.props.header} eventid={this.props.eventid} /></div>
                        </div>

                        <div className="d-flex row">
                        <div className="p-2 col-md-12"><ShippingsForm parentObject={this}  header={this.props.header} eventid={this.props.eventid}/></div>
                        </div>
                        </div> :null}
     
                        <div className="d-flex row">
                        <div className="col-md-12">  
                        {this.render_formButton()}
                        </div>
                        </div>
                   </div>   
                </div>
 
        )
    }
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Logistics);

