import React, { Component } from 'react'
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

export class CompleteEvent  extends Component {
    constructor(props){
        super(props);
          this.state = {
            eventname:'',
            eventid : '',
            eventdate:'', 
          };    
    }
    componentDidMount(){
        axios.get(base_url+'events/listbyeventid/'+this.props.eventid, this.props.header )
        .then(res =>{
           var temp=res.data[0];
           this.setState({formObj:temp});
         }).catch(function (error) { console.log(error); });        
    }
    render() {
        return (
            <div id="sucessmsg" className="shadow-sm p-3 mb-5 bg-white rounded">
                <div className="d-flex">
                   <h5><span className="textblue">CONGRATS you have successfully added your event!<br/><br/>
                      You can edit details at any time by clicking on the event name in the <NavLink to="/app/dashboard"><span style={{color: "blue"}}>Dashboard.</span></NavLink><br/><br/>

                      You'll also be able to open your Summary Report by clicking on the PDF logo.<br/><br/>

                    
                    IMPORTANT: After the event, be sure to login and update Step #7 ROI Report and Step #8 Post Event.<br/>
                     You'll then have the calculated Event ROI </span></h5>
                </div>
               
             </div>
        )
    }
    
}


const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(CompleteEvent );