///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Shippings = {
	product : '', 
	shipp_time:'',
	calanderlink:'',
	shippingback:'',
	eventsId : '', 
	from_address1 : '', 
	from_address2 : '', 
	from_city : '', 
	from_state : '', 
	from_country : '', 
	from_zipcode : '', 
	from_phone : '', 
	from_email : '', 
	to_address1 : '', 
	to_address2 : '', 
	to_city : '', 
	to_state : '', 
	to_country : '', 
	to_zipcode : '', 
	to_phone : '', 
	to_email : '', 
	delivery_lastdate : '', 
	shipping_back : '', 
	donatable_item : '', 
	notes : '', 
	status : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}