////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {Button,Modal} from 'react-bootstrap';
import { FaPen, FaPrint, FaFileExcel, FaFilePdf, FaEnvelopeOpen } from 'react-icons/fa';
import { base_url } from '../constants/Constants';
import { Misc } from '../services/Misc';
import { Permissions } from '../models/Permissions';
import {Tasks } from '../models/Tasks';
import AutoCompleteText from '../utils/AutoCompleteText';
import FileUpload from '../utils/FileUpload';
import '../styles/Styles.css';
import $ from 'jquery';

class TasksForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
			edit : true,
            showtask:false,
			showEdit : false,
			formObj : Tasks,
			taskList : [],
			permissions : Permissions
		}
		this.handleEdit = this.handleEdit.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.newRecord = this.newRecord.bind(this);
		this.enableEdit = this.enableEdit.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.updateFilename = this.updateFilename.bind(this);
		this.handleTaskModal = this.handleTaskModal.bind(this);
		this.handleTaskname = this.handleTaskname.bind(this);
		this.handleTasktask = this.handleTasktask.bind(this);
		this.handleTaskstartdate  = this.handleTaskstartdate.bind(this);
		this.handleTaskduedate = this.handleTaskduedate.bind(this);
		this.handleTaskdetails = this.handleTaskdetails.bind(this);

	}

	componentDidMount(){
		axios.get(base_url+'permissions/getbypermissionsgroup/'+this.props.user.groupid+'/Tasks', this.props.user.header )
		.then(res =>{ this.setState({ permissions:res.data[0]}); }).catch(function (error) { console.log(error); });
		
		axios.get(base_url+'tasks/listbyeventid/'+this.props.eventid,  this.props.user.header )
			.then(res =>{
                // console.log(res.data);
				 this.setState({ taskList:res.data });
				}).catch(function (error) { console.log(error); });    
	}

	newRecord = () => { this.setState({ id : '', showEdit : true, formObj : Tasks }); }

	updateFilename( filename ){
		//update related formObj field as needed for fileupload 
	}
	handleTaskModal = (e) => {
        this.setState({showtask:!this.state.showtask});
	}
	handleTaskname = (e) =>{
		let newformObj=this.state.formObj;
		newformObj.staffname=e.target.value
		this.setState({ formObj:newformObj })
	}
    handleTaskstartdate = (e) =>{
		let newformObj=this.state.formObj;
		newformObj.start_datetime=e.target.value
		this.setState({ formObj:newformObj })
	}
	handleTasktask = (e) =>{
		let newformObj=this.state.formObj;
		newformObj.task=e.target.value
		this.setState({ formObj:newformObj })
	}
	handleTaskduedate = (e) =>{
		let newformObj=this.state.formObj;
		newformObj.due_date=e.target.value
		this.setState({ formObj:newformObj })
	}
	handleTaskdetails = (e) =>{
		let newformObj=this.state.formObj;
		newformObj.details=e.target.value
		this.setState({ formObj:newformObj })
	}
	render_taskslist(){
		if( this.state.taskList != undefined && this.state.taskList.length > 0){
		return this.state.taskList.map((item, index) =>{
		  return (
			<tr key={item.id} id={index}>
			    <td>{item.staffname}</td>
			    <td>{item.task}</td>
				<td>{item.start_datetime}</td>
				<td>{item.due_date}</td>
		  		<td>{item.details}</td>            
		   </tr>
		  ) 
		   }, this);
	  }
	
	}
	enableEdit( item ){
		if(item.id !== ''){
			axios.get(base_url+'tasks/getbyid/'+item.id, this.props.user.header )
			.then(res =>{
				this.setState({ formObj:res.data});
				this.setState({id:item.id, showEdit:true });
			}).catch(function (error) { console.log(error); });
		}
	}

	onSubmit(){
		let testnewform=this.state.formObj;
		testnewform.status='Active';
		testnewform.events_id=this.props.eventid;
		this.setState({formObj:testnewform});
			axios.post(base_url+'tasks/save', testnewform, this.props.user.header )
			.then(res =>{
				console.log(res.data.data);
				//this.setState({ formObjdata:res.data.data});
			axios.get(base_url+'tasks/listbyeventid/'+this.props.eventid,  this.props.user.header )
			.then(res =>{
                // console.log(res.data);
				 this.setState({ taskList:res.data });
				 {this.handleTaskModal()}
				}).catch(function (error) { console.log(error); });    
			}).catch(function (error) { console.log(error); });
	}

	handleEdit = () => { this.setState({edit:!this.state.edit}); }

	handleCancel = () => { 	this.setState({showEdit:false }); }

	handleReset = () => { this.setState({ formObj:Tasks}); }

	render() {

		$(function () {	$('[data-toggle="popover"]').popover({container: 'body'}) })

		if( this.state.permissions.dateread === 'N' ){ return ( <div>Access Denied</div> ); }
		else{ return (
			<div id="idtasks">
				 <div className="d-flex row">
					<div className="form-group col-md-12">
					<label className="label-custom">Tasks</label>
						<table className="table table-bordered table-sm  ">
							<thead className="thead">
								<tr>
									<td>Staff Name</td>
									<td>Task</td>
									<td>Start Date</td>
									<td>Due Date</td>
									<td>Details</td>
								</tr>
							</thead>
							<tbody>
								{this.render_taskslist()}
							</tbody>
						</table>
						</div>
				</div>
				<div className="d-flex row">
					<div className="form-group col-md-10"></div>
					<div className="form-group col-md-2">
						<button type="button" className="btn btn-primary btn-block btn-sm" onClick={()=>{this.handleTaskModal()}}>+Add</button>
					</div>
				</div>

					<Modal size="lg" show={this.state.showtask} onHide={()=>{this.handleTaskModal()}} >
					<Modal.Header closeButton>
					<Modal.Title>Tasks</Modal.Title>
					</Modal.Header>
						<Modal.Body>
							<div className="form-row">
								<div className="form-group col-md-5">
								<label htmlFor="idstaffname" className="label-custom">Staff Name</label>
								<input name="staffname" id="idtaffname" type="text" placeholder="Enter Staff Name" className="form-control form-control-sm"  value={this.state.formObj.staffname} onChange={(e)=>{this.handleTaskname(e)}}/>
							</div>
							<div className="form-group col-md-7">
								<label htmlFor="idtask" className="label-custom">Task</label>
								<input name="task" id="idtask" type="text" placeholder="Task Name" className="form-control form-control-sm" value={this.state.formObj.task} onChange={(e)=>{this.handleTasktask(e)}}/>
							</div>
							</div>
							<div className="form-row">
								<div className="form-group col-md-3">
									<label htmlFor="idstart_datetime" className="label-custom">Start Date</label>
									<input name="start_datetime" id="idstart_datetime" type="date" placeholder="" className="form-control form-control-sm" value={this.state.formObj.start_datetime} onChange={(e)=>{this.handleTaskstartdate(e)}} />
								</div>
								<div className="form-group col-md-3">
									<label htmlFor="iddue_date" className="label-custom">Due Date</label>
									<input name="due_date" id="iddue_date" type="date" placeholder="" className="form-control form-control-sm"  value={this.state.formObj.due_date} onChange={(e)=>{this.handleTaskduedate(e)}} />
								</div>
								<div className="form-group col-md-6">
									<label htmlFor="iddetails" className="label-custom">Details</label>
									<input name="details" id="details" type="text" placeholder="Enter Details" className="form-control form-control-sm"  value={this.state.formObj.details} onChange={(e)=>{this.handleTaskdetails(e)}}/>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="warning" onClick={()=>{this.handleTaskModal()}}>Close</Button>
							<Button variant="primary" onClick={()=>{this.onSubmit()}}>Save</Button>
						</Modal.Footer>
					</Modal>
		  </div>		
		)}
		}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(TasksForm);
