///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Hotels = {
	eventsId : '', 
	staffname : '', 
	lastname : '', 
	hotelname : '', 
	hoteladdress : '',
	checkin : '', 
	checkout : '', 
	reservationlink : '', 
	notes : '', 
	status : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}