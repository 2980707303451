import React, { Component } from 'react'
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import axios from 'axios';
import Logistics from './Logistics';
import Staff from './Staff';
import MarketingsForm from './MarketingsForm';
import ReturnoninvestmentsForm from './ReturnoninvestmentsForm';
import Exhibitorlogo from '../assets/exhibitor_logo.png';
import PosteventForm from './PosteventForm';
import { NavLink } from 'react-router-dom';
import EventsForm from './EventsForm';
import VendorsForm from './VendorsForm';
import BudgetsForm from './BudgetsForm';
import { CompleteEvent } from './CompleteEvent';
import '../styles/Styles.css';

export class Addevent extends Component {
    constructor(props){
        super(props);
          this.state = {
            step:1,
            current_step:1,
            eventname:'',
            eventid : '',
            eventdate:'',
            enddate:'',
            welcome :false,
            body:true,
            businessobjective: '',
            complete:false,
            notcomplete:true,
            
          };  
          this.eventStep=this.eventStep.bind(this);
          this.eventStepBack=this.eventStepBack.bind(this);  
    }
    componentDidMount(){  
            
    }
    formatDate = (dt) =>{
        const date = new Date(dt);
        const month = date.toLocaleString('default', { month: 'long' });
        return month+' '+ date.getDate()+', '+date.getFullYear();   
    }

    backToLogin = () => {
        this.props.history.push('/login');
    }

    updateStep =(id,cstep)=>{
        this.props.history.push('/app/editevent/?id='+id+'&cstep='+cstep); 
	}

    eventStep = (evname,evdate,endate,evid) =>{
       // this.setState({enddate:endate});
       let step = this.state.step + 1;
       if(step > 6){
        let d1= new Date();
        let d2= new Date(evdate);
            if(d1 < d2){
                this.setState({welcome: true});
                return;
            }
        }
       var temp=this.formatDate(evdate);
       this.setState({eventname:evname, eventdate:temp, step:step, current_step:step,eventid:evid});
       console.log(this.state.step,this.state.current_step);
       axios.post(base_url+'events/updatestep', {step:step, eventid:evid }, this.props.user.header )
       .then(res =>{
       console.log(res);   
       }).catch(function (error) { console.log(error); });  
       
       this.updateStep(evid,2);
    }
    eventStepBack = () =>{
        let step = this.state.step - 1;
        this.setState({ step:step });
        //console.log(step);
    }
    set_Step=(stp)=>{ 
        this.setState({step:stp});

    }
    render_menu(stp,label){
        if(stp == this.state.step){
        return(
            <div className="btnstyleActive" onClick={()=>{this.set_Step(stp)}}>
            <span className="circleBtnActive">{stp}</span><span className="textActive">{label}</span>
            </div> 
        );
        }else if(stp <= this.state.current_step){
        return(
            <div className="btnstyle" onClick={()=>{this.set_Step(stp)}}>
                <span className="circleBtnActive">{stp}</span><span className="textActive">{label}</span>
            </div>  
        );
        }else{
            return(
            <div className="btnstyle" >
                <span className="circleBtn">{stp}</span><span>{label}</span>
            </div>
            );
        }
    }
    render_menus(){
        return(
            
            <div className="nav flex-column"  aria-orientation="vertical">
             {this.render_menu(1,'Event Info')}
             {this.render_menu(2,'Logistics & Documents')}
             {this.render_menu(3,'Staff')}
             {this.render_menu(4,'Marketing')}
             {this.render_menu(5,'Vendors')}
             {this.render_menu(6,'Budget')}
             {this.render_menu(7,'ROI Report')}
             {this.render_menu(8,'Post Event')}
             
             </div>
        )
    }
    render_body(){
        switch(this.state.step) {
             case 1: return (<EventsForm parentObject={this}  header={this.props.user.header} eventid={this.state.eventid} eventname={this.state.eventname} eventdate={this.state.eventdate} enddate={this.state.enddate} accountid={this.props.user.accountid}/>);
            // case 2: return (<Logistics parentObject={this}  header={this.props.user.header}  eventid={this.state.eventid} eventname={this.state.eventname} enddate={this.state.enddate} eventdate={this.state.eventdate} />);
             case 3: return (<Staff parentObject={this}  header={this.props.user.header}  eventid={this.state.eventid} eventname={this.state.eventname} eventdate={this.state.eventdate} enddate={this.state.enddate} />);
             case 4: return (<MarketingsForm parentObject={this}  header={this.props.user.header} eventid={this.state.eventid} eventname={this.state.eventname} eventdate={this.state.eventdate} enddate={this.state.enddate}/>);
             case 5: return (<VendorsForm parentObject={this}  header={this.props.user.header} eventid={this.state.eventid} eventname={this.state.eventname} eventdate={this.state.eventdate} enddate={this.state.enddate}/>);
             case 6: return (<BudgetsForm parentObject={this}  header={this.props.user.header} eventid={this.state.eventid} eventname={this.state.eventname} eventdate={this.state.eventdate} enddate={this.state.enddate}/>);
             case 7: return (<ReturnoninvestmentsForm parentObject={this}  header={this.props.user.header} eventid={this.state.eventid} eventname={this.state.eventname} eventdate={this.state.eventdate} enddate={this.state.enddate}/>);
             case 8: return (<PosteventForm parentObject={this}  header={this.props.user.header} eventid={this.state.eventid} eventname={this.state.eventname} eventdate={this.state.eventdate} enddate={this.state.enddate} />);
             default: return (<EventsForm parentObject={this}  header={this.props.user.header} eventid={this.state.eventid} eventname={this.state.eventname} eventdate={this.state.eventdate} enddate={this.state.enddate}/>);
        }
    }


    render() {
            return(
                <div className="container-fluid">
                <br/>
                <div className="d-flex row flex-wrap">
                    <div className="p-2 col-md-3"><NavLink to="/app/dashboard"><img  src={Exhibitorlogo} alt="Logo" /></NavLink></div>
                    <div className="p-2 col-sm-6"><div className="pluscolor"><b>{this.state.eventname}</b></div></div>
                    <div className="p-2 col-sm-3"><div className="pluscolor"><b>{this.state.eventdate}</b></div></div>
               </div>
                <hr/> 
                <div className="d-flex row">
                     <div className="p-2 col-md-3">{this.render_menus()}</div>
                     {this.state.welcome?<div className="p-2 col-md-9"><CompleteEvent/></div>:null}
                     {this.state.body?<div className="p-2 col-md-9">{this.render_body()}</div>:null}
                </div>
             </div>
            )  
        
            }
}


const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Addevent);