////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {Button,Modal} from 'react-bootstrap';
import { Validators } from '../utils/Validators';
import { FaPen,FaTrash } from 'react-icons/fa';
import { base_url } from '../constants/Constants';
import { Permissions } from '../models/Permissions';
import {Vendors } from '../models/Vendors';
import NotesForm from './NotesForm';
import '../styles/Styles.css';
import $ from 'jquery';

class VendorsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
			edit : true,
			showmodal:false,
			showEdit : false,
			formObj : Vendors,
			vendorList : [],
			validations:{},
			permissions : Permissions,
			eventid : '',
			eventname :'',
			eventdate :'',
		}
		
		this.onSubmit = this.onSubmit.bind(this);
		this.handleModal=this.handleModal.bind(this);
		this.handleVendorName = this.handleVendorName.bind(this);
		this.handleContactName = this.handleContactName.bind(this);
		this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
		this.handleVendorLink = this.handleVendorLink.bind(this);
		this.handleDeleteRow = this.handleDeleteRow.bind(this);
		this.addNewVendor = this.addNewVendor.bind(this);
		this.getFormattedPhoneNum = this.getFormattedPhoneNum.bind(this);
	}

	componentDidMount(){
		let validtemp={};
		for (let key in Vendors) { validtemp[key] = ""; }
		this.setState({validations:validtemp});
		
		console.log(this.state.formObj);
		axios.get(base_url+'vendors/listbyeventid/'+this.props.eventid,  this.props.user.header )
				.then(res =>{
                  if(res.data.length > 0){
				 this.setState({ vendorList:res.data });
				 console.log(res.data);
				  }else{
					  var temp={};
					temp={id:0,events_id:this.props.eventid,status:'Active',vendorname:"<Enter Vendor name>",contactname:"<Enter Contact Name>",phone:"(xxx) xxx-xxxx",vendorLink:"<Enter vendorlink>"};
					  axios.post(base_url+'vendors/save', temp, this.props.user.header ).then(res =>{
						  console.log(res.data)
						axios.get(base_url+'vendors/listbyeventid/'+this.props.eventid,  this.props.user.header )
						.then(res =>{
						 this.setState({ vendorList:res.data });
						 console.log(res.data);
						}).catch(function (error) { console.log(error); });    
					  }).catch(function (error) { console.log(error); }); 
					 
				  }
				}).catch(function (error) { console.log(error); });    
	}
	validate = (val) =>{
		let tempvalid = this.state.validations;
		switch(val){
				case "vendorname": { tempvalid.vendorname = Validators.name(this.state.formObj.vendorname); } break;
				case "contactname": { tempvalid.contactname = Validators.name(this.state.formObj.contactname); } break;
				case "vendorLink": { tempvalid.vendorLink = Validators.website(this.state.formObj.vendorLink); } break;
				case "phone": { tempvalid.phone = Validators.phone(this.state.formObj.phone); } break;
			}
			this.setState({validations:tempvalid});
		}
	continue = (e) => {
        this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.enddate,this.props.eventid); 			
    }
    back = (e) => {
        this.props.parentObject.eventStepBack();
	}
	addNewVendor = () =>{
		let temp={vendorname:"",contactname:"",phone:"",vendorLink:""};
		this.setState({ formObj:temp});
		let temps='';
		for (let key in this.state.validations) { temps += this.state.validations[key];}
	    if(temps !== "" ){
			this.setState({ validations:temp });
		}	
		this.setState({showmodal:!this.state.showmodal});	
	}
	handleModal = (row) =>{
         
	 	if(row !== undefined && row >= 0 && this.state.vendorList.length >0){
			let temp={id:"",_id:"",vendorname:"",contactname:"",phone:"",vendorLink:""};
			temp.id=this.state.vendorList[row].id;
			temp._id=this.state.vendorList[row]._id;
			if(this.state.vendorList[row].vendorname === "<Enter Vendor name>"){
				temp.vendorname="";
			}else{
				temp.vendorname=this.state.vendorList[row].vendorname ;
			}
			if(this.state.vendorList[row].contactname === "<Enter Contact Name>"){
				temp.contactname="";
			}else{
				temp.contactname=this.state.vendorList[row].contactname;
			}
			if(this.state.vendorList[row].vendorLink === "<Enter vendorlink>"){
				temp.vendorLink="";
			}else{
				temp.vendorLink=this.state.vendorList[row].vendorLink;
			}
			if(this.state.vendorList[row].phone === "(xxx) xxx-xxxx"){
				temp.phone="";
			}else{
				temp.phone=this.state.vendorList[row].phone;
			}

			this.setState({formObj:temp});
			} 
			this.setState({showmodal:!this.state.showmodal});
	}
	handleVendorName = (e) => {
		let newformObj=this.state.formObj;
		var string = e.target.value;
		if(newformObj !== undefined){
		newformObj.vendorname = string.charAt(0).toUpperCase() + string.slice(1);
		this.setState({ formObj:newformObj });

		}
		  
	}
	handleContactName = (e) => {
		let newformObj=this.state.formObj;
		var string = e.target.value;
		if(newformObj !== undefined){
		newformObj.contactname = string.charAt(0).toUpperCase() + string.slice(1);
		this.setState({ formObj:newformObj })
		}
		//console.log(this.state.formObj); 	  
	}
	handlePhoneNumber = (e) => {
		let newformObj=this.state.formObj;
		if(newformObj !== undefined){
		newformObj.phone=e.target.value
		this.setState({ formObj:newformObj })
		}
		//console.log(this.state.formObj); 	  
	}
	handleVendorLink = (e) => {
		let newformObj=this.state.formObj;
		if(newformObj !== undefined){
		newformObj.vendorLink=e.target.value
		this.setState({ formObj:newformObj })
		}
		//console.log(this.state.formObj); 	  
	}
	handleDeleteRow = (i) => {
		let newform=this.state.vendorList[i];
		newform.status='Inactive';
		console.log(newform);
		axios.post(base_url+'vendors/save', newform, this.props.user.header )
		.then(res =>{
			axios.get(base_url+'vendors/listbyeventid/'+this.props.eventid,  this.props.user.header )
			.then(res =>{
			// console.log(res.data);
			 this.setState({ vendorList:res.data });
			}).catch(function (error) { console.log(error); });    
		}).catch(function (error) { console.log(error); });
	}
	getFormattedPhoneNum = () =>{
		let phnnum=this.state.formObj.phone;
		var pattern = new RegExp(/^[0-9\b]+$/);
		if(phnnum !== null && phnnum !== ''){ 
		   let output = "(";
		   phnnum.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
		 {
		   if ( g1.length ) {
		   output += g1;
		   if ( g1.length == 3 ) {
			 output += ")";
			 if ( g2.length ) {
			   output += " " + g2; 
			   if ( g2.length == 3 ) {
				 output += "-";
				 if ( g3.length ) {
				   output += g3;
				 }
			   }
			 }
			}
		   }
		 }   
		 );        
	   let newform=this.state.formObj;
	   newform.phone=output;		  
		} 
	 }      
	render_VendorList(){
		if( this.state.vendorList != undefined && this.state.vendorList.length > 0){
			return this.state.vendorList.map((item, index) =>{
			  return (
				<tr key={index} id={index}>
				  <td>{item.vendorname}</td>
				  <td>{item.contactname}</td>
				  <td>{item.phone}</td>
				  <td>{item.vendorLink} 
				  <span><FaPen size="15px" color="blue" onClick={()=>{this.handleModal(index)}}/>&nbsp;&nbsp;
				   <FaTrash size="15px" color="red" onClick={() => {this.handleDeleteRow(index)}} />&nbsp;&nbsp;</span></td>         
			      </tr>
			  ) 
			}, this);
		}
	}
	render_formButton(){
        if(this.props.eventAction === 'edit'){
            return(
				<div className="d-flex justify-content-end row">
					 <div className="p-2">
						 <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()} >Back</button>
					 </div>
					 <div className="p-2">
						 <button type="button" className="btn btn-primary btn-block" onClick={() =>this.continue()}>Next</button>
					 </div>
				</div>
   
            )
        }else{
            return(
               <div className="d-flex justify-content-end row">
					<div className="p-2">
						<button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()} >Back</button>
					</div>
					<div className="p-2">
						<button type="button" className="btn btn-primary btn-block" onClick={() =>this.continue()}>Next</button>
					</div>
               </div>
   
            )
        }
    }
	

	onSubmit(){
		let temp='';
        for (let key in Vendors) { this.validate(key);}
        for (let key in this.state.validations) { temp += this.state.validations[key];}
        if(temp == "" ){
		   let newform=this.state.formObj;
		    newform.status='Active';
			newform.events_id=this.props.eventid;
			console.log(newform);
			axios.post(base_url+'vendors/save', newform, this.props.user.header )
			.then(res =>{
				axios.get(base_url+'vendors/listbyeventid/'+this.props.eventid,  this.props.user.header )
				.then(res =>{
				 this.setState({ vendorList:res.data });
				 {this.handleModal()}
				}).catch(function (error) { console.log(error); });    
			}).catch(function (error) { console.log(error); });
		}	
	}


	render() {

	return (
		<div id="idvendors" className="shadow-sm p-3 mb-5 bg-white rounded">
			 <div className="d-flex row">
				<div className="p-2 col-md-12">
					<label htmlFor="vendortable" className="label-custom">Add Your Vendors List</label>
					<table className="table table-bordered table-sm table-responsive-stack">
						<thead className="thead">
							<tr>
								<td>Vendors Name</td>
								<td>Contact Name</td>
								<td>Phone</td>
								<td>Vendors Link</td> 
							</tr>
						</thead>
						<tbody>
							{this.render_VendorList()}
						</tbody>
					</table>
					<div className="p-2 col-md-12">
					<div className="d-flex justify-content-end row">
					<div className="p-2">
					 <button type="button" className="btn btn-primary btn-block btn-sm" onClick={(e)=>{this.addNewVendor()}}>+Add</button>
               </div>
			   </div>
			   </div>
			   </div>
			   </div>
			   <div className="d-flex row">
			   <div className="p-2 col-md-12">
                 <NotesForm parentObject={this}  header={this.props.header} eventid={this.props.eventid} stepname='vendors'/> 
                </div> 
			   </div>
			   <div className="d-flex row">
                        <div className="col-md-12">  
                        {this.render_formButton()}
                        </div>
                </div>
			<Modal size="lg" show={this.state.showmodal} onHide={()=>{this.addNewVendor()}} >
				<Modal.Header closeButton>
				<Modal.Title>Vendors</Modal.Title>
				</Modal.Header>
					<Modal.Body>
					<div className="brdcolor">
					<div className="form-row">
						<div className="form-group col-md-6">
						<label htmlFor="id_vendorname" className="label-custom">Vendors Name</label>  
						<input name="vendorname" id="id_vendorname" type="text" value={this.state.formObj.vendorname} onChange={(e)=>{this.handleVendorName(e)}} placeholder="Enter Vendors Name"  onBlur={()=>{ this.validate("vendorname")}} className="form-control form-control-sm" style={this.state.validations.vendorname==""?{}:{"borderColor":"red"}} required /> 	
                                 <span className="errormsg">{this.state.validations.vendorname}</span>
						</div>
						<div className="form-group col-md-6">
						<label htmlFor="id_contactname" className="label-custom">Contact Name</label>  
						<input name="contactname" id="id_contactname" type="text" value={this.state.formObj.contactname} onChange={(e)=>{this.handleContactName(e)}}  placeholder="Enter Contact Name"  onBlur={()=>{ this.validate("contactname")}} className="form-control form-control-sm" style={this.state.validations.contactname==""?{}:{"borderColor":"red"}} required /> 	
                                 <span className="errormsg">{this.state.validations.contactname}</span>
						</div>
					</div>
					<div className="form-row">
						<div className="form-group col-md-6">
						<label htmlFor="id_Phone" className="label-custom">Phone</label>  
						<input name="phone" id="id_Phone" type="text" value={this.state.formObj.phone} onChange={(e)=>{this.handlePhoneNumber(e)}} onKeyUp={()=>{this.getFormattedPhoneNum()}} placeholder="Enter Phone"  onBlur={()=>{ this.validate("phone")}} className="form-control form-control-sm" style={this.state.validations.phone==""?{}:{"borderColor":"red"}} required /> 	
                             <span className="errormsg">{this.state.validations.phone}</span>
						</div>
						<div className="form-group col-md-6">
						<label htmlFor="id_vendorLink" className="label-custom">Vendors Link</label>  
						<input name="vendorLink" id="id_vendorLink" type="text" value={this.state.formObj.vendorLink} onChange={(e)=>{this.handleVendorLink(e)}}  placeholder="Enter Vendor Link"  onBlur={()=>{ this.validate("vendorLink")}} className="form-control form-control-sm" style={this.state.validations.vendorLink==""?{}:{"borderColor":"red"}} required /> 	
                            <span className="errormsg">{this.state.validations.vendorLink}</span>
						</div>
					</div>
					</div>	
					</Modal.Body>
				<Modal.Footer>
					<Button variant="warning" onClick={()=>{this.addNewVendor()}}> Close </Button>
					<Button variant="primary" onClick={()=>{this.onSubmit()}}> Save</Button>
				</Modal.Footer>
            </Modal>
     </div>
		)}
		
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(VendorsForm);
