///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Users = {
	firstname : '',
	lastname : '',
	company : '',
    email : '',
	title : '',
	phone : '',
	password : '',
	confirmpwd : '',
	address1: '',
	address2: '',
	city: '',
	admin:'',
	state: '',
	zip: '',
	charging_date :'',
	cardname: '',
	cardnumber: '',
	expmonth: '',
	expyear: '', 
	cvc: '', 
	designation : '',
	language : '',
	currency : '',
	timezone : '',
	emailverificationcode : '',
	resetPasswordToken : '',
	resetPasswordExpires : '',
	token : '',
	note : '',
	type : '',
	status : '',
	id : '',
	date_created : { type : String },
	date_updated : '',
	
}