import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import { base_url } from '../constants/Constants';
import axios from 'axios';

async function getDocumentDefnition(accountid, header, options){
    var total =0.00;
    var orders=[
        {accountid: "13",
        amount: "$420.00",
       description: "Yearly Subscription",
        cardnumber: "4242424242424242",
        date_created: "2021-02-13T11:25:44.326Z",
        date_updated: "2021-02-13T11:25:44.326Z",
        id: "7",
        status: "success",
        transaction_type: "card"},
        {accountid: "13",
        amount: "$50.00",
        description: "books",
        cardnumber: "4242424242424242",
        date_created: "2021-02-13T11:20:44.326Z",
        date_updated: "2021-02-13T11:25:44.326Z",
        id: "6",
        status: "success",
        transaction_type: "card"}
    ];
    await   axios.post(base_url+'transactions/listby/',{accountid:accountid},header)
    .then(res =>{
        console.log(res.data);
        if(res.data.length > 0 ){ orders= res.data; }
    }).catch((error) => { console.log(error); });
    
    

var users={
    accountid: "13",
address1: "#1105 BSK",
address2: "Bengaluru",
cardname: "",
cardnumber: "",
city: "BANASANKARI 3rd Stage",
company: "Deemsoft",
currency: "",
cvc: "",
email: "deemsoft5+1@gmail.com",
emailverificationcode: "Active",
expmonth: "",
expyear: "",
firstname: "examplename@",
id: "13",
language: "",
lastname: "lastname@",
note: "",
phone: "(999) 678-9876",
state: "Indiana",
status: "Enabled",
zipcode:"12334",
};
await axios.post(base_url+'users/userid/',{accountid: accountid},header )
    .then(res =>{
        //console.log(res.data[0]);
        if(res.data.length > 0 ){
        users=res.data[0];
        console.log(users);
        }
        // console.log(users);
 }).catch(function (error) { console.log(error); }); 
 let dt=new Date(users.date_created);
var day = ("0" + dt.getDate()).slice(-2);
var month = ("0" + (dt.getMonth() + 1)).slice(-2);
var year = dt.getFullYear();
let date1= month+'-'+day+'-'+year;

var imageData='data:image/jpg;base64,';
await axios.post(base_url+'file/b64download',{filename: 'exhibitor_logo.png'},header)
.then(res=>{
    console.log(res.data.data);
    imageData += res.data.data;
 }).catch(function (error) { console.log(error); });  

    var orderList=[
        [	
            { text: 'Orderid', style: 'tableHeader' }, 	
            {text:'Description',style:'tableHeader'},			
            { text: 'Date', style: 'tableHeader' }, 
            { text: 'Amount', style: 'tableHeader' }, 
        ]
    ];
    for(var i in orders) { 
        console.log(orders) ;
        let dt=new Date(orders[i].date_created);
        var day = ("0" + dt.getDate()).slice(-2);
        var month = ("0" + (dt.getMonth() + 1)).slice(-2);
        var year = dt.getFullYear();
       let date= month+'-'+day+'-'+year;
        total+=Number(orders[i].amount);
        orderList.push(
        [
            { text: ''+orders[i].id+'' , style:'header4'},	
            { text: ''+orders[i].description+'' , style:'header4'},					
            { text: ''+date+'' , style:'header2'},
            { text: ''+orders[i].amount+'' , style:'header2'},
            
        ]);
    }

    var documentDefinition = {
        pageOrientation: 'page',
       // footer: { text: 'This is computer generated '+title+' signature is not required', alignment:'center'},	
        content: 
         [
        {  table:{
			headerRows: 1,
			widths: [ 70, '*' ],
			body:[[{	
					table:{ 
					widths: [ '*'],
					body: [	
						
						    [{ alignment:'left', image: imageData, fit:[120, 120], border:[false, false, false, false] },],
						]},
					border:[false, false, false, false]
					},		
        {
        table: { 
            headerRows: 1,
            widths: [ 440],
            body: [ 
                  [ {text:'INVOICE', style:'header3', border:[false, false, false, false]},],
                  ]
            },
            border:[false, false, false, false]
            },	
        ]]
    }
},
        
        
        {  table:{
			headerRows: 1,
			widths: [ '*', 100 ],
			body:[[{	
					table:{ 
					widths: [ 40,60],
					body: [
                             [{ text: '', alignment:'left', border:[false, false, false, false] }, ]
                    	]},
					border:[false, false, false, false]
					},		
        {
        table: { 
            headerRows: 1,
            widths: [ 30, '*' ],
            body: [ 
                    [{ text: 'No : ', alignment:'right', border:[false, false, false, false] }, 
                     { text: '00'+users.id+'', alignment:'right', border:[false, false, false, false]} 
                    ],
                    [{ text: 'Date ', alignment:'right', border:[false, false, false, false] }, 
                     { text: date1, alignment:'right', border:[false, false, false, false]} 
                    ]
                  ]
            },
            border:[false, false, false, false]
            },	
        ]]
    }
},
        { text:'\n'},
        { table:{
            headerRows: 1,
            widths: [ '70%', '*' ],		
              body: [
                    [{ text:'Billed To: \n'+users.firstname+' '+users.lastname+'\n'+users.address1+' '+users.address2+' \n '+users.city+' '+users.state+' '+users.zip+'\n', alignment:'left', fontSize:13, border:[false, false, false, false]}, 
                 ]
                    ]
            }
        },
        {text:'\n'},
        { table:{
            headerRows: 1,
            widths: [ 60,'*',90, 80],
              body: orderList
            },
            layout: {
            hLineWidth: function (i, node) {
                return  1;
            },
            vLineWidth: function (i, node) {
                return  1;
            },
            hLineColor: function (i, node) {
                return  '#1160a6';
            },
            vLineColor: function (i, node) {
                return  '#1160a6';
            },
        },
        },
        {text:'\n\n'},	
        {  table:{
			headerRows: 1,
			widths: [ 30,'*'],
			body:[[{	
					table:{ 
					widths: [  70,'*' ],
                    body: [   [{ text: 'SUBTOTAL :',style:'header2', alignment:'right',bold:true, border:[false, false, false, false] }, 
                              { text: '$'+total+'', style:'header2', alignment:'left', border:[false, false, false, false]} 
                              ],
                              [{ text: 'DISCOUNT :',style:'header2', alignment:'right',bold:true, border:[false, false, false, false] }, 
                               { text: '$0.00', style:'header2', alignment:'left', border:[false, false, false, false]} 
                              ],
                              [{ text: 'TAX :',style:'header2', alignment:'right',bold:true, border:[false, false, false, false] }, 
                               { text: '$0.00', style:'header2', alignment:'left', border:[false, false, false, false]} 
                              ],
                              [{ text: 'TOTAL :',style:'header2', alignment:'right',bold:true, border:[false, false, false, false] }, 
                               { text: '$'+total+'', style:'header2', alignment:'left', border:[false, false, false, false]} 
                              ]
                          ]},
					border:[false, false, false, false]
			       },		
                  {
               table: { 
            headerRows: 1,
            widths: [ 400],
            body: [[{ text:'INVOICE TOTAL',fontSize:10, alignment:'right', border:[false, false, false, false] }],
                  [{ text:'$'+total, style:'header5',alignment:'right', border:[false, false, false, false] },],
                  ]
            },
            border:[false, false, false, false]
            },	
        ]]
    }
  },
    ],
        styles:{
            header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'right',color:'#1160a6'},
            header1:{fontSize:13, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
            header2:{fontSize:12,alignment:'right'},
            header4:{fontSize:12,alignment:'left'},
            header3:{fontSize:22, alignment:'right',bold:true,color:'#1160a6'},
            header5:{fontSize:25, alignment:'left',bold:true,color:'#1160a6'},
            tableHeader:{fillColor:'#1160a6', color:'white'},
            label:{fontSize:11},
         }
    };
const {vfs} = vfsFonts.pdfMake;
pdfMake.vfs = vfs; 
if(options == 'open'){  
pdfMake.createPdf(documentDefinition).open(); 
}else if(options == 'download'){
 pdfMake.createPdf(documentDefinition).download(); 
}else{
 pdfMake.createPdf(documentDefinition).print(); 
}
return ;
}

export const PdfRec = {    
openPdf: function(accountid, header){  
    console.log(header);
   
    var dd=getDocumentDefnition(accountid, header, 'open');
  
},

DownloadPdf: function(accountid, header){  
    getDocumentDefnition(accountid, header, 'download');
},

PrintPdf: function(accountid, header){  
    getDocumentDefnition(accountid, header, 'print');
}
}