///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Staff = {
	eventsId : '', 
	staffname : '',
	firstname : '', 
	lastname : '', 
	title : '', 
	designation : '', 
	phone : '', 
	email : '', 
	dates_attending : '', 
	role : '', 
	notes : '', 
	status : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}