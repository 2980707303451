import React, { Component } from 'react'
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import axios from 'axios';
import queryString from 'query-string';
import { NavLink } from 'react-router-dom';
import Exhibitorlogo from '../assets/exhibitor_logo.png';


export class EditEventDashboard extends Component {
    constructor(props){
        super(props);
          this.state = {
            step: 1,
            current_step: 1,
            eventname:'',
            eventid : '',
            eventdate:'',
            eventObj:{},
            logisticsObj:{},
            staffObj:{},
            marketingObj:{},
            vendorObj :{},
            budgetObj:{},
            roiObj:{},
            posteventObj:{},
            
          };  
        
    }
    componentDidMount(){
            let url = this.props.location.search;
            let params = queryString.parse(url); 
            this.setState({eventid:params.id});
            console.log(params.id);
            axios.get(base_url+'events/listbyeventid/'+params.id,  this.props.user.header )
            .then(res =>{
                 console.log(res.data);
                 var temp=res.data[0];
                 var temp1=this.formatDate(temp.start_date);
                 var stp= parseInt(temp.step);
                 this.setState({eventname:temp.eventname, eventdate:temp1 ,step:stp ,current_step:stp});
                 this.setState({eventObj:res.data[0]})
                 if(stp>=2){
                    axios.get(base_url+'logistics/listbyeventid/'+params.id,  this.props.user.header )
                    .then(res =>{
                            if(res.data.length >0){
                                this.setState({logisticsObj:res.data[0]})
                            }
                    }).catch(function (error) { console.log(error); });  

                 }
            }).catch(function (error) { console.log(error); });  
           
    }
    formatDate = (dt) =>{
        const date = new Date(dt);
        const month = date.toLocaleString('default', { month: 'long' });
        return month+' '+ date.getDate()+', '+date.getFullYear();   

    }   
  
    editEvent = (id,cstep) => {
	    this.props.history.push('/app/editevent/?id='+id+'&cstep='+cstep); 
	}
    render() {
        return (
            <div className="container-fluid">
                <br/>
                <div className="d-flex row ">
                    <div className="col-md-3"><NavLink to="/app/dashboard"><span className="smalllogo"><img  src={Exhibitorlogo} alt="Logo" /></span></NavLink></div>
                    <div className="col-sm-7"><div className="pluscolor"><b>{this.state.eventname}</b></div></div>
                    <div className="col-sm-2"><div className="pluscolor"><b>{this.state.eventdate}</b></div></div>
               </div>
                <hr/>
                <div className="d-flex  flex-wrap">
                <div className="p-2 col-md-8">
                <div className="d-flex  flex-wrap">
                    <div className="boxstyle1" onClick={() =>this.editEvent(this.state.eventid, 1)}>
                            <div className="form-row "></div>
                                <br/>
                            <div className="form-row ">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-4" onClick={() =>this.editEvent(this.state.eventid, 1)}><b><span className="textblue">EventInfo</span></b></div>
                                <div className="col-sm-4"></div>
                            </div>
                            <div className="form-row ">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">{this.state.eventObj.eventname}</div>
                            <div className="col-sm-1"></div>
                           </div><br/>
                           <div className="form-row ">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">{this.state.eventObj.start_date}</div>
                            <div className="col-sm-1"></div>
                           </div>
                    </div>
                    <div className="boxstyle1" onClick={() =>this.editEvent(this.state.eventid, 2)}>
                    <div className="form-row "></div>
                                <br/>
                            <div className="form-row ">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-4" onClick={() =>this.editEvent(this.state.eventid, 2)}><b><span className="textblue">Event Logistics</span></b></div>
                                <div className="col-sm-4"></div>
                            </div>
                            <div className="form-row ">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">{this.state.eventObj.portal_url}</div>
                            <div className="col-sm-1"></div>
                           </div>
                        
                    </div>
                    <div className="boxstyle1" onClick={() =>this.editEvent(this.state.eventid, 3)}>
                    <div className="form-row "></div>
                                <br/>
                            <div className="form-row ">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-4" onClick={() =>this.editEvent(this.state.eventid, 3)}><b><span className="textblue">Staff</span></b></div>
                                <div className="col-sm-4"></div>
                            </div>
                            <div className="form-row ">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10"></div>
                            <div className="col-sm-1"></div>
                           </div>
                    </div>
                     <div className="boxstyle1" onClick={() =>this.editEvent(this.state.eventid, 4)}>
                     <div className="form-row "></div>
                                <br/>
                            <div className="form-row ">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-4" onClick={() =>this.editEvent(this.state.eventid, 4)}><b><span className="textblue">Marketing</span></b></div>
                                <div className="col-sm-4"></div>
                            </div>
                            <div className="form-row ">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10"></div>
                            <div className="col-sm-1"></div>
                           </div>
                    </div>
                    <div className="boxstyle1" onClick={() =>this.editEvent(this.state.eventid, 5)}>
                    <div className="form-row "></div>
                                <br/>
                            <div className="form-row ">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-4" onClick={() =>this.editEvent(this.state.eventid, 5)}><b><span className="textblue">Vendors</span></b></div>
                                <div className="col-sm-4"></div>
                            </div>
                            <div className="form-row ">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10"></div>
                            <div className="col-sm-1"></div>
                           </div>
                    </div>
                    <div className="boxstyle1" onClick={() =>this.editEvent(this.state.eventid, 7)}>
                    <div className="form-row "></div>
                                <br/>
                            <div className="form-row ">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-4" onClick={() =>this.editEvent(this.state.eventid, 7)}><b><span className="textblue">ROI/Reporting</span></b></div>
                                <div className="col-sm-4"></div>
                            </div>
                            <div className="form-row ">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10"></div>
                            <div className="col-sm-1"></div>
                           </div>
                    </div>
                    <div className="boxstyle1">
                    <div className="form-row "></div>
                                <br/>
                            <div className="form-row ">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-4" ><b><span className="textblue">Documents</span></b></div>
                                <div className="col-sm-4"></div>
                            </div>
                            <div className="form-row ">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10"></div>
                            <div className="col-sm-1"></div>
                           </div>
                        
                    </div>
                    <div className="boxstyle3">
                    <div className="form-row "></div>
                                <br/>
                            <div className="form-row ">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-4" ><b><span className="textblue">Notes</span></b></div>
                                <div className="col-sm-4"></div>
                            </div>
                            <div className="form-row ">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">{this.state.eventObj.notes}</div>
                            <div className="col-sm-1"></div>
                           </div>
                        
                    </div>
                </div>
                </div>
                <div className="p-2 col-md-4">
                 <div className="d-flex  flex-wrap">  
                 <div className="boxstyle2" onClick={() =>this.editEvent(this.state.eventid, 6)}>
                 <div className="form-row "></div>
                                <br/>
                            <div className="form-row ">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-6" onClick={() =>this.editEvent(this.state.eventid, 6)}><b><span className="textblue">Budget Details</span></b></div>
                                <div className="col-sm-4"></div>
                            </div>
                            <div className="form-row ">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10"></div>
                            <div className="col-sm-1"></div>
                           </div>
                </div>
                </div>
                </div>
            </div>
   
             </div>
        )
    }
    
}


const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(EditEventDashboard );