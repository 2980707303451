/////////////////////////////////////////////////
// Warning : Propeerty of Deemsoft, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
//////////////////////////////////////////////////////

import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { base_url } from '../constants/Constants';
import { logout } from '../actions/LoginAction';
import Exhibitorlogo from '../assets/exhibitor_logo.png';

class Logout extends Component {
  constructor(props){
super(props);
this.state = {

}
} 
backToLogin = () => {
  this.props.history.push('/login');
}
 
componentDidMount(){
console.log(this.props.user.header);
var formObj = {};
axios.post(base_url+'main/logout', formObj, this.props.user.header)
.then(res => {
logout();
});
this.props.history.push('/login');
}
render() {
return ( 
  <div className="container">
    <br/><br/><br/>
     <div className="d-flex justify-content-center">
      <span onClick={() =>this.backToLogin()}><span><img  src={Exhibitorlogo} alt="Logo" /></span></span>
    </div><br/><br/>
    <div className="d-flex justify-content-center">
       <h5><NavLink to="/login">Back to Login</NavLink><br/></h5>
    </div>

    <div className="d-flex justify-content-center">
       <h5>Thank You!!!</h5>
    </div>
   
   

  </div>
 );
}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Logout);
