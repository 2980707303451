import React, { Component } from 'react'
import axios from 'axios';
import { base_url } from '../constants/Constants';
import StaffForm from './StaffForm';
import HotelsForm from './HotelsForm';
import MeetingsForm from './MeetingsForm';
import NotesForm from './NotesForm';

export class Staff extends Component {
    constructor(props){
        super(props);
        this.state={
           eventid : '',
           eventname :'',
           eventdate :'',
           showvirtual : false,

        }
        
    } 
    componentDidMount(){
     
      axios.get(base_url+'events/listbyeventid/'+this.props.eventid, this.props.header )
            .then(res =>{
               var temp=res.data[0];
                if(temp.event_type == 'In Person'){
                this.setState({showvirtual:true});
               } 
               this.setState({formObj:temp});
             }).catch(function (error) { console.log(error); });  
      
      }

     continue = (e) => {
      this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.enddate,this.props.eventid); 	
     }
    back = (e) => {
       this.props.parentObject.eventStepBack();
     }
     render_formButton(){
      if(this.props.eventAction === 'edit'){
          return(
            <div className="d-flex justify-content-end row">
              <div className="p-2">
              <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()}>Back</button>  
              </div>
              <div className="p-2">
              <button type="button" className="btn btn-primary btn-block" onClick={() =>this.continue()}>Next</button>  
              </div>
             </div>
 
          )
      }else{
          return(
             <div className="d-flex justify-content-end row">
              <div className="p-2">
              <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()}>Back</button>  
              </div>
              <div className="p-2">
              <button type="button" className="btn btn-primary btn-block" onClick={() =>this.continue()}>Next</button>  
              </div>
             </div>
 
          )
      }
  }


    render() {
        
        return (
            <div id="idstaff" className="shadow-sm p-3 mb-5 bg-white rounded">
                   
                   <div className="d-flex row">
                     <div className="p-2 col-md-12"><StaffForm parentObject={this}  header={this.props.header} eventid={this.props.eventid}/></div>
                    </div>
                    {this.state.showvirtual?<div>
                    <div className="d-flex row">
                      <div className="p-2 col-md-12"><HotelsForm parentObject={this}  header={this.props.header} eventid={this.props.eventid}/></div>
                    </div>
                    <div className="d-flex row">
                     <div className="p-2 col-md-12"><MeetingsForm parentObject={this}  header={this.props.header} eventid={this.props.eventid}/></div>
                    </div>
                    </div>:null}

                    <div className="d-flex row">
			               <div className="p-2 col-md-12">
                        <NotesForm parentObject={this}  header={this.props.header} eventid={this.props.eventid} stepname='staff'/> 
                      </div> 
			                </div>
                    
                    <div className="d-flex row">
                        <div className="col-md-12"> {this.render_formButton()}</div>
                    </div> 
            </div>

        )
    }
}

export default Staff
