import React, { Component } from 'react'
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import axios from 'axios';
import { Validators } from '../utils/Validators';
import queryString from 'query-string';
import { FaTrash,FaPen } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Exhibitorlogo from '../assets/exhibitor_logo.png';
import { Creditcards } from '../models/Creditcards';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import '../styles/Styles.css';

export class CreditcardsForm extends Component {

  constructor(props){
      super(props);
        this.state = {
          cardsObj:Creditcards,
          monthly:true,
          yearly:false,
          show:false,
          checkcard:false,
          cardvalidations:{},
          creditcardsList : [] 
        };  
        this.onSubmit=this.onSubmit.bind(this);
        this.cardsDetailsChange=this.cardsDetailsChange.bind(this);
        this.validatecards=this.validatecards.bind(this);
        this.handleDeleteRow=this.handleDeleteRow.bind(this);  
  }
  componentDidMount(){
    console.log(this.state.cardsObj);
    let validtemp1={};
    for (let key in Creditcards) { validtemp1[key] = ""; }
    this.setState({cardvalidations:validtemp1});

    let tempForm1={};
    for (let key in Creditcards) { tempForm1[key] = ""; }
    this.setState({ cardsObj:tempForm1});

    axios.post(base_url+'creditcards/cardsdetails/',{accountid: this.props.accountid}, this.props.header )
    .then(res =>{
          console.log(res.data);
          this.setState({ creditcardsList:res.data});
      }).catch(function (error) { console.log(error); });  


  }
  cardsDetailsChange =(val,e) =>{
    let cardsform=this.state.cardsObj;
    switch(val){
      case "cardname": { cardsform.cardname = e.target.value; } break; 
      case "cardnumber": { cardsform.cardnumber = e.target.value; } break; 
      case "cvc": { cardsform.cvc = e.target.value; } break; 
      case "expmonth": { cardsform.expmonth = e.target.value; } break; 
      case "expyear": { cardsform.expyear= e.target.value; } break; 
      case "cardstatus": { cardsform.cardstatus= e.target.value; } break; 

    }
    this.setState({cardsObj:cardsform});
  }
  validatecards=(val) =>{
    let tempval = this.state.cardvalidations;
    switch(val){
    case "cardname": { tempval.cardname = Validators.name(this.state.cardsObj.cardname); } break; 
    case "cardnumber": { tempval.cardnumber = Validators.cardnumber(this.state.cardsObj.cardnumber); } break; 
    case "cvc": { tempval.cvc = Validators.cvc(this.state.cardsObj.cvc); } break; 
    case "cardstatus": { tempval.cardstatus = Validators.select(this.state.cardsObj.cardstatus); } break; 
    case "expmonth": { tempval.expmonth = Validators.month(this.state.cardsObj.expmonth); 
      
      if(tempval.expmonth ==""){
        if(this.state.cardsObj.expyear == ""){
          tempval.expyear="Please fill the Expiration Year.";
          this.setState({cardvalidations:tempval});
        }else{
        var today, someday;
        var exMonth=this.state.cardsObj.expmonth;
        var exYear=this.state.cardsObj.expyear;
        today = new Date();
        someday = new Date();
        someday.setFullYear(exYear, exMonth, 1);

        if (someday < today) {
          tempval.expyear="The expiry date is before today's date. Please select a valid expiry date";
          this.setState({validations:tempval});
        }
      }
      }} break; 
    case "expyear": { tempval.expyear= Validators.year(this.state.cardsObj.expyear);

    if(tempval.expyear =="" ){

      if(this.state.cardsObj.expmonth == ""){
        tempval.expmonth="Please fill the Expiration Month.";
        this.setState({cardvalidations:tempval});
      }else{
      var today, someday;
      var exMonth=this.state.cardsObj.expmonth;
      var exYear=this.state.cardsObj.expyear;
      today = new Date();
      someday = new Date();
      someday.setFullYear(exYear, exMonth, 1);

      if (someday < today) {
        tempval.expyear="The expiry date is before today's date. Please select a valid expiry date";
        this.setState({cardvalidations:tempval});
      }
    }
    }
    } break; 
  }
  this.setState({cardvalidations:tempval});
  }
  
  onClickMonthly=(e)=>{
    this.setState({monthly:true,yearly:false});
  }
  onClickYearly=(e)=>{
      this.setState({monthly:false,yearly:true});
  }
    
    subscriptionType(){
      if(this.state.monthly == true){
        var temp=this.state.cardsObj;
        temp.amount="35.00";
        console.log(temp.amount);
        temp.subscription_type ="Monthly Subscription";
        this.setState({cardsObj:temp});
      }else  if(this.state.yearly == true){
      var temp=this.state.cardsObj;
      temp.amount="250.00";
      console.log(temp.amount);
      temp.subscription_type ="Yearly Subscription";
      this.setState({cardsObj:temp});
    }else{
      var temp=this.state.cardsObj;
      temp.amount="";
      temp.subscription_type ="";
      this.setState({cardsObj:temp});
    }
    }

    setcardType = () =>{
    axios.post(base_url+'creditcards/cardsdetails/',{accountid: this.props.accountid}, this.props.header )
    .then(res =>{
          console.log(res.data);
          var temp=res.data;
          for(let i=0;i<temp.length;i++){
            if(temp[i].cardstatus === "Primary"){
              let temps=temp[i];
              temps.cardstatus = "Secondary";
              axios.post(base_url+'creditcards/save', temps,this.props.header )
              .then(res =>{
                console.log(res.data);
            }).catch(function (error) { console.log(error); });  
            }
          }
      }).catch(function (error) { console.log(error); });  
  return;
  }
    handleModal=()=>{
      this.setState({checkcard:false});

      let temp={cardname:"",cardnumber:"",expyear:"",expmonth:"",cvc:"",cardstatus:""}
      this.setState({ cardsObj:temp});

    let temps='';
    for (let key in this.state.cardvalidations) { temps += this.state.cardvalidations[key];}
      if(temps !== "" ){
      this.setState({ cardvalidations:temp});
    }
    this.setState({show:!this.state.show});
  }	

    handleDeleteRow =(i) =>{
    let temp=this.state.creditcardsList;
    let newcard=temp[i];
    newcard.accountid=this.props.accountid;
    newcard.status='Inctive';
    console.log(newcard);
    axios.post(base_url+'creditcards/save', newcard,this.props.header )
    .then(res =>{
      console.log(res);
      axios.post(base_url+'creditcards/cardsdetails/',{accountid: this.props.accountid}, this.props.header )
      .then(res =>{
      this.setState({ creditcardsList:res.data});
      }).catch(function (error) { console.log(error); }); 
    }).catch(function (error) { 
      console.log(error);        
    });   

  }
  saveNewCard =(row)=>{
    if(row != undefined && row >= 0 && this.state.creditcardsList.length >0){
      let temp={cardname:"",cardnumber:"",expyear:"",expmonth:"",cvc:""}
      var encoded = this.state.creditcardsList[row].card;
      var actual = JSON.parse(atob(encoded));
//console.log(this.state.creditcardsList[row]._id);
      temp.id=this.state.creditcardsList[row].id;
      temp._id=this.state.creditcardsList[row]._id;
      temp.cardname=actual.cardname;
      temp.cardnumber=actual.cardnumber;
      temp.expyear=actual.expyear;
      temp.expmonth=actual.expmonth;
      temp.cvc=actual.cvc;
      temp.cardstatus=this.state.creditcardsList[row].cardstatus;
      this.setState({ cardsObj:temp});
      if(temp.cardstatus === "Primary"){
        this.setState({checkcard:true});
      }else{
        this.setState({checkcard:false});
      }
  }
  this.setState({show:!this.state.show});
}

render_CardsList(){

  if( this.state.creditcardsList != undefined && this.state.creditcardsList.length > 0){
  return this.state.creditcardsList.map((item, index) =>{
    var encoded=item.card;
    var actual = JSON.parse(atob(encoded));
// console.log(actual.cardnumber);

      var cc=actual.cardnumber;
    cc=cc.replace(/\W/gi, '').replace(/^(.{4})(.{4})(.{4})(.*)$/, "$1 $2 $3 $4");
    cc = cc.replace(/\d{4}(?= \d{4})/g, "XXXX"); 

    return (
      <div key={index} id={index}>
        <li  className="invisiblefollow">
          <input type="text" id="index" value={cc} name="cardnumber"/>
          <span><FaPen size="15px" color="blue" onClick={()=>{this.saveNewCard(index)}}/>&nbsp;&nbsp;
        <FaTrash size="15px" color="red" onClick={()=>{this.handleDeleteRow(index)}}/>&nbsp;&nbsp;</span>  
        </li>   <br/>      
      </div>
    ) 
      }, this);
  }
}



onSubmit(){
//  console.log(this.state.cardsObj.cardstatus);
      this.subscriptionType();
      let temp='';
      for (let key in Creditcards) { this.validatecards(key);}  
      for (let key in this.state.cardvalidations) { temp += this.state.cardvalidations[key];} 
      if(temp == ""){
      if(this.state.cardsObj.cardstatus === "Primary"){
        this.setcardType();
      }  
      var newcardsobj=this.state.cardsObj;
      var encoded = btoa(JSON.stringify(newcardsobj));
        newcardsobj.card = encoded;
        newcardsobj.status="Active";
        newcardsobj.accountid=this.props.accountid;
        newcardsobj.cardname="";
        newcardsobj.cardnumber="";
        newcardsobj.expyear="";
        newcardsobj.expmonth="";
        newcardsobj.cvc="";
        console.log(this.state.cardsObj.cardstatus);
    axios.post(base_url+'creditcards/save', newcardsobj,this.props.header )
    .then(res =>{
      axios.post(base_url+'creditcards/cardsdetails/',{accountid: this.props.accountid}, this.props.header )
      .then(res =>{
      console.log(res.data);
      this.setState({ creditcardsList:res.data});
      this.handleModal();
    }).catch(function (error) { console.log(error); }); 
    }).catch(function (error) { 
      console.log(error);        
    });   
  }
}
  render() {
      return (
          <div className="container-fluid">
          <br/>
          <div className="d-flex row">
          <div className="p-2">
              <label className="label-custom pluscolor">Credit Card Details</label>
              <ol>
              {this.render_CardsList()}
              </ol>
              <div className="d-flex justify-content-end row">
                <div className="p-2">
                <button type="button" className="btn btn-primary btn-block btn-sm" onClick={()=>{this.handleModal()}}>+Add</button> 
                </div>
              </div>
          </div>
          </div>

          <Modal size="lg"  show={this.state.show} onHide={()=>{this.handleModal()}} >
          <Modal.Header closeButton>
            <Modal.Title>Credit Cards</Modal.Title>
          </Modal.Header>
            <ModalBody>
              <div className="brdcolor">
              < div className="d-flex row">
                <div className="p-2 flex-fill"> 
                <label className="label-custom">Name on The Card</label>                      
                <input type="text"  id="cardname" name="cardname" value={this.state.cardsObj.cardname} placeholder="Name on The Card" required onChange={(e)=>{this.cardsDetailsChange("cardname",e)}} onBlur={()=>{ this.validatecards("cardname")}} className="form-control" style={this.state.cardvalidations.cardname==""?{}:{"borderColor":"red"}} required   />
                    <span className="errormsg">{this.state.cardvalidations.cardname}</span>   
                  </div>
                <div className="p-2 flex-fill "> 
                <label className="label-custom">Card Number</label>                       
                <input type="number"  id="cardnumber" name="cardnumber"  min="1" max="16" value={this.state.cardsObj.cardnumber} placeholder="Card Number"  onChange={(e)=>{this.cardsDetailsChange("cardnumber",e)}} onBlur={()=>{ this.validatecards("cardnumber")}} className="form-control" style={this.state.cardvalidations.cardnumber==""?{}:{"borderColor":"red"}} required   />
                  <span className="errormsg">{this.state.cardvalidations.cardnumber}</span>    
                </div>
                <div className="p-2 flex-fill "> 
                <label className="label-custom">Set Card</label>                       
                <select  id="cardstatus" name="cardstatus"  value={this.state.cardsObj.cardstatus}  onChange={(e)=>{this.cardsDetailsChange("cardstatus",e)}} onBlur={()=>{ this.validatecards("cardstatus")}} className="form-control" style={this.state.cardvalidations.cardstatus==""?{}:{"borderColor":"red"}}    >
                  <option value="">---select---</option>
                  <option>Primary</option>
                  {this.state.checkcard ? null : <option>Secondary</option>}
                </select>
                  <span className="errormsg">{this.state.cardvalidations.cardstatus}</span>    
                </div>
              </div>
              <div className="d-flex row">
                <div className="p-2 flex-fill"> 
                <label className="label-custom">Expiration Month</label>                      
                <input type="number"  id="expmonth" name="expmonth" value={this.state.cardsObj.expmonth}  min="1" max="2" placeholder="Expiration Month" onChange={(e)=>{this.cardsDetailsChange("expmonth",e)}} onBlur={()=>{ this.validatecards("expmonth")}} className="form-control" style={this.state.cardvalidations.expmonth==""?{}:{"borderColor":"red"}} required   />
                          <span className="errormsg">{this.state.cardvalidations.expmonth}</span>     
                </div>
                <div className="p-2 flex-fill">
                  <label className="label-custom">Expiration Year</label>                       
                <input type="number"  id="expyear" name="expyear" value={this.state.cardsObj.expyear}  min="1" max="4"placeholder="Expiration Year" onChange={(e)=>{this.cardsDetailsChange("expyear",e)}}  onBlur={()=>{ this.validatecards("expyear")}} className="form-control" style={this.state.cardvalidations.expyear==""?{}:{"borderColor":"red"}} required   />
                          <span className="errormsg">{this.state.cardvalidations.expyear}</span> 
                </div>
                <div className="p-2">    
                <label className="label-custom">CVC</label>                   
                <input type="number"  id="cvc" name="cvc" value={this.state.cardsObj.cvc}  min="1" max="3" placeholder="CVC" required onChange={(e)=>{this.cardsDetailsChange("cvc",e)}} onBlur={()=>{ this.validatecards("cvc")}} className="form-control" style={this.state.cardvalidations.cvc==""?{}:{"borderColor":"red"}} required   />
                        <span className="errormsg">{this.state.cardvalidations.cvc}</span> 
                </div>
                </div> 
                </div>
          </ModalBody>
          <ModalFooter>
          <Button variant="warning" onClick={()=>{this.handleModal()}}>Close</Button>
          <Button variant="primary" onClick={() =>this.onSubmit()}>Save</Button>
        </ModalFooter>
      </Modal>    
    </div>      
      )
  }
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(CreditcardsForm);
