////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {Button,Modal} from 'react-bootstrap';
import { Validators } from '../utils/Validators';
import { TimeOnly } from '../utils/DateTime';
import { FaPen,FaTrash } from 'react-icons/fa';
import { base_url } from '../constants/Constants';
import { Permissions } from '../models/Permissions';
import {Shippings } from '../models/Shippings';
import '../styles/Styles.css';


class ShippingsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
			showshipp:false,
			edit : true,
			showEdit : false,
			formObj : Shippings,
			validations:{},
			shippingback :false,
			donating:false,
			shippingList : [],
			permissions : Permissions
		}
		this.changeShippingProduct = this.changeShippingProduct.bind(this);
		this.changeShippingAddress = this.changeShippingAddress.bind(this);
		this.changeShippngBack = this.changeShippngBack.bind(this);
		this.changeDonating = this.changeDonating.bind(this);
		this.changeShippingDate = this.changeShippingDate.bind(this);
		this.handleShippModal=this.handleShippModal.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.validate = this.validate.bind(this);
		this.changeShipping = this.changeShipping.bind(this);
		this.handleDeleteRow=this.handleDeleteRow.bind(this);
		this.changeNewShipp = this.changeNewShipp.bind(this);
		
	}

	componentDidMount(){
		let validtemp={};
		for (let key in Shippings) { validtemp[key] = ""; }
		this.setState({validations:validtemp});

		axios.get(base_url+'shippings/listbyeventid/'+this.props.eventid, this.props.user.header )
		.then(res =>{
			if(res.data.length > 0){
			 this.setState({ shippingList:res.data});
			}else{
				var temp = {};
				temp={id:0,events_id:this.props.eventid,status:'Active',product:"<Enter Shipping Product>", from_address1:"<Enter Shipping address>",shipp_time: "12-12-2020",shippingback:"NA",donatable_item:"NA",calanderlink:"<Enter Calendar link>"}; 
				axios.post(base_url+'shippings/save', temp, this.props.user.header ).then(res =>{
					axios.get(base_url+'shippings/listbyeventid/'+this.props.eventid,   this.props.user.header )
				   .then(res =>{
					this.setState({ shippingList:res.data });
				}).catch(function (error) { console.log(error); });

				}).catch(function (error) { console.log(error); });
				
			}
			}).catch(function (error) { console.log(error); });
	}
	validate = (val) =>{
		let tempvalid = this.state.validations;
		switch(val){
			case "product": { tempvalid.product = Validators.name(this.state.formObj.product); } break;
			case "from_address1": { tempvalid.from_address1 = Validators.name(this.state.formObj.from_address1); } break;
			case "shipp_time": { tempvalid.shipp_time = Validators.time(this.state.formObj.shipp_time); } break;
			case "calanderlink": { tempvalid.calanderlink = Validators.website(this.state.formObj.calanderlink); } break;
		}
		this.setState({validations:tempvalid});
	}

	changeShipping = (val,e) =>{
		let tempform = this.state.formObj;
		switch(val){
			case "product": { var string = e.target.value; tempform.product = string.charAt(0).toUpperCase() + string.slice(1);  } break;
			case "from_address1": { var string = e.target.value; tempform.from_address1 = string.charAt(0).toUpperCase() + string.slice(1);  } break;
			case "shipp_time": { tempform.shipp_time = e; this.validate("shipp_time"); } break;
			case "calanderlink": { tempform.calanderlink = e.target.value; } break;
		}
		this.setState({formObj:tempform});
	}
	handleShippModal=()=>{
		let temp={product:"", from_address1:"",shipp_time: "",shippingback:"",donatable_item:"",calanderlink:""};
		this.setState({ formObj:temp });
		let temps='';
		for (let key in this.state.validations) { temps += this.state.validations[key];}
	    if(temps !== "" ){
			this.setState({ validations:temp });
		}
        this.setState({showshipp:!this.state.showshipp});
	}
	changeNewShipp = (row) => {
		if(row != undefined && row >=0 && this.state.shippingList.length >0){
			let temp={id:"",_id:"",product:"", from_address1:"",shipp_time: "",shippingback:"",donatable_item:"",calanderlink:""};
			temp.id=this.state.shippingList[row].id;
			temp._id=this.state.shippingList[row]._id;
			
			if(this.state.shippingList[row].product === '<Enter Shipping Product>'){
				temp.product="";
			}else{
				temp.product=this.state.shippingList[row].product;
			}
			if(this.state.shippingList[row].from_address1 === '<Enter Shipping address>'){
				temp.from_address1="";
			}else{
				temp.from_address1=this.state.shippingList[row].from_address1;
			}
			if(this.state.shippingList[row].calanderlink === '<Enter Calendar link>'){
				temp.calanderlink="";
			}else{
				temp.calanderlink=this.state.shippingList[row].calanderlink;
			}
			if(this.state.shippingList[row].shippingback === 'NA'){
				temp.shippingback="";
			}else{
				temp.shippingback=this.state.shippingList[row].shippingback;
			}
			if(this.state.shippingList[row].donatable_item === 'NA'){
				temp.donatable_item="";
			}else{
				temp.donatable_item=this.state.shippingList[row].donatable_item;
			}
			if(this.state.shippingList[row].shipp_time === '12-12-2020'){
				temp.shipp_time="";
			}else{
				temp.shipp_time=this.state.shippingList[row].shipp_time;
			}

			this.setState({formObj:temp});
			}
        this.setState({showshipp:!this.state.showshipp});
	}
	
	changeShippingProduct=(e)=>{
		let newform=this.state.formObj;
		newform.product=e.target.value
		this.setState({ formObj:newform });
		//console.log(this.state.formObj.product);
	  
	}
	changeShippingAddress=(e)=>{
	   let newform=this.state.formObj;
	   newform.from_address1=e.target.value
	   this.setState({ formObj:newform });
	   //console.log(this.state.formObj.from_address1);
	}
	resetAll(){
		this.setState({
			shippingback: false,
			donating: false,
		 });
	}
	changeDonating=(e)=>{
		{this.resetAll()}
		this.setState({	shippingback:false, donating: true});
	}
	changeShippngBack=(e)=>{
		{this.resetAll()}
		this.setState({shippingback:true,donating: false});
	}
	changeShippingDate=(e)=>{
		let newform=this.state.formObj;
		newform.shipp_time=e.target.value
		this.setState({ formObj:newform });
	}
	changeCalenderLink=(e)=>{
		let newform=this.state.formObj;
		newform.calanderlink=e.target.value
		this.setState({ formObj:newform });
	}
	handleDeleteRow = (i) => {
		{this.shippcheckType()}
		let newform=this.state.shippingList[i];
		newform.status='Inactive';
		newform.events_id=this.props.eventid;
			axios.post(base_url+'shippings/save', newform , this.props.user.header )
			.then(res =>{
				console.log(res.data.data);
				this.setState({  formObjdata:res.data.data});
				axios.get(base_url+'shippings/listbyeventid/'+this.props.eventid,   this.props.user.header )
				.then(res =>{
					this.setState({ shippingList:res.data });
				}).catch(function (error) { console.log(error); });
			}).catch(function (error) { console.log(error); });
	}
	render_ShippingList(){
		if( this.state.shippingList != undefined && this.state.shippingList.length > 0){
		return this.state.shippingList.map((item, index) =>{
			let dt=new Date(item.shipp_time);
			var h = dt.getHours();
			var m =("0" + dt.getMinutes()).slice(-2);
			var _time = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
		  return (
				<tr key={index} id={index}>
				<td>{item.product}</td>
				<td>{item.from_address1}</td>
				<td>{_time }</td>
				<td>{item.shippingback}</td>
				<td>{item.donatable_item}</td> 
				<td>{item.calanderlink}
				<span><FaPen size="15px" color="blue" onClick={()=>{this.changeNewShipp(index)}}/>&nbsp;&nbsp;
				   <FaTrash size="15px" color="red" onClick={() => {this.handleDeleteRow(index)}} />&nbsp;&nbsp;</span></td>            
		   </tr>
		  ) 
		   }, this);
	  }
	}
	shippcheckType(){
        if(this.state.shippingback == true){
            var temp=this.state.formObj;
			temp.shippingback='Yes';
			temp.donatable_item='No';
            this.setState({formObj:temp});
        }else if(this.state.donating == true){
			var temp=this.state.formObj;
			temp.donatable_item='Yes';
			temp.shippingback='No';
			this.setState({formObj:temp});
        }else{
			var temp=this.state.formObj;
			temp.shippingback='No';
			temp.donatable_item='No';
			this.setState({formObj:temp}); 
        }
     }
  

	onSubmit(){
		let temp='';
		for (let key in Shippings) { this.validate(key);}
		{this.shippcheckType()}
        for (let key in this.state.validations) { temp += this.state.validations[key];}
	    if(temp == "" ){
		let newform=this.state.formObj;
		newform.status='Active';
		newform.events_id=this.props.eventid;
			axios.post(base_url+'shippings/save', newform , this.props.user.header )
			.then(res =>{
				console.log(res.data.data);
				this.setState({  formObjdata:res.data.data});
				axios.get(base_url+'shippings/listbyeventid/'+this.props.eventid,   this.props.user.header )
				.then(res =>{
					this.setState({ shippingList:res.data });
					{this.handleShippModal()}
				}).catch(function (error) { console.log(error); });
			}).catch(function (error) { console.log(error); });
		}
	}

	render() {
      return (
			
			<div id="idshippings">
						<div className="d-flex row">
                        <div className="form-group col-md-12">
                        <label className="label-custom">Add Your Shipping Products</label>
                            <table className="table table-bordered table-sm table-responsive-stack">
                                <thead className="thead">
                                    <tr>
                                        <td>Shipping Items</td>
                                        <td>Address/Link</td>
                                        <td>Time</td>
                                        <td>Shipping Back</td>
                                        <td>Donating</td>
                                        <td>Calender Link</td>
                                    </tr>
                                </thead>
                                <tbody>
									{this.render_ShippingList()}
		                        </tbody>
                            </table>
						<div className="p-2 col-md-12">
                        <div className="d-flex justify-content-end row">
                        <div className="p-2">
                            <button type="button" className="btn btn-primary btn-block btn-sm" onClick={()=>{this.handleShippModal()}} >+Add</button>
                        </div>
                        </div>
						</div>
						</div>
						</div>
						<Modal size="lg" show={this.state.showshipp} onHide={()=>{this.handleShippModal()}} >
						<Modal.Header closeButton>
						<Modal.Title>Shipping Info</Modal.Title>
						</Modal.Header>
						<Modal.Body>
						<div className="brdcolor">
						<div className="form-row">
							<div className="form-group col-md-6">
							<label htmlFor="idproduct" className="label-custom" >Shipping Items</label> 
							<input value={this.state.formObj.product} placeholder="Enter Shipping Items" name="product" id="idproduct" type="text" onChange={(e)=>{this.changeShipping("product",e)}} onBlur={()=>{ this.validate("product")}} className="form-control form-control-sm" style={this.state.validations.product==""?{}:{"borderColor":"red"}} required /> 	
                             <span className="errormsg">{this.state.validations.product}</span>

							</div>
							<div className="form-group col-md-6">
							<label htmlFor="idfrom_address1" className="label-custom">Address</label>
							<input type="text" id="idfrom_address1" placeholder="Enter Shipping address" name="from_address1" value={this.state.formObj.from_address1} onChange={(e)=>{this.changeShipping("from_address1",e)}} onBlur={()=>{ this.validate("from_address1")}} className="form-control form-control-sm" style={this.state.validations.from_address1==""?{}:{"borderColor":"red"}} required /> 	
                             <span className="errormsg">{this.state.validations.from_address1}</span>
							</div>
                        </div>
						
						<div className="form-row">	
						    <div className="form-group col-md-1"></div>				
							<div className="form-group form-check col-md-5">
							<input className="form-check-input"  type="checkbox" name="shippingback" id="id_shippingback" value="shippingback" checked={this.state.shippingback} onChange={(e)=>{this.changeShippngBack(e)}} />
							<label  htmlFor="id_shippingback" className="label-custom">Shipping Back</label>
							</div>
							<div className="form-group form-check col-md-6">
							<input  className="form-check-input" type="checkbox" name="donatable_item" id="iddonatable_item" value="donating" checked={this.state.donating} onChange={(e)=>{this.changeDonating(e)}}/>
							<label  htmlFor="iddonatable_item" className="label-custom">Donating?</label>
							</div>
						</div>
						
						<div className="form-row">
							<div className="form-group col-md-9">
							<label  htmlFor="idcalanderlink" className="label-custom">Calender Link</label>
							<input type="text" id="idcalanderlink" placeholder="Enter Calender Link" name="calanderlink" value={this.state.formObj.calanderlink}  onChange={(e)=>{this.changeShipping("calanderlink",e)}} onBlur={()=>{ this.validate("calanderlink")}} className="form-control form-control-sm" style={this.state.validations.calanderlink==""?{}:{"borderColor":"red"}} required /> 	
                             <span className="errormsg">{this.state.validations.calanderlink}</span>
							 </div>
							 <div className="form-group col-md-3">
							<label htmlFor="idShipp_time" className="label-custom">Time</label><br/>
							<TimeOnly name="shipp_time" 
						        value={this.state.formObj.shipp_time}  
					         	onChange={this.changeShipping}
					        	class_name={ this.state.validations.shipp_time==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
					            onBlur={this.validate}
				            	/><br/><span className="errormsg">{this.state.validations.shipp_time}</span>
							</div>
						</div>
					    </div>
						</Modal.Body>
						<Modal.Footer>
						<Button variant="warning" onClick={()=>{this.handleShippModal()}}>
						Close
						</Button>
						<Button variant="primary"  onClick={()=>this.onSubmit()}>
						Save
						</Button>
						</Modal.Footer>
						</Modal>
		</div>
		)}
		
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(ShippingsForm);
