////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { FaTrash,FaUpload,FaFile, FaFileDownload, FaFolder } from 'react-icons/fa';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import fileDownload from 'js-file-download';
import { Permissions } from '../models/Permissions';
import {Returnoninvestments } from '../models/Returnoninvestments';
import FileUpload from '../utils/FileUpload';
import '../styles/Styles.css';
import $ from 'jquery';

class ReturnoninvestmentsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
			edit : true,
			showEdit : false,
      formObj : Returnoninvestments,
      validations:{},
      eventid : '',
      eventname :'',
      eventdate :'',
      cfile:'',
      achived_yes:true,
      achived_no:false,
      bObjective:[],
     
     
     
		}
	
    this.onSubmit = this.onSubmit.bind(this);
    this.formattedInput = this.formattedInput.bind(this);	
    this.updateFilename = this.updateFilename.bind(this);
    this.validate = this.validate.bind(this);
    this.changeROI = this.changeROI.bind(this);
    this.handleNotes = this.handleNotes.bind(this);
    this.objectiveYes = this.objectiveYes.bind(this);
    this.objectiveNo = this.objectiveNo.bind(this);
    this.currencyFormat=this.currencyFormat.bind(this);

	}

	componentDidMount(){
    let validtemp={};
		for (let key in Returnoninvestments) { validtemp[key] = ""; }
    this.setState({validations:validtemp});
    
    if(this.props.eventid !== '' && this.props.eventid !== undefined){   
   axios.get(base_url+'events/listbyeventid/'+this.props.eventid, this.props.header )
   .then(res =>{
      var temp=res.data[0].bussinesobjective;
      this.setState({bObjective:temp});
      console.log(this.state.bObjective);
    }).catch(function (error) { console.log(error); });  

    axios.get(base_url+'budgets/listbyeventid/'+this.props.eventid,  this.props.user.header )
                .then(res =>{
				 if(res.data.length > 0 ){
          var temp=res.data[0].total_cost;
					this.setState({ total_cost:temp});
         }
        }).catch(function (error) { console.log(error); });  
  
    axios.get(base_url+'returnoninvestments/listbyeventid/'+this.props.eventid,  this.props.user.header )
    .then(res =>{
      if(res.data.length > 0 ){
          var temp=res.data[0];
        if(temp.business_obj_achieved == "YES"){
					console.log(temp.business_obj_achieved);
					this.setState({achived_yes:true,achived_no:false});
				   }else if(temp.business_obj_achieved == "NO"){
					this.setState({achived_yes:false,achived_no:true});
				   }else{
					this.setState({achived_yes:false,achived_no:false});
					}
        this.setState({formObj:temp});
      }else{
        var temp={};
        for (let key in Returnoninvestments) { temp[key] = ""; }
        temp.cost_per_leads="0.00";
        this.setState({formObj:temp});

      }
      }).catch(function (error) { console.log(error); }); 
  }
  }

  validate = (val) =>{
		let tempvalid = this.state.validations;
		switch(val){
		//	case "business_obj_achieved": { tempvalid.business_obj_achieved = Validators.number(this.state.formObj.business_obj_achieved); } break;
			//case "total_number_attendees": { tempvalid.total_number_attendees = Validators.number(this.state.formObj.total_number_attendees); } break;
			//case "visiter_booth": { tempvalid.visiter_booth = Validators.number(this.state.formObj.visiter_booth); } break;
			//case "certifications": { tempvalid.certifications = Validators.name(this.state.formObj.certifications); } break;
      case "total_leads": { tempvalid.total_leads = Validators.number(this.state.formObj.total_leads); } break; 
      //case "total_media_impressions": { tempvalid.total_media_impressions = Validators.number(this.state.formObj.total_media_impressions); } break;
     // case "cost_per_leads": { tempvalid.cost_per_leads = Validators.number(this.state.formObj.cost_per_leads); } break; 
      case "total_sales": { tempvalid.total_sales = Validators.number(this.state.formObj.total_sales); } break;
      //case "exhibiting_cost": { tempvalid.exhibiting_cost = Validators.number(this.state.formObj.exhibiting_cost); } break; 
      //case "media_contacts_link": { tempvalid.media_contacts_link = Validators.number(this.state.formObj.media_contacts_link); } break;
		//	case "social_media_mentions": { tempvalid.social_media_mentions = Validators.number(this.state.formObj.social_media_mentions); } break; 
      //case "other_exhibitors_list": { tempvalid.other_exhibitors_list= Validators.number(this.state.formObj.other_exhibitors_list); } break; 
    //	case "slide_deck": { tempvalid.slide_deck = Validators.name(this.state.formObj.slide_deck); } break;  
    }
    //console.log(tempvalid);
    this.setState({validations:tempvalid});
  }

	updateFilename( filename,index ){
    if(index == 1){
      let temp=this.state.formObj;
      temp.totalleadfile=filename;
      this.setState({formObj:temp});
    }
    if(index == 2){
      let temp=this.state.formObj;
      temp.mediafile=filename;
      this.setState({formObj:temp});
    }
    if(index == 3){
      let temp=this.state.formObj;
      temp.certificationsfile=filename;
      this.setState({formObj:temp});
    }
    if(index == 4){
      let temp=this.state.formObj;
      temp.slidedeckfile=filename;
      this.setState({formObj:temp});
    }
    if(index == 5){
      let temp=this.state.formObj;
      temp.totalexhibitorfile=filename;
      this.setState({formObj:temp});
    }
		//update related formObj field as needed for fileupload 
  }
  formattedInput=()=>{
    let temp=this.state.validations;
    var bList = this.state.formObj;
    let num=bList.total_sales;
    if(bList.total_sales !== "" && bList.total_sales !== "0" && bList.total_sales !== "0.00" ){
		let nums = num.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    bList.total_sales =this.currencyFormat(Number(nums));
    temp.total_sales="";
    }else{
      temp.total_sales="Please Enter Number";
    }
    this.setState({ formObj:bList }); 
    this.setState({validations:temp});
    this.calculate_CostperLead();

   
  }
 
	continue=e=>{
    this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.enddate,this.props.eventid); 	 		
  }
   back=e=>{
    this.props.parentObject.eventStepBack();
  }
  changeROI = (val,e) =>{
		let temproi = this.state.formObj;
		switch(val){
			case "business_obj_achieved": { temproi.business_obj_achieved = e.target.value; } break;
			case "total_number_attendees": { temproi.total_number_attendees = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');} break;
			case "visiter_booth": { temproi.visiter_booth = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); } break;
			case "certifications": { var string = e.target.value; temproi.certifications =  string.charAt(0).toUpperCase() + string.slice(1); } break;
      case "total_leads": { temproi.total_leads = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); this.calculate_CostperLead(); } break; 
      case "total_media_impressions": { temproi.total_media_impressions =  e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); } break;
      case "cost_per_leads": { temproi.cost_per_leads =  e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); } break; 
      case "total_sales": { temproi.total_sales =  e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); this.validate("total_sale"); } break;
      case "exhibiting_cost": { temproi.exhibiting_cost = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); } break; 
      case "media_contacts_link": { temproi.media_contacts_link = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); } break;
			case "social_media_mentions": { temproi.social_media_mentions = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); } break; 
      case "other_exhibitors_list": { temproi.other_exhibitors_list= e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');} break; 
      case "slide_deck": { var string = e.target.value;  temproi.slide_deck =  string.charAt(0).toUpperCase() + string.slice(1);} break;  
      case "notes": { temproi.notes = e.target.value } break;  
    }
    console.log(temproi);
    this.setState({formObj:temproi});
  }
  
  handleNotes = (e) => {
    let newformObj=this.state.formObj;
    newformObj.notes=e.target.value
    this.setState({ formObj:newformObj });
  }

  objectiveYes = ()=>{
   this.setState({achived_yes:true,achived_no:false});
  }
  objectiveNo = ()=>{
    this.setState({achived_no:true,achived_yes:false});
  }

  businessObjectiveAchived(){
		if(this.state.achived_yes == true){
			var temp=this.state.formObj;
			temp.business_obj_achieved='YES';
			this.setState({formObj:temp});
		}else{
		 var temp=this.state.formObj;
		 temp.business_obj_achieved='NO';
		 this.setState({formObj:temp});
		}
	 }

  render_formButton(){
    if(this.props.eventAction === 'edit'){
        return(
          <div className="d-flex  justify-content-end row">
          <div className="p-2">
               <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()} >Back</button>
          </div>
         <div className="p-2">
           <button type="button" className="btn btn-primary btn-block" onClick={() =>this.onSubmit()}>Next</button>
         </div>
        </div>

        )
    }else{
        return(
           <div className="d-flex justify-content-end row">
            <div className="p-2">
              <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()} >Back</button>
            </div>
            <div className="p-2">
              <button type="button" className="btn btn-primary btn-block" onClick={() =>this.onSubmit()}>Next</button>
            </div>
           </div>

        )
    }
}
currencyFormat=(num)=>{
  if(num !== undefined ){
  return  num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }else{
   return '';
  }
 }

calculate_CostperLead(){
      var roiList=this.state.formObj;
      var sales= 0;
          sales=roiList.total_sales;
      var lead= 1;
          lead=roiList.total_leads;
          console.log(lead);
        var cost=0;
      if(sales !== undefined && sales !== 0 && sales !== '' && lead !== '' && lead !== undefined && lead !== 1 ){
      
        sales=parseFloat(sales.replace(/,/g, ''));
        console.log(sales);
        cost= parseFloat(sales)/parseFloat(lead);
        console.log(cost);
         //cost= Number(sales)/Number(lead);
     
    }
      let costs=this.currencyFormat(cost);
      roiList.cost_per_leads=costs;
      this.setState({formObj:roiList}); 
 
}
render_objective(){
  const bobj=this.state.bObjective;
  const count = bobj.filter(function(item){
    if (item.status === true) {
      return true;
    } else {
      return false;
    }
  });
  if(count != undefined &&count.length > 0){
		return count.map((item, index) =>{
     
      if(item.status === true){
      let bname=item.name;
      if(count.length-1 !== index){
       bname=item.name+', '
      }else{
        bname=item.name+''
      }
		  return (
				<span key={index} id={index}>
				{bname}
        </span>
      ) 
      }
     }, this);
	  }
}
downloadFile=(url,filename)=>{
  axios.get(base_url+url,  {responseType: 'blob', headers:this.props.header.headers}).then(res => {
  fileDownload(res.data, filename);
  });
   }

   checkOnlyValid(){
    let tempvalid = this.state.validations;
	    tempvalid.total_number_attendees = "";
			tempvalid.visiter_booth ="";
	    tempvalid.certifications = "";
      tempvalid.total_media_impressions = "";
      tempvalid.media_contacts_link = "";
		  tempvalid.social_media_mentions ="";
      tempvalid.other_exhibitors_list= "";
      tempvalid.slide_deck ="";
    this.setState({validations:tempvalid});

   }


	onSubmit(){
   this.businessObjectiveAchived();
   let temp='';
        for (let key in Returnoninvestments) { this.validate(key);}
        this.checkOnlyValid();
        for (let key in this.state.validations) { temp += this.state.validations[key];}
	        if(temp == "" ){
          let newform=this.state.formObj;
          newform.status='Active';
          newform.events_id=this.props.eventid;
          this.setState({formObj:newform});
            axios.post(base_url+'returnoninvestments/save',newform, this.props.user.header )
            .then(res =>{
              console.log(res.data.data);
              axios.get(base_url+'returnoninvestments/listbyeventid/'+this.props.eventid,  this.props.user.header )
              .then(res =>{
                console.log(res.data);
              this.setState({rOiformList:res.data});
              this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.enddate,this.props.eventid); 
            }).catch(function (error) { console.log(error); });
            }).catch(function (error) { console.log(error); });
       }
	}

	render() {
    let temps='file/download/'+this.state.formObj.certificationsfile+'?id='+this.props.user.accountid;
    let temps1='file/download/'+this.state.formObj.slidedeckfile+'?id='+this.props.user.accountid;
    let temps2='file/download/'+this.state.formObj.totalexhibitorfile+'?id='+this.props.user.accountid;
    let temps3='file/download/'+this.state.formObj.totalleadfile+'?id='+this.props.user.accountid;
    let temps4='file/download/'+this.state.formObj.mediafile+'?id='+this.props.user.accountid;
    
     
 return (
			<div id="idreturnoninvestments" className="shadow-sm p-3 mb-5 bg-white rounded">
         <div className="brdcolor" >
						<div className="d-flex row">
                 <div className="p-2 roiboxing">
                   <label htmlFor="idbusiness_objective" className="textblue"><b>Business Objective?</b></label></div>
                   <div className="p-2">{this.render_objective()}</div>  
             </div>
              <div className="borderstyle">
                  <div className="d-flex row">
                    <div className= "p-2 roiboxing ">
                        <label htmlFor="idexhibiting_cost" className="label-custom">Exhibition Total Cost</label>
                     </div>
                     <div className="p-2">
                     <div className="dollar dollarroiinput">{this.state.total_cost}</div>
                      </div>
                 </div><br/>
                 
                 <div className="d-flex row">
                    <div className="p-2 roiboxing">
                        <label htmlFor="idtotal_sales" className="label-custom">Total Event Sales</label>
                     </div>
                     <div className= "p-2 ">
                       <div className="dollar">
                        <input type="text" className="form-control form-control-sm" id="idtotal_sales" name="total_sales" value={this.state.formObj.total_sales} onChange={(e)=>{this.changeROI("total_sales",e)}} onBlur={()=>{ this.formattedInput()}}  className="form-control form-control-sm" style={this.state.validations.total_sales==""?{}:{"borderColor":"red"}} required /> 	
                      </div>
                    </div>
                    <span className="errormsg">{this.state.validations.total_sales}</span>
                </div> 

                <div className="d-flex row">  
                   <div className="p-2 roiboxing">
                      <label htmlFor="idtotal_leads" className="label-custom">Total Leads</label>
                   </div>
                    <div className= "p-2 roilinebreak">
                      <input type="text" id="idtotal_leads"  name="total_leads" value={this.state.formObj.total_leads} onChange={(e)=>{this.changeROI("total_leads",e)}}  onBlur={()=>{  this.validate("total_leads")}} className="form-control form-control-sm" style={this.state.validations.total_leads==""?{}:{"borderColor":"red"}} required /> 	
                    </div>
                    <span className="errormsg">{this.state.validations.total_leads}</span>
                    <div className= "p-2">
                      <FileUpload parentObject={this}  header={this.props.header} eventid={this.props.eventid} folder='docs' subfolder={this.props.user.accountid} indexid="1" uploadtext="Upload Lead List"/>
                    </div>
                    <div  className= "p-2 "><span className="cursorcolor" onClick={()=>{this.downloadFile(temps3,this.state.formObj.totalleadfile)}} >{this.state.formObj.totalleadfile}</span></div>
                 </div>

                 <div className="d-flex row">   
                     <div className="p-2 roiboxing">
                        <label htmlFor="idcost_per_leads" className="label-custom">Cost/Leads</label>
                      </div>
                      <div className= "p-2">
                       <div className="dollar dollarroiinput">
                       {this.state.formObj.cost_per_leads}
                      </div>
                     </div>
                </div>
                <div className="d-flex row"><span className="p-2 textblue"><b> Total Number of Brand Impressions</b></span><br/><br/></div>
                 <div className="d-flex row">
                    <div className= "p-2 roiboxing">
                        <label htmlFor="idtotal_number_attendees" className="label-custom">How Many Attendees Total</label>
                    </div>
                    <div className= "p-2">
                       <input type="number" id="idtotal_number_attendees" className="form-control form-control-sm" name="total_number_attendees" value={this.state.formObj.total_number_attendees} onChange={(e)=>{this.changeROI("total_number_attendees",e)}} onBlur={()=>{ this.validate("total_number_attendees")}}  style={this.state.validations.total_number_attendees==""?{}:{"borderColor":"red"}} required /> 	
                      </div>
                      <span className="errormsg">{this.state.validations.total_number_attendees}</span>
                 </div><br/>
                 <div className="d-flex row">
                    <div className= "p-2 roiboxing">
                        <label htmlFor="idvisiter_booth" className="label-custom">How Many Attendees Visited Our Booth</label>
                    </div>
                    <div className= "p-2">
                       <input type="number" id="idvisiter_booth" className="form-control form-control-sm" name="visiter_booth" value={this.state.formObj.visiter_booth} onChange={(e)=>{this.changeROI("visiter_booth",e)}} onBlur={()=>{ this.validate("visiter_booth")}} style={this.state.validations.visiter_booth==""?{}:{"borderColor":"red"}} required /> 	
                      </div>
                      <span className="errormsg">{this.state.validations.visiter_booth}</span>
                 </div><br/>
                    
                   <div className="d-flex row">
                     <div className= "p-2 roiboxing">
                        <label htmlFor="idtotal_media_impressions" className="label-custom">Number of Media Impressions</label>
                      </div>
                      <div className= "p-2 ">
                        <input type="number" id="idtotal_media_impressions"  name="total_media_impressions" value={this.state.formObj.total_media_impressions} onChange={(e)=>{this.changeROI("total_media_impressions",e)}}onBlur={()=>{ this.validate("total_media_impressions")}} className="form-control form-control-sm" style={this.state.validations.total_media_impressions==""?{}:{"borderColor":"red"}} required /> 	
                      </div>
                      <span className="errormsg">{this.state.validations.total_media_impressions}</span>
                    </div><br/>

                    <div className="d-flex row">
                      <div className= "p-2 roiboxing">
                        <label htmlFor="idsocial_media_mentions" className="label-custom">Social Media Mentions</label>
                       </div>
                       <div className= "p-2 ">
                        <input type="number" id="idsocial_media_mentions"  name="social_media_mentions" value={this.state.formObj.social_media_mentions} onChange={(e)=>{this.changeROI("social_media_mentions",e)}} onBlur={()=>{ this.validate("social_media_mentions")}} className="form-control form-control-sm" style={this.state.validations.social_media_mentions==""?{}:{"borderColor":"red"}} required /> 	
                      </div>
                      <span className="errormsg">{this.state.validations.social_media_mentions}</span>
                    </div><br/>

                    <div className="d-flex row">
                      <div className= "p-2 roiboxing">
                          <label htmlFor="mediacont" className="label-custom">Media Contacts</label>
                      </div>
                      <div className= "p-2 roilinebreak">
                        <input type="number" id="mediacont" name="media_contacts_link" value={this.state.formObj.media_contacts_link} onChange={(e)=>{this.changeROI("media_contacts_link",e)}} onBlur={()=>{ this.validate("media_contacts_link")}} className="form-control form-control-sm" style={this.state.validations.media_contacts_link==""?{}:{"borderColor":"red"}} required /> 	
                      </div>
                      <span className="errormsg">{this.state.validations.media_contacts_link}</span>
                      <div className= "p-2 ">
                      <FileUpload parentObject={this}  header={this.props.header} eventid={this.props.eventid} folder='docs' subfolder={this.props.user.accountid} indexid="2" uploadtext="Upload Media List"/>
                      </div>
                      <div  className= "p-2"><span className="cursorcolor" onClick={()=>{this.downloadFile(temps4,this.state.formObj.mediafile)}}>{this.state.formObj.mediafile}</span></div>
                     </div><br/>
                    </div>

                    <div className="d-flex row"><span className="p-2 textblue"><b>Knowledge Transfer and Industry Trends</b></span></div><br/><br/>

                    <div className="d-flex row">
                     <div className= "p-2 roiboxing">
                        <label htmlFor="idcertifications" className="label-custom" >Enter Certification Name</label>
                      </div>
                      <div className="p-2 roiboxing">
                         <input type="text" id="idcertifications" name="certifications" value={this.state.formObj.certifications} onChange={(e)=>{this.changeROI("certifications",e)}} onBlur={()=>{ this.validate("certifications")}} className="form-control form-control-sm" style={this.state.validations.certifications==""?{}:{"borderColor":"red"}} required /> 	
                         <span className="errormsg">{this.state.validations.certifications}</span>
                      </div>
                       <div className="p-2"><FileUpload parentObject={this}  header={this.props.header} eventid={this.props.eventid} folder='docs' subfolder={this.props.user.accountid} indexid="3" uploadtext="Upload"/></div>
                      <div className="p-2"><span className="cursorcolor" onClick={()=>{this.downloadFile(temps,this.state.formObj.certificationsfile)}}>{this.state.formObj.certificationsfile}</span></div>
                    </div><br/>

                    <div className="d-flex row">
                      <div className= "p-2 roiboxing">
                          <label htmlFor="slidedesk" className="label-custom">Slide Decks</label>
                       </div>
                       <div className="p-2 roiboxing">
                          <input type="text" id="slidedesk"  className="form-control form-control-sm" name="slide_deck" value={this.state.formObj.slide_deck} onChange={(e)=>{this.changeROI("slide_deck",e)}}onBlur={()=>{ this.validate("slide_deck")}} className="form-control form-control-sm" style={this.state.validations.slide_deck==""?{}:{"borderColor":"red"}} required /> 	
                          <span className="errormsg">{this.state.validations.slide_deck}</span>
                      </div>
                       <div className="p-2"><FileUpload parentObject={this}  header={this.props.header} eventid={this.props.eventid} folder='docs' subfolder={this.props.user.accountid} indexid="4" uploadtext="Upload"/></div>
                      <div className="p-2"><span className="cursorcolor" onClick={()=>{this.downloadFile(temps1,this.state.formObj.slidedeckfile)}}>{this.state.formObj.slidedeckfile}</span></div>
                    </div><br/>

                    <div className="d-flex row">
                      <div className= "p-2 roiboxing">
                          <label htmlFor="totalnumber_exhibitor"><span className="textblue"><b>Total Number Exhibitors </b></span></label>
                      </div>
                      <div className="p-2 roiboxing">
                        <input type="number" id="totalnumber_exhibitor" className="form-control form-control-sm" name="other_exhibitors_list" value={this.state.formObj.other_exhibitors_list} onChange={(e)=>{this.changeROI("other_exhibitors_list",e)}} onBlur={()=>{ this.validate("other_exhibitors_list")}}  style={this.state.validations.other_exhibitors_list==""?{}:{"borderColor":"red"}} required /> 	
                        <span className="errormsg">{this.state.validations.other_exhibitors_list}</span>
                      </div>
                      <div className="p-2">
                      <FileUpload parentObject={this}  header={this.props.header} eventid={this.props.eventid} folder='docs' subfolder={this.props.user.accountid} indexid="5" uploadtext="Upload Exhibitor List"/></div>
                      <div className="p-2"><span className="cursorcolor" onClick={()=>{this.downloadFile(temps2,this.state.formObj.totalexhibitorfile)}}>{this.state.formObj.totalexhibitorfile}</span></div>
                    </div><br/>
                  

                    <div className="d-flex row">
                      <div className="p-2 col-md-12">
                         <label htmlFor="businessachived" className="label-custom">Was The Business Objective Achieved?</label>
                        </div> 
                    </div>  
                    <div className="d-flex row">
                    <div className="p-2">
                    <div className="form-check">
                    <input className="form-check-input" type="radio" name="optradio" id="idyes" value="yes" checked={this.state.achived_yes} onChange={()=>{this.objectiveYes()}}  />
                    <label className="label-custom" htmlFor="idyes">
                       Yes
                    </label>
                    </div>
                    </div>
                    <div className="p-2">
                    <div className="form-check">
                    <input className="form-check-input" type="radio" name="optradio" id="idno" value="no" checked={this.state.achived_no} onChange={()=>{this.objectiveNo()}} />
                    <label className="label-custom" htmlFor="idno">
                       No
                    </label>
                    </div>
                    </div>
                    </div><br/>
                    <div className="d-flex row">
			              <div className="p-2 col-md-12">
			                  <label htmlFor="idnotes" className="label-custom">Add Notes</label>
                         <textarea type="text" rows="1" cols="10" className="form-control" name="notes" id="idnotes" value={this.state.formObj.notes} onChange={(e)=>{this.changeROI("notes",e)}}  />
                        </div> 
			               </div>
                  <div className="d-flex row">
                        <div className="col-md-12">  
                        {this.render_formButton()}
                        </div>
                    </div>
         
              </div>
					</div>
		)}
		
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(ReturnoninvestmentsForm);
