///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Budgets = {
	exhibitor_costList : '', 
	marketing_costList : '', 
	travel_costList : '', 
	total_cost : '', 
	description : '', 
	status : '', 
	id : '', 
	notes: '',
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}