////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';
import { base_url } from '../constants/Constants';
import { Permissions } from '../models/Permissions';
import {Budgets } from '../models/Budgets';
import '../styles/Styles.css';
import $ from 'jquery';

class BudgetsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
			edit : true,
			showEdit : false,
			formObj : Budgets,
			eventid : '',
			eventname :'',
			eventdate :'',
			itemname :'',
			itemprice :'',
			budgetList :[],
			marketingList :[],
			travelList :[],
		}
	
		
		this.onSubmit = this.onSubmit.bind(this);
		this.onchangeItemname=this.onchangeItemname.bind(this);
		this.onchangeItemprice=this.onchangeItemprice.bind(this);
		this.currencyFormat = this.currencyFormat.bind(this);
		this.handleObjectChangeName = this.handleObjectChangeName.bind(this);
		this.handleObjectChangePrice = this.handleObjectChangePrice.bind(this);
		this.handleObjectChangeMarketingName = this.handleObjectChangeMarketingName.bind(this);
		this.handleObjectChangeTravelName = this.handleObjectChangeTravelName.bind(this);
		this.handleObjectChangeMarketingPrice = this.handleObjectChangeMarketingPrice.bind(this);
		this.handleObjectChangeTravelPrice = this.handleObjectChangeTravelPrice.bind(this);
		this.addExhibitionCost =this.addExhibitionCost.bind(this);
		this.addMarketingCost =this.addMarketingCost.bind(this);
		this.addTravelCost =this.addTravelCost.bind(this);
		this.handleNotes = this.handleNotes.bind(this);
		this.deleteExhibitionrecord = this.deleteExhibitionrecord.bind(this);	
		this.deleteMarketingrecord = this.deleteMarketingrecord.bind(this);	
		this.deleteTravelrecord = this.deleteTravelrecord.bind(this);
		this.formattedInputExhibitor = this.formattedInputExhibitor.bind(this);	
		this.formattedInputMarketing = this.formattedInputMarketing.bind(this);
		this.formattedInputTravel = this.formattedInputTravel.bind(this);

	}

	componentDidMount(){

		  axios.get(base_url+'budgets/listbyeventid/'+this.props.eventid,  this.props.user.header )
                .then(res =>{
				 console.log(res.data);
				 if(res.data.length > 0 ){
					this.setState({ formObj:res.data[0]});
				 }else{
					 var temp = [];
					 temp.push({id: 1,name:"Booth", price:"0.00"});
					 temp.push({id: 2,name:"Shipping", price:"0.00"});
					 temp.push({id: 3,name:"Material Handling", price:"0.00"});
					 temp.push({id: 4,name:"Union Fees", price:"0.00"});
					 temp.push({id: 5,name:"Insurance", price:"0.00"});
					 temp.push({id: 6,name:"Furnishing", price:"0.00"});
					 temp.push({id: 7,name:"Carpet", price:"0.00"});
					 temp.push({id: 8,name:"Electrical", price:"0.00"});
					 temp.push({id: 9,name:"A/V Audio Video", price:"0.00"});
					 temp.push({id: 10,name:"Signage", price:"0.00"});
					 temp.push({id: 11,name:"Refrigerator", price:"0.00"});
					 temp.push({id: 12,name:"Corkage", price:"0.00"});
					 temp.push({id: 13,name:"Catering", price:"0.00"});
					 temp.push({id: 14,name:"Hosted Bar", price:"0.00"});
					 temp.push({id: 15,name:"Cleaning", price:"0.00"});
					 var tempForm =this.state.formObj;
					 tempForm.exhibitor_costList=temp;
				
					 var temp2 = [];
					 temp2.push({id:1,name:"Event Emails", price:"0.00"});
					 temp2.push({id:2,name:"Social Media", price:"0.00"});
					 temp2.push({id:3,name:"Lead Scanner", price:"0.00"});
					 tempForm.marketing_costList=temp2;
					 
					 var temp3 = [];
					 temp3.push({id:1,name:"Flight", price:"0.00"});
					 temp3.push({id:2,name:"Hotel", price:"0.00"});
					 temp3.push({id:3,name:"Car/ Transportation", price:"0.00"});
					 temp3.push({id:4,name:"Food", price:"0.00"});
					 temp3.push({id:5,name:"Entertainment", price:"0.00"});
					 temp3.push({id:6,name:"Parking", price:"0.00"});
					 tempForm.travel_costList=temp3;
					 tempForm.events_id=this.props.eventid;
					 tempForm.status="Active";
					 tempForm.total_exhibitorcost="0.00";
					 tempForm.total_marketingcost="0.00";
					 tempForm.total_travelcost="0.00";
					 this.setState({ formObj:tempForm}); 
					 axios.post(base_url+'budgets/save',tempForm, this.props.user.header )
		             .then(res =>{
			           axios.get(base_url+'budgets/listbyeventid/'+this.props.eventid,  this.props.user.header )
			              .then(res =>{
							  if(res.data.length > 0){
						   this.setState({formObj:res.data[0]});
							  }
		              	}).catch(function (error) { console.log(error); }); 
	                 }).catch(function (error) { console.log(error); });

				 }
			  }).catch(function (error) { console.log(error); });   
			     
	}
	continue=e=>{
        this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.enddate,this.props.eventid); 			
      }
      back=e=>{
        this.props.parentObject.eventStepBack();
	  }
	onchangeItemname = (e) =>{
		let newObj=this.state.formObj;
		newObj.itemname=e.target.value
		this.setState({formObj:newObj});
		//console.log(this.state.formObj.itemname);
	} 
	onchangeItemprice = (e) =>{
		let newObj=this.state.formObj;
		newObj.itemprice=e.target.value
		this.setState({formObj:newObj});
	}  
	handleNotes = (e) => {
		let newformObj=this.state.formObj;
		newformObj.notes=e.target.value
		this.setState({ formObj:newformObj });
	  }
	 render_formButton(){
        if(this.props.eventAction === 'edit'){
            return(
				<div className="d-flex justify-content-end row">
					<div className="p-2">
					    <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()} >Back</button>
					</div>
					<div className="p-2">
					   <button type="button" className="btn btn-primary btn-block" onClick={() =>this.onSubmit()}>Next</button>
					</div>
				</div>
   
            )
        }else{
            return(
               <div className="d-flex justify-content-end row">
					<div className="p-2">
					     <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()} >Back</button>
					</div>
					<div className="p-2">
					     <button type="button" className="btn btn-primary btn-block" onClick={() =>this.onSubmit()}>Next</button>
					</div>
               </div>
   
            )
        }
	}
	formattedInputMarketing=(id,num)=>{
		let nums = num.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

		var bList = this.state.formObj;
		const myRowIndex = bList.marketing_costList.findIndex((row) => row.id === id);
		bList.marketing_costList[myRowIndex].price =this.currencyFormat(nums);
		this.setState({ formObj:bList }); 
		this.calculateMarketingCost();
		this.net_total();

	}
	formattedInputExhibitor=(id,num)=>{
		let nums = num.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

		var bList = this.state.formObj;
		const myRowIndex = bList.exhibitor_costList.findIndex((row) => row.id === id);
		bList.exhibitor_costList[myRowIndex].price =this.currencyFormat(nums);
		this.setState({ formObj:bList }); 
		this.calculateExhibitionCost();
		this.net_total();

	}
	formattedInputTravel=(id,num)=>{
		let nums = num.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

		var bList = this.state.formObj;
		const myRowIndex = bList.travel_costList.findIndex((row) => row.id === id);
		bList.travel_costList[myRowIndex].price =this.currencyFormat(nums);
		this.setState({ formObj:bList }); 
		this.calculateTravelCost();
		this.net_total();

	}


	handleObjectChangeName = (id, event) => {
		event.preventDefault();
		var bList = this.state.formObj;
		const myRowIndex = bList.exhibitor_costList.findIndex((row) => row.id === id);
		bList.exhibitor_costList[myRowIndex].name = event.target.value;
		this.setState({ formObj:bList }); 
	}

	handleObjectChangePrice = (id, event) => {
		event.preventDefault();
		event.target.value = event.target.value ? event.target.value : 0;
         if(!isNaN(event.target.value)){
		var bList = this.state.formObj;
		const myRowIndex = bList.exhibitor_costList.findIndex((row) => row.id === id);
		bList.exhibitor_costList[myRowIndex].price=event.target.value.replace(/[^0-9.]/g, '');
		this.setState({ formObj:bList }); 

		this.calculateExhibitionCost();
		this.net_total();
		 }
	}

	handleObjectChangeMarketingName = (id, event) => {
			event.preventDefault();
			var bList = this.state.formObj;
			const myRowIndex = bList.marketing_costList.findIndex((row) => row.id === id);
			bList.marketing_costList[myRowIndex].name = event.target.value;
	    	this.setState({ formObj:bList }); 
	}

   handleObjectChangeMarketingPrice = (id, event) => {
	event.preventDefault();
	event.target.value = event.target.value ? event.target.value : 0;
         if(!isNaN(event.target.value)){
			var bList = this.state.formObj;
			const myRowIndex = bList.marketing_costList.findIndex((row) => row.id === id);
			bList.marketing_costList[myRowIndex].price =event.target.value.replace(/[^0-9.]/g, '');
			this.setState({ formObj:bList }); 
			this.calculateMarketingCost();
			this.net_total();
		 }
	}

	handleObjectChangeTravelName = (id, event) => {
		event.preventDefault();
		var bList = this.state.formObj;
		const myRowIndex = bList.travel_costList.findIndex((row) => row.id === id);
		bList.travel_costList[myRowIndex].name = event.target.value;
		this.setState({ formObj:bList }); 
	}

    handleObjectChangeTravelPrice = (id, event) => {
		event.preventDefault();
	    event.target.value = event.target.value ? event.target.value : 0;
         if(!isNaN(event.target.value)){
			var bList = this.state.formObj;
			const myRowIndex = bList.travel_costList.findIndex((row) => row.id === id);
			bList.travel_costList[myRowIndex].price =event.target.value.replace(/[^0-9.]/g, '');
			this.setState({ formObj:bList }); 
			this.calculateTravelCost();
			this.net_total();
		 }
    }	
	  
	render_ExhibitionCost(){
	   if(this.state.formObj.exhibitor_costList !== undefined && this.state.formObj.exhibitor_costList !== ''){
		return this.state.formObj.exhibitor_costList.map((item, index) =>{
			var display="borderstyleinvisible label-custom";
			if(item.name === ""){
				display="label-custom";
			}
			return (
				  <tr key={index} id={index}>
					<td className={display}>
					<input type="text" name="itemname" value={item.name} onChange={(e)=>{this.handleObjectChangeName(item.id, e)}}></input></td>
					<td className="dollar"><input type="text"  name="itemprice" value={item.price} onChange={(e)=>{this.handleObjectChangePrice(item.id, e)}} onBlur={()=>{this.formattedInputExhibitor(item.id,item.price)}}></input>
					<span><FaTrash size="15px" color="red" onClick={() => {this.deleteExhibitionrecord(index)}} /></span>
					</td>       
			      </tr>
			) 
			}
			, this);
		
	  }
	}
	render_MarketingCost(){
	   if(this.state.formObj.marketing_costList !== undefined && this.state.formObj.marketing_costList !== ''){
		return this.state.formObj.marketing_costList.map((item, index) =>{
			var display="borderstyleinvisible label-custom";
			if(item.name === ""){
				display="label-custom";
			}
			return (
				  <tr key={index} id={index}>
					<td className={display}>
						<input type="text"  name="itemname" value={item.name} onChange={(e)=>{this.handleObjectChangeMarketingName(item.id, e)}}></input></td>
					<td  className="dollar"><input type="text"    name="itemprice" value={item.price} onChange={(e)=>{this.handleObjectChangeMarketingPrice(item.id, e)}} onBlur={()=>{this.formattedInputMarketing(item.id,item.price)}}></input>
					<span><FaTrash size="15px" color="red" onClick={() => {this.deleteMarketingrecord(index)}} /></span></td>
				  </tr>
			) 
			}, this);
		
	  }
	}
	render_TravelCost(){	
	   if(this.state.formObj.travel_costList !== undefined && this.state.formObj.travel_costList !== ''){
		return this.state.formObj.travel_costList.map((item, index) =>{
			var display="borderstyleinvisible label-custom";
			if(item.name === ""){
				display="label-custom";
			}
			return (
				  <tr key={index} id={index}>
					<td className={display}>
					<input type="text"   name="itemname" value={item.name} onChange={(e)=>{this.handleObjectChangeTravelName(item.id, e)}}></input></td>
					<td className="dollar" ><input type="text"  name="itemprice" value={item.price} onChange={(e)=>{this.handleObjectChangeTravelPrice(item.id, e)}} onBlur={()=>{this.formattedInputTravel(item.id,item.price)}}></input>
					<span><FaTrash size="15px" color="red" onClick={() => {this.deleteTravelrecord(index)}} /></span></td>       
				  </tr>
			) 
			}, this);
	  }
	}
	addExhibitionCost = () =>{
		var exList=this.state.formObj.exhibitor_costList;
		var count=0;
        if(exList.length >0){
			var  max = Math.max.apply(null,  exList.map(item => item.id));
			count=max+1;
		}else{
			count=1;
		}
		var bList = this.state.formObj;
		bList.exhibitor_costList.push({id: count,name : "",price: "0.00"});
		this.setState({formObj:bList});
	}	
	addMarketingCost = () =>{
		//var count=this.state.formObj.marketing_costList.length+1;
		var markList=this.state.formObj.marketing_costList;
		var count=0;
        if(markList.length >0){
			var  max = Math.max.apply(null,  markList.map(item => item.id));
			count=max+1;
		}else{
			count=1;
		}
		var bList = this.state.formObj;
		bList.marketing_costList.push({id: count,name : "",price: "0.00"});
		this.setState({formObj:bList});
	}	
	addTravelCost = () =>{
		//var count=this.state.formObj.travel_costList.length+1;
		var traList=this.state.formObj.travel_costList;
		var count=0;
        if(traList.length >0){
			var  max = Math.max.apply(null,  traList.map(item => item.id));
			count=max+1;
		}else{
			count=1;
		}
		var bList = this.state.formObj;
		bList.travel_costList.push({id: count,name : "",price: "0.00"});
		this.setState({formObj:bList});
	}	

    currencyFormat=(num)=>{
		if(num !== undefined ){
		 num=parseFloat(num);
		return  num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
		}else{
		 return '0.00';
		}
	 }
	calculateExhibitionCost(){
	  var a = this.state.formObj.exhibitor_costList;
	  var total = 0;
	  if(this.state.formObj.exhibitor_costList !== undefined && this.state.formObj.exhibitor_costList !== '' ){
      for (var i=0; i<a.length; i++) {
		total += parseFloat(a[i].price.replace(/,/g, '')); 
	  }
	  let totals=this.currencyFormat(total);
	  let temp=this.state.formObj;
	  temp.total_exhibitorcost=totals;
	  this.setState({formObj:temp});
	}
	 // console.log(total);
	}
	calculateMarketingCost(){
		var a = this.state.formObj.marketing_costList;
		var total = 0;
		if(this.state.formObj.marketing_costList !== undefined && this.state.formObj.marketing_costList !== ''){
		for (var i=0; i<a.length; i++) {
		  total += parseFloat(a[i].price.replace(/,/g, '')); 
		}
		let totals=this.currencyFormat(total);
		let temp=this.state.formObj;
		temp.total_marketingcost=totals;
		this.setState({formObj:temp});
	  }
		//console.log(total);
	 }
	 calculateTravelCost(){
		var a = this.state.formObj.travel_costList;
		var total = 0;
		if(this.state.formObj.travel_costList !== undefined && this.state.formObj.travel_costList !== ''){
		for (var i=0; i<a.length; i++) {
		  total += parseFloat(a[i].price.replace(/,/g, '')); 
		}
		let totals=this.currencyFormat(total);
		let temp=this.state.formObj;
		temp.total_travelcost=totals;
		this.setState({formObj:temp});
	  }
	  //console.log(total);
	 }
	 net_total(){
		var temp=this.state.formObj;
		var excost=0;
		var marketing=0;
		var travel=0;
		var total=0;
		excost = temp.total_exhibitorcost;
		//console.log(excost);
		marketing = temp.total_marketingcost;
		travel = temp.total_travelcost;

		if(excost !== undefined && excost !== 0 && marketing !== undefined && marketing !== 0 && travel !== undefined && travel !== 0 ){
		excost=parseFloat(excost.replace(/,/g, ''));
		//console.log(excost);
		marketing=parseFloat(marketing.replace(/,/g, ''));
		travel=parseFloat(travel.replace(/,/g, ''));
		total=parseFloat(excost)+parseFloat(marketing)+parseFloat(travel);
		}
		 let nettotal=this.currencyFormat(total);
		 temp.total_cost=nettotal;
		 this.setState({formObj:temp});
	 }

	 deleteExhibitionrecord = (i) =>{
		 let temp=this.state.formObj;
		 temp.exhibitor_costList.splice(i,1);
		 this.setState({formObj:temp});
		 this.calculateExhibitionCost();
		 this.calculateMarketingCost();
		 this.calculateTravelCost();
		 this.net_total();
		axios.post(base_url+'budgets/save',this.state.formObj, this.props.user.header )
		  .then(res =>{
			   axios.get(base_url+'budgets/listbyeventid/'+this.props.eventid,  this.props.user.header )
			  .then(res =>{
				  if(res.data.length > 0){
				  this.setState({formObj:res.data[0]});
				  }
			}).catch(function (error) { console.log(error); }); 
	  }).catch(function (error) { console.log(error); });
  }
  deleteMarketingrecord = (i) =>{
	let temp=this.state.formObj;
	temp.marketing_costList.splice(i,1);
	this.setState({formObj:temp});
    this.calculateExhibitionCost();
    this.calculateMarketingCost();
    this.calculateTravelCost();
    this.net_total();
   axios.post(base_url+'budgets/save',this.state.formObj, this.props.user.header )
	 .then(res =>{
		  axios.get(base_url+'budgets/listbyeventid/'+this.props.eventid,  this.props.user.header )
		 .then(res =>{
			 this.setState({formObj:res.data[0]});
	   }).catch(function (error) { console.log(error); }); 
 }).catch(function (error) { console.log(error); });
}
deleteTravelrecord = (i) =>{
	let temp=this.state.formObj;
	temp.travel_costList.splice(i,1);
	this.setState({formObj:temp});
    this.calculateExhibitionCost();
    this.calculateMarketingCost();
    this.calculateTravelCost();
    this.net_total();
   axios.post(base_url+'budgets/save',this.state.formObj, this.props.user.header )
	 .then(res =>{
		  axios.get(base_url+'budgets/listbyeventid/'+this.props.eventid,  this.props.user.header )
		 .then(res =>{
			 this.setState({formObj:res.data[0]});
	     }).catch(function (error) { console.log(error); }); 
 }).catch(function (error) { console.log(error); });
}


onSubmit(){
	this.calculateExhibitionCost();
	this.calculateMarketingCost();
	this.calculateTravelCost();
	this.net_total();
		axios.post(base_url+'budgets/save',this.state.formObj, this.props.user.header )
			.then(res =>{
				console.log(res);
				 axios.get(base_url+'budgets/listbyeventid/'+this.props.eventid,  this.props.user.header )
                .then(res =>{
				  console.log(res.data);
				  if(res.data[0].total_cost !== '0.00'){
				    this.setState({ formObj:res.data });
				    this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.enddate,this.props.eventid);
				  } 
            }).catch(function (error) { console.log(error); }); 
		}).catch(function (error) { console.log(error); });
	}

	render() {
     return (
			<div id="idbudgets" className="shadow-sm p-3 mb-5 bg-white rounded">
				<div className="d-flex row">
					<div className="p-2 flex-fill">
					<label className="label-custom">Add Your Exhibition Cost</label>
					<div className="d-flex">
					  <table className="table-sm table-responsive-budget" >
						<thead className="thead">
							<tr>
                             	<td>Exhibition Cost</td>
								<td></td>
							</tr>
							</thead>
							<tbody>
								{this.render_ExhibitionCost()}
								 <tr>
									<td className="budgettotal"><b>TOTAL</b></td>
									<td className="dollar"><div className="dollartotal">{this.state.formObj.total_exhibitorcost}</div></td>
								</tr>
								 <tr>
									<td></td>
									<td  className="dollartotal">
									   <button type="button" className="btn btn-primary btn-sm" onClick={this.addExhibitionCost}>+Add Line Item</button>
									</td>
								</tr>
							</tbody>
						</table>
						</div>
					</div>
					<div className="p-2 flex-fill">
						<label className="label-custom">Add Your Marketing Cost</label>
							<table className="table-sm table-responsive-budget">
								<thead className="thead">
									<tr>
										<td>Marketing Cost</td>
										<td></td>
									</tr>
								</thead>
								<tbody>{this.render_MarketingCost()}
									<tr>
										<td className="budgettotal"><b>TOTAL</b></td>
	                                    <td className="dollar"><div className="dollartotal">{this.state.formObj.total_marketingcost}</div></td>
									</tr>
									<tr>
										<td></td>
										<td className="dollartotal">	
								          <button type="button" className="btn btn-primary btn-sm" onClick={this.addMarketingCost}>+Add Line Item</button>
										</td>
									</tr>
								</tbody>
							</table>
							<div>
							<label className="label-custom">Add Your Traveling Cost</label>
								<table className="table-sm table-responsive-budget">
                                   <thead className="thead">
                                      <tr>
										<td>Traveling Cost</td>  
										<td></td>  
                                      </tr>
                                   </thead>
                                   <tbody>
                                     {this.render_TravelCost()}
                                     <tr>
                                      <td className="budgettotal"><b>TOTAL</b></td>
									  <td className="dollar"><div className="dollartotal">{this.state.formObj.total_travelcost}</div></td>
									 </tr>
									 <tr><td></td>
									  <td className="dollartotal">
                                     <button type="button" className="btn btn-primary btn-sm" onClick={this.addTravelCost}>+Add Line Item</button>
									 </td>
									 </tr>
                                  </tbody>
                              </table>
                         </div>
				         </div>
				   </div>
				   <div className="form-group row">
                         <div className="form-group col-md-6"></div>
                         <div className="form-group col-md-2"></div>
                         <div className="form-group col-md-4">
						 <label htmlFor="totalcost"><b>NET TOTAL</b></label>&nbsp;&nbsp;&nbsp;
                         <b>${this.state.formObj.total_cost}</b>
                        </div>
                  </div>
				  <div className="brdcolor" >
				  <div className="d-flex row">
                     <div className="p-2 col-md-12">
                     <label htmlFor="idnotes" className="label-custom">Add Notes</label>
                     <textarea type="text" rows="1" cols="10" className="form-control" name="notes" id="idnotes" value={this.state.formObj.notes} onChange={(e)=>{this.handleNotes(e)}}  />
                     </div> 
					</div> 
                 </div>

                 <div className="d-flex row">
                    <div className="col-md-12">  
                     {this.render_formButton()}
                     </div>
                 </div>
		</div>
		)}
		
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(BudgetsForm);
