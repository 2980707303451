////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {Modal,Button} from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import {Documents } from '../models/Documents';
import FileUploader from '../utils/FileUploader';
import fileDownload from 'js-file-download';
import { FaFileUpload, FaUpload,FaTrash } from "react-icons/fa";
import '../styles/Styles.css';


class DocumentsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
			edit : true,
			showEdit : false,
			formObj : Documents,
			uploadDocs : [],
			documentsList :[],
			filename :'',
			filetype :'',
			indexno:null,
			dltconfirm:false,
			uploadfilename:'',
			showdoc :false,
		}
		
		this.updateFilename = this.updateFilename.bind(this);
		this.handleObjectChange = this.handleObjectChange.bind(this);
		this.addDocument = this.addDocument.bind(this);
		this.handleDeleteRow = this.handleDeleteRow.bind(this);
		this.confirmdelete = this.confirmdelete.bind(this);
	}

	componentDidMount(){
		//console.log(this.props.header);
		axios.get(base_url+'documents/listbyeventid/'+this.props.eventid,  this.props.user.header )
			.then(res =>{
				if(res.data.length > 0){
					console.log(res.data);
				 this.setState({documentsList:res.data });
				}else{
					var temp = {};
					temp={id: 1,events_id:this.props.eventid,status:'Active',document:"Exhibitor Contract",filename:""};
					axios.post(base_url+'documents/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); }); 
					temp={id: 2,events_id:this.props.eventid,status:'Active',document:"Invoice",filename:""};
					axios.post(base_url+'documents/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); }); 
					temp={id: 3,events_id:this.props.eventid,status:'Active',document:"Seller's Permit",filename:""};
					axios.post(base_url+'documents/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); }); 
					temp={id: 4,events_id:this.props.eventid,status:'Active',document:"Liability and Insurance",filename:""};
					axios.post(base_url+'documents/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); }); 
					temp={id: 5,events_id:this.props.eventid,status:'Active',document:"Food and Health Permit ",filename:""};
					axios.post(base_url+'documents/save', temp, this.props.user.header ).then(res =>{
						axios.get(base_url+'documents/listbyeventid/'+this.props.eventid,  this.props.user.header )
			            .then(res =>{
							 this.setState({ documentsList:res.data });
							 console.log(this.state.documentsList);
			 	        }).catch(function (error) { console.log(error); }); 
					}).catch(function (error) { console.log(error); }); 
					
				}
				}).catch(function (error) { console.log(error); }); 
	}
	updateFilename = (filename,index) =>{
		//console.log(index);
		let temp=this.state.documentsList;
		temp[index].filename=filename;
		this.setState({documentsList:temp});
		//let newdoc=temp.document[index];
		let newdoc=temp[index];
		newdoc.status='Active';
		newdoc.events_id=this.props.eventid;
		console.log(newdoc);
			axios.post(base_url+'documents/save', newdoc, this.props.user.header )
			.then(res =>{
				console.log(res.data);
				axios.get(base_url+'documents/listbyeventid/'+this.props.eventid,  this.props.user.header )
			 .then(res =>{
				 this.setState({ documentsList:res.data });
			 	}).catch(function (error) { console.log(error); }); 
			}).catch(function (error) { console.log(error); });

	}
	handleDeleteRow =() =>{
		if(this.state.indexno !== null ){
		let temp=this.state.documentsList;
		//let newdoc=temp.document[index];
		let newdoc=temp[this.state.indexno];
		newdoc.status='Inctive';
		newdoc.events_id=this.props.eventid;
		console.log(newdoc);
			axios.post(base_url+'documents/save', newdoc, this.props.user.header )
			.then(res =>{
				console.log(res.data);
				axios.get(base_url+'documents/listbyeventid/'+this.props.eventid,  this.props.user.header )
			 .then(res =>{
				 this.setState({ documentsList:res.data });
				 this.setState({dltconfirm:!this.state.dltconfirm});
			 	}).catch(function (error) { console.log(error); }); 
			}).catch(function (error) { console.log(error); });
		}
	}
	confirmdeleteModal = (i) =>{
		console.log(i);
		this.setState({dltconfirm:!this.state.dltconfirm,indexno:i});
	}

	onSubmit= (i) =>{
		let newformObj=this.state.documentsList[i];
		newformObj.status='Active'
		newformObj.events_id=this.props.eventid;
			axios.post(base_url+'documents/save', newformObj, this.props.user.header )
			.then(res =>{
				console.log(res.data);
				axios.get(base_url+'documents/listbyeventid/'+this.props.eventid,  this.props.user.header )
			 .then(res =>{
				 this.setState({ documentsList:res.data });
			 	}).catch(function (error) { console.log(error); }); 
			}).catch(function (error) { console.log(error); });

   }
   downloadFile=(url,filename)=>{
	axios.get(base_url+url,  {responseType: 'blob', headers:this.props.header.headers}).then(res => {
	fileDownload(res.data, filename);
	});
   }
  
    handleObjectChange = (id, event) => {
	event.preventDefault();
	var bList = this.state.documentsList;
	var string = event.target.value;
	//const myRowIndex = bList.findIndex((row) => row.id === id);
	bList[id].document= string.charAt(0).toUpperCase() + string.slice(1);
	this.setState({ formObj:bList }); 
    }
   render_uploadFilelist(){
	 if( this.state.documentsList != undefined && this.state.documentsList.length > 0){
	 return this.state.documentsList.map((item, index) =>{
		 let temp='file/download/'+item.filename+'?id='+this.props.user.accountid;
		 var display="invisiblefollow label-custom";
		 if(item.document === ""){
			 display="bobjective label-custom";
		 } 
	   return (
			<tr key={index} id={index}>
			<td className={display}><input type="text" name="document" id={index} value={item.document}  onChange={(e)=>{this.handleObjectChange(index, e)}} onBlur={()=>{this.onSubmit(index)}} placeholder="Type In Document Name"></input></td>
	        <td><dd className="cursorcolor" onClick={()=>{this.downloadFile(temp,item.filename)}}>{item.filename}</dd></td>
			<td className="budgettotal"><span>&nbsp;&nbsp;<FaTrash size="15px" color="red" onClick={()=>{this.confirmdeleteModal(index)}} />&nbsp;&nbsp;</span>
		    <FileUploader parentObject={this}  header={this.props.header} eventid={this.props.eventid} folder='docs' subfolder={this.props.user.accountid} indexid={index} uploadtext="Upload" />
			</td> 
		</tr>
	   ) 
		}, this);
	  }
   
   }
   addDocument = () =>{
	var doc=this.state.documentsList;
	var count=0;
	if(doc.length >0){
		var  max = Math.max.apply(null,  doc.map(item => item.id));
		count=max+1;
		doc.push({id: count,document:"",filename:""});
	}else{
		count=1;
		doc=[];
		doc.push({id: count,document:"",filename:""});
	}
	this.setState({documentsList:doc});
}
confirmdelete = (i) =>{
	var r =window.confirm("Are You Sure??Want to Delete this Record!!");
  if (r == true) {
   this.handleDeleteRow(i);
  } 
}

	render() {
       return (
		<div id="iddocuments">
             <div className="d-flex row">
                        <div className="form-group col-md-12">
                        <label className="label-custom">Add Your Exhibitor Documents</label>
                            <table className="table table-sm table-responsive-stack">
                                <thead className="thead">
                                    <tr>
										<td>Document</td>
									    <td></td> 
										<td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.render_uploadFilelist()}
                                </tbody>
                            </table>
							<div className="p-2 col-md-12">
								<div className="d-flex justify-content-end row">
								  <div className="p-2"> <button type="button" className="btn btn-primary btn-block btn-sm" onClick={()=>{this.addDocument()}}>+Add</button></div>
								</div>
                            </div>
                     </div>
                        </div>
			<Modal show={this.state.dltconfirm} onHide={this.confirmdeleteModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                <br/><br/>
				
                <div className="d-flex justify-content-center row">
                     <h6> Are You Sure?? Want to Delete this Record!! </h6> 
                     <br/><br/><br/>
                </div>
                <div className="d-flex justify-content-center row">
                   <Button variant="danger"  onClick={this.handleDeleteRow} >Yes</Button>
                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                   <Button variant="warning" onClick={this.confirmdeleteModal}>No</Button>
                </div>
                </Modal.Body>
            </Modal>
        </div>
					
	 )}
	}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(DocumentsForm);
