///////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of Deemsoft Global Systems LLC USA, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { base_url } from '../constants/Constants';
import { connect } from 'react-redux';
import '../styles/Styles.css';
import { Popover,OverlayTrigger,Button} from 'react-bootstrap';

class Test extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
			edit : true,          
            showEdit : true,  
			welcome:true,        

		}
		
    }
	componentDidMount(){
		/*
		today=07-12-2021
		charging date=

		 */

		var date=new Date();
		date.setDate(date.getDate()+13);
		date.setMonth(date.getMonth()-1);
		var y=date.getFullYear();
		var d=date.getDate();
		var m=date.getMonth()+1;
		var dt= (m <= 9 ? '0' + m : m) + '-' +(d <= 9 ? '0' + d : d) + '-' + y;
		console.log(dt);

		var priorDate = new Date("07-26-2021");
		priorDate.setMonth(priorDate.getMonth()-1);
		  var y1 = priorDate.getFullYear();
		  var d1= priorDate.getDate();
		  var m1= priorDate.getMonth()+1;
		  var dt1= (m1 <= 9 ? '0' + m1 : m1) + '-' +(d1 <= 9 ? '0' + d1 : d1) + '-' + y1;
		  console.log(dt1);
		  var dt2='01-29-'+y1;
		  //console.log(dt2);
if(m1 === 2 && (dt1 === '02-29-'+y1 || dt1 === '02-28-'+y1)){
//console.log("Feb");	
if((y1 % 100 === 0) ? (y1 % 400 === 0) : (y1 % 4 === 0)){
	//console.log("Leap year");

}else{
	//console.log(y1+" is Not Leap year");
}

}else{
	//console.log(y1+"other month");
}
		var obj = {cardname:"Jyoti",cardnumber:"4242424242424242",expmonth:"12",expyear:"2023",amount:"200",subscription_type:"Monthly Subscription"};
        var encoded = btoa(JSON.stringify(obj));
		console.log(encoded);
		var actual = JSON.parse(atob(encoded));
		//console.log(actual);

	}
	savefun = () =>{
		var cardobj = {cardname:"Jyoti", cvc:"121",cardnumber:"4242424242424242",expmonth:"12",expyear:"2023",amount:"35.00",subscription_type:"Monthly Subscription",status:"Active"};
		//cardobj.status="Active";
		var encoded = btoa(JSON.stringify(cardobj));
		//console.log(encoded);
	  //  cardobj.accountid=this.state.accountid;

	  var cardstl={card: encoded,status: "Active",accountid: "26"};
	  axios.post(base_url+'creditcards/save', cardstl )
	  .then(res =>{
			//console.log(res);
		}).catch(function (error) { console.log(error); }); 
	}


	  
	  render(){
		return(
		  <div className="container">
		       <button className="btn btn-primary btn-sm" onClick={()=>{this.savefun()}}>Submit</button>

		</div>
		)
	  }
}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Test);