import { Store } from '../services/Store';

export const getPage = () => {
  setTimeout(() => {
    Store.dispatch({
      type: 'GET_CURRENT_PAGE',
      page: '',
    })
  }, 500)
}

export const setPage = (value) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      Store.dispatch({
        type: 'SET_CURRENT_PAGE',
        page: value,        
      })
      resolve()
    }, 500)
  })
}