import React from "react";
import { base_url } from '../constants/Constants';
import axios from 'axios';
import { FaFileUpload, FaUpload, FaCloudUploadAlt } from "react-icons/fa";

export default class Uploadfile extends React.Component {
    constructor(props){
		super(props);
		this.state = {
            text:'',
            eventid:'',
		}
    }
    componentDidMount(){
		//console.log(this.props.eventid);  
	}

    btnClick = (fileid) =>{
        console.log(fileid);
        let fileElem = document.getElementById('id'+fileid);
        if (fileElem) {
            fileElem.click();
          }  
    } 
     uploadFile=(e)=>{
        
        if(e.currentTarget.files.length > 0 ){
            const formData = new FormData();
            let fileObj = e.currentTarget.files[0];
            formData.append('myfile', fileObj);
            formData.append('filetype', this.props.folder);
            formData.append('id', this.props.subfolder);
            formData.append('file', e.currentTarget.files[0]);
            axios.post(base_url+'file/upload/',formData, this.props.header).then(res =>{ 
                console.log(this.props.indexid);
                this.props.parentObject.updateFilename(fileObj.name,this.props.indexid)
            }).catch(function (error) { console.log(error); }); 
        }
    } 
    render(){  
          let fileid='id'+this.props.indexid;
          let btntext=this.props.uploadtext;
        return (
            <div>
                <input id={fileid} accept={this.props.accepts} style={{ display:'none' }} onChange={this.uploadFile} type="file" />
                <p><FaCloudUploadAlt size="30px" color="rgb(50, 138, 209)"  disabled={this.props.disabled} eventid={this.state.eventid} onClick={()=>{this.btnClick(this.props.indexid)}} /></p>
       {/*  <button className="btn btn-primary btn-block btn-sm" disabled={this.props.disabled} eventid={this.state.eventid} onClick={()=>{this.btnClick(this.props.indexid)}} type="button">{btntext}</button>             
             */}</div>
        );
    }
}
