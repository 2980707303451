import React from 'react';
import '../styles/Styles.css';
//import $ from 'jquery';
import "bootstrap/dist/css/bootstrap.min.css";

  const flashMessage = ( message, type) => {
    switch(type){
			case 'Success':
				return (<div className="alert alert-success" role="alert"><strong>{type}</strong> {message}</div>);
			case 'Failure':
				return (<div className="alert alert-warning" role="alert"><strong>{type}</strong> {message}</div>);
				default:
					return null;
		}
  }

export default flashMessage;
