import React from "react";
import { base_url } from '../constants/Constants';
import axios from 'axios';

export default class AutoCompleteText extends React.Component {
    constructor(props){
		super(props);
		this.state = {
            text:'',
            suggestions:[],
		}

    }

    onTextChanged = ( e ) => {         
        const value = e.target.value;
        let suggestions = [];
        let headers = this.props.header;
        if( value.length > 2 ){         
            axios.get(base_url+this.props.url+value,headers).then(res =>{ 
                suggestions = res.data;
                console.log(suggestions); 
                suggestions = suggestions.filter((item) => {
                    let itemStr = item.id.toString() + item.name.toLowerCase();
                     return itemStr.includes(value.toLowerCase()) !== -1  })         
                this.setState( () => ({ suggestions, text:value }));
            }).catch(function (error) { console.log(error); });           
        }
		this.setState( () => ({ suggestions, text:value }));
    }
    
    suggesetionsSelected(item){
        this.setState(() => ({
            text:item.name,
            suggestions:[],
        }));
        this.props.parentObject.enableEdit(item);
    }
    renderSuggestions(){
        const {suggestions} = this.state;

        if(suggestions.length === 0){ return null}

        return(
            <table>
                <tbody>
                    { suggestions.map((item) => <tr key={item.id} onClick={() => this.suggesetionsSelected(item)}><td>{item.name}</td></tr>) }
                </tbody>
            </table>
        );
       
    }

    render(){
        const {text} = this.state;

        return (
            <div>
                <input className="form-control" value={text} onChange={this.onTextChanged} type="text" /> 
                {this.renderSuggestions()}
            </div>
        );
    }
}
