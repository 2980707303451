////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {Button,Modal} from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import fileDownload from 'js-file-download';
import Uploadfile from '../utils/Uploadfile';
import { base_url } from '../constants/Constants';
import {Marketings } from '../models/Marketings';
import NotesForm from './NotesForm';


class MarketingsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			showactivity:false,
			showEdit : false,
			mytimer : null,
			activityList : [],
			formObj : Marketings,
			eventid : '',
			eventname :'',
			eventdate :'',
		}
		this.onSubmit = this.onSubmit.bind(this);
		this.updateFilename = this.updateFilename.bind(this);
		this.handleDeleteRow = this.handleDeleteRow.bind(this);
		this.addMarketing = this.addMarketing.bind(this);
		this.handleObjectChange=this.handleObjectChange.bind(this);
		this.notesChange=this.notesChange.bind(this);
	}

	componentDidMount(){
		//alert(this.props.eventname);
		axios.get(base_url+'marketings/listbyeventid/'+this.props.eventid,  this.props.user.header )
				.then(res =>{
					if(res.data.length > 0){
					 this.setState({ activityList:res.data });
					}else{
						var temp = {};
						temp={id:1,events_id:this.props.eventid,status:'Active',activity:"Social Media Handles",filename:"",notes:""};
						axios.post(base_url+'marketings/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); });
						temp={id:2,events_id:this.props.eventid,status:'Active',activity:"Pre Event Email",filename:"",notes:""};
						axios.post(base_url+'marketings/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); });
						temp={id:3,events_id:this.props.eventid,status:'Active',activity:"Post Event Email",filename:"",notes:""};
						axios.post(base_url+'marketings/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); });
						temp={id:4,events_id:this.props.eventid,status:'Active',activity:"Digital Signage",filename:"",notes:""};
						axios.post(base_url+'marketings/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); });
						temp={id:5,events_id:this.props.eventid,status:'Active',activity:"Logo",filename:"",notes:""};
						axios.post(base_url+'marketings/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); });
						temp={id:6,events_id:this.props.eventid,status:'Active',activity:"Video",filename:"",notes:""};
						axios.post(base_url+'marketings/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); });
						temp={id:7,events_id:this.props.eventid,status:'Active',activity:"Product Page Links",filename:"",notes:""};
						axios.post(base_url+'marketings/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); });
						temp={id:8,events_id:this.props.eventid,status:'Active',activity:"Marketing PDF",filename:"",notes:""};
						axios.post(base_url+'marketings/save', temp, this.props.user.header ).then(res =>{}).catch(function (error) { console.log(error); });
						temp={id:9,events_id:this.props.eventid,status:'Active',activity:"Images",filename:"",notes:""};
						axios.post(base_url+'marketings/save', temp, this.props.user.header ).then(res =>{
							axios.get(base_url+'marketings/listbyeventid/'+this.props.eventid,  this.props.user.header )
							.then(res =>{
								 this.setState({ activityList:res.data });
								}).catch(function (error) { console.log(error); }); 
						}).catch(function (error) { console.log(error); });
						
					}
					}).catch(function (error) { console.log(error); }); 
	}

	continue = (e) => {
        this.props.parentObject.eventStep(this.props.eventname,this.props.eventdate,this.props.enddate,this.props.eventid); 	
    }
    back = (e) => {
        this.props.parentObject.eventStepBack();
	}
	updateFilename = (filename,index) =>{
		console.log(index);
		let temp=this.state.activityList;
		temp[index].filename=filename;
		this.setState({activityList:temp});
		//let newdoc=temp.document[index];
		let newdoc=temp[index];
		newdoc.status='Active';
		newdoc.events_id=this.props.eventid;
		console.log(newdoc);
		axios.post(base_url+'marketings/save', newdoc, this.props.user.header )
		.then(res =>{
			axios.get(base_url+'marketings/listbyeventid/'+this.props.eventid,  this.props.user.header )
			.then(res =>{
				 this.setState({ activityList:res.data });
				}).catch(function (error) { console.log(error); }); 
		}).catch(function (error) { console.log(error); });

	}
	downloadFile=(url,filename)=>{
		axios.get(base_url+url,  {responseType: 'blob', headers:this.props.header.headers}).then(res => {
		fileDownload(res.data, filename);
		});
	   }
	handleObjectChange = (id, event) => {
		event.preventDefault();
		var bList = this.state.activityList;
		var string = event.target.value;
		bList[id].activity=string.charAt(0).toUpperCase() + string.slice(1);
		this.setState({ formObj:bList}); 
	}
	notesChange = (id, event) => {
		event.preventDefault();
		var bList = this.state.activityList;
		var string = event.target.value;
		bList[id].notes=string.charAt(0).toUpperCase() + string.slice(1);
		this.setState({ formObj:bList}); 
	}
	handleDeleteRow = (i) => {
		let newform=this.state.activityList[i];
		newform.status='Inctive';
		newform.events_id=this.props.eventid;
		axios.post(base_url+'marketings/save', newform, this.props.user.header )
		.then(res =>{
			axios.get(base_url+'marketings/listbyeventid/'+this.props.eventid,  this.props.user.header )
			.then(res =>{
				 this.setState({ activityList:res.data });
				}).catch(function (error) { console.log(error); }); 
		}).catch(function (error) { console.log(error); });
	}
	addMarketing =()=>{
	var doc=this.state.activityList;
	var count=0;
	if(doc.length >0){
		var  max = Math.max.apply(null,  doc.map(item => item.id));
		count=max+1;
		doc.push({id: count,activity:"",filename:"",notes:""});
	}else{
		count=1;
		doc=[];
		doc.push({id: count,activity:"",filename:"",notes:""});
	}
	this.setState({activityList:doc});

	}
	
	render_Marketinglist(){
		if( this.state.activityList != undefined && this.state.activityList.length > 0){
		return this.state.activityList.map((item, index) =>{
			let temp='file/download/'+item.filename+'?id='+this.props.user.accountid;
			var display="invisiblefollow label-custom";
			if(item.activity === ""){
				display="bobjective label-custom";
			} 
		  return (
			   <tr key={index} id={index}> 
			   <td className={display}><input type="text" name="activity" id={index} value={item.activity}  onChange={(e)=>{this.handleObjectChange(index, e)}} onBlur={()=>{this.onSubmit(index)}} placeholder="Type In Activity List"></input></td>
			   <td className={display}><input type="text" name="notes"  value={item.notes}  onChange={(e)=>{this.notesChange(index, e)}} onBlur={()=>{this.onSubmit(index)}} placeholder="Type In Notes"></input></td>
			   <td><dd className="cursorcolor" onClick={()=>{this.downloadFile(temp,item.filename)}}>{item.filename}</dd></td>
			   <td className="budgettotal"><span>&nbsp;&nbsp;<FaTrash size="15px" color="red" onClick={()=>{this.handleDeleteRow(index)}} />&nbsp;&nbsp;</span>
			   <Uploadfile parentObject={this}  header={this.props.header} eventid={this.props.eventid} folder='docs' subfolder={this.props.user.accountid} indexid={index} uploadtext="Upload" /> 
			   </td> 
		   </tr>
		  ) 
		   }, this);
		 }
	  
	  }
	
	
	render_formButton(){
        if(this.props.eventAction === 'edit'){
            return(
				<div className="d-flex justify-content-end row">
				 <div className="p-2">
				 <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()}>Back</button>
				  </div>
				 <div className="p-2">
				 <button type="button" className="btn btn-primary btn-block" onClick={()=>{this.continue()}}>Next</button>
				</div>
				</div>
   
            )
        }else{
            return(
               <div className="d-flex justify-content-end row">
                <div className="p-2">
                <button type="button" className="btn btn-secondary btn-block" onClick={() =>this.back()}>Back</button>
                 </div>
                <div className="p-2">
                <button type="button" className="btn btn-primary btn-block" onClick={()=>{this.continue()}}>Next</button>
               </div>
               </div>
   
            )
        }
	}

	onSubmit(i){
		  let temp=this.state.activityList[i];
		    temp.status='Active';
			temp.events_id=this.props.eventid;
			console.log(temp)
			axios.post(base_url+'marketings/save', temp, this.props.user.header )
			.then(res =>{
				axios.get(base_url+'marketings/listbyeventid/'+this.props.eventid,  this.props.user.header )
				.then(res =>{
					 this.setState({ activityList:res.data });
					}).catch(function (error) { console.log(error); }); 
			}).catch(function (error) { console.log(error); });
	}

	render() {
		 return (
			<div id="idmarketings" className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="d-flex row">
               <div className="p-2 flex-fill">
				   <label className="label-custom">Add Your Event Marketing Activations</label>
				<table id='data' className="table table-sm table-responsive-stack">
			      <thead className="thead"><tr>
					  <td>Marketing Activations</td>
					  <td>Notes</td>
					  <td></td>
					  <td></td>
				  </tr></thead>
				  <tbody>{this.render_Marketinglist()}</tbody>
				  </table>  
			<div className="p-2 col-md-12">	  
			 <div className="d-flex justify-content-end row">
              <div className="p-2">
                  <button type="button" className="btn btn-primary btn-block btn-sm" onClick={()=>{this.addMarketing()}}>+Add</button>
              </div>
            </div> 
			</div> 
                </div>
            </div>
			<div className="d-flex row">
			   <div className="p-2 col-md-12">
                 <NotesForm parentObject={this}  header={this.props.header} eventid={this.props.eventid} stepname='marketings'/> 
                </div> 
			</div>
		
		    <div className="d-flex row">
                <div className="col-md-12">  
                {this.render_formButton()}
                </div>
            </div>
		
     </div>
		)}
		
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(MarketingsForm);
