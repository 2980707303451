///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';

const Header = () => {
return (
	<div>
      <title>ExhibitorPro</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  </div>
	)
}
export default Header