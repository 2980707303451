////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { base_url } from '../constants/Constants';
import {Notes } from '../models/Notes';
import '../styles/Styles.css';

class NotesForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			mytimer : null,
			formObj : Notes,
		}
        this.onSubmit = this.onSubmit.bind(this);
        this.handleNotes = this.handleNotes.bind(this);	
	}

	componentDidMount(){
		axios.post(base_url+'notes/listnotes',{eventid:this.props.eventid, stepname:this.props.stepname },  this.props.user.header )
		.then(res =>{ 
			if(res.data.length > 0){  
				console.log(res.data);         
			this.setState({ formObj:res.data[0] });
			}else{
				let temp=this.state.formObj;
				temp.note='';
				this.setState({formObj:temp});
			}
		}).catch(function (error) { console.log(error); }); 
	
    }

    handleNotes = (e) =>{
		clearTimeout(this.state.mytimer);
        let newformObj=this.state.formObj;
		newformObj.note=e.target.value;
		var myVar = setTimeout(this.onSubmit, 1000);
		this.setState({ formObj:newformObj, mytimer:myVar });
	}

	onSubmit(){
		let newform=this.state.formObj;
		newform.status='Active';
        newform.events_id=this.props.eventid;
        newform.stepname=this.props.stepname;
	    this.setState({formObj:newform});
		axios.post(base_url+'notes/save', newform, this.props.user.header )
		.then(res =>{
				console.log(res);
				axios.post(base_url+'notes/listnotes',{eventid:this.props.eventid, stepname:this.props.stepname },  this.props.user.header )
				.then(res =>{
						this.setState({ formObj:res.data});
				}).catch(function (error) { console.log(error); }); 
		}).catch(function (error) { console.log(error); });
	}

	render() {
		let note_id = this.props.stepname;
	 return (
			<div id="idnotes">
				<div className="brdcolor" >
                <div className="d-flex row">
                        <div className="form-group col-md-12">
                        <label htmlFor="notes" className="label-custom">Add Notes</label>
                        <textarea rows="1" cols="10" className="form-control" id={note_id} name="note" type="text" value={this.state.formObj.note} onChange={(e)=>{this.handleNotes(e)}} />
                        </div>
                   </div> 
				 </div>    
		  </div>		
		)}
	}


const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(NotesForm);
