import React, { Component } from 'react'
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Exhibitorlogo from '../assets/exhibitor_logo.png';
import { FaUserCircle,FaPlus,FaFilePdf } from 'react-icons/fa';
import Creditcards, { CreditcardsForm }  from './CreditcardsForm';
import { TransactionsForm } from './TransactionsForm';

export class Accounts extends Component {

    constructor(props){
        super(props);
          this.state = {
            formObj:Creditcards,
            checkadmin: false,
          };  
        
    }
    componentDidMount(){
        axios.post(base_url+'creditcards/cardsdetails/',{accountid: this.props.user.accountid}, this.props.user.header )
            .then(res =>{
				console.log(res);
           this.setState({formObj:res.data[0]});
         }).catch(function (error) { console.log(error); }); 

         axios.post(base_url+'users/userid/',{accountid: this.props.user.accountid}, this.props.user.header )
         .then(res =>{
             console.log(res.data[0].type);
             if(res.data[0].type === 'admin'){
                 this.setState({checkadmin:true});
             }
             this.setState({userObj:res.data[0]});
      }).catch(function (error) { console.log(error); });   

    }
    gotoAccounts = () => {
		this.props.history.push('/app/account'); 
	}
	gotoAdmin = () => {
		this.props.history.push('/app/admin'); 
	}
	backToLogout = () => {
		this.props.history.push('/logout');
	}
    gotoProfile = () => {
		this.props.history.push('/app/profile/?id='+this.props.user.accountid); 
	}
    render() {
        return (
            <div className="container-fluid">
            <br/>
            <div className="d-flex row">
                <div className="p-2 col-md-3"><NavLink to="/app/dashboard"><img  src={Exhibitorlogo} alt="Logo" /></NavLink></div>
                <div className="p-2 eventalign col-md-6"><div className="pluscolor"><b>Account Details</b></div></div>
                <div className="p-2 flex-grow-1"></div>
                <div className="p-2 dropdown"><span id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">< FaUserCircle size="35px"/></span>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
		    	{this.state.checkadmin?<span  className="dropdown-item" onClick={() =>this.gotoAdmin()}><span>Admin</span></span>:null}
			    <span  className="dropdown-item" onClick={() =>this.gotoAccounts()}><span>Account</span></span>
				<span  className="dropdown-item" onClick={() =>this.gotoProfile()}><span >Profile</span></span>
				<div className="dropdown-divider"></div>
				<span className="dropdown-item" onClick={() =>this.backToLogout()} >Logout</span>
			</div>
            </div>
           </div>
            <hr/> 
            <div className="d-flex row">
            <div className="p-2 col-md-12"><CreditcardsForm parentObject={this}  header={this.props.user.header} accountid={this.props.user.accountid} /></div>
            </div>
            <div className="d-flex row">
            <div className="p-2 col-md-12"><TransactionsForm parentObject={this}  header={this.props.user.header} accountid={this.props.user.accountid} /></div>
            </div>

           
         </div>
        )
    }
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Accounts);
