import React, {Component} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import { base_url } from '../constants/Constants';
import flashMessage from '../utils/Flash';
import { Users } from '../models/Users';
import { Validators } from '../utils/Validators';
import FlashMessage from 'react-flash-message';
import Exhibitorlogo from '../assets/exhibitor_logo.png';
import '../styles/Styles.css';
import FloatingLabel from "floating-label-react";
import { Creditcards } from '../models/Creditcards';

export default class Signup extends Component {
  constructor(props){
    super(props);
      this.state = { 
        formObj:Users, 
        cardsObj:Creditcards,          
        showSuccessMsg : false,      	
        message:'',
        msgType:'',
        yearly:false,
        monthly:true,
        firstpage:true,
        nextpage1:false,
        nextpage2:false,
        nextpage3:false,
        nextpage4:false,
        nextpage5:false,
        nextpage6:false,
        showRegistration:true,
        accountid:'',
        emailverificationcode:'',
        declinemsg:'',
        validations:{},
        cardvalidations:{},
      }; 
      this.changeUser = this.changeUser.bind(this);
      this.validate = this.validate.bind(this);
      this.getFormattedPhoneNum = this.getFormattedPhoneNum.bind(this);
    }
    componentDidMount(){
      //console.log(this.props.headers);
      var cc="4242424242424242";
      cc=cc.replace(/\W/gi, '').replace(/^(.{4})(.{4})(.{4})(.*)$/, "$1 $2 $3 $4");
      cc = cc.replace(/\d{4}(?= \d{4})/g, "XXXX");
      //console.log(cc);

    let validtemp={};
		for (let key in Users) { validtemp[key] = ""; }
    this.setState({validations:validtemp});

    let tempForm={};
    for (let key in Users) { tempForm[key] = ""; }
    this.setState({formObj:tempForm});

    let validtemp1={};
		for (let key in Creditcards) { validtemp1[key] = ""; }
    this.setState({cardvalidations:validtemp1});

    let tempForm1={};
    for (let key in Creditcards) { tempForm1[key] = ""; }
    this.setState({ cardsObj:tempForm1});

    /* axios.post(base_url+'creditcards/listby/',{_id: '601e475d760a572e8c589e30'})
    .then(res =>{
    console.log(res);
    }).catch(function (error) { 
      console.log(error);        
    }); 
     */
    axios.get(base_url+'users/list/')
    .then(res =>{
    //console.log(res);
    }).catch(function (error) { 
      console.log(error);        
    }); 

  }


  backToLogin = () => {
      this.props.history.push('/login');
  }
  changeUser = (val,e) =>{
		let tempform = this.state.formObj;
		switch(val){
			case "firstname": {var string = e.target.value; tempform.firstname = string.charAt(0).toUpperCase() + string.slice(1); } break;
			case "lastname": { var string = e.target.value; tempform.lastname =string.charAt(0).toUpperCase() + string.slice(1);} break;
			case "company": { var string = e.target.value; tempform.company = string.charAt(0).toUpperCase() + string.slice(1); } break;
			case "phone": { tempform.phone = e.target.value; } break; 
      case "email": { tempform.email = e.target.value; } break; 
      case "password": { tempform.password = e.target.value; } break; 
      case "confirmpwd": { tempform.confirmpwd = e.target.value; } break;
			case "address1": { var string = e.target.value; tempform.address1 =string.charAt(0).toUpperCase() + string.slice(1); } break; 
			case "address2": { var string = e.target.value; tempform.address2 = string.charAt(0).toUpperCase() + string.slice(1); } break; 
			case "city": {var string = e.target.value; tempform.city = string.charAt(0).toUpperCase() + string.slice(1); } break; 
			case "state": {var string = e.target.value; tempform.state = string.charAt(0).toUpperCase() + string.slice(1); } break; 
			case "country": { tempform.country = e.target.value; } break; 
			case "zip": { tempform.zip = e.target.value; } break; 
		/* 	case "cardname": { tempform.cardname = e.target.value; } break; 
			case "cardnumber": { tempform.cardnumber = e.target.value; } break; 
			case "cvc": { tempform.cvc = e.target.value; } break; 
			case "expmonth": { tempform.expmonth = e.target.value; } break; 
			case "expyear": { tempform.expyear= e.target.value; } break;  */
		}

		this.setState({formObj:tempform});
  }
  cardsDetailsChange =(val,e) =>{
    let cardsform=this.state.cardsObj;
    switch(val){
      case "cardname": { var string = e.target.value; cardsform.cardname = string.charAt(0).toUpperCase() + string.slice(1); } break; 
			case "cardnumber": { cardsform.cardnumber = e.target.value; } break; 
			case "cvc": { cardsform.cvc = e.target.value; } break; 
			case "expmonth": { cardsform.expmonth = e.target.value; } break; 
      case "expyear": { cardsform.expyear= e.target.value; } break; 
    }
    this.setState({cardsObj:cardsform});
  }
  
  validate = (val) =>{
		let tempval = this.state.validations;
		switch(val){
			case "firstname": { tempval.firstname =Validators.name(this.state.formObj.firstname); } break;
			case "lastname": { tempval.lastname = Validators.name(this.state.formObj.lastname); } break;
			case "company": { tempval.company = Validators.name(this.state.formObj.company); } break;
			case "phone": { tempval.phone = Validators.phone(this.state.formObj.phone); } break; 
      case "email": {tempval.email = Validators.email(this.state.formObj.email);
        if(tempval.email == ""){
        var emailObj = {email:this.state.formObj.email};
                axios.post(base_url+'main/emailverify', emailObj)        
                .then(res => {
                  if(res.data.msg === 'success'){   
                    tempval.email='The email address you have entered is already associated with another account'; 
                    this.setState({validations:tempval});
                  }                 
                }).catch(function (error) { 
                console.log(error);        
              }); 
            }      
       } break; 
      case "password": { tempval.password = Validators.password(this.state.formObj.password);
        if(tempval.password == ""){
          var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
          if(this.state.formObj.password.match(passw)){
            tempval.password ="";
            this.setState({validations:tempval});
          }else{
            tempval.password ='Password should be 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter';
            this.setState({validations:tempval});
          }
        }
       } break; 
      case "confirmpwd": { tempval.confirmpwd = Validators.password(this.state.formObj.confirmpwd);
        if(tempval.confirmpwd== ""){
          if(this.state.formObj.password == ''){
           tempval.confirmpwd="Please fill the password field first.";
           this.setState({validations:tempval});
          }else{
            if(this.state.formObj.password !== this.state.formObj.confirmpwd){
              tempval.confirmpwd="Password and confirm password is not matching";
              this.setState({validations:tempval});
            }
          }
        } } break;
			case "address1": { tempval.address1 = Validators.name(this.state.formObj.address1); } break; 
		//	case "address2": { tempval.address2 = Validators.name(this.state.formObj.address2); } break; 
			case "city": { tempval.city = Validators.name(this.state.formObj.city); } break; 
			case "state": { tempval.state = Validators.state(this.state.formObj.state); } break; 
			case "country": { tempval.country = Validators.name(this.state.formObj.country); } break; 
			case "zip": { tempval.zip = Validators.zipcode(this.state.formObj.zip); } break; 
		}
		this.setState({validations:tempval});
  }
  validatecards=(val) =>{
    let tempval = this.state.cardvalidations;
    
    switch(val){

  	case "cardname": { tempval.cardname = Validators.name(this.state.cardsObj.cardname); } break; 
    case "cardnumber": { tempval.cardnumber = Validators.cardnumber(this.state.cardsObj.cardnumber); } break; 
    case "cvc": { tempval.cvc = Validators.cvc(this.state.cardsObj.cvc); } break; 
   // case "amount": { tempval.amount = Validators.cvc(this.state.cardsObj.amount); } break; 
    case "expmonth": { tempval.expmonth = Validators.month(this.state.cardsObj.expmonth); 
      
    
      if(tempval.expmonth ==""){

        if(this.state.cardsObj.expyear == ""){
          tempval.expyear="Please fill the Expiration Year.";
          this.setState({cardvalidations:tempval});
        }else{
        var today, someday;
        var exMonth=this.state.cardsObj.expmonth;
        var exYear=this.state.cardsObj.expyear;
        today = new Date();
        someday = new Date();
        someday.setFullYear(exYear, exMonth, 1);

        if (someday < today) {
          tempval.expyear="The expiry date is before today's date. Please select a valid expiry date";
          this.setState({validations:tempval});
        }
      }
      }} break; 
    case "expyear": { tempval.expyear= Validators.year(this.state.cardsObj.expyear);

    if(tempval.expyear =="" ){

      if(this.state.cardsObj.expmonth == ""){
        tempval.expmonth="Please fill the Expiration Month.";
        this.setState({cardvalidations:tempval});
      }else{
      var today, someday;
      var exMonth=this.state.cardsObj.expmonth;
      var exYear=this.state.cardsObj.expyear;
      today = new Date();
      someday = new Date();
      someday.setFullYear(exYear, exMonth, 1);

      if (someday < today) {
        tempval.expyear="The expiry date is before today's date. Please select a valid expiry date";
        this.setState({cardvalidations:tempval});
      }
    }
    }
    } break; 
  }
	this.setState({cardvalidations:tempval});
  }
  resetAll= () =>{
    this.setState({
      nextpage1:false,nextpage2:false,nextpage3:false,nextpage4:false,showRegistration:false,showSuccessMsg : false,
    nextpage5:false,nextpage6:false});
  }
  showSuccessMsg=()=>{
    this.resetAll();
    this.setState({showSuccessMsg:true});
  }
  
  backToLogin = () => {
      this.props.history.push('/login');      
  }
  backToSignup=()=>{
    this.resetAll();
    this.setState({declinemsg:"", nextpage2:true});
    this.props.history.push('/signup');      

  }

  onClickYearly=(e)=>{
    this.setState({yearly:true});
    }
 
 subscriptionType(){
  var tempcard=this.state.cardvalidations;
     
   if(this.state.monthly == true){
    var temp=this.state.cardsObj;
    temp.amount="35.00";
    console.log(temp.amount);
    tempcard.amount="";
    temp.subscription_type ="Monthly Subscription";
    this.setState({cardsObj:temp});
  }else{
    var temp=this.state.cardsObj;
    temp.amount="";
    temp.subscription_type =""; 
    tempcard.amount="Please Select Subscription Type";
    this.setState({cardsObj:temp});
    }

     this.setState({cardvalidations:tempcard});

  
 }
  nextPage1 = () =>{ 
  let temp='';
  for (let key in Users) { this.validate(key);}  
  let tempnext=this.state.validations;
  tempnext.address1="";
  tempnext.address2="";
  tempnext.city="";
  tempnext.state="";
  tempnext.zip="";
 /*  tempnext.cardname="";
  tempnext.cardnumber="";
  tempnext.expmonth="";
  tempnext.expyear="";
  tempnext.cvc=""; */
  this.setState({validations:tempnext});
  for (let key in this.state.validations) { temp += this.state.validations[key];} 
	if(temp == ""){
        this.resetAll();
       this.setState({nextpage1:true});
  }
 
}
  nextPage2=()=>{
    let temp='';
  for (let key in Users) { this.validate(key);}  
  for (let key in this.state.validations) { temp += this.state.validations[key];} 
	if(temp == ""){
          this.resetAll();
          this.setState({nextpage2:true});
  }
  
  }
  nextPage3=()=>{
    let temp='';
  for (let key in Creditcards) { this.validatecards(key);}  
  for (let key in this.state.cardvalidations) { temp += this.state.cardvalidations[key];} 
	if(temp == ""){
     this.resetAll();
    this.setState({nextpage3:true});
          
  }
  
  }
  nextPage4=()=>{

    this.resetAll();
    this.setState({nextpage4:true});
    this.backToLogin();

  }
  nextPage5=()=>{
    this.resetAll();
    this.setState({nextpage5:true});
  }
  nextPage6=()=>{
    this.resetAll();
    this.setState({nextpage6:true});
  }
getFormattedPhoneNum = () =>{
  let phnnum=this.state.formObj.phone;
  var pattern = new RegExp(/^[0-9\b]+$/);
  if(phnnum !== null && phnnum !== ''){ 
     let output = "(";
     phnnum.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
   {
     if ( g1.length ) {
     output += g1;
     if ( g1.length == 3 ) {
       output += ")";
       if ( g2.length ) {
         output += " " + g2; 
         if ( g2.length == 3 ) {
           output += "-";
           if ( g3.length ) {
             output += g3;
           }
         }
       }
      }
     }
   }   
   );        
 let newform=this.state.formObj;
 newform.phone=output;
    
  } 
}   
 


  saveRegistration = () => {
    this.subscriptionType();    
    let temp='';
    for (let key in Creditcards) { this.validatecards(key);}  
    for (let key in this.state.cardvalidations) { temp += this.state.cardvalidations[key];} 
    if(temp == "" ){
      this.nextPage6();

      var date=new Date();
      date.setDate(date.getDate()+13);
      //date.setMonth(date.getMonth()-1);
      var y=date.getFullYear();
      var d=date.getDate();
      var m=date.getMonth()+1;
      var dt= (m <= 9 ? '0' + m : m) + '-' +(d <= 9 ? '0' + d : d) + '-' + y;
    
         var formobj = this.state.formObj; 
        // formobj.emailverificationcode=this.state.emailverificationcode;
         //console.log(formobj.emailverificationcode);

         var newcardsobj = this.state.cardsObj; 
         var cardencoded = btoa(JSON.stringify(newcardsobj));
         console.log(cardencoded);
       //  newcardsobj.card = cardencoded;
        // newcardsobj.status="Active";

        // newcardsobj.accountid=this.state.accountid;
         newcardsobj.charging_date=dt;
         
         var cardobj = this.state.cardsObj;
         cardobj.status="Active";
         var encoded = btoa(JSON.stringify(cardobj));
         console.log(encoded);
       //  cardobj.accountid=this.state.accountid;

        axios.post(base_url+'creditcards/verifycreditcard', {card:encoded}) 
         .then(res => {
             console.log(res);
             if(res.data.status === 'success'){ 
               this.nextPage6();
                 var formobj = this.state.formObj; 
                  formobj.status='Inactive';
                  formobj.charging_date =dt;
                  console.log(this.state.formobj);
                  axios.post(base_url+'main/signup', formobj)
                    .then(res => {
                      this.setState({accountid:res.data.data,emailverificationcode:res.data.verificationcode});
                      if(res.data.status=== "Success"){
                          // newcardsobj.accountid=this.state.accountid;
                          console.log(cardencoded);
                           var cardstl={card: cardencoded ,cardstatus:"Primary",status: "Active",accountid: this.state.accountid,charging_date: dt};
                            axios.post(base_url+'creditcards/save', cardstl )
                            .then(res =>{
                   
                             if(res.data.status === 'success'){ 
                                  //var newform={description:this.state.cardsObj.subscription_type,accountid:this.state.accountid,transaction_type:'card',amount:this.state.cardsObj.amount, cardnumber:this.state.cardsObj.cardnumber,status:"success"}
                                 /*  axios.post(base_url+'transactions/save', newform )
                                    .then(res =>{ */
                                        // console.log(res.data);
                                       // if(res.data.result === "success"){
                                           var formobj = this.state.formObj; 
                                           formobj.emailverificationcode=this.state.emailverificationcode;
                                           //console.log(formobj.emailverificationcode);
                                            axios.post(base_url+'main/sendactivationemail',formobj)
                                            .then(res =>{
                                              console.log(res);
                                                this.resetAll();           
                                                this.setState({nextpage4:true});
                                        }).catch(function (error) { console.log(error); });  
                                        
                                     /*    }else{
                                            this.resetAll(); 
                                            //console.log("page5 card details")          
                                            this.setState({nextpage5:true});
                                          }   */    
                           // }).catch(function (error) { console.log(error); }); 
                          }else{
                            this.resetAll();           
                            this.setState({nextpage2:true});
                        }                  
                       }).catch(function (error) { console.log(error);});        
                  }else{
                     this.resetAll(); 
                     this.setState({nextpage1:true});
                  }     
                 }).catch(function (error) {console.log(error);});   
            }else{
            console.log(res.data.msg.raw.message);
            this.setState({declinemsg:res.data.msg.raw.message});
           this.nextPage5();
          } 
        }).catch(function (error) {
          console.log(error);
        });   
      
       }   
     
}
  render() {
    return (
      <div className="container-fluid">
       <div className="brdcolor" >
        <br/><br/><br/>                     
          {this.state.showRegistration ?
          <div>               
              <div className="d-flex justify-content-center">
               {this.state.showSuccessMsg ?
                  <div>
                      <FlashMessage duration={5000} persistOnHover={true}>
                      {flashMessage(this.state.message, this.state.msgType)}
                      </FlashMessage>	
                      <br/><br/>
                  </div>
                  :null
                  }
              </div>  
              <div className="d-flex justify-content-center">
                <span   onClick={() =>this.backToLogin()}><img  src={Exhibitorlogo} alt="Logo" /></span>
                </div><br/>
             <div className="d-flex justify-content-center">
                <span className="wlcmcolor"><b>Become an ExhibitorPro.</b></span>
              </div><br/>	<br/>                        
              <div className="d-flex justify-content-center">
                  <div className="p-2 col-md-4">                       
                      <input type="text" id="firstname" name="firstname" value={this.state.formObj.firstname} placeholder="First Name" required onChange={(e)=>{this.changeUser("firstname",e)}}  onBlur={()=>{ this.validate("firstname")}} className="form-control " style={this.state.validations.firstname==""?{}:{"borderColor":"red"}} required   />
					          	<span className="errormsg">{this.state.validations.firstname}</span>
                  </div>
               </div>
               <div className="d-flex justify-content-center">
                <div className="p-2 col-md-4">                       
                    <input type="text"  id="lastname" name="lastname" value={this.state.formObj.lastname}  placeholder="Last Name" required onChange={(e)=>{this.changeUser("lastname",e)}}  onBlur={()=>{ this.validate("lastname")}} className="form-control " style={this.state.validations.lastname==""?{}:{"borderColor":"red"}} required   />
					        	<span className="errormsg">{this.state.validations.lastname}</span>
                </div>
               </div>
               <div className="d-flex justify-content-center">
                <div className="p-2 col-md-4">                      
                  <input type="text"  id="company" name="company" value={this.state.formObj.company} placeholder="Company Name"  required onChange={(e)=>{this.changeUser("company",e)}} onBlur={()=>{ this.validate("company")}} className="form-control" style={this.state.validations.company==""?{}:{"borderColor":"red"}} required   />
					      	<span className="errormsg">{this.state.validations.company}</span>
                  </div>
                 </div>
                  <div className="d-flex justify-content-center">
                     <div className="p-2 col-md-4">                      
                       <input type="text"  id="email" name="email" value={this.state.formObj.email} placeholder="Work Email"  required onChange={(e)=>{this.changeUser("email",e)}} onBlur={()=>{ this.validate("email")}} className="form-control" style={this.state.validations.email==""?{}:{"borderColor":"red"}} required   />
				            	 <span className="errormsg">{this.state.validations.email}</span>
                     </div>
                  </div>
                  <div className="d-flex justify-content-center">
                      <div className="p-2 col-md-4">                       
                          <input type="text"  id="phone" name="phone" value={this.state.formObj.phone}  placeholder="Phone" onKeyUp={()=>{this.getFormattedPhoneNum()}} onChange={(e)=>{this.changeUser("phone",e)}} onBlur={()=>{ this.validate("phone")}} className="form-control" style={this.state.validations.phone==""?{}:{"borderColor":"red"}} required   />
					               	<span className="errormsg">{this.state.validations.phone}</span>
                      </div>
                  </div>
                  <div className="d-flex justify-content-center">
                      <div className="p-2 col-md-2">                      
                        <input type="password"  id="password" name="password" value={this.state.formObj.password} placeholder="Create Password"  onChange={(e)=>{this.changeUser("password",e)}}  onBlur={()=>{ this.validate("password")}} className="form-control" style={this.state.validations.password==""?{}:{"borderColor":"red"}} required   />
				            		<span className="errormsg">{this.state.validations.password}</span>
                      </div>
                      <div className="p-2 col-md-2">                      
                        <input type="password"  id="confirmpwd" name="confirmpwd" value={this.state.formObj.confirmpwd} placeholder="Confirm Password" onChange={(e)=>{this.changeUser("confirmpwd",e)}} onBlur={()=>{ this.validate("confirmpwd")}} className="form-control" style={this.state.validations.confirmpwd==""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.confirmpwd}</span>
                      </div>
                 </div>
                 <br/> 
                 <div className="d-flex justify-content-center"> 
                  <div className="p-2 col-md-4">                          
                    <button type="button"  className="btn btn-primary btn-block" onClick={() =>this.nextPage1()}> Next</button>                         
                  </div>
                </div>           
          </div>
           :        
          null
         }       
       <div>{this.state.nextpage1?<div>
             <div className="d-flex justify-content-center">
                <span   onClick={() =>this.backToLogin()}><img  src={Exhibitorlogo} alt="Logo" /></span>
                </div><br/>

             <div className="d-flex justify-content-center">
               <span className="wlcmcolor">Become an ExhibitorPro.</span>
              </div>	<br/><br/>
              <div className="d-flex justify-content-center">
                <div className="p-2 col-md-3">                       
                  <input type="text"  id="address1" name="address1" value={this.state.formObj.address1} placeholder="Address1" onChange={(e)=>{this.changeUser("address1",e)}} onBlur={()=>{ this.validate("address1")}} className="form-control " style={this.state.validations.address1==""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.address1}</span>    
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="p-2 col-md-3">                       
                  <input type="text"  id="address2" name="address2" value={this.state.formObj.address2} placeholder="Address2" onChange={(e)=>{this.changeUser("address2",e)}}  className="form-control"  required   />    
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="p-2 col-md-1">                       
                  <input type="text"  id="city" name="city" value={this.state.formObj.city} placeholder="City" onChange={(e)=>{this.changeUser("city",e)}} onBlur={()=>{ this.validate("city")}} className="form-control" style={this.state.validations.city==""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.city}</span>    
                </div>              
                <div className="p-2 col-md-1">                       
                  <input type="text"  id="state" name="state" value={this.state.formObj.state} placeholder="State" onChange={(e)=>{this.changeUser("state",e)}} onBlur={()=>{ this.validate("state")}} className="form-control" style={this.state.validations.state==""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.state}</span>    
                </div>
                <div className="p-2 col-md-1">                       
                  <input type="text"  id="zip" name="zip" value={this.state.formObj.zip}  min="1" max="6" placeholder="Zipcode" onChange={(e)=>{this.changeUser("zip",e)}} onBlur={()=>{ this.validate("zip")}} className="form-control" style={this.state.validations.zip==""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.zip}</span>    
                </div>
              </div><br/>
              <div className="d-flex justify-content-center"> 
                  <div className="p-2 col-md-3">                         
                    <button type="button"  className="btn btn-primary btn-block " onClick={() =>this.nextPage2()} >Next</button>                         
                  </div>
                </div> 
        </div>
        :null} </div> 
       <div> {this.state.nextpage2?<div>
        <div className="d-flex justify-content-center">
          <span onClick={() =>this.backToLogin()}><img  src={Exhibitorlogo} alt="Logo" /></span>
        </div><br/>
        <div className="d-flex justify-content-center">
          <span className="wlcmcolor">Become an ExhibitorPro.</span>
        </div><br/><br/>
       < div className="d-flex justify-content-center">
              <div className="p-2 col-md-3">                       
                <input type="text"  id="cardname" name="cardname" value={this.state.cardsObj.cardname} placeholder="Name on The Card" required onChange={(e)=>{this.cardsDetailsChange("cardname",e)}} onBlur={()=>{ this.validatecards("cardname")}} className="form-control" style={this.state.cardvalidations.cardname==""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.cardvalidations.cardname}</span>   
              </div>
       </div>
       <div className="d-flex justify-content-center">
              <div className="p-2 col-md-3">                       
                <input type="number"  id="cardnumber" name="cardnumber"  min="1" max="16" value={this.state.cardsObj.cardnumber} placeholder="Card Number"  onChange={(e)=>{this.cardsDetailsChange("cardnumber",e)}} onBlur={()=>{ this.validatecards("cardnumber")}} className="form-control" style={this.state.cardvalidations.cardnumber==""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.cardvalidations.cardnumber}</span>    
              </div>
        </div>
        <div className="d-flex justify-content-center">
              <div className="p-2 col-md-1">                       
                <input type="number"  id="expmonth" name="expmonth" value={this.state.cardsObj.expmonth}  min="1" max="2" placeholder="Exp Month" onChange={(e)=>{this.cardsDetailsChange("expmonth",e)}} onBlur={()=>{ this.validatecards("expmonth")}} className="form-control" style={this.state.cardvalidations.expmonth==""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.cardvalidations.expmonth}</span>     
              </div>
              <div className="p-2 col-md-1">                       
                <input type="number"  id="expyear" name="expyear" value={this.state.cardsObj.expyear}  min="1" max="4"placeholder="Exp Year" onChange={(e)=>{this.cardsDetailsChange("expyear",e)}}  onBlur={()=>{ this.validatecards("expyear")}} className="form-control" style={this.state.cardvalidations.expyear==""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.cardvalidations.expyear}</span> 
              </div>
              <div className="p-2 col-md-1">                       
                <input type="number"  id="cvc" name="cvc" value={this.state.cardsObj.cvc}  min="1" max="4" placeholder="CVC" required onChange={(e)=>{this.cardsDetailsChange("cvc",e)}} onBlur={()=>{ this.validatecards("cvc")}} className="form-control" style={this.state.cardvalidations.cvc==""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.cardvalidations.cvc}</span> 
              </div>
        </div><br/> 
           <div className="d-flex justify-content-center"> 
                  <div className="p-2 col-md-3">                          
                  <button type="button"  className="btn btn-primary btn-block " onClick={() =>this.nextPage3()}>Next</button>                         
                  </div>
                </div> 
        </div>
        :
        null
        }</div>
        <div>{this.state.nextpage3?<div>
          <div className="d-flex justify-content-center">
          <span onClick={() =>this.backToLogin()}><img  src={Exhibitorlogo} alt="Logo" /></span>
         </div><br/>
         <div className="d-flex justify-content-center">
          <span className="wlcmcolor">Become an ExhibitorPro.</span>
         </div><br/><br/>
         <div className="d-flex justify-content-center">
         <div className="p-2">
          <label className="form-check-label" htmlFor="radio1">
            <input type="radio" className="form-check-input" id="radio1" name="optradio" value="monthly" checked={this.state.monthly} onChange={(e)=>{this.onClickYearly(e)}}/><span className="textblue">Monthly Subscription - $35.00</span>
          </label>
        </div>
        </div><br/>
      
      
       
          <div className="d-flex justify-content-center"> 
                  <div className="p-2 col-md-2">                          
                  <button type="button"  className="btn btn-primary btn-block " onClick={() =>this.saveRegistration()}>Next</button>                         
                  </div>
                </div> 
        </div>:null}</div>

        <div>{this.state.nextpage4?<div>
          <div className="d-flex justify-content-center">
          <span onClick={() =>this.backToLogin()}><img  src={Exhibitorlogo} alt="Logo" /></span>
         </div><br/>
         <div className="d-flex justify-content-center">
          <span className="wlcmcolor">Become an ExhibitorPro.</span>
         </div><br/><br/>
         <div className="d-flex justify-content-center">
           <div className="p-2 col-md-6">
             <span className="textgreen">Go to your email to complete Activation. Please check your Spam or Trash folder if you do not see in your inbox.</span></div><br/>
           </div>      
           <br/>
    <div className="d-flex justify-content-center"> 
                  <div className="p-2 col-md-3">                          
                  <button type="button"  className="btn btn-primary btn-block " onClick={() =>this.nextPage4()}>Get Started</button>                         
                  </div>
                </div> 
        </div>:null}</div>

        <div>{this.state.nextpage5?<div>
          <div className="d-flex justify-content-center">
          <span onClick={() =>this.backToLogin()}><img  src={Exhibitorlogo} alt="Logo" /></span>
         </div><br/>
         <div className="d-flex justify-content-center">
          <span className="wlcmcolor">Become an ExhibitorPro.</span>
         </div><br/><br/>
         <div className="d-flex justify-content-center"><span style={{color:"red"}}>{this.state.declinemsg}</span></div><br/>
         <div className="d-flex justify-content-center">
           <h6 className="textblue"> Please Enter the Correct Card Details to Continue Signup your Account...</h6>
          </div><br/>
          <div className="d-flex justify-content-center"> 
                  <div className="p-2 col-md-2">                          
                  <button type="button"  className="btn btn-primary btn-block " onClick={()=>this.backToSignup()}>Try Again</button>                         
                  </div>
                </div> 
        </div>:null}</div>
        <div>{this.state.nextpage6?<div>
            <br/> <br/> <br/> <br/> <br/> <br/>
         <div className="d-flex justify-content-center">
           <h6 className="textblue">Please Wait for a Few Minutes Your Account Creation is Under Process...</h6>
           </div>
           <br/>
           <div className="d-flex justify-content-center">
           <div className="spinner-grow text-muted spinner-grow-sm"></div>&nbsp;
           <div className="spinner-grow text-primary spinner-grow-sm"></div>&nbsp;
           <div className="spinner-grow text-muted spinner-grow-sm"></div>&nbsp;
           <div className="spinner-grow text-primary spinner-grow-sm"></div>
          </div><br/>
        </div>:null}</div>
      </div>  
     </div>
    
    );
  }

}
