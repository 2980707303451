////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {Button,Modal} from 'react-bootstrap';
import { FaPen, FaTrash } from 'react-icons/fa';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { DateTime } from '../utils/DateTime';
import { Permissions } from '../models/Permissions';
import {Hotels } from '../models/Hotels';
import '../styles/Styles.css';


class HotelsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			id : '',
			edit : true,
			showhotel:false,
			showEdit : false,
			hotelList : [],
			validations:{},
			formObj : Hotels,
			permissions : Permissions
		}
	
		this.onSubmit = this.onSubmit.bind(this);
		this.validate = this.validate.bind(this);
		this.handleHotelname = this.handleHotelname.bind(this);
		this.handleHotelAddress = this.handleHotelAddress.bind(this);
		this.handleHotelCheckin = this.handleHotelCheckin.bind(this);
		this.handleHotelCheckout = this.handleHotelCheckout.bind(this);
		this.handleHotelLink = this.handleHotelLink.bind(this);
		this.handleDeleteRow = this.handleDeleteRow.bind(this);
		this.changeNewHotel = this.changeNewHotel.bind(this);
		this.changeHotel = this.changeHotel.bind(this);
	}

	componentDidMount(){
		let validtemp={};
		for (let key in Hotels) { validtemp[key] = ""; }
		this.setState({validations:validtemp});

		axios.get(base_url+'hotels/listbyeventid/'+this.props.eventid,  this.props.user.header )
				.then(res =>{
                 if(res.data.length > 0){
				 this.setState({ hotelList:res.data });
				 }else{
					 var temp={};
					// axios.post(base_url+'hotels/save', hotelnewform, this.props.user.header ).then(res =>{	}).catch(function (error) { console.log(error); });    
					 temp={id:0,events_id:this.props.eventid,status:'Active',staffname:"<Enter Staff Name>",hoteladdress:"<Enter Hotel Address>",checkin:"12-12-2020",checkout:"12-14-2020",reservationlink:"<Enter Reservation link>"};
					 axios.post(base_url+'hotels/save',temp, this.props.user.header ).then(res =>{
						axios.get(base_url+'hotels/listbyeventid/'+this.props.eventid,  this.props.user.header )
			        	.then(res =>{
							this.setState({hotelList:res.data})
						 }).catch(function (error) { console.log(error); });  
						}).catch(function (error) { console.log(error); });   
				 }
				}).catch(function (error) { console.log(error); });    
	}
	handleHotelModal = () =>{
		let temp={staffname:"",hoteladdress:"",checkin:"",checkout:"",reservationlink:""};
		this.setState({ formObj:temp });
		let temps='';
		for (let key in this.state.validations) { temps += this.state.validations[key];}
	    if(temps !== "" ){
			this.setState({ validations:temp });
		}
		this.setState({ showhotel:!this.state.showhotel });
		
	}
	validate = (val) =>{
		let tempvalid = this.state.validations;
		switch(val){
			case "staffname": { tempvalid.staffname = Validators.name(this.state.formObj.staffname); } break;
			case "hoteladdress": { tempvalid.hoteladdress = Validators.name(this.state.formObj.hoteladdress); } break;
			case "checkin": { tempvalid.checkin = Validators.time(this.state.formObj.checkin); } break;
			case "checkout": { tempvalid.checkout = Validators.time(this.state.formObj.checkout); } break;
			case "reservationlink": { tempvalid.reservationlink = Validators.website(this.state.formObj.reservationlink); } break;
		}
		this.setState({validations:tempvalid});
	}

	changeHotel = (val,e) =>{
		let tempform = this.state.formObj;
		switch(val){
			case "staffname": { var string = e.target.value; tempform.staffname = string.charAt(0).toUpperCase() + string.slice(1);} break;
			case "hoteladdress": {var string = e.target.value; tempform.hoteladdress = string.charAt(0).toUpperCase() + string.slice(1);} break;
			case "checkin": { tempform.checkin = e; } break;
			case "checkout": { tempform.checkout = e; } break;
			case "reservationlink": { tempform.reservationlink = e.target.value; } break;
		}
		this.setState({formObj:tempform});
	}
	changeNewHotel = (row) => {
		if(row != undefined && row >=0 && this.state.hotelList.length >0){
			let temp={id:"",_id:"",staffname:"",hoteladdress:"",checkin:"",checkout:"",reservationlink:""};
			temp.id=this.state.hotelList[row].id;
			temp._id=this.state.hotelList[row]._id;

			if(this.state.hotelList[row].staffname === "<Enter Staff Name>" ){
				temp.staffname="";
			}else{
				temp.staffname=this.state.hotelList[row].staffname;
			}

			if(this.state.hotelList[row].hoteladdress === "<Enter Hotel Address>" ){
				temp.hoteladdress="";
			}else{
				temp.hoteladdress=this.state.hotelList[row].hoteladdress;
			}
			if(this.state.hotelList[row].reservationlink  === "<Enter Reservation link>" ){
				temp.reservationlink ="";
			}else{
				temp.reservationlink=this.state.hotelList[row].reservationlink;
			}
			if(this.state.hotelList[row].checkin  === "12-12-2020" ){
				temp.checkin ="";
			}else{
				temp.checkin=this.state.hotelList[row].checkin;
			}
			if(this.state.hotelList[row].checkout  === "12-14-2020" ){
				temp.checkout ="";
			}else{
				temp.checkout=this.state.hotelList[row].checkout;
			}

			this.setState({formObj:temp});
			}
        this.setState({ showhotel:!this.state.showhotel });
	}
	handleHotelname = (e) =>{
		let newformObj=this.state.formObj;
		var string = e.target.value;
		newformObj.staffname=string.charAt(0).toUpperCase() + string.slice(1);
		this.setState({ formObj:newformObj });
	}
	handleHotelAddress = (e) =>{
		let newformObj=this.state.formObj;
		var string = e.target.value;
		newformObj.hoteladdress = string.charAt(0).toUpperCase() + string.slice(1);
		this.setState({ formObj:newformObj })		
	}
	handleHotelCheckin = (e) =>{
		let newformObj=this.state.formObj;
		newformObj.checkin=e.target.value
		this.setState({ formObj:newformObj })		
	}
	handleHotelCheckout = (e) =>{
		let newformObj=this.state.formObj;
		newformObj.checkout=e.target.value
		this.setState({ formObj:newformObj })
	}
	handleHotelLink = (e) =>{
		let newformObj=this.state.formObj;
		newformObj.reservationlink=e.target.value
		this.setState({ formObj:newformObj })
	}
	handleDeleteRow = (i) => {
		let hotelnewform=this.state.hotelList[i];
		hotelnewform.status='Inactive';
		hotelnewform.events_id=this.props.eventid;
			axios.post(base_url+'hotels/save', hotelnewform, this.props.user.header )
			.then(res =>{
				axios.get(base_url+'hotels/listbyeventid/'+this.props.eventid,  this.props.user.header )
				.then(res =>{
                  this.setState({hotelList:res.data});
				}).catch(function (error) { console.log(error); });    
			}).catch(function (error) { console.log(error); });
	}
	render_Hotellist(){
		if( this.state.hotelList != undefined && this.state.hotelList.length > 0){
		return this.state.hotelList.map((item, index) =>{
			let dt=new Date(item.checkin);
			var day = ("0" + dt.getDate()).slice(-2);
			var month = ("0" + (dt.getMonth() + 1)).slice(-2);
			var year = dt.getFullYear();
			var t = dt.getHours();
			var min=("0" + dt.getMinutes()).slice(-2);
			var _time1 = (t > 12) ? (t-12 + ':' + min +' PM') : (t + ':' + min +' AM');
			let chkin= month+'-'+day+'-'+year+'('+_time1+')';

			let dt1=new Date(item.checkout);
			var day = ("0" + dt1.getDate()).slice(-2);
			var month = ("0" + (dt1.getMonth() + 1)).slice(-2);
			var year = dt1.getFullYear();
			var h = dt1.getHours();
			var m =("0" + dt1.getMinutes()).slice(-2);
			var _time2 = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
			let chkout= month+'-'+day+'-'+year+'('+_time2+')';
		  return (
				<tr key={index} id={index}>
			    <td>{item.staffname}</td>
			     <td>{item.hoteladdress}</td>
				  <td>{chkin}</td>
				  <td>{chkout}</td>
				   <td>{item.reservationlink}
				   <span><FaPen size="15px" color="blue" onClick={()=>{this.changeNewHotel(index)}}/>&nbsp;&nbsp;
				   <FaTrash size="15px" color="red" onClick={() => {this.handleDeleteRow(index)}} />&nbsp;&nbsp;</span></td>            
		        </tr>
		  ) 
		}, this);
	  }
	
	}
	onSubmit(){
		let temp='';
        for (let key in Hotels) { this.validate(key);}
        for (let key in this.state.validations) { temp += this.state.validations[key];}
	    if(temp == "" ){
		let hotelnewform=this.state.formObj;
		hotelnewform.status='Active';
		hotelnewform.events_id=this.props.eventid;
			axios.post(base_url+'hotels/save', hotelnewform, this.props.user.header )
			.then(res =>{
				console.log(res.data.data);
				axios.get(base_url+'hotels/listbyeventid/'+this.props.eventid,  this.props.user.header )
				.then(res =>{
                 console.log(res.data);
				 this.setState({ hotelList:res.data });
				 {this.handleHotelModal()}
				}).catch(function (error) { console.log(error); });    
			}).catch(function (error) { console.log(error); });
		}
	}

	render() {
      return (
			<div id="idhotels">
             <div className="d-flex row">
              <div className="form-group col-md-12">
                <label htmlFor="hotelinfo" className="label-custom">Add Your Hotel Info</label>
                  <table className="table table-bordered table-sm table-responsive-stack ">
                      <thead className="thead">
                          <tr>
                              <td>Staff Name</td>
                              <td>Hotel Address</td>
                              <td>Checked In</td>
                              <td>Checked Out</td>
                              <td>Reservation Link</td>
                          </tr>
                      </thead>
	                	<tbody>{this.render_Hotellist()}</tbody>
                  </table>
			  <div className="p-2 col-md-12"> 
              <div className="d-flex justify-content-end row">
                     <div className="p-2">
                         <button type="button" className="btn btn-primary btn-block btn-sm" onClick={()=>{this.handleHotelModal()}}>+Add</button>
                     </div>
					 </div>
               </div>
			   </div>
              </div>
                 
			<Modal size="lg" show={this.state.showhotel} onHide={()=>{this.handleHotelModal()}} >
            <Modal.Header closeButton>
                  <Modal.Title>Hotel Info</Modal.Title>
                     </Modal.Header>
                          <Modal.Body>
						  <div className="brdcolor">
                            <div className="form-row">
                                 <div className="form-group col-md-5">
                                 <label htmlFor="id_hotelname"  className="label-custom">Staff Name</label>  
                                 <input name="hotelname" id="id_hotelname" type="text" placeholder="Enter Staff Name" className="form-control form-control-sm" value={this.state.formObj.staffname} onChange={(e)=>{this.handleHotelname(e)}} onBlur={()=>{this.validate("staffname")}} style={this.state.validations.staffname==""?{}:{"borderColor":"red"}} />
								 <span className="errormsg">{this.state.validations.staffname}</span>
                              </div>
                                 <div className="form-group col-md-7">
                                 <label htmlFor="idhoteladdress"  className="label-custom">Hotel Address</label>  
                                 <input name="hoteladdress" id="idhoteladdress" type="text" placeholder="Enter Hotel Address" className="form-control form-control-sm" value={this.state.formObj.hoteladdress} onChange={(e)=>{this.handleHotelAddress(e)}} onBlur={()=>{this.validate("hoteladdress")}} style={this.state.validations.hoteladdress==""?{}:{"borderColor":"red"}} />
								 <span className="errormsg">{this.state.validations.hoteladdress}</span>
							  </div>
                            </div>

                           <div className="form-row">
                               <div className="form-group col-md-6">
                                 <label htmlFor="idcheckin"  className="label-custom">Check In</label><br/>
								 <DateTime name="checkin" 
					                       	value={this.state.formObj.checkin}  
					                      	onChange={this.changeHotel}
					                      	class_name={ this.state.validations.checkin==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
					                        onBlur={this.validate}
				                        	/><br/><span className="errormsg">{this.state.validations.checkin}</span>
							  </div>
                              <div className="form-group col-md-6">
                                 <label htmlFor="idcheckout"  className="label-custom">Check Out</label><br/>
								 <DateTime name="checkout" 
					                       	value={this.state.formObj.checkout}  
					                      	onChange={this.changeHotel}
					                      	class_name={ this.state.validations.checkout==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
					                        onBlur={this.validate}
				                        	/><br/> <span className="errormsg">{this.state.validations.checkout}</span>
							  </div>
                         </div>
						 <div className="form-row">
						 <div className="form-group col-md-12">
                                 <label htmlFor="idreservationlink"  className="label-custom">Reservation Link</label>
                                 <input name="reservationlink" id="idreservationlinks" type="text" placeholder="Enter Reservation Link" className="form-control form-control-sm" value={this.state.formObj.reservationlink} onChange={(e)=>{this.handleHotelLink(e)}} onBlur={()=>{this.validate("reservationlink")}} style={this.state.validations.reservationlink==""?{}:{"borderColor":"red"}} />
								 <span className="errormsg">{this.state.validations.reservationlink}</span>
							  </div>
						 </div>
						 </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="warning" onClick={()=>{this.handleHotelModal()}}>
                Close
            </Button>
            <Button variant="primary"  onClick={()=>{this.onSubmit()}}>
                Save
            </Button>
            </Modal.Footer>
      </Modal>
    </div>
)}
		
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(HotelsForm);
