
import { Store } from '../services/Store'

export const getLoggedUser = () => {
  setTimeout(() => {
    Store.dispatch({
      type: 'GET_LOGGED_USER',
      pending: false,
    })
  }, 500)
}

export const login = (values) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      Store.dispatch({
        type: 'SET_LOGGED_USER',
        logged: true,
        pending: false,
        email:values.email,
        name:values.name,
        phone:values.phone,
		    header:{'headers': {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+values.token}},
        userid:values.userid,
        groupid:values.groupid,
        accountid:values.accountid,
      })
      resolve()
    }, 500)
  })
}

export const logout = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      Store.dispatch({
        type: 'SET_LOGGED_USER',
        logged: false,
        pending: true,
        email:'',
        name:'',
        phone:'',
		    header:'',
        userid:'',
        groupid:'',
        accountid:'',
      })
      resolve()
    }, 500)
  })
}