import React, {Component} from 'react';
import axios from 'axios';
import { base_url } from '../constants/Constants';
import flashMessage from '../utils/Flash';
import { Validators } from '../utils/Validators';
import {Modal,Button} from 'react-bootstrap';
import FlashMessage from 'react-flash-message'
import queryString from 'query-string';
import Exhibitorlogo from '../assets/exhibitor_logo.png';

export default class ResetPassword extends React.Component {
  constructor(props){
    super(props);
      this.state = { 
        newpassword : '',
        confirmpwd : '',      
        showSuccessMsg : false,      	
        message:'',
        msgType:'',
        showpopup:'',
      };     
    }
    componentDidMount(){
     
    }
  
  newpasswordChange = (e) =>{   
    this.setState({ newpassword:e.target.value});
  }

  confirmpwdChange = (e) =>{   
    this.setState({ confirmpwd:e.target.value});
  }
  
  backToLogin = () => {
    this.props.history.push('/login');
  }
  openPopup =() =>{
    this.setState({showpopup:!this.state.showpopup});
  }



  resetPassword = () => {
   if(this.state.newpassword !== "" && this.state.confirmpwd !== "" ){
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if(this.state.newpassword.match(passw)){
    if(this.state.newpassword === this.state.confirmpwd){
      let url = this.props.location.search;
      let params = queryString.parse(url);                   
      var formObj = {resetcode:params.id, newpassword:this.state.newpassword};           
      axios.post(base_url+'main/submitpass', formObj)
          .then(res => { 
              this.setState({msgType:'THANK YOU', message:'Your account password updated successfully, You can login with new password.'});
              //alert("Your account password updated successfully")
              this.openPopup();
              setTimeout(this.backToLogin, 8000);
      }).catch(function (error) { 
          console.log(error);          
      });
      setTimeout(this.backToLogin,5000);  
    }else{                    
        this.setState({  msgType:'WARNING !!!',message:'Confirm password is not matching with password...'}); 
        this.openPopup();
    }
  }else{
    this.setState({ msgType:'WARNING !!!',message:'Password should be 6 to 20 characters.Including UPPER/LOWER Case and Number'}); 
    this.openPopup();
  }
}else{
  this.setState({ msgType:'WARNING !!!',message:'Please Enter Both New Password and Confirm Password'}); 
  this.openPopup();
  }
  }
    
  render() {
    return (
      <div className="container">
        <div className="brdcolor" >
        <br/><br/><br/><br/><br/><br/><br/>     
        <div className="row"> 
          <div className="col-md-4">
          </div>         
          <div className="col-md-4">
              <div>
                {this.state.showSuccessMsg ?
                  <div>
                    <FlashMessage duration={5000} persistOnHover={true}>
                      {flashMessage(this.state.message, this.state.msgType)}
                    </FlashMessage>	
                  </div>
                :null
                }
              </div> 
              <div className="d-flex justify-content-center">
                <span onClick={() =>this.backToLogin()}><img  src={Exhibitorlogo} alt="Logo" /></span>
                </div><br/>             
              <div className="row">
                  <div className="col-md-12 text-center loginsignintxt">                               
                      <span className="wlcmcolor"><b>Reset Password</b></span>                             
                  </div>                            
              </div>
              <br/>              
              <div className="row">
                <div className="col-md-12">                 
                  <div className="form-group">                      
                      <input type="password" className="form-control" id="newpassword" name="newpassword" value={this.state.newpassword} placeholder="New Password"  required onChange={this.newpasswordChange}/>
                      <div className="valid-feedback">Valid.</div>
                      <div className="invalid-feedback">Please fill out this field.</div>
                  </div>
                  <div className="form-group">                      
                      <input type="password" className="form-control" id="confirmpwd" name="confirmpwd" value={this.state.confirmpwd} placeholder="Confirm Password" required onChange={this.confirmpwdChange}/>
                      <div className="valid-feedback">Valid.</div>
                      <div className="invalid-feedback">Please fill out this field.</div>
                  </div>
                </div>              
              </div> 

              <div className="row">                
                <div className="col-md-12 text-right">                               
                  <button type="button" className="btn btn-primary btn-block" onClick={() =>this.resetPassword()}>Submit</button>                           
                </div>
              </div>
              <br/>                                
            </div>
            <div className="col-md-4">

            </div>
            <Modal show={this.state.showpopup} onHide={this.openPopup}  size="sm"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered>
                <Modal.Body>
                <br/><br/>
                <div className="d-flex justify-content-center row">
              <h5><strong className="textblue">{this.state.msgType}</strong> </h5> 
              <br/>
              <br/> 
              </div>
              <div className="d-flex justify-content-center row">
              <div className="p-2"><h6>{this.state.message}</h6></div>
              </div>
              <br/>
              <br/>
                <div className="d-flex justify-content-center row">
               <Button variant="primary" onClick={this.openPopup}>OK</Button>
               </div>
                </Modal.Body>
              </Modal>
        </div>
        </div>     
      </div>
    );
  }
}

