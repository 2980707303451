///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Propeerty of DeemsoftGlobal Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Postevent = {
	followups : [], 
	actions_items : '', 
	ratings : '', 
	event_success_reasons : '', 
	repeat_event : '', 
	status : '', 
	notes : '', 
	eventsId : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}